import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import i18n from 'i18next'

import { RootState } from '@store/rootReducer'
import {
  selectCanvasSelectedObjectPk,
  selectCanvasSelectedObjectProperty
} from '@store/slices/shapes/selectors'

import DefaultDataSelector from '@/components/imagemap/right-panel/data-source/'
import TextDataSelector from '@/components/imagemap/right-panel/data-source/TextDataSelector'
import { OBJECTS_WITH_DATA } from '@components/canvas'
import EmptyElement from '@components/imagemap/right-panel/EmptyElement'
import ElementWithErrors from '@components/imagemap/right-panel/ElementWithErrors'

import './DataSource.less'

const DataSelector = () => {
  const selectedItemId = useSelector(selectCanvasSelectedObjectPk)
  //@ts-ignore
  const type = useSelector((s: RootState) => selectCanvasSelectedObjectProperty(s, 'type'))
  //@ts-ignore
  const errors = useSelector((s: RootState) => selectCanvasSelectedObjectProperty(s, 'errors'))
  const [hasErrors, setHasErrors] = useState(false)

  useEffect(() => {
    if (Array.isArray(errors) && errors.length) {
      setHasErrors(true)
    } else {
      setHasErrors(false)
    }
  }, [errors])

  if (!selectedItemId) {
    return <EmptyElement title={i18n.t('editor.right-panel.node.nothing-selected')} />
  }

  if (hasErrors) {
    return <ElementWithErrors title="A problem occurred with the shape's data configuration" />
  }

  if (!OBJECTS_WITH_DATA.includes(type)) {
    return <EmptyElement title={i18n.t('editor.right-panel.data-source.no-element')} />
  }

  return {
    textbox: <TextDataSelector />,
    chart: <DefaultDataSelector />,
    table: <DefaultDataSelector />
  }[type]
}

export default DataSelector
