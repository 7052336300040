import { ChartData, ChartType } from 'chart.js'

import { ValueAxis } from '../types/chart'

export function mapChartTypes(chartType: string): [ChartType, string | null] {
  switch (chartType) {
    case 'COLUMN_STACKED':
      return ['bar', 'stacked']
    case 'COLUMN_STACKED_100':
      return ['bar', 'stacked100']
    case 'BAR_CLUSTERED':
      return ['horizontalBar', null]
    case 'BAR_STACKED':
      return ['horizontalBar', 'stacked']
    case 'BAR_STACKED_100':
      return ['horizontalBar', 'stacked100']
    case 'COLUMN_CLUSTERED':
    case 'BAR_OF_PIE':
      return ['bar', null]
    case 'DOUGHNUT':
    case 'DOUGHNUT_EXPLODED':
      return ['doughnut', null]
    case 'PIE_EXPLODED':
    case 'PIE_OF_PIE':
    case 'PIE':
      return ['pie', null]
    case 'RADAR_MARKERS':
    case 'RADAR_FILLED':
    case 'RADAR':
      return ['radar', null]
    case 'LINE_MARKERS':
      return ['line', null]
    case 'LINE_STACKED_100':
      return ['line', 'stacked100']
    case 'LINE_STACKED':
      return ['line', 'stacked']
    case 'LINE_MARKERS_STACKED':
    case 'LINE_MARKERS_STACKED_100':
    case 'LINE':
      return ['line', null]
    case 'XY_SCATTER_LINES':
      return ['scatter', null]
    case 'AREA':
    case 'AREA_STACKED':
    case 'AREA_STACKED_100':
      return ['line', 'area']
    default:
      return [null, null]
  }
}

export function splitNames(name: string) {
  const words = name.split(' ')
  const multiLineNames = []
  const LINE_LENGTH = 20
  let wordAcc = ''
  words.forEach(word => {
    if (wordAcc.length + word.length < LINE_LENGTH) {
      wordAcc = `${wordAcc} ${word}`
    } else {
      if (wordAcc) {
        multiLineNames.push(wordAcc)
      }
      wordAcc = word
    }
  })
  if (wordAcc) {
    multiLineNames.push(wordAcc)
  }
  return multiLineNames
}

export function calcOffsetAndAlign(
  position: 'top' | 'left' | 'right' | 'bottom',
  baseAngle: 0 | -90,
  height: number,
  margin: number = 4
): [number, number] {
  let [offset, angle] = [0, 0]
  offset = Math.sqrt(margin ** 2 + height ** 2)
  angle = (Math.asin(margin / offset) * 180) / Math.PI
  if (baseAngle === -90) {
    switch (position) {
      case 'left':
        return [height + margin, baseAngle - 52.5]

      case 'right':
        return [height + margin, baseAngle + 52.5]

      case 'top':
        return [height + 10, baseAngle]

      case 'bottom':
        return [height - 10, baseAngle]
    }
  }

  if (baseAngle === 0) {
    switch (position) {
      case 'left':
        return [height - margin, baseAngle]

      case 'right':
        return [height + margin, baseAngle]

      case 'top':
        return [offset, baseAngle - angle]

      case 'bottom':
        return [offset, baseAngle + angle]
    }
  }

  return [offset, angle]
}

/**
 * Find `suggestedMin` and `suggestedMax` based on the chart conf or data range.
 * @returns {[number, number]} tuple where the first value is min scale, the 2nd is max scale.
 */
export function findValueAxisMinMax(
  valueAxis: ValueAxis,
  data: ChartData,
  margin = 0.1
): [number, number] {
  if (!valueAxis) {
    return findDataMinMax(data, margin)
  }
  const { maximumScale, minimumScale } = valueAxis
  if (maximumScale !== null && minimumScale !== null) {
    return [maximumScale * 100, minimumScale * 100]
  }
  const [dataMin, dataMax] = findDataMinMax(data, margin)
  const min = minimumScale !== null ? minimumScale : dataMin
  const max = maximumScale !== null ? maximumScale : dataMax
  return [min, max]
}

/**
 * Find min and max for suggestedMin and suggestedMax ticks' properties based on data.
 * @param {ChartData} data
 * @param {number} [margin=0]
 * @return {[number, number]}
 */
export function findDataMinMax(
  data: ChartData | undefined,
  margin: number = 0.1
): [number, number] {
  if (data == null) return [0, 0]
  let min = 0
  let max = 0
  data.datasets.forEach(dataset => {
    dataset.data.forEach(value => {
      if (value < min) min = value
      if (value > max) max = value
    })
  })
  return [Math.ceil((1 + margin) * min), Math.ceil((1 + margin) * max)]
}

export function mapLegendPosition(position: string): string {
  const chartJsLabelPositions = ['top', 'left', 'bottom', 'right', 'chartArea']
  if (chartJsLabelPositions.includes(position)) {
    return position
  } else {
    return 'top'
  }
}
