export interface IAuthState {
  isAuthenticated: boolean
  accessToken: Token | null
  refreshToken: Token | null
  currentUser: User | null
  signInReq: RequestInformation
  mfaSignInReq: RequestInformation
  signUpReq: RequestInformation
  getCurrentUserReq: RequestInformation
}

export interface Token {
  type: TokenTypes
  key: string
  payload: TokenPayload
}

export enum TokenTypes {
  ACCESS = 'access',
  REFRESH = 'refresh'
}

export interface RequestInformation {
  loading: boolean
  error: string | null
}

export interface TokenPayload {
  token_type: TokenTypes
  exp: number
  jti: string
  userId: number
}

export interface User {
  [x: string]: any
  company: number
  email: string
  id: number
  isMfaActivated: boolean
  license: string
  mfaCodeExpiredAt: string
  mfaCodeIssuedAt: string
  name: string
  isSuperuser: boolean
  isStaff: boolean
  experimentalFeaturesEnabled?: boolean
}
