import axios, { CancelToken } from 'axios'

import { AddProjectTemplateResponse, DownloadProjectTemplateResponse } from '@/interfaces/template'

const API_URL = '/api/projects'

//note much of the logic in the app assumed a single template
//because of that there is still a single primary template in the backend
//the first one that was uploaded
//therefore we use that for stuff such as downloading and getting image assets

export async function addProjectTemplate(
  { projectId, fileName }: { projectId: number; fileName: string },
  cancelToken?: CancelToken
): Promise<AddProjectTemplateResponse> {
  const response = await axios.post<AddProjectTemplateResponse>(
    `${API_URL}/${projectId}/template/`,
    {
      filename: fileName
    },
    {
      cancelToken
    }
  )
  return response.data
}

export async function deleteProjectTemplate({
  projectId,
  templateId
}: {
  projectId: number
  templateId: number
}): Promise<void> {
  await axios.delete<AddProjectTemplateResponse>(`${API_URL}/${projectId}/template/${templateId}/`)
}

export async function appendProjectTemplate(
  {
    projectId,
    fileName,
    actionType
  }: {
    projectId: number
    fileName: string
    actionType: string
  },
  cancelToken?: CancelToken
): Promise<AddProjectTemplateResponse> {
  const response = await axios.post<AddProjectTemplateResponse>(
    `${API_URL}/${projectId}/template/`,
    {
      filename: fileName,
      actionType: actionType
    },
    {
      cancelToken
    }
  )
  return response.data
}

export async function downloadProjectTemplate(
  projectId: number,
  templateId: number
): Promise<DownloadProjectTemplateResponse> {
  const response = await axios.post<DownloadProjectTemplateResponse>(
    `${API_URL}/${projectId}/template/${templateId}/download/`
  )
  return response.data
}

export async function downloadSlideProjectTemplate(
  projectId: number,
  templateId: number,
  slideIds: number[]
): Promise<DownloadProjectTemplateResponse> {
  const response = await axios.post<DownloadProjectTemplateResponse>(
    `${API_URL}/${projectId}/template/${templateId}/slide/`,
    { slideIds: slideIds }
  )
  return response.data
}

export async function downloadReport(
  projectId: number,
  templateId: number,
  dataSourceId: number,
  columnIndex: number
): Promise<DownloadProjectTemplateResponse> {
  const response = await axios.post<DownloadProjectTemplateResponse>(
    `${API_URL}/${projectId}/template/${templateId}/appendix_report/`,
    { dataId: dataSourceId, columnIndex: columnIndex, reportFormatType: 'bar_chart' }
  )
  return response.data
}

export async function getTemplateAsset(
  projectId: number,
  templateId: number,
  key: string,
  cancelToken?: CancelToken
): Promise<any> {
  const response = await axios.get<any>(
    `${API_URL}/${projectId}/template/${templateId}/get_asset/?key=${key}`,
    {
      cancelToken,
      responseType: 'blob'
    }
  )
  return response.data
}
