import axios, { AxiosResponse } from 'axios'

import { LogsMessage } from '@/interfaces/logs'

const API_URL = '/api'

export async function sendErrorMessage({
  message,
  currentUser,
  stack,
  projectId,
  slideId,
  shapeId
}): Promise<string> {
  const url = `${API_URL}/logs/`
  const response = await axios.post(url, {
    message,
    currentUser,
    stack,
    projectId,
    slideId,
    shapeId
  })
  return response.data
}
