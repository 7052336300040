import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Spin, Form, Select, Space } from 'antd'

import { updateShapeData } from '@/store/slices/shapes/actions'
import {
  selectCanvasSelectedObject,
  selectCanvasSelectedObjectProperty,
  selectInterpolationData,
  selectShapeData
} from '@store/slices/shapes/selectors'

import {
  updateDataTransformation,
  updateTextInterpolation
} from '@/services/data-transfomations-service'

import '../styles.less'
import { useForm } from 'antd/lib/form/Form'
import { RootState } from '@/store/rootReducer'
import { IInterpolation } from './types'

import i18n from 'i18next'

const shapeDataSelectionStrategies = [
  { label: 'By column position', value: 'by_column_position' },
  { label: 'By absolute position', value: 'by_absolute_position' },
  { label: 'By column name', value: 'by_name' },
  { label: 'By column position in group', value: 'by_parent_group' },
  { label: 'All columns by group position', value: 'by_group_inclusion' },
  { label: 'All columns by group name', value: 'by_group_name' }
]

const interpolationStrategies = [
  { label: 'By absolute position', value: 'by_absolute_position' },
  { label: 'By column position', value: 'by_column_position' },
  { label: 'By column name', value: 'by_name' },
  { label: 'By column position in group', value: 'by_parent_group' }
]

interface IFormDataSelectionStrategy {
  strategy: string
}

interface IFormInterpolationStrategy {
  strategy: string
  tag: string
}

const AutomationProperty = () => {
  //@ts-ignore
  const type: string = useSelector((s: RootState) => selectCanvasSelectedObjectProperty(s, 'type'))
  return (
    <>
      {(type === 'table' || type === 'chart') && <ShapeDataSelectionStrategy />}
      {type === 'textbox' && <InterpolationStrategy />}
    </>
  )
}

const ShapeDataSelectionStrategy = () => {
  const dispatch = useDispatch()
  const [form] = useForm<IFormDataSelectionStrategy>()
  const selectedObject = useSelector(selectCanvasSelectedObject)
  const { dataSelection } = useSelector(selectShapeData)
  const [strategyLoading, setStrategyLoading] = useState(false)
  const currentSelectionStrategy = useMemo(() => dataSelection?.selectionStrategy, [dataSelection])
  const hasData = dataSelection != null

  const onStrategyChange = async value => {
    setStrategyLoading(true)
    try {
      await updateDataTransformation(dataSelection.id, { selectionStrategy: value })
      dispatch(
        updateShapeData(selectedObject.id, { ...selectedObject.data, selectionStrategy: value })
      )
    } catch (e) {
      console.error('Failed to change selection strategy. Log:', e)
    }
    setStrategyLoading(false)
  }

  useEffect(() => {
    form.setFieldsValue({
      strategy: currentSelectionStrategy || (hasData ? 'by_column_position' : null)
    })
  }, [currentSelectionStrategy])

  return (
    <Spin spinning={strategyLoading}>
      <Form form={form}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form.Item name="strategy" label="Data selection strategy:">
            <Select
              placeholder="Change strategy"
              onChange={onStrategyChange}
              loading={strategyLoading}
              disabled={strategyLoading}
              options={hasData ? shapeDataSelectionStrategies : []}
            />
          </Form.Item>
        </Space>
      </Form>
    </Spin>
  )
}

const InterpolationStrategy = () => {
  const dispatch = useDispatch()
  const [form] = useForm<IFormInterpolationStrategy>()
  const selectedObject = useSelector(selectCanvasSelectedObject)
  const interpolation: IInterpolation = selectedObject.data?.interpolation || {}
  const [currentTag, setCurrentTag] = useState(null)
  const { dataSelection } = useSelector(state => selectInterpolationData(state, currentTag))
  const [strategyLoading, setStrategyLoading] = useState(false)
  const currentReorderingStrategy = useMemo(
    () => dataSelection?.reorderingStrategy,
    [dataSelection, currentTag]
  )
  const hasData = dataSelection != null

  const onChangeTag = value => {
    setCurrentTag(value)
  }

  const onStrategyChange = async value => {
    setStrategyLoading(true)
    try {
      await updateTextInterpolation(dataSelection.id, { reorderingStrategy: value })
      dispatch(
        updateShapeData(selectedObject.id, {
          ...selectedObject.data,
          interpolation: {
            ...selectedObject.data.interpolation,
            [currentTag]: {
              ...selectedObject.data.interpolation[currentTag],
              reorderingStrategy: value
            }
          }
        })
      )
    } catch (e) {
      console.error('Failed to change selection strategy. Log:', e)
    }
    setStrategyLoading(false)
  }

  useEffect(() => {
    form.setFieldsValue({ tag: currentTag })
    form.setFieldsValue({
      strategy: currentReorderingStrategy || (hasData ? 'by_absolute_position' : null)
    })
  }, [currentTag, currentReorderingStrategy])

  useEffect(() => {
    if (currentTag === null) {
      let tags = Object.keys(interpolation)
      if (tags.length > 0) {
        setCurrentTag(tags.find(el => el === selectedObject.text.replace(/[{}]/g, '')) || tags[0])
      }
    }
  }, [])

  return (
    <Spin spinning={strategyLoading}>
      <Form form={form}>
        <Space direction="vertical" style={{ maxWidth: '100%' }}>
          <Form.Item
            name="tag"
            label={i18n.t('editor.right-panel.shape-settings.select-interpolation')}
            required={true}
          >
            <Select onChange={onChangeTag} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {Object.entries(interpolation).map(([id, item]) => (
                <Select.Option value={id} key={id}>
                  {id} - {item.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Space>

        <Space direction="vertical" style={{ width: '100%' }}>
          <Form.Item name="strategy" label="Data selection strategy:">
            <Select
              placeholder="Change strategy"
              onChange={onStrategyChange}
              loading={strategyLoading}
              disabled={strategyLoading}
              options={hasData ? interpolationStrategies : []}
            />
          </Form.Item>
        </Space>
      </Form>
    </Spin>
  )
}

export default AutomationProperty
