import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectAccessToken, selectRefreshToken } from '@store/slices/auth/selectors'
import { refreshToken } from '@store/slices/auth/thunks'

export function useTokenRefresh() {
  const access = useSelector(selectAccessToken)
  const refresh = useSelector(selectRefreshToken)
  const dispatch = useDispatch()

  useEffect(() => {
    if (access && refresh) {
      const timeTillExpiration =
        new Date(access.payload.exp * 1000).getTime() - new Date().getTime()
      const timeout = setTimeout(() => {
        const isRefreshTokenValid =
          new Date(refresh.payload.exp * 1000).getTime() - new Date().getTime() >= 0
        if (isRefreshTokenValid) {
          dispatch(refreshToken({ refresh: refresh.key }))
        }
      }, timeTillExpiration)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [access, dispatch, refresh])
}
