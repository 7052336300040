import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'antd'

import {
  selectCanvasSelectedObject,
  selectShapeStoredConfig
} from '@/store/slices/shapes/selectors'
import { clearCanvasSelectedObject, setStoredConfig } from '@store/slices/shapes/actions'

import CopyShapeConfModal from './CopyShapeConfModal'

export default function CopyShapeConf() {
  const dispatch = useDispatch()
  const selectedShape = useSelector(selectCanvasSelectedObject)
  const storedShape = useSelector(selectShapeStoredConfig)
  const disabled = storedShape == null && selectedShape == null
  const baseShapeId = storedShape != null ? (storedShape.id as number) : null
  const targetShapeId =
    storedShape != null && selectedShape != null && selectedShape.pk !== storedShape.id
      ? selectedShape.pk
      : null
  const onClick = () => {
    if (storedShape == null && selectedShape != null) {
      dispatch(setStoredConfig({ id: selectedShape.pk }))
    } else {
      dispatch(setStoredConfig(null))
    }
  }

  const onCancel = () => dispatch(setStoredConfig(null))
  const onConfirm = () => {
    dispatch(setStoredConfig(null))
    dispatch(clearCanvasSelectedObject())
  }

  return (
    <>
      <Button onClick={onClick} disabled={disabled} type="text">
        {storedShape == null ? 'Copy data transformations' : 'Select target shape'}
      </Button>
      <CopyShapeConfModal
        baseShapeId={baseShapeId}
        targetShapeId={targetShapeId}
        visible={baseShapeId !== null && targetShapeId !== null}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    </>
  )
}
