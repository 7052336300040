import { useEffect, useState } from 'react'

import { Form, InputNumber, Select, Space } from 'antd'
import { useForm } from 'antd/es/form/Form'

import i18n from 'i18next'

import CommonModal from '@/components/common/CommonModal'
import Info from '@/components/common/Info'
import MultiSelectDropdown from '@/components/common/MultiSelectDropdown'

import { ActionProps, ActionTypes, FilterAction } from './types'

export enum FilterTypes {
  Above = 'above',
  Below = 'below',
  Between = 'between'
}

export interface FilterProps {
  isVisible: boolean
  onSubmit: (formValues: Omit<FilterAction, 'shapeDataSelection'>) => void
  onCancel: () => void
  initialValues?: Partial<FilterAction>
}

export type FormValues = {
  axis: any
  by: string[]
  ascending: boolean
  filterType: FilterTypes.Above
  above?: number
  below?: number
}

const Filter = ({
  rows,
  columns,
  onSubmit,
  onCancel,
  isVisible,
  initialValues
}: ActionProps<FilterAction>) => {
  const [form] = useForm<FormValues>()

  const [byLength, setByLength] = useState(0)
  const [labelsVisible, setLabelsVisible] = useState(false)
  const filterNames = {
    [FilterTypes.Above]: i18n.t(
      'editor.right-panel.data-source.form.data-transformation.filter.above'
    ),
    [FilterTypes.Below]: i18n.t(
      'editor.right-panel.data-source.form.data-transformation.filter.below'
    ),
    [FilterTypes.Between]: i18n.t(
      'editor.right-panel.data-source.form.data-transformation.filter.between'
    )
  }
  const [currentAxis, setCurrentAxis] = useState<0 | 1>(0)

  const submitForm = () => {
    const formValues = form.getFieldsValue(true)
    const { filterType, by, axis, ...other } = formValues
    const payload = {
      actionType: ActionTypes.FILTER,
      by,
      axis,
      specs: { filter_type: filterType, ...other }
    }

    onSubmit(payload)
  }

  const onAxisChange = (value: 0 | 1) => {
    //form.resetFields()
    form.setFieldsValue({ axis: value, by: [] })
    setByLength(0)
    setCurrentAxis(value)
  }

  const onByChange = (value: number[]) => {
    form.setFieldsValue({ by: value })
    setByLength(form.getFieldsValue(true).by.length)
  }

  useEffect(() => {
    form.setFieldsValue({
      axis: 0,
      by: [],
      filterType: FilterTypes.Above,
      above: 0,
      below: 0,
      ...initialValues
    })
  }, [form, initialValues])

  const popoverContent = (
    <div>
      <strong>Function</strong>
      <p>
        Remove columns or rows from the selected object which contain values that are above,
        <br />
        below or in between the level set in the rule. The rule works by filtering in columns or
        rows.
        <br />
        Therefore, setting a value above five will remove columns/rows which contain values below
        <br /> this level
      </p>
      <strong>How to use</strong>
      <p>[Filter]: Select to filter out Columns or Rows from the object</p>
      <p>[From the following…]: Select the column(s)/row(s) to search for values</p>
      <p>[Which includes values…]: Select to filter in values Above, Below or In between</p>
      <p>[Above/Below/In between]: Input numerical values.</p>
    </div>
  )

  return (
    <CommonModal
      title={
        <span>
          Filter <Info>{popoverContent}</Info>
        </span>
      }
      visible={isVisible}
      onCancel={onCancel}
      onOk={submitForm}
      okDisabled={byLength <= 0}
    >
      <Form form={form} onFinish={submitForm} layout="vertical">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form.Item
            name="axis"
            rules={[{ required: true }]}
            label={i18n.t('editor.right-panel.data-source.form.data-transformation.actions.filter')}
          >
            <Select<string | number, { value: string; children: string }>
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={onAxisChange}
            >
              <Select.Option value={0}>
                {i18n.t('editor.right-panel.data-source.form.columns')}
              </Select.Option>

              <Select.Option value={1}>
                {i18n.t('editor.right-panel.data-source.form.rows')}
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            rules={[{ required: true }]}
            label={i18n.t(
              `editor.right-panel.data-source.form.data-transformation.${
                currentAxis === 1 ? 'from-columns' : 'from-rows'
              }`
            )}
            shouldUpdate={(prevValues, curValues) => prevValues.axis !== curValues.axis}
          >
            {({ getFieldValue }) => {
              const axis = getFieldValue('axis')
              const options = axis === 0 ? rows : columns
              return (
                <Form.Item name="by">
                  <MultiSelectDropdown
                    onChange={onByChange}
                    visible={labelsVisible}
                    setVisible={setLabelsVisible}
                    dropdownSelect={() => {
                      form.setFieldsValue({ by: options.map(({ index }) => index) })
                      setByLength(form.getFieldsValue(true).by.length)
                    }}
                    dropdownClear={() => {
                      form.setFieldsValue({ by: [] })
                      setByLength(form.getFieldsValue(true).by.length)
                    }}
                    dropdownOk={() => setLabelsVisible(false)}
                    dropdownCancel={() => {
                      form.setFieldsValue({ by: [] })
                      setLabelsVisible(false)
                    }}
                  >
                    {options.map(({ name, index }) => (
                      <Select.Option value={index} key={index}>
                        {name}
                      </Select.Option>
                    ))}
                  </MultiSelectDropdown>
                </Form.Item>
              )
            }}
          </Form.Item>

          <Form.Item
            name="filterType"
            label={i18n.t(
              'editor.right-panel.data-source.form.data-transformation.filter.include-values'
            )}
          >
            <Select<string | number, { value: string; children: string }>
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {Object.keys(FilterTypes).map((key, index) => (
                <Select.Option value={FilterTypes[key]} key={index}>
                  {filterNames[FilterTypes[key]]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            shouldUpdate={(prevValues, curValues) => prevValues.filterType !== curValues.filterType}
          >
            {({ getFieldValue }) => {
              const filterType = getFieldValue('filterType')
              return {
                [FilterTypes.Above]: (
                  <>
                    <Form.Item
                      name="above"
                      rules={[{ required: true, message: 'Enter value' }]}
                      label={i18n.t(
                        'editor.right-panel.data-source.form.data-transformation.filter.above'
                      )}
                    >
                      <InputNumber />
                    </Form.Item>
                  </>
                ),
                [FilterTypes.Below]: (
                  <>
                    <Form.Item
                      name="below"
                      rules={[{ required: true, message: 'Enter value' }]}
                      label={i18n.t(
                        'editor.right-panel.data-source.form.data-transformation.filter.below'
                      )}
                    >
                      <InputNumber />
                    </Form.Item>
                  </>
                ),
                [FilterTypes.Between]: (
                  <Space>
                    <Form.Item
                      name="above"
                      rules={[{ required: true, message: 'Enter value' }]}
                      label={i18n.t(
                        'editor.right-panel.data-source.form.data-transformation.filter.above'
                      )}
                    >
                      <InputNumber />
                    </Form.Item>

                    <Form.Item
                      name="below"
                      rules={[{ required: true, message: 'Enter value' }]}
                      label={i18n.t(
                        'editor.right-panel.data-source.form.data-transformation.filter.below'
                      )}
                    >
                      <InputNumber />
                    </Form.Item>
                  </Space>
                )
              }[filterType]
            }}
          </Form.Item>
        </Space>
      </Form>
    </CommonModal>
  )
}

export default Filter
