import { Select } from 'antd'

import DropDownMenu from '../imagemap/right-panel/data-source/DropDownMenu'

const MultiSelectDropdown = props => {
  const {
    visible,
    setVisible,
    dropdownOk,
    dropdownCancel,
    dropdownClear,
    dropdownSelect,
    align,
    ...otherProps
  } = props
  return (
    <Select<string | number, { value: string; children: string }>
      showSearch
      mode={'multiple'}
      optionFilterProp="children"
      autoClearSearchValue={false}
      filterOption={(input, option) => {
        //returns true or false for each option if any of comma-separated input values match partially
        const allInputs = input.split(',')
        const optionsArr = allInputs.map(
          input => option!.children.trim().toLowerCase().indexOf(input.trim().toLowerCase()) >= 0
        )

        return optionsArr.includes(true)
      }}
      dropdownRender={menu => (
        <DropDownMenu
          menu={menu}
          onSelect={props.dropdownSelect}
          onClear={props.dropdownClear}
          onOk={props.dropdownOk}
          onCancel={props.dropdownCancel}
        />
      )}
      dropdownMatchSelectWidth={false}
      dropdownStyle={
        props.align != 'left'
          ? {
              display: props.visible ? 'inline-block' : 'none'
            }
          : {
              display: props.visible ? 'inline-block' : 'none',
              width: 'fit-content',
              maxWidth: '33%',
              minWidth: '15%'
            }
      }
      dropdownAlign={
        props.align != 'left'
          ? undefined
          : {
              points: ['tr', 'tl'], //align dropdown's top-right to top-left of input element
              offset: [-5, 0] //align offset
            }
      }
      onDropdownVisibleChange={bool => props.setVisible(bool)}
      {...otherProps}
    >
      {props.children}
    </Select>
  )
}

export default MultiSelectDropdown
