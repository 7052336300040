export enum AxisValues {
  ROWS = 'rows',
  COLUMNS = 'columns'
}

export enum SortOrientation {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum SigTestTypes {
  allVsAll = 'all_vs_all',
  controlGroup = 'control_group',
  nextDataPoint = 'next_data_point',
  previousDataPoint = 'previous_data_point'
}

export interface BaseSigTestingRequest {
  analysisType: SigTestTypes
  axis: AxisValues
  baseMinimum: number
  confidence: number
  useFormattedData?: boolean
}

export interface AllVsAllSigTestingRequest extends BaseSigTestingRequest {
  excludeAxisValues?: string[]
}

export interface ControlGroupSigTestingRequest extends BaseSigTestingRequest {
  controlGroup: string
  testGroups: string[]
}

export interface AllVsAllSigTestingDto {
  id: number
  analysisType: SigTestTypes
  axis: AxisValues
  exclude: string[]
  baseMinimum: number
  confidence: number
  dataId: number
  sectionId: number
  table: number
  isSignificant: boolean
  useFormattedData?: boolean
}

export interface ControlGroupSigTestingDto {
  id: number
  analysisType: SigTestTypes
  axis: AxisValues
  controlGroup: string
  testGroup: string[]
  baseMinimum: number
  confidence: number
  dataId: number
  sectionId: number
  table: number
  isSignificant: boolean
  useFormattedData?: boolean
}

export interface ISignificanceTest {
  params: {
    columns?: {
      [key: string]: string
    }
    rows?: {
      [key: string]: string
    }
  }
  values: {
    [rowName: string]: {
      [columnName: string]: string | null
    }
  }
}

export type SigTestingRequest = AllVsAllSigTestingRequest | ControlGroupSigTestingRequest
export type TSigTesting<T extends SigTestTypes> = T extends SigTestTypes.controlGroup
  ? ControlGroupSigTestingDto
  : AllVsAllSigTestingDto
