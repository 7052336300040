import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'antd'
import type { RadioChangeEvent } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

import { FileSyncOutlined } from '@ant-design/icons'
import i18n from 'i18next'

import { selectCurrentProjectId } from '@/store/slices/projects/selectors'
import { refreshSlide } from '@/store/slices/slides/actions'

import { switchDataSource } from '@/services/data-service'

import CommonModal from '@/components/common/CommonModal'

import { DataSourceDto } from '@/interfaces/data'

import SelectDataTarget from './SelectDataTarget'
import WarningMessage from './WarningMessage'

import '../styles.less'

import { clearCanvasSelection } from '@/store/slices/shapes/actions'

import CompatibilityReport, { ReportEntry } from '@/components/imagemap/main/CompatibilityReport'

import CompatibilityFeedback from './CompatibilityReport'

type SwitchDataProps = {
  dataSource: DataSourceDto
}

export interface ICompatibilityReport {
  compatibility: number
  records: Record<number, ReportEntry>
}

const SwitchData = ({ dataSource }: SwitchDataProps) => {
  const projectId = useSelector(selectCurrentProjectId)
  const [targetSourceId, setTargetSourceId] = useState<number>(null)
  const [report, setReport] = useState<ICompatibilityReport>(null)
  const [step, setStep] = useState<0 | 1 | 2 | 3>(0)
  const [isVisible, setIsVisible] = useState(false)
  const [globalStrategy, setGlobalStrategy] = useState(false)
  const [reindexingStrategy, setReindexingStrategy] = useState<
    'by_name' | 'by_parent_group' | 'by_group_inclusion' | null
  >(null)
  const [matchingOption, setMatchingOption] = useState(0)
  const dispatch = useDispatch()

  const resetState = () => {
    setStep(0)
    setTargetSourceId(null)
    setIsVisible(false)
    setReport(null)
  }

  const onCheckCompatibility = async () => {
    const report = await switchDataSource({
      projectId,
      dataSourceId: dataSource.id,
      targetId: targetSourceId,
      reindexingStrategy: reindexingStrategy,
      globalStrategy: globalStrategy,
      commit: false,
      matchingOption: matchingOption
    })
    setReport(report)
    setStep(2)
  }

  const onCancel = () => {
    resetState()
  }

  const onSwitchPerform = async () => {
    await switchDataSource({
      projectId,
      dataSourceId: dataSource.id,
      targetId: targetSourceId,
      reindexingStrategy: reindexingStrategy,
      globalStrategy: globalStrategy,
      commit: true,
      matchingOption: matchingOption
    })
    setStep(3)
  }

  const onSwitchEnd = async () => {
    resetState()
    dispatch(refreshSlide())
    dispatch(clearCanvasSelection())
  }

  const onDeleteEntry = async (id: number, _tableId: number) => {
    const { [id]: _, ...rest } = report.records
    setReport({ compatibility: report.compatibility, records: rest })
  }

  const onEditEntry = (id: number, _tableId: number) => {
    const entry = report.records[id]
    entry.compatible = 1
    setReport({
      compatibility: report.compatibility,
      records: { ...report.records, [id]: entry }
    })
  }

  const onStrategyChange = (e: RadioChangeEvent) => {
    setReindexingStrategy(e.target.value)
  }

  const onGlobalStrategyChange = (e: CheckboxChangeEvent) => {
    setGlobalStrategy(e.target.checked)
  }

  const steps: { [key: number]: JSX.Element } = {
    0: (
      <WarningMessage
        onConfirm={(option: 0 | 1) => {
          setMatchingOption(option)
          setStep(1)
        }}
      />
    ),
    1: (
      <SelectDataTarget
        dataSource={dataSource}
        onTargetChange={setTargetSourceId}
        reindexingStrategy={reindexingStrategy}
        onStrategyChange={onStrategyChange}
        globalStrategy={globalStrategy}
        onGlobalStrategyChange={onGlobalStrategyChange}
      />
    ),
    2: <CompatibilityFeedback report={report} onConfirm={onSwitchPerform} onCancel={onCancel} />,
    3: (
      <CompatibilityReport
        visible={!!report}
        report={report?.records}
        onDeleteEntry={onDeleteEntry}
        onFinish={onSwitchEnd}
        onEditEntry={onEditEntry}
        editable={true}
      />
    )
  }

  const btnSteps = {
    0: [],
    1: [
      <Button onClick={onCheckCompatibility} type="primary" key={0}>
        Check compatibility
      </Button>,
      <Button onClick={onCancel} key={1}>
        Cancel
      </Button>
    ],
    2: [],
    3: []
  }

  return (
    <>
      <FileSyncOutlined
        id="data-sources-filesyncoutlined"
        key="switch"
        onClick={() => setIsVisible(true)}
      />
      <CommonModal
        title={i18n.t('editor.left-panel.data-sources.switch-data.title')}
        visible={isVisible}
        onCancel={onCancel}
        className="data-uploader"
        footer={btnSteps[step]}
      >
        {steps[step]}
      </CommonModal>
    </>
  )
}

export default SwitchData
