import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Form, InputNumber, Space, Spin, Switch } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import { selectCurrentProjectId } from '@/store/slices/projects/selectors'
import { updateShapeVisualSettings } from '@/store/slices/shapes/actions'
import { refreshSlide } from '@/store/slices/slides/actions'
import {
  selectCanvasSelectedObject,
  selectCanvasSelectedObjectPk
} from '@store/slices/shapes/selectors'

import { updateShape } from '@/services/shape-service'

import { getNumberFormat, getValuesFromNumberFormat } from '@/components/imagemap/right-panel/utils'
import { FabricTableOptions } from '@components/canvas/objects/Table/types'

interface IForm {
  colHeaders: boolean
  rowHeaders: boolean
  percent: boolean
  places: number
}

const TableProperty = () => {
  const selectedObject = useSelector(selectCanvasSelectedObject) as FabricTableOptions
  const dispatch = useDispatch()
  const projectId = useSelector(selectCurrentProjectId)
  const shapeId = useSelector(selectCanvasSelectedObjectPk)
  const [form] = useForm<IForm>()
  const [loading, setLoading] = useState(false)
  const [decimalPlaces, setDecimalPlaces] = useState<number>(0)
  const [showAsPercentge, setShowAsPercentage] = useState<boolean>(false)
  const prevDecimalPlacesValueRef = useRef<number>(null)
  const tableSettings = selectedObject?.visualSettings?.table
  const columnHeadersOn =
    tableSettings?.showColHeaders !== undefined ? tableSettings.showColHeaders : true
  const rowHeadersOn =
    tableSettings?.showRowHeaders !== undefined ? tableSettings.showRowHeaders : true
  const numberFormat = selectedObject?.visualSettings?.numberFormat

  const onToggleProperty = async (bool: boolean, propertyName: string) => {
    const payload = {
      visualSettings: {
        ...selectedObject?.visualSettings,
        table: {
          ...selectedObject?.visualSettings?.table,
          [propertyName]: bool
        }
      }
    }
    setLoading(true)
    await updateShape({ projectId, shapeId, payload })
    setLoading(false)
    dispatch(refreshSlide())
  }

  const onChangeDecimalPlaces = (value: number) => {
    if (value !== null && value !== undefined) {
      prevDecimalPlacesValueRef.current = value
      setTimeout(() => {
        setDecimalPlaces(value)
      }, 1200)
    }
  }

  const updateNumberFormat = async (numberFormat: string) => {
    const payload = {
      ...selectedObject?.visualSettings,
      numberFormat: numberFormat
    }
    setLoading(true)
    dispatch(updateShapeVisualSettings(selectedObject.id, payload))
    setLoading(false)
  }

  const onChangeShowAsPercentage = async (bool: boolean) => {
    let numberFormat = getNumberFormat(decimalPlaces, bool)
    await updateNumberFormat(numberFormat)
  }

  useEffect(() => {
    if (prevDecimalPlacesValueRef.current === decimalPlaces) {
      let numberFormat = getNumberFormat(decimalPlaces, showAsPercentge)
      updateNumberFormat(numberFormat)
    }
  }, [decimalPlaces])

  useEffect(() => {
    if (numberFormat) {
      const [places, show] = getValuesFromNumberFormat(numberFormat)
      setDecimalPlaces(places)
      setShowAsPercentage(show)
      form.setFieldsValue({
        percent: show,
        places: places
      })
    }
  }, [numberFormat])

  return (
    <Spin spinning={loading}>
      <Form form={form}>
        <Space direction="vertical">
          <Form.Item name="colHeaders" label="Column headers" className="form-item-flex">
            <Switch
              className="float-right"
              onChange={(bool: boolean) => onToggleProperty(bool, 'showColHeaders')}
              defaultChecked={columnHeadersOn}
            />
          </Form.Item>

          <Form.Item name="rowHeaders" label="Row headers" className="form-item-flex">
            <Switch
              className="float-right"
              onChange={(bool: boolean) => onToggleProperty(bool, 'showRowHeaders')}
              defaultChecked={rowHeadersOn}
            />
          </Form.Item>

          <Form.Item
            name="percent"
            label="Percentages"
            valuePropName="checked"
            className="form-item-flex"
          >
            <Switch
              className="float-right"
              onChange={(bool: boolean) => onChangeShowAsPercentage(bool)}
              defaultChecked={showAsPercentge}
            />
          </Form.Item>

          <Form.Item name="places" label="Decimal places" className="form-item-flex">
            <InputNumber
              className="float-right"
              onChange={onChangeDecimalPlaces}
              precision={0}
              max={24}
              min={0}
            />
          </Form.Item>
        </Space>
      </Form>
    </Spin>
  )
}

export default TableProperty
