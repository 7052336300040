import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Spin } from 'antd'

import { updateShapeData, updateShapeVisualSettings } from '@/store/slices/shapes/actions'
import { selectCanvasSelectedObject, selectInterpolationData } from '@store/slices/shapes/selectors'

import {
  createTextInterpolation,
  getTextInterpolation,
  updateTextInterpolation
} from '@/services/data-transfomations-service'
import { resetShape } from '@/services/shape-service'
import {
  createTextTransformation,
  deleteTextTransformation,
  getTextTransformations,
  updateTextTransformation
} from '@services/data-actions-service'

import { ActionTypes } from '@/components/imagemap/right-panel/data-actions/types'
import { useDataTable, useRowsNColumns } from '@/components/imagemap/right-panel/hooks'
import SortBy from '@components/imagemap/right-panel/data-actions/SortBy'
import TextTransformationList from '@components/imagemap/right-panel/data-actions/TextTransformationList'
import TextTransformations from '@components/imagemap/right-panel/data-actions/TextTransformations'

import { convertData, getGlobalNumberFormat } from '../helpers'
import { useOutputData } from '../hooks'
import DataSelection from './DataSelection'
import InterpolationWrapper from './InterpolationWrapper'
import LeftSidePanel from './LeftSidePanel'
import OutputTable from './OutputTable'
import RightSidePanel from './RightSidePanel'
import Table from './Table'

export default function InterpolationView({ dataKey }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const selectedObject = useSelector(selectCanvasSelectedObject)
  const [leftIsOpen, setLeftIsOpen] = useState(false)
  const [rightIsOpen, setRightIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [actionQueue, setActionQueue] = useState([])
  const [initialValues, setInitialValues] = useState(null)
  const [selectedAction, setSelectedAction] = useState(null)
  const [transformationsLoading, setTransformationsLoading] = useState(false)

  const { projectId, dataSource, dataSection, dataTable, dataSelection } = useSelector(state =>
    selectInterpolationData(state, dataKey)
  )
  const [dataTableId, setDataTableId] = useState(() => dataTable?.id || null)
  const [dataSourceId, setDataSourceId] = useState(() => dataSource?.id || null)
  const [dataSectionId, setDataSectionId] = useState(() => dataSection?.id || null)
  const { data: dataTableInstance } = useDataTable(dataTableId)
  const { rows, columns } = useRowsNColumns(dataTableId)

  const colHeaders = useMemo(() => dataTableInstance?.headers || [], [dataTableInstance])
  const rowHeaders = dataTableInstance?.index || []
  const tableData = useMemo(() => convertData(dataTableInstance) || [], [dataTableInstance])
  const [selections, setSelections] = useState([])
  const receiveSelections = useCallback(value => setSelections(value), [])
  const numberFormat = useMemo(() => {
    const code = selectedObject?.visualSettings?.interpolation?.[dataKey]?.numberFormat
    if (dataSelection?.sourceType === 'data') return code
    return null
  }, [dataSelection?.sourceType, selectedObject, dataKey])

  const [outputData, outputDataDispatch] = useOutputData(null, numberFormat)

  const handleFormSubmit = ({ dataSourceId, sectionId, tableId }) => {
    setDataTableId(tableId)
    setDataSourceId(dataSourceId)
    setDataSectionId(sectionId)
    setLeftIsOpen(false)
  }

  const clearDataSelection = async () => {
    try {
      await resetShape({ projectId, shapeId: selectedObject.pk })
      dispatch(updateShapeData(selectedObject.id, null))
      setDataTableId(null)
      outputDataDispatch({ type: 'reset' })
    } catch (e) {
      console.error(`An error occurred while attempting to clear data selections: ${e.message}`)
    }
  }

  const createInterpolation = async interpolationData => {
    let { tag, dataFrame, value, id } = await createTextInterpolation(interpolationData)
    const payload = {
      interpolation: {
        ...selectedObject.data?.interpolation,
        [tag]: {
          ...selectedObject.data?.interpolation[tag],
          id,
          value,
          dataSourceId,
          dataSectionId,
          tableId: dataTableId,
          sourceType: interpolationData?.sourceType,
          rowTags: interpolationData?.rowTags,
          selectionStrategy: interpolationData?.selectionStrategy
        }
      }
    }
    dispatch(updateShapeData(selectedObject.id, payload))
    return dataFrame
  }

  const updateInterpolation = async interpolationData => {
    let { tag, dataFrame, value, id } = await updateTextInterpolation(
      selectedObject.data.interpolation[dataKey].id,
      interpolationData
    )
    const payload = {
      interpolation: {
        ...selectedObject.data?.interpolation,
        [tag]: {
          ...selectedObject.data?.interpolation[tag],
          id,
          dataSourceId,
          dataSectionId,
          value,
          tableId: dataTableId,
          sourceType: interpolationData?.sourceType
        }
      }
    }
    dispatch(updateShapeData(selectedObject.id, payload))
    return dataFrame
  }

  const updateNumberFormat = numberFormat => {
    dispatch(
      updateShapeVisualSettings(selectedObject.id, {
        interpolation: {
          ...selectedObject?.visualSettings?.interpolation,
          [dataKey]: { numberFormat }
        }
      })
    )
  }

  const handleSelectData = async ({ sourceType, selectionStrategy, rowTags }) => {
    let interpolationData = {
      selectionStrategy,
      rowTags,
      sourceType,
      tag: dataKey,
      row: selections[0][0],
      column: selections[1][0],
      dataTable: dataTableId,
      shape: selectedObject.pk
    }

    const code =
      sourceType === 'data'
        ? getGlobalNumberFormat(
            dataTableInstance?.tableMeta?.numberFormat,
            selections[0],
            selections[1]
          )
        : '0'
    setIsLoading(true)
    try {
      let dataFrame
      if (dataSelection?.id) {
        dataFrame = await updateInterpolation(interpolationData)
      } else {
        dataFrame = await createInterpolation(interpolationData)
      }
      updateNumberFormat(code)
      outputDataDispatch({
        type: 'set_data',
        data: dataFrame.data,
        rowHeaders: dataFrame.index,
        colHeaders: dataFrame.columns,
        formatCode: code
      })
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
    if (selectionStrategy === 'cell') {
      history.goBack()
    }
  }

  const create = async payload => {
    try {
      const { dataFrame, ...action } = await createTextTransformation(dataSelection?.id, payload)
      setActionQueue([...actionQueue, action])
      outputDataDispatch({
        type: 'transform',
        data: dataFrame.data,
        rowHeaders: dataFrame.index,
        colHeaders: dataFrame.columns
      })
    } catch (e) {
      console.error(e)
    }
  }

  const update = async payload => {
    try {
      const { dataFrame } = await updateTextTransformation(dataSelection?.id, payload.id, payload)
      outputDataDispatch({ type: 'transform', data: dataFrame.data })
    } catch (e) {
      console.error(e)
    }
  }

  const handleSubmitTransformation = async payload => {
    setTransformationsLoading(true)
    if (initialValues?.id != null) {
      update({ ...payload, id: initialValues?.id })
    } else {
      create(payload)
    }
    setTransformationsLoading(false)
    setSelectedAction(null)
    setInitialValues(null)
  }

  const onDeleteTransformation = async (id: number) => {
    try {
      const { dataFrame } = await deleteTextTransformation(dataSelection?.id, id)
      setActionQueue(actionQueue.filter(item => item.id !== id))
      outputDataDispatch({ type: 'transform', data: dataFrame.data })
    } catch (e) {
      console.error(e)
    }
  }

  const onEditTransformation = payload => {
    setInitialValues(payload)
    setSelectedAction(payload.type)
  }

  const onOutputTableSubmit = async ([row, column]: [number, number]) => {
    let payload: { row?: number; column?: number } = {}
    if (dataSelection?.selectionStrategy === 'row') {
      payload.column = column
    }
    if (dataSelection?.selectionStrategy === 'column') {
      payload.row = row
    }
    if (payload.row == null && payload.column == null) {
      return
    }
    await updateTextInterpolation(dataSelection?.id, payload)
  }

  const getInterpolation = useCallback(async () => {
    if (dataSelection?.id != null && actionQueue.length === 0) {
      const { dataFrame } = await getTextInterpolation(dataSelection?.id)
      outputDataDispatch({
        type: 'set_data',
        data: dataFrame.data,
        rowHeaders: dataFrame.index,
        colHeaders: dataFrame.columns,
        formatCode: numberFormat
      })
    }
  }, [dataSelection?.id, outputDataDispatch, numberFormat, actionQueue])

  useEffect(() => {
    getInterpolation()
  }, [getInterpolation])

  const fetchTextTransformations = useCallback(async () => {
    if (dataSelection?.id == null) {
      return
    }
    try {
      const { transformations, dataFrame } = await getTextTransformations(dataSelection?.id)
      setActionQueue(transformations)
      if (transformations.length > 0) {
        outputDataDispatch({
          type: 'transform',
          data: dataFrame.data,
          rowHeaders: dataFrame.index,
          colHeaders: dataFrame.columns,
          formatCode: numberFormat
        })
      }
    } catch (error) {
      console.error(error.message)
    }
  }, [dataSelection?.id, outputDataDispatch, numberFormat])

  useEffect(() => {
    fetchTextTransformations()
  }, [fetchTextTransformations])

  return (
    <Spin wrapperClassName="insert" spinning={isLoading}>
      {(leftIsOpen || rightIsOpen) && (
        <div
          className="layover"
          onClick={() => {
            if (leftIsOpen) {
              setLeftIsOpen(false)
            }
            if (rightIsOpen) {
              setRightIsOpen(false)
            }
          }}
        />
      )}

      <LeftSidePanel
        rightIsOpen={rightIsOpen}
        leftIsOpen={leftIsOpen}
        setLeftIsOpen={setLeftIsOpen}
        handleFormSubmit={handleFormSubmit}
      >
        <DataSelection
          handleFormSubmit={handleFormSubmit}
          dataSourceId={dataSource?.id}
          dataSectionId={dataSection?.id}
          dataTableId={dataTable?.id}
        />
      </LeftSidePanel>

      <div className="content">
        <InterpolationWrapper
          hasData={tableData.length > 0}
          setLeftIsOpen={setLeftIsOpen}
          handleSelectData={handleSelectData}
          rowMetaTags={dataTableInstance?.rowsMeta || []}
          dataKey={dataKey}
        >
          {props => (
            <Table
              {...props}
              sendSelections={receiveSelections}
              data={tableData}
              colHeaders={colHeaders}
              rowHeaders={rowHeaders}
              tableMeta={dataTableInstance?.tableMeta?.tags}
              selectionMode="single"
            />
          )}
        </InterpolationWrapper>

        <OutputTable
          setIsLoading={setIsLoading}
          data={outputData.data}
          clearDataSelection={clearDataSelection}
          rowHeaders={outputData.rowHeaders}
          colHeaders={outputData.colHeaders}
          setRightIsOpen={setRightIsOpen}
          onSubmit={onOutputTableSubmit}
          showTransform={true}
        />
      </div>

      <RightSidePanel
        setRightIsOpen={setRightIsOpen}
        leftIsOpen={leftIsOpen}
        rightIsOpen={rightIsOpen}
      >
        <TextTransformations
          onSelect={() => setSelectedAction(ActionTypes.SORTBY)}
          loading={transformationsLoading}
        >
          <SortBy
            columns={columns}
            rows={rows}
            isVisible={selectedAction === ActionTypes.SORTBY}
            onSubmit={handleSubmitTransformation}
            onCancel={() => setSelectedAction(null)}
            initialValues={initialValues}
          />
        </TextTransformations>
        <TextTransformationList
          onEdit={onEditTransformation}
          onDelete={onDeleteTransformation}
          queue={actionQueue}
        />
      </RightSidePanel>
    </Spin>
  )
}
