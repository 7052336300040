import { DataSourceDto } from '@/interfaces/data'
import { TemplateDto } from '@/interfaces/template'

export enum ProjectFileTypes {
  DATA = 'data',
  TEMPLATE = 'template'
}

export interface DeleteProjectResponse {
  taskId: string
}

export interface ProjectDto {
  id: number
  name: string
  projectType: string
  accountId: number
  createdAt: string
  dataSources: DataSourceDto[]
  modifiedAt: string
  templates: TemplateDto[]
  slideOrder: number[]
}
