import axios, { CancelToken } from 'axios'

import { ICompatibilityReport } from '@/components/imagemap/left-panel/data-source/switch-data/SwitchData'

import { AddProjectDataSourceResponse, DataFrameDto, DataSourceDto } from '@/interfaces/data'
import { IColumn, IParser, IRow, IRowMeta, ITable, ITableMeta } from '@/interfaces/interfaces'

const API_URL = '/api/projects'

export async function updateProjectDataSource(
  {
    projectId,
    dataId,
    newData,
    compatibility
  }: {
    projectId: number
    dataId: number
    newData: Partial<DataSourceDto>
    compatibility?: boolean
  },
  cancelToken?: CancelToken
): Promise<AddProjectDataSourceResponse> {
  const response = await axios.put<AddProjectDataSourceResponse>(
    `${API_URL}/${projectId}/data/${dataId}/`,
    {
      ...newData,
      compatibility
    },
    { cancelToken }
  )
  return response.data
}

export async function deleteProjectDataSource(projectId: number, dataId: number): Promise<void> {
  await axios.delete<AddProjectDataSourceResponse>(`${API_URL}/${projectId}/data/${dataId}/`)
}

export async function getAllDataSources(projectId: number) {
  const response = await axios.get<DataSourceDto[]>(`${API_URL}/${projectId}/data/`)

  return response.data
}

export async function getDataSourceSections(
  { projectId, dataId }: { projectId: number; dataId: number },
  cancelToken?: CancelToken
): Promise<{ taskId: string }> {
  const response = await axios.get<{ taskId: string }>(
    `${API_URL}/${projectId}/data/${dataId}/preview_sections/`,
    { cancelToken }
  )

  return response.data
}

export async function getDataSourceTables(id: number, projectId: number) {
  const response = await axios.get(`${API_URL}/${projectId}/data/${id}`)
  return response.data
}

export async function getAllTables<T extends number | number[]>(sectionId: T): Promise<ITable[]> {
  let sectionIds: number[] = Array.isArray(sectionId) ? sectionId : [sectionId]
  let queryParams = sectionIds.map(id => 'section_pk=' + id.toString()).join('&')
  const url = `/api/data_tables/?${queryParams}`
  const response = await axios.get<ITable[]>(url)
  return response.data
}

export async function getRawData(
  { projectId, dataId }: { projectId: number; dataId: number },
  cancelToken?: CancelToken
): Promise<{ taskId: string }> {
  const response = await axios.post<{ taskId: string }>(
    `${API_URL}/${projectId}/data/${dataId}/get_spss_categories/`,
    { cancelToken }
  )

  return response.data
}

/** Collection of rows and columns related to a given `DataTable` */
export interface IRowsNColumns {
  id: number
  columns: IColumn[]
  rows: IRow[]
  table_meta: ITableMeta[]
  row_meta: IRowMeta[]
}

export async function getLabelsForTable(dataTableId: number): Promise<IRowsNColumns> {
  const response = await axios.get<IRowsNColumns>(`/api/data_tables/${dataTableId}/labels/`)
  return response.data
}

export interface GetDataFromTableProps {
  projectId: number
  tableId: number
  columns: number[]
  rows: number[]
  dataId: number
}

export async function getDataFromTable({
  tableId,
  columns,
  rows
}: GetDataFromTableProps): Promise<DataFrameDto> {
  const response = await axios.post<{ data: DataFrameDto }>(`/api/data_tables/${tableId}/data/`, {
    columns,
    rows
  })
  return response.data.data
}

export interface SwitchDataProps {
  projectId: number | string
  dataSourceId: number | string
  replaceDataSourceId: number | string
  checkCompatibility?: boolean
  matchingOption?: 0 | 1
}

export async function switchDataSource({
  projectId,
  dataSourceId,
  targetId,
  commit,
  reindexingStrategy,
  globalStrategy,
  matchingOption = 0
}) {
  const url = `${API_URL}/${projectId}/data/${dataSourceId}/switch_data/`
  const response = await axios.post<ICompatibilityReport>(url, {
    id: targetId,
    commit,
    reindexingStrategy,
    globalStrategy,
    matchingOption
  })
  return response.data
}

export async function getCompanyParsers(): Promise<IParser[]> {
  const url = `/api/parsers/`
  const response = await axios.get(url)
  return response.data
}

export interface ITableViewData {
  id?: number
  name?: string
  index: string[]
  columns: string[]
  data: (string | number)[][]
  headers?: { name: string; span: number }[][]
  tableMeta: {
    numberFormat: string[][]
    tags: { value: string }[][]
  }
  rowsMeta: string[]
}

export async function getTableDataFrame(url: string): Promise<ITableViewData> {
  const response = await axios.get(url)
  return response.data
}
