import { useState } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Button, Layout, notification } from 'antd'

import { AppDispatch } from '@/store/store'
import { reSendActivationEmail } from '@store/slices/auth/thunks'

import { Flex } from '@/components/flex'
import { Navbar } from '@components/common/Navbar'

import Form from './Form'

import '../styles.less'

const { Header, Content } = Layout

const SignUp = () => {
  const history = useHistory()
  const dispatch: AppDispatch = useDispatch()
  const [accountCreated, setAccountCreated] = useState(false)
  const [username, setUsername] = useState('username')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [hideResendEmail, setHideResendEmail] = useState(false)
  const [confirmEmail, setConfirmEmail] = useState(false)

  const onSuccess = (created: boolean, username: string, email: string, password: string) => {
    setAccountCreated(created)
    setUsername(username)
    setEmail(email)
    setPassword(password)
  }

  const ReSendActivationEmail = () => {
    // setHideResendEmail(true)
    // setTimeout(() => {
    //   setHideResendEmail(false)
    // }, 120000)
    dispatch(reSendActivationEmail({ email, password })).then(() => {
      openNotification('Activation Email was sent')
    })
  }

  const openNotification = message => {
    notification.open({
      message: 'Activation Email was sent',
      description: message,
      onClick: () => {}
    })
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Ldw7ZAeAAAAADGpbb0i18-kA78eb8slUU6BYnqn">
      <Layout className="layout" style={{ background: 'white' }}>
        <Header style={{ background: 'white' }}>
          <Navbar
            onPage="main"
            right={<Button onClick={() => history.push('sign-in')}>Sign in</Button>}
          />
        </Header>
        <Content>
          <div className="auth__container">
            <div className="auth__divider">
              {accountCreated ? (
                <>
                  <Flex justifyContent="center" alignItems="center">
                    <div className="auth__card">
                      <div className="card__header">
                        <h3>Hi, {username}</h3>
                      </div>

                      <div className="card__body">
                        {confirmEmail ? (
                          <div className="auth__text-block--normal">
                            Thank you for confirming your email <br />
                            Sign in to your account now
                          </div>
                        ) : (
                          <div className="auth__text-block--normal">
                            To confirm your account, check <br />
                            your email and click the verification link <br />
                            to get started
                          </div>
                        )}
                      </div>

                      <footer className="card__footer">
                        {confirmEmail ? (
                          <Button type="primary" onClick={() => history.push('sign-in')}>
                            Sign In
                          </Button>
                        ) : (
                          <Button type="primary" onClick={() => setConfirmEmail(true)}>
                            I've confirmed my email address
                          </Button>
                        )}
                        {!(hideResendEmail || confirmEmail) ? (
                          <Button type="link" block onClick={ReSendActivationEmail}>
                            Resend Activation Email
                          </Button>
                        ) : null}
                      </footer>
                    </div>
                  </Flex>

                  <Flex justifyContent="center" alignItems="center">
                    {confirmEmail ? (
                      <div className="auth__text-block--large">Email address confirmed</div>
                    ) : (
                      <div className="auth__text-block--large">
                        Verification link <br />
                        sent to your email
                      </div>
                    )}
                  </Flex>
                </>
              ) : (
                <>
                  <Form onSuccess={onSuccess} />
                  <Flex justifyContent="center" alignItems="center">
                    <div className="auth__text-block--large">
                      Automate <br />
                      PowerPoint <br />
                      generation with <br />
                      Indico Labs <br />
                    </div>
                  </Flex>
                </>
              )}
            </div>
          </div>
        </Content>
      </Layout>
    </GoogleReCaptchaProvider>
  )
}

export default SignUp
