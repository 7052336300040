import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Form, Input, Select, Space } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import { ClearOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons'

import { selectCurrentProjectId } from '@/store/slices/projects/selectors'
import { refreshSlide } from '@/store/slices/slides/actions'
import { selectCanvasSelectedObject } from '@store/slices/shapes/selectors'

import { updateShape } from '@/services/shape-service'

import { Switcher } from '@/components/form/UseFormattedDataSwitch'
import { useDefaultFieldValues } from '@/components/imagemap/right-panel/hooks'
import { FabricTableOptions } from '@components/canvas/objects/Table/types'

import { tableSelectionToArrayRepr, tableSelectionToStringRepr } from '../utils'

import './styles.less'

import { updateProjectUpdateTime } from '@/services/project-service'

import CommonModal from '@/components/common/CommonModal'

export type Condition = 'gt' | 'gte' | 'eq' | 'lt' | 'lte'
export type Selection<T = []> = T extends [] ? [[number, number], [number, number]] : string
export type TableCondition<T = []> = {
  color: string
  selection: Selection<T>
  applyTo: 'value-color' | 'cell-background'
  minLimitType: Condition
  minLimitValue: string
  maxLimitType: Condition
  maxLimitValue: string
}

const { Option } = Select

const TableConditionProperty = () => {
  const [tableConditions, setTableConditions] = useState<Partial<TableCondition<string>>[]>([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [showSecondCondition, setShowSecondCondition] = useState([])
  const [useFormattedValues, setUseFormattedValues] = useState(true)
  const selectedItem = useSelector(selectCanvasSelectedObject) as FabricTableOptions
  const projectId = useSelector(selectCurrentProjectId)
  const currentConditions: Partial<TableCondition>[] =
    selectedItem?.visualSettings?.table?.formatting?.conditions || []
  const dispatch = useDispatch()
  const [form] = useForm()
  const [setIndex, setConditionValue] = useDefaultFieldValues({
    form,
    formListName: 'conditions',
    colorFieldName: 'color',
    applyToFieldName: 'applyTo'
  })

  const getInitialSelectionRange = (): [[number, number], [number, number]] => {
    const df = selectedItem.data?.dataFrame
    if (!df)
      return [
        [0, 0],
        [0, 0]
      ]
    return [
      [0, 0],
      [df.columns.length - 1, df.index.length - 1]
    ]
  }

  const currentSelectionRange = getInitialSelectionRange()
  const conditionDescription = {
    eq: 'is equal',
    gt: 'greater than',
    gte: 'greater than or equal',
    lt: 'less than',
    lte: 'less than or equal'
  }

  const applyConditions = async () => {
    let conditions = toInternalRepr(tableConditions)
    const payload = {
      visualSettings: {
        ...selectedItem?.visualSettings,
        table: {
          ...selectedItem?.visualSettings?.table,
          formatting: {
            conditions,
            useFormattedValues
          }
        }
      }
    }
    await updateShape({ projectId, shapeId: selectedItem.pk, payload })
    await updateProjectUpdateTime(projectId)
    dispatch(refreshSlide())
  }

  /**
   * Change selections from array-like to string representation.
   * Internally we use arrays only.
   */
  const toInternalRepr = (
    conditions: Partial<TableCondition<string>>[]
  ): Partial<TableCondition>[] => {
    return conditions.map(condition => {
      if (condition.selection) {
        let selectionAsArray = tableSelectionToArrayRepr(condition.selection)
        return { ...condition, selection: selectionAsArray }
      }
      return null
    })
  }

  /**
   * Change selections from string to array-like representation.
   * That is done only for users.
   */
  const toExternalRepr = (
    conditions: Partial<TableCondition>[]
  ): Partial<TableCondition<string>>[] => {
    return conditions.map(condition => {
      let selectionAsString = tableSelectionToStringRepr(condition.selection)
      return { ...condition, selection: selectionAsString }
    })
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    form.submit()
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const onSubmit = ({ conditions }) => {
    //if we aren't showing the second condition we don't want to use maxLimit conditions
    let conditionsArr = conditions.map((condition, index) => {
      if (!showSecondCondition[index]) {
        let { maxLimitType, maxLimitValue, ...otherConditions } = condition
        return otherConditions
      } else {
        return condition
      }
    })
    setTableConditions(conditionsArr)
  }

  const onAddCondition = () => {
    const selection = tableSelectionToStringRepr(currentSelectionRange)
    setTableConditions([...tableConditions, { selection: selection }])
    setShowSecondCondition([...showSecondCondition, false])
  }

  const onRemoveCondition = (index: number) => {
    let conditions = [...tableConditions]
    conditions.splice(index, 1)
    setTableConditions(conditions)

    let showSecondOptions = [...showSecondCondition]
    showSecondOptions.splice(index, 1)
    setShowSecondCondition(showSecondOptions)
  }

  const clearFormatting = async () => {
    const payload = {
      visualSettings: {
        ...selectedItem?.visualSettings,
        table: {
          ...selectedItem?.visualSettings?.table,
          formatting: null
        }
      }
    }
    await updateShape({ projectId, shapeId: selectedItem.pk, payload })
    await updateProjectUpdateTime(projectId)
    setTableConditions([])
    form.resetFields()
    dispatch(refreshSlide())
  }

  /** Set default values for newly added conditions */
  const onConditionTypeChange = (value: keyof typeof conditionDescription, index: number) => {
    setIndex(index)
    setConditionValue(value)
  }

  useEffect(() => {
    if (currentConditions) {
      let arr = currentConditions.map(cond => !!cond.maxLimitValue && !!cond.minLimitValue)
      setShowSecondCondition(arr)
      setTableConditions(toExternalRepr(currentConditions))
      form.setFieldsValue({ conditions: toExternalRepr(currentConditions) })
    }
  }, [])

  return (
    <>
      <ul>
        {tableConditions.map((condition, index) => {
          const {
            minLimitType,
            minLimitValue,
            maxLimitType,
            maxLimitValue,
            color,
            applyTo,
            selection
          } = condition
          return (
            <li key={index} className="condition-rules">
              <div className="container symbol" style={{ background: color }}>
                <span className="symbol-container" style={{ background: color }}>
                  <span className="cell-background-symbol" style={{ background: color }}></span>
                </span>
              </div>
              <div className="container">
                <div className="condition-type">
                  Value {conditionDescription[minLimitType]}: {minLimitValue}
                  <br></br>
                  {selection}
                </div>
                {maxLimitType ? (
                  <div className="condition-type">
                    Value {conditionDescription[applyTo]} {conditionDescription[maxLimitType]}:{' '}
                    {maxLimitValue}
                  </div>
                ) : null}
              </div>
            </li>
          )
        })}
      </ul>
      <Switcher
        onToggle={value => setUseFormattedValues(value)}
        defaultChecked={true}
        checked={useFormattedValues}
      />
      <Button type="dashed" block onClick={showModal}>
        Edit conditions
      </Button>
      <Button style={{ marginTop: '0.5rem' }} block type="primary" onClick={applyConditions}>
        Apply conditions
      </Button>
      <Button
        type="primary"
        style={{ whiteSpace: 'normal', height: 'auto', marginTop: '0.5rem' }}
        onClick={clearFormatting}
        disabled={tableConditions.length === 0}
        block
        danger
      >
        <ClearOutlined />
        Clear conditional formatting
      </Button>
      <CommonModal
        width={1200}
        title="Edit conditions"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} initialValues={tableConditions} onFinish={e => onSubmit(e)}>
          <Form.List name="conditions">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <Space
                      size="middle"
                      key={field.key}
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        columnGap: '0.1rem',
                        rowGap: '0.1rem',
                        margin: '1rem 0',
                        width: '100%'
                      }}
                    >
                      <Form.Item
                        style={{ width: '70px' }}
                        key={field.key + 'selection'}
                        {...field}
                        name={[field.name, 'selection']}
                      >
                        <Input
                          style={{ border: '1px solid rgba(0,0,0,0.2)' }}
                          placeholder="A1:B1"
                        />
                      </Form.Item>

                      <Form.Item
                        {...field}
                        key={field.key + 'minLimitType'}
                        name={[field.name, 'minLimitType']}
                        rules={[
                          {
                            required: true,
                            message: 'This field is required'
                          }
                        ]}
                        style={{ width: '210px' }}
                      >
                        <Select<string | number, { value: string; children: string }>
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(value: keyof typeof conditionDescription) =>
                            onConditionTypeChange(value, index)
                          }
                          placeholder="Select condition"
                        >
                          <Option value="eq">Is equal</Option>
                          <Option value="gt">Is greater than</Option>
                          <Option value="gte">Is greater than or equal to</Option>
                          <Option value="lte">Is less than or equal to</Option>
                          <Option value="lt">Is less than</Option>
                        </Select>
                      </Form.Item>

                      <Form.Item
                        style={{ width: '60px' }}
                        key={field.key + 'minLimitValue'}
                        {...field}
                        name={[field.name, 'minLimitValue']}
                      >
                        <Input
                          style={{ border: '1px solid rgba(0,0,0,0.2)' }}
                          placeholder="value"
                        />
                      </Form.Item>

                      {showSecondCondition[index] ? (
                        <>
                          <p
                            style={{
                              paddingLeft: '10px',
                              paddingRight: '10px',
                              paddingTop: '10px'
                            }}
                          >
                            and
                          </p>
                          <Form.Item
                            key={field.key + 'maxLimitType'}
                            name={[field.name, 'maxLimitType']}
                            rules={[
                              {
                                required: true,
                                message: 'This field is required'
                              }
                            ]}
                            style={{ width: '210px' }}
                          >
                            <Select<string | number, { value: string; children: string }>
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              placeholder="Select second condition"
                            >
                              <Option value="eq">Is equal</Option>
                              <Option value="gt">Is greater than</Option>
                              <Option value="gte">Is greater than or equal to</Option>
                              <Option value="lte">Is less than or equal to</Option>
                              <Option value="lt">Is less than</Option>
                            </Select>
                          </Form.Item>

                          <Form.Item
                            style={{ width: '60px' }}
                            key={field.key + 'maxLimitValue'}
                            name={[field.name, 'maxLimitValue']}
                          >
                            <Input
                              style={{ border: '1px solid rgba(0,0,0,0.2)' }}
                              placeholder="value"
                            />
                          </Form.Item>
                        </>
                      ) : null}
                      <p style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '10px' }}>
                        then
                      </p>
                      <Form.Item
                        {...field}
                        name={[field.name, 'applyTo']}
                        key={field.key + 'applyTo'}
                        rules={[
                          {
                            required: true,
                            message: 'This field is required'
                          }
                        ]}
                        style={{ width: '190px' }}
                      >
                        <Select<string | number, { value: string; children: string }>
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder="Apply to"
                        >
                          <Option value="cell-background">Cell background color</Option>
                          <Option value="value-color">Font color</Option>
                        </Select>
                      </Form.Item>

                      <Form.Item
                        {...field}
                        style={{ minWidth: '50px' }}
                        key={field.key + 'color'}
                        name={[field.name, 'color']}
                        rules={[
                          {
                            required: true,
                            message: 'This field is required'
                          }
                        ]}
                      >
                        <Input
                          style={{ border: '1px solid rgba(0,0,0,0.2)' }}
                          type="color"
                          placeholder="Color"
                        />
                      </Form.Item>

                      <Form.Item>
                        <Button
                          type="text"
                          onClick={() => {
                            remove(index)
                            onRemoveCondition(index)
                          }}
                        >
                          <CloseOutlined />
                        </Button>
                      </Form.Item>

                      <Button
                        type="dashed"
                        onClick={() => {
                          let showSecondOptions = [...showSecondCondition]
                          showSecondOptions[index] = !showSecondOptions[index]
                          setShowSecondCondition(showSecondOptions)
                        }}
                      >
                        {showSecondCondition[index]
                          ? 'Remove second condition'
                          : 'Add second condition'}
                      </Button>
                    </Space>
                  ))}
                  <Button
                    type="dashed"
                    onClick={() => {
                      add({ selection: tableSelectionToStringRepr(currentSelectionRange) })
                      onAddCondition()
                    }}
                    block
                  >
                    <PlusOutlined /> Add condition
                  </Button>
                </div>
              )
            }}
          </Form.List>
        </Form>
      </CommonModal>
    </>
  )
}

export default TableConditionProperty
