import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@store/rootReducer'
import { objectAdapter } from '@store/slices/shapes/reducer'

const selectFirst = <T>(_, first: T) => first
const selectSecond = <T>(_, first, second: T) => second
const getObjectWithKey = (selectedObject, key: string) => {
  return selectedObject && selectedObject[key]
}

export function selectObjectsState(state: RootState) {
  return state.shapes.objects
}

export function selectCanvasSelectedObjectId(state: RootState) {
  return state.shapes.selectedObject.id
}

export function selectCanvasSelectedObjectPk(state: RootState) {
  return state.shapes.selectedObject.pk
}

export function selectShapeStoredConfig(state: RootState) {
  return state.shapes.storedConfig
}

export function selectCanvasSelectedObjectDataDefinition(state: RootState) {
  const shapeId = state.shapes.selectedObject.id
  const shape = state.shapes.objects.entities[shapeId]
  if (!shape?.data) {
    return null
  }
  const definition = shape?.data?.definition
  const dataFrame = shape?.data?.dataFrame
  return { ...definition, dataFrame }
}

export function selectCanvasSelectionLoading(state: RootState): boolean {
  return state.shapes.selectedObject.loading
}

export const objectSelectors = objectAdapter.getSelectors(selectObjectsState)
export const selectCanvasAllObjects = objectSelectors.selectAll
export const selectCanvasObjectsWithoutWorkArea = createSelector(
  objectSelectors.selectAll,
  objects => objects.filter(obj => obj.id !== 'workarea')
)
export const selectCanvasObjectById = objectSelectors.selectById
export const selectCanvasObjectIds = objectSelectors.selectIds
export const selectCanvasSelectedObject = createSelector(
  s => s,
  selectCanvasSelectedObjectId,
  objectSelectors.selectById
)

export const selectCanvasSelectedObjectProperty = createSelector(
  selectCanvasSelectedObject,
  selectFirst,
  getObjectWithKey
)

export const selectCanvasObjectProperty = createSelector(
  objectSelectors.selectById,
  selectSecond,
  getObjectWithKey
)

export const selectCanvasAllObjectProperty = createSelector(
  objectSelectors.selectAll,
  selectFirst,
  (objects, key: string) => objects.map(obj => getObjectWithKey(obj, key))
)

export const selectShapeData = createSelector(
  state => [
    selectCanvasSelectedObject(state),
    state?.data?.dataSources,
    state?.projects?.currentProject?.id
  ],
  ([shape, dataSources, projectId]) => {
    try {
      const dataFrame = shape?.data?.dataFrame
      const { transformationId, dataSourceId, tableId, dataSectionId } = shape.data.definition
      const selectionStrategy = shape.data?.selectionStrategy
      const rowTags = shape.data?.rowTags
      const dataSource = dataSources.find(entry => entry.id === dataSourceId)
      const dataSection = dataSource.dataSections.find(el => el.id === dataSectionId)
      const dataTable = dataSection.dataTables.find(el => el.id === tableId)
      return {
        projectId,
        shapeId: shape.pk,
        dataSelection: { dataFrame, selectionStrategy, id: transformationId, rowTags },
        dataSource: { name: dataSource.filename, id: dataSource.id },
        dataSection: { name: dataSection.name, id: dataSection.id },
        dataTable: { name: dataTable.name, id: dataTable.id }
      }
    } catch {
      return {
        projectId: projectId,
        shapeId: shape?.pk,
        dataSelection: null,
        dataSource: null,
        dataSection: null,
        dataTable: null
      }
    }
  }
)

function preselect(state, key: string) {
  return [
    selectCanvasSelectedObject(state),
    state?.data?.dataSources,
    state?.projects?.currentProject?.id,
    key
  ]
}

export const selectInterpolationData = createSelector(
  preselect,
  ([shape, dataSources, projectId, key]) => {
    try {
      const {
        id,
        dataSourceId,
        tableId,
        dataSectionId,
        value,
        sourceType,
        selectionStrategy,
        reorderingStrategy,
        rowTags
      } = shape.data.interpolation[key]
      const dataSource = dataSources.find(entry => entry.id === dataSourceId)
      const dataSection = dataSource.dataSections.find(el => el.id === dataSectionId)
      const dataTable = dataSection.dataTables.find(el => el.id === tableId)
      return {
        key,
        projectId,
        shapeId: shape.pk,
        dataSelection: { value, id, sourceType, selectionStrategy, reorderingStrategy, rowTags },
        dataSource: { name: dataSource.filename, id: dataSource.id },
        dataSection: { name: dataSection.name, id: dataSection.id },
        dataTable: { name: dataTable.name, id: dataTable.id }
      }
    } catch (e) {
      return {
        projectId: projectId,
        shapeId: shape?.pk,
        dataSelection: null,
        dataSource: null,
        dataSection: null,
        dataTable: null
      }
    }
  }
)

export const selectCanvasObjects = objectSelectors.selectAll
