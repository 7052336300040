import axios from 'axios'

import { Action } from '@/components/imagemap/right-panel/data-actions/types'

import { TextTransformation } from '@/interfaces/data-actions'
import { IShapeData } from '@/interfaces/shape'

export type DataActionDTO = Action & { shapeData: IShapeData }

export async function createDataAction(action: Action) {
  const response = await axios.post<DataActionDTO>(`/api/actions/`, action)
  return response.data
}

export async function getDataActions(dataTransformId: number): Promise<Action[]> {
  const response = await axios.get<Action[]>(`/api/actions/?data_selection_pk=${dataTransformId}`)
  return response.data
}

export async function deleteDataAction(actionId: number) {
  const response = await axios.delete<IShapeData['dataFrame']>(`/api/actions/${actionId}/`)
  return response.data
}

export async function updateDataAction(actionId: number, action: Partial<Action>) {
  const response = await axios.put<DataActionDTO>(`/api/actions/${actionId}/`, action)
  return response.data
}

export async function createTextTransformation(
  interpolationId: number,
  payload: TextTransformation
) {
  payload = { ...payload, interpolation: interpolationId }
  const response = await axios.post(
    `/api/interpolations/${interpolationId}/text-transformations/`,
    payload
  )
  return { ...response.data.object, dataFrame: response.data.dataFrame }
}

export async function updateTextTransformation(
  interpolationId: number,
  id: number,
  payload: Partial<TextTransformation>
) {
  const response = await axios.put(
    `/api/interpolations/${interpolationId}/text-transformations/${id}/`,
    payload
  )
  return { ...response.data.object, dataFrame: response.data.dataFrame }
}

export async function getTextTransformations(
  interpolationId: number
): Promise<{ transformations: TextTransformation[]; dataFrame: any }> {
  const response = await axios.get(`/api/interpolations/${interpolationId}/text-transformations/`)
  return { transformations: response.data.objects, dataFrame: response.data.dataFrame }
}

export async function deleteTextTransformation(interpolationId: number, id: number) {
  const response = await axios.delete(
    `/api/interpolations/${interpolationId}/text-transformations/${id}`
  )
  return response.data
}
