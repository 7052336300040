import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { Button, Radio, Switch, Tag } from 'antd'

import { intersection } from 'lodash'

import { selectInterpolationData } from '@store/slices/shapes/selectors'

import { useLocalStorage } from '@/hooks'

const { CheckableTag } = Tag

const InterpolationWrapper = ({
  children,
  hasData,
  handleSelectData,
  rowMetaTags,
  setLeftIsOpen,
  dataKey
}) => {
  const { dataSelection } = useSelector(state => selectInterpolationData(state, dataKey))
  const uniqueRowTags: string[] = useMemo(
    () => Array.from(new Set(rowMetaTags || [])),
    [rowMetaTags]
  )
  const [dynamicMappingEnabled, setDynamicMappingEnabled] = useState(false)
  const [sourceType, setSourceType] = useLocalStorage<string>('interpolationSourceType', 'data')
  const [activeRowTags, setActiveRowTags] = useLocalStorage<string[]>('interpolationRowTags', [])

  useEffect(() => {
    if (uniqueRowTags.length > 0 && intersection(uniqueRowTags, activeRowTags).length === 0) {
      setActiveRowTags([])
    }

    if (dataSelection?.rowTags) {
      setActiveRowTags(dataSelection.rowTags)
    } else if (activeRowTags.length === 0) {
      setActiveRowTags(uniqueRowTags)
    }

    if (dataSelection?.selectionStrategy) {
      if (['column', 'row'].includes(dataSelection.selectionStrategy)) {
        setDynamicMappingEnabled(true)
        setSourceType(dataSelection.selectionStrategy)
      } else {
        setDynamicMappingEnabled(false)
        setSourceType(
          {
            data: 'data',
            meta: 'meta',
            col_name: 'colName',
            row_name: 'rowName'
          }[dataSelection.sourceType]
        )
      }
    }
  }, [dataSelection, uniqueRowTags])

  const onCheckTag = (tag: string, checked: boolean) => {
    if (checked) {
      setActiveRowTags([...activeRowTags, tag])
    } else {
      setActiveRowTags(activeRowTags.filter(x => x !== tag))
    }
  }

  const onDynamicEnable = (enabled: boolean) => {
    setDynamicMappingEnabled(enabled)
    if (enabled) {
      setSourceType('column')
    } else {
      setSourceType('data')
    }
  }
  const disabledRows = useMemo(() => {
    return (
      rowMetaTags
        ?.map((el, idx) => {
          return activeRowTags.includes(el) ? null : idx
        })
        ?.filter(el => el !== null) || []
    )
  }, [activeRowTags, rowMetaTags])

  const selectBtnDisabled = !hasData

  const childrenProps = {
    disabledRows,
    selectionStrategy: sourceType
  }

  const handleOnSelect = () => {
    // here sourceType and selectionStrategy are merged, so we need to
    // distinguish them when API call is made
    const sourceTypeOptions = {
      data: 'data',
      meta: 'meta',
      rowName: 'row_name',
      columnName: 'col_name',
      column: 'data',
      row: 'data'
    }
    let selectionStrategy = 'cell'
    if (sourceType === 'column') {
      selectionStrategy = 'column'
    }
    if (sourceType === 'row') {
      selectionStrategy = 'row'
    }

    handleSelectData({
      selectionStrategy,
      sourceType: sourceTypeOptions[sourceType],
      rowTags: activeRowTags
    })
  }

  return (
    <div className="input-table-col">
      <div className="header-section">
        <h3>Input table</h3>
      </div>
      <div className="table-container">
        {hasData ? (
          children(childrenProps)
        ) : (
          <div className="centered-btn">
            <Button
              onClick={() => {
                setLeftIsOpen(true)
              }}
            >
              Select Data
            </Button>
          </div>
        )}
      </div>
      {hasData && (
        <div className="control-panel">
          <div className="control-panel-item">
            <span className="description">Row tags:</span>
            {uniqueRowTags.map((tag: string) => (
              <CheckableTag
                key={tag}
                checked={activeRowTags?.includes(tag)}
                onChange={checked => onCheckTag(tag, checked)}
              >
                {tag}
              </CheckableTag>
            ))}
          </div>
          <div className="control-panel-item">
            <span className="description">Enable dynamic mapping:</span>
            <Switch checked={dynamicMappingEnabled} onChange={onDynamicEnable} />
          </div>

          <div className="control-panel-item">
            <span className="description">Selection mode:</span>
            {dynamicMappingEnabled ? (
              <Radio.Group
                size="small"
                buttonStyle="solid"
                value={sourceType}
                onChange={e => setSourceType(e.target.value)}
              >
                <Radio.Button value="column">Columns</Radio.Button>
                <Radio.Button value="row">Rows</Radio.Button>
              </Radio.Group>
            ) : (
              <Radio.Group
                size="small"
                buttonStyle="solid"
                value={sourceType}
                onChange={e => setSourceType(e.target.value)}
              >
                <Radio.Button value="data">Table Values</Radio.Button>
                <Radio.Button value="meta">Table Details</Radio.Button>
                <Radio.Button value="rowName">Row Names</Radio.Button>
                <Radio.Button value="columnName">Column Names</Radio.Button>
              </Radio.Group>
            )}
          </div>
        </div>
      )}
      <div className="section">
        <div>
          {hasData && (
            <Button disabled={selectBtnDisabled} type="default" onClick={handleOnSelect}>
              Select
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default InterpolationWrapper
