import { range } from 'lodash'

import { DataFrameDefinition } from '@/interfaces/data'
import { IShapeData } from '@/interfaces/shape'

import { applyNumberFormatting } from '../../utils'
import { DataGrid } from './dataGrid'
import { TableLayout, TableWithDataLayout, TableWithoutDataLayout } from './overlay'
import { DataFormat, DataGridData, TableDefinition, TableVisualSettings } from './types'
import { CalculateTableDimensions } from './utils'

export abstract class AbstractTable {
  table: TableDefinition
  layout: TableLayout

  constructor(table: TableDefinition) {
    this.table = table
  }

  abstract formatData(): DataGridData

  abstract create(): any
}

export class TableWithData extends AbstractTable {
  data: DataFrameDefinition
  visualSettings: TableVisualSettings
  util: CalculateTableDimensions
  numberFormat: string
  dataGridOptions: {
    showRowHeaders: boolean
    showColHeaders: boolean
    showCoordinates: boolean
  }
  dataGrid?: DataGrid

  constructor(table: TableDefinition, data: IShapeData, visualSettings: TableVisualSettings) {
    super(table)
    this.data = data.dataFrame
    this.layout = new TableWithDataLayout(data, table, visualSettings)
    this.util = new CalculateTableDimensions(table, data, visualSettings)
    this.dataGridOptions = {
      showRowHeaders: visualSettings?.table?.showRowHeaders === false ? false : true,
      showColHeaders: visualSettings?.table?.showColHeaders === false ? false : true,
      showCoordinates: visualSettings?.table?.showCoordinates === false ? false : true
    }
    this.numberFormat = visualSettings?.numberFormat || '0'
  }

  /**
   * Format `data` into array of objects.
   * @returns {DataGridData} array of objects where keys are column names.
   */
  formatData(): DataGridData {
    let formattedData = []
    let { columns, index: rows, data } = this.data
    let columnHeaders = ['', ...columns]
    rows.forEach((_row: string, rowIndex: number) => {
      let entry = {}
      columnHeaders.forEach((column: string, columnIndex: number) => {
        if (columnIndex === 0) {
          // The first column is reserved for row headers.
          entry[column] = rows[rowIndex]
        } else {
          entry[column] = data[rowIndex][columnIndex - 1]
        }
      })
      formattedData.push(entry)
    })
    return formattedData
  }

  /**
   * Format `data` into 2D array.
   * @returns {DataFormat} `(number|string)[][]`
   */
  private formatMappedData2DArray(): DataFormat {
    let formattedData = []
    let { columns, index, data } = this.data
    columns = ['', ...columns]
    formattedData.push(columns)
    index.forEach((row: string, index: number) => {
      let formattedRowData = data[index].map(value => {
        return applyNumberFormatting(value, this.numberFormat)
      })
      formattedData.push([row, ...formattedRowData])
    })
    return formattedData
  }

  public create() {
    const [bgColors, valueColors, symbols] = this.layout.getLayouts()
    this.dataGrid = new DataGrid(this.formatMappedData2DArray(), this.dataGridOptions)
    this.setCellSizes()
    const coords = this.util.calculateCellValuesCoordinates()
    const columnWidths = this.util.calculateColumnWidths()
    this.dataGrid.renderText(valueColors, coords, columnWidths)
    this.dataGrid.renderCell(bgColors)
    this.dataGrid.afterRenderCell(symbols, coords)
    return this.dataGrid.element
  }

  public setCellSizes() {
    const rowHeights = this.util.calculateRowHeights()
    const columnWidths = this.util.calculateColumnWidths()
    rowHeights.forEach((height, idx) => {
      const index = idx - Number(!this.dataGridOptions.showColHeaders)
      this.dataGrid.element.setRowHeight(index, height)
    })
    columnWidths.forEach((width, idx) => {
      this.dataGrid.element.setColumnWidth(idx, width)
    })
  }
}

export class TableWithoutData extends AbstractTable {
  dataGrid: DataGrid

  constructor(table: TableDefinition) {
    super(table)
    this.layout = new TableWithoutDataLayout(table)
    this.dataGrid = new DataGrid(this.formatData())
  }

  public create() {
    const [bg] = this.layout.getLayouts()
    this.setCellSizes()
    this.dataGrid.renderCell(bg)
    return this.dataGrid.element
  }

  formatData(): DataFormat {
    const columnsLength = this.table.columnsWidths.length
    const rowsLength = this.table.rowsHeights.length
    return range(rowsLength).map(rowIndex => {
      return range(columnsLength).map(colIndex => {
        return this.table.cells[colIndex + columnsLength * rowIndex].text.value
      })
    })
  }

  public setCellSizes() {
    this.table.rowsHeights.forEach((height, idx) => {
      this.dataGrid.element.setRowHeight(idx, height)
    })
    this.table.columnsWidths.forEach((width, idx) => {
      this.dataGrid.element.setColumnWidth(idx, width)
    })
  }
}

export function factoryManager(
  table: TableDefinition,
  data: IShapeData,
  visualSettings: TableVisualSettings
) {
  const factory = data
    ? new TableWithData(table, data, visualSettings)
    : new TableWithoutData(table)
  return factory
}
