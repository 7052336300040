import { createReducer } from '@reduxjs/toolkit'

import {
  completeProject,
  getProjectLayouts,
  getProjectTemplates,
  setCurrentProject,
  setCurrentProjectName,
  setProjects,
  setProjectSlidesOrder,
  setProjectTemplates
} from '@store/slices/projects/actions'
import { IProjectsState } from '@store/slices/projects/types'

const initialState: IProjectsState = {
  projects: [],
  currentProject: null,
  templates: [],
  layouts: []
}

export const projectsReducer = createReducer(initialState, builder => {
  builder.addCase(setProjects, (state, { payload }) => {
    state.projects = payload
  })
  builder.addCase(setCurrentProject, (state, { payload }) => {
    state.currentProject = payload
  })
  builder.addCase(setCurrentProjectName, (state, { payload }) => {
    state.currentProject.name = payload
  })
  builder.addCase(setProjectSlidesOrder, (state, { payload }) => {
    state.currentProject.slideOrder = payload
  })
  builder.addCase(getProjectTemplates, (state, { payload }) => {
    state.templates = payload
  })
  builder.addCase(getProjectLayouts, (state, { payload }) => {
    state.layouts = payload
  })
  builder.addCase(setProjectTemplates, (state, { payload }) => {})
  builder.addCase(completeProject, (state, { payload }) => {})
})
