import { createReducer } from '@reduxjs/toolkit'

import {
  addDataSourceUploadStatus,
  clearDataSourceUpload,
  setDataSources,
  setDataSourceUploadCeleryTaskId,
  setDataSourceUploadCompatibility,
  setDataSourceUploadDataSections,
  setDataSourceUploadDataSource,
  setDataSourceUploadRawData
} from '@store/slices/data/action'
import { DataSourceUpload } from '@store/slices/data/types'

import { DataSourceDto } from '@/interfaces/data'

export interface DataState {
  dataSources: DataSourceDto[]
  dataSourceUpload: DataSourceUpload
}

const initialState: DataState = {
  dataSources: [],
  dataSourceUpload: {
    status: null,
    dataSource: null,
    compatibility: null,
    celeryTaskId: null,
    dataSections: [],
    rawData: []
  }
}

export const dataReducer = createReducer(initialState, builder => {
  builder
    .addCase(addDataSourceUploadStatus, (state, action) => {
      state.dataSourceUpload.status = action.payload
    })
    .addCase(setDataSourceUploadCompatibility, (state, action) => {
      state.dataSourceUpload.compatibility = action.payload
    })
    .addCase(setDataSourceUploadDataSource, (state, action) => {
      state.dataSourceUpload.dataSource = action.payload
    })
    .addCase(setDataSourceUploadDataSections, (state, action) => {
      state.dataSourceUpload.dataSections = action.payload
    })
    .addCase(setDataSourceUploadRawData, (state, action) => {
      state.dataSourceUpload.rawData = action.payload
    })
    .addCase(setDataSourceUploadCeleryTaskId, (state, action) => {
      state.dataSourceUpload.celeryTaskId = action.payload
    })
    .addCase(clearDataSourceUpload, state => {
      state.dataSourceUpload = initialState.dataSourceUpload
    })
    .addCase(setDataSources, (state, action) => {
      state.dataSources = action.payload
    })
})
