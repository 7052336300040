import { Popover } from 'antd'
import type { TooltipPlacement } from 'antd/lib/tooltip'

import { InfoCircleOutlined } from '@ant-design/icons'

const popoverStyles = {
  overlayInnerStyle: {
    maxWidth: '680px',
    maxHeight: '480px',
    overflow: 'auto'
  }
}

export default function Info({
  children,
  title,
  placement = 'rightTop'
}: {
  children: React.ReactNode
  title?: string
  placement?: TooltipPlacement
}) {
  return (
    <Popover title={title} placement={placement} content={children} {...popoverStyles}>
      <InfoCircleOutlined style={{ cursor: 'pointer' }} />
    </Popover>
  )
}
