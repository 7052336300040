import { selectCanvasObjects } from '@/store/slices/shapes/selectors'
import { RootState } from '@store/rootReducer'
import { DataSourceAddStatus } from '@store/slices/data/types'

import {
  CheckProjectDataSourceCompatibilityResponse,
  DataSectionDto,
  DataSourceDto,
  IInterpolation
} from '@/interfaces/data'

export function selectDataSources(state: RootState): DataSourceDto[] {
  return state.data.dataSources
}

export function selectDataSourceAddStatus(state: RootState): DataSourceAddStatus {
  return state.data.dataSourceUpload.status
}

export function selectNewDataSource(state: RootState): DataSourceDto {
  return state.data.dataSourceUpload.dataSource
}

export function selectNewDataSourceCompatibility(
  state: RootState
): CheckProjectDataSourceCompatibilityResponse {
  return state.data.dataSourceUpload.compatibility
}

export function selectUploadDataSourceCeleryTaskId(state: RootState): string {
  return state.data.dataSourceUpload.celeryTaskId
}

export function selectDataSectionsAddStatus(state: RootState): DataSectionDto[] {
  return state.data.dataSourceUpload.dataSections
}

export function selectRawData(state: RootState): string[] {
  return state.data.dataSourceUpload.rawData
}

/** Selects data sources that are used for the current slide. */
export function selectSlideDataSources(state: RootState): DataSourceDto[] {
  let ids = new Set()
  const objects = selectCanvasObjects(state)
  for (const shape of objects) {
    if (shape?.data) {
      if (shape?.data?.interpolation) {
        for (const interpolation of Object.values(shape?.data?.interpolation)) {
          //@ts-ignore
          ids.add(interpolation.dataSourceId)
        }
      } else {
        ids.add(shape?.data?.definition?.dataSourceId)
      }
    }
  }
  const dataSourceIds = Array.from(ids)
  return state?.data?.dataSources.filter(dataSource => dataSourceIds.includes(dataSource.id))
}

export function selectSlideDataSelections(state: RootState): number[] {
  return selectCanvasObjects(state)
    .filter(shape => shape.data != null)
    .map(shape => shape?.data?.definition?.transformationId)
}

export function selectTextInterpolationIds(state: RootState): number[] {
  const interpolations: IInterpolation[][] = selectCanvasObjects(state)
    .filter(shape => shape.data != null)
    .map(shape => Object.values(shape?.data?.interpolation || {}))
  return interpolations.flat().map(entry => entry.id)
}
