import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Spin } from 'antd'

import { updateShapeData, updateShapeVisualSettings } from '@/store/slices/shapes/actions'
import { selectCanvasSelectedObject, selectShapeData } from '@store/slices/shapes/selectors'

import {
  createDataTransformation,
  updateDataTransformation
} from '@/services/data-transfomations-service'
import { resetShape } from '@/services/shape-service'

import { useDataTable } from '@/components/imagemap/right-panel/hooks'
import DataActions from '@components/imagemap/right-panel/data-actions'

import { convertData, getGlobalNumberFormat } from '../helpers'
import { useOutputData } from '../hooks'
import DataSelection from './DataSelection'
import InputTableWrapper from './InputTableWrapper'
import LeftSidePanel from './LeftSidePanel'
import OutputTable from './OutputTable'
import RightSidePanel from './RightSidePanel'
import Table from './Table'

export default function DefaultDataView() {
  const dispatch = useDispatch()
  const selectedObject = useSelector(selectCanvasSelectedObject)
  const [leftIsOpen, setLeftIsOpen] = useState(false)
  const [rightIsOpen, setRightIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { projectId, dataSource, dataSection, dataSelection, dataTable } =
    useSelector(selectShapeData)

  const [dataTableId, setDataTableId] = useState(() => dataTable?.id || null)
  const { data: dataTableInstance } = useDataTable(dataTableId) // this is for original table
  const colHeaders = useMemo(() => dataTableInstance?.headers || [], [dataTableInstance])
  const rowHeaders = dataTableInstance?.index || []
  const tableData = useMemo(() => convertData(dataTableInstance) || [], [dataTableInstance])
  const [selections, setSelections] = useState([])
  const receiveSelections = useCallback(value => setSelections(value), [])
  const formatCode = useMemo(
    () => selectedObject?.visualSettings?.numberFormat,
    [selectedObject?.visualSettings?.numberFormat]
  )

  const [outputData, outputDataDispatch] = useOutputData(dataSelection?.dataFrame, formatCode)

  const transformData = useCallback(() => {
    if (dataSelection?.dataFrame) {
      const { data, index, columns } = dataSelection.dataFrame
      outputDataDispatch({
        type: 'transform',
        data: data,
        rowHeaders: index,
        colHeaders: columns,
        formatCode: formatCode
      })
    }
  }, [dataSelection?.dataFrame, outputDataDispatch, formatCode])

  useEffect(() => {
    transformData()
  }, [transformData])

  const handleFormSubmit = ({ tableId }) => {
    setDataTableId(tableId)
    setLeftIsOpen(false)
  }

  const updateData = async payload => {
    try {
      const { dataFrame, shapeData } = await updateDataTransformation(dataSelection.id, payload)
      dispatch(updateShapeData(selectedObject.id, shapeData, false))
      return dataFrame
    } catch (error) {
      console.error(error.message)
    }
  }

  const createData = async payload => {
    try {
      const { dataFrame, shapeData } = await createDataTransformation({
        ...payload,
        shape: selectedObject.pk
      })
      dispatch(updateShapeData(selectedObject.id, shapeData))
      return dataFrame
    } catch (error) {
      console.error(error.message)
    }
  }

  const updateNumberFormat = numberFormat => {
    if (numberFormat) {
      dispatch(updateShapeVisualSettings(selectedObject.id, { numberFormat }, false))
    } else if (selectedObject.visualSettings) {
      dispatch(updateShapeVisualSettings(selectedObject.id, null))
    }
  }

  const handleSelectData = async (activeRowTags, selectionStrategy) => {
    const [rows, columns] = selections
    selectionStrategy = {
      freeform: 'by_absolute_position',
      column: 'by_column_position',
      row: 'by_column_position',
      group: 'by_group_inclusion'
    }[selectionStrategy]
    const payload = {
      rows,
      columns,
      selectionStrategy,
      dataTable: dataTableId,
      rowTags: activeRowTags
    }

    setIsLoading(true)
    let dataFrame
    if (dataSelection) {
      dataFrame = await updateData(payload)
    } else {
      dataFrame = await createData(payload)
    }
    if (dataFrame != null) {
      const code = getGlobalNumberFormat(dataTableInstance?.tableMeta?.numberFormat, rows, columns)
      outputDataDispatch({
        type: 'set_data',
        data: dataFrame.data,
        rowHeaders: dataFrame.index,
        colHeaders: dataFrame.columns,
        formatCode: code
      })
      updateNumberFormat(code)
    }
    setIsLoading(false)
  }

  const clearDataSelection = async () => {
    try {
      await resetShape({ projectId, shapeId: selectedObject.pk })
      dispatch(updateShapeData(selectedObject.id, null))
      setDataTableId(null)
      outputDataDispatch({ type: 'reset' })
    } catch (e) {
      console.error(`An error occurred while attempting to clear data selections: ${e.message}`)
    }
  }

  return (
    <Spin wrapperClassName="insert" spinning={isLoading}>
      {(leftIsOpen || rightIsOpen) && (
        <div
          className="layover"
          onClick={() => {
            if (leftIsOpen) {
              setLeftIsOpen(false)
            }
            if (rightIsOpen) {
              setRightIsOpen(false)
            }
          }}
        />
      )}

      <LeftSidePanel
        rightIsOpen={rightIsOpen}
        leftIsOpen={leftIsOpen}
        setLeftIsOpen={setLeftIsOpen}
        handleFormSubmit={handleFormSubmit}
      >
        <DataSelection
          handleFormSubmit={handleFormSubmit}
          dataSourceId={dataSource?.id}
          dataSectionId={dataSection?.id}
          dataTableId={dataTable?.id}
        />
      </LeftSidePanel>

      <div className="content">
        <InputTableWrapper
          hasData={tableData.length > 0}
          rowMetaTags={dataTableInstance?.rowsMeta || []}
          setLeftIsOpen={setLeftIsOpen}
          handleSelectData={handleSelectData}
        >
          {props => (
            <Table
              {...props}
              sendSelections={receiveSelections}
              data={tableData}
              colHeaders={colHeaders}
              rowHeaders={rowHeaders}
              tableMeta={dataTableInstance?.tableMeta?.tags}
              selectionMode="multiple"
            />
          )}
        </InputTableWrapper>

        <OutputTable
          setIsLoading={setIsLoading}
          data={outputData.data}
          clearDataSelection={clearDataSelection}
          rowHeaders={outputData.rowHeaders}
          colHeaders={outputData.colHeaders}
          setRightIsOpen={setRightIsOpen}
        />
      </div>

      <RightSidePanel
        setRightIsOpen={setRightIsOpen}
        leftIsOpen={leftIsOpen}
        rightIsOpen={rightIsOpen}
      >
        <DataActions />
      </RightSidePanel>
    </Spin>
  )
}
