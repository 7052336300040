import React, { useCallback, useEffect } from 'react'

import { fabric } from 'fabric'

import { useCanvas } from '@components/canvas/hooks/useCanvas'
import { CanvasComponentProps } from '@components/canvas/types/component'
import { FabricImageObject, FabricImageOptions } from '@components/canvas/types/image'

import { useAngleHandler } from '../handlers/objects/useAngleHandler'
import { useCommonPropsHandler } from '../handlers/objects/useCommonPropsHandler'
import { useShadowHandler } from '../handlers/objects/useShadowHandler'
import { useVisibilityHandler } from '../handlers/objects/useVisibilityHandler'
import { useFabricObject } from '../utils/useFabricObject'

const imageFactory = (options: FabricImageOptions): FabricImageObject => {
  const image = new Image(options.width, options.height)
  return new fabric.Image(image, options as any) as FabricImageObject
}

const NSPlaceholder = ({ id, options }: CanvasComponentProps<FabricImageOptions>) => {
  const factory = useCallback(() => imageFactory(options), [])
  const object = useFabricObject<FabricImageObject, FabricImageOptions>(factory, id, options)
  const canvas = useCanvas()
  const SVGIcon =
    '<svg viewBox="64 64 896 896" focusable="false" data-icon="warning" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M464 720a48 48 0 1096 0 48 48 0 10-96 0zm16-304v184c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V416c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8zm475.7 440l-416-720c-6.2-10.7-16.9-16-27.7-16s-21.6 5.3-27.7 16l-416 720C56 877.4 71.4 904 96 904h832c24.6 0 40-26.6 27.7-48zm-783.5-27.9L512 239.9l339.8 588.2H172.2z"></path></svg>'

  const addGroup = () => {
    const rect = new fabric.Rect({
      fill: 'transparent',
      width: Math.max(object.width, 25),
      height: Math.max(object.height, 25),
      left: 0,
      top: 0,
      stroke: '#000000',
      strokeWidth: 1,
      strokeDashArray: [5, 5]
    })

    let svg = null

    fabric.loadSVGFromString(SVGIcon, function (objects) {
      objects.forEach((obj, i) => {
        obj.top = object.height / 2
        obj.left = object.width / 2
        svg = obj
      })
    })

    const group = new fabric.Group([rect, svg], {
      left: options.left,
      top: options.top,
      width: rect.width,
      height: rect.height,
      selectable: false,
      type: 'NO_SHAPE_TYPE'
    })

    group.forEachObject(function (obj) {
      obj.set({
        left: 0 - obj.getBoundingRect().width / 2,
        originX: 'left'
      })
      obj.setCoords()
    })
    canvas.add(group)
  }

  if (!!object) {
    addGroup()
  }
  // useEffect(() => {
  // }, [options, canvas, object])

  useCommonPropsHandler(object)
  useAngleHandler(object)
  useShadowHandler(object)
  useVisibilityHandler(object)

  return <></>
}

export default React.memo(NSPlaceholder)
