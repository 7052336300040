import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Form, Select, Space } from 'antd'

import i18n from 'i18next'

import { selectDataSources } from '@/store/slices/data/selectors'

import { useTables } from '../hooks'
import EmptyDataSourceUploader from './EmptyDataSourceUploader'

const SelectDataFields = ({ interpolation, form, onTableSelect, sectionId, setSectionId }) => {
  const dataSources = useSelector(selectDataSources)
  const [tablesDropdownVisible, setTablesDropdownVisible] = useState(false)
  const [uploaderVisible, setUploaderVisible] = useState(false)
  const [sections, setSections] = useState([])
  const [sectionsVisible, setSectionsVisible] = useState(false)

  const { tables } = useTables(sectionId)

  const onChange = (key: 'dataSourceId' | 'sectionId' | 'tableId', id: number) => {
    switch (key) {
      case 'dataSourceId':
        form.resetFields(['sectionId', 'tableId'])
        const sections = dataSources.find(source => source.id === id)?.dataSections
        setSections(sections)
        if (sections.length === 1) {
          setSectionId(sections[0].id)
          form.setFieldsValue({ sectionId: sections[0].id })
        }
        break
      case 'sectionId':
        form.resetFields(['tableId'])
        setSectionId(id)
        break
      case 'tableId':
        onTableSelect(id)
    }
  }

  useEffect(() => {
    const { dataSourceId, sectionId, tableId } = form.getFieldsValue()
    if (interpolation && dataSourceId == null && sectionId == null && tableId == null) {
      const { dataSourceId, dataSectionId, tableId } = interpolation
      form.setFieldsValue({
        dataSourceId: dataSourceId,
        sectionId: dataSectionId,
        tableId: tableId
      })
      setSectionId(dataSectionId)
      onTableSelect(tableId)
    }
    if (!interpolation) {
      form.resetFields()
      setSectionId(null)
    }
  }, [interpolation])

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Form.Item
        name="dataSourceId"
        rules={[{ required: true }]}
        label={i18n.t('editor.right-panel.data-source.form.data-sources')}
      >
        <Select<string | number, { value: string; children: string }>
          showSearch
          placeholder="Search data sources"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option!.children[option!.children.length - 1]
              ?.toLowerCase()
              ?.indexOf(input.toLowerCase()) >= 0
          }
          dropdownMatchSelectWidth={false}
          dropdownStyle={{
            display: uploaderVisible ? 'inline-block' : 'none',
            width: 'fit-content',
            maxWidth: '33%',
            minWidth: '15%',
            maxHeight: '50vh'
          }}
          dropdownAlign={{
            points: ['tr', 'tl'], //align dropdown's top-right to top-left of input element
            offset: [-5, 0] //align offset
          }}
          onDropdownVisibleChange={open => setUploaderVisible(open)}
          onChange={(id: number) => onChange('dataSourceId', id)}
          notFoundContent={<EmptyDataSourceUploader onClose={() => setUploaderVisible(false)} />}
        >
          {dataSources.slice(0).map((source, index) => (
            <Select.Option value={source.id} key={source.id}>
              {index + 1}. {source.filename}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        shouldUpdate={(prevValues, curValues) => prevValues.dataSourceId !== curValues.dataSourceId}
        noStyle
      >
        <Form.Item
          name="sectionId"
          rules={[{ required: true }]}
          label={i18n.t('editor.right-panel.data-source.form.sheet')}
          hidden={sections.length <= 1}
        >
          <Select<string | number, { value: string; children: string }>
            showSearch
            placeholder="Search sections"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(id: number) => onChange('sectionId', id)}
            dropdownMatchSelectWidth={false}
            dropdownStyle={{
              display: sectionsVisible ? 'inline-block' : 'none',
              width: 'fit-content',
              maxWidth: '33%',
              minWidth: '15%'
            }}
            dropdownAlign={{
              points: ['tr', 'tl'], //align dropdown's top-right to top-left of input element
              offset: [-5, 0] //align offset
            }}
            onDropdownVisibleChange={open => setSectionsVisible(open)}
          >
            {sections.map(({ id, name }) => (
              <Select.Option value={id} key={id}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form.Item>

      <Form.Item
        name="tableId"
        rules={[{ required: true }]}
        label={i18n.t('editor.right-panel.data-source.form.tables')}
      >
        <Select<string | number, { value: string; children: string }>
          showSearch
          placeholder="Search tables"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={(id: number) => onChange('tableId', id)}
          dropdownMatchSelectWidth={false}
          dropdownAlign={{
            points: ['tr', 'tl'], //align dropdown's top-right to top-left of input element
            offset: [-5, 0] //align offset
          }}
          dropdownStyle={{
            display: tablesDropdownVisible ? 'inline-block' : 'none',
            width: 'fit-content',
            maxWidth: '33%',
            minWidth: '15%'
          }}
          onDropdownVisibleChange={bool => setTablesDropdownVisible(bool)}
        >
          {tables.map(({ id, name }) => (
            <Select.Option value={id} key={id}>
              {name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Space>
  )
}

export default SelectDataFields
