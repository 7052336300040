import { IColumn, IRow } from '@/interfaces/interfaces'

export enum ActionTypes {
  REORDER = 'reorder',
  SORTBY = 'sort_by',
  FILTER = 'filter',
  REVERSE = 'reverse',
  TRANSPOSE = 'transpose',
  TOP_AND_BOTTOM = 'top_and_bottom',
  STRING_FILTER = 'string_filter',
  SHIFT_LABELS = 'shift_labels',
  COPY_ORDER = 'copy_order'
}

export interface BaseAction<T = null, K = number[]> {
  id?: number
  actionType: ActionTypes
  by?: K | number[]
  axis: 0 | 1
  specs?: T | object
  shapeDataSelection: number
  linkedDataSelection?: number
  valid?: boolean
}

export interface ActionProps<T = null> {
  isVisible: boolean
  clearOptions?: boolean
  columns?: IColumn[]
  rows?: IRow[]
  onSubmit?: (formValues) => void
  onCancel: () => void
  initialValues?: Partial<T>
}

type ReorderBy = {
  columns: (string | number)[]
  rows: (string | number)[]
}

export type ReorderAction = Omit<BaseAction<object, ReorderBy>, 'axis'>
export type TransposeAction = Omit<BaseAction, 'axis' | 'by'>
export type ReverseAction = Omit<BaseAction, 'by'>
export type SortByAction = BaseAction<{ ascending: boolean }>
export type FilterAction = BaseAction
export type TopAndBottomAction = BaseAction
export type CopyOrder = BaseAction<object, number[]>

export type Action<T extends ActionTypes = null> = T extends ActionTypes.REORDER
  ? ReorderAction
  : T extends ActionTypes.FILTER
  ? FilterAction
  : T extends ActionTypes.SORTBY
  ? SortByAction
  : T extends ActionTypes.REVERSE
  ? ReverseAction
  : T extends ActionTypes.TRANSPOSE
  ? TransposeAction
  : T extends ActionTypes.TOP_AND_BOTTOM
  ? TopAndBottomAction
  : T extends ActionTypes.COPY_ORDER
  ? CopyOrder
  :
      | ReorderAction
      | TransposeAction
      | ReverseAction
      | SortByAction
      | FilterAction
      | TopAndBottomAction
