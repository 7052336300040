import axios from 'axios'

import { DataTransformationDto, IInterpolation } from '@/interfaces/data-transformations'
import { IShapeData } from '@/interfaces/shape'

type DataSelectionDTO = DataTransformationDto & { shapeData: Partial<IShapeData> }

export async function createDataTransformation(dataTransformation: Partial<DataTransformationDto>) {
  const response = await axios.post<DataSelectionDTO>(`/api/data_selection/`, dataTransformation)
  return response.data
}

export async function createManyDataTransformation(
  dataTransformations: Partial<DataTransformationDto>[]
) {
  const response = await axios.post<DataSelectionDTO>(
    `/api/data_selection/bulk_create/`,
    dataTransformations
  )
  return response.data
}
export async function getDataTransformation(dataTransformId: number) {
  const response = await axios.get(`/api/data_selection/${dataTransformId}/`)
  return response.data
}

export async function updateDataTransformation(
  dataTransformId: number,
  dataTransformation: Partial<DataTransformationDto>
) {
  const response = await axios.put<DataSelectionDTO>(
    `/api/data_selection/${dataTransformId}/`,
    dataTransformation
  )
  return response.data
}

export async function createTextInterpolation(
  interpolation: IInterpolation
): Promise<IInterpolation> {
  const response = await axios.post(`/api/interpolation/`, interpolation)
  return response.data
}

export async function updateTextInterpolation(
  interpolationId: number,
  interpolation: Partial<IInterpolation>
) {
  const response = await axios.put<IInterpolation>(
    `/api/interpolation/${interpolationId}/`,
    interpolation
  )
  return response.data
}

export async function deleteTextInterpolation(interpolationId: number) {
  return (await axios.delete<void>(`/api/interpolation/${interpolationId}`)).data
}

export async function getTextInterpolation(interpolationId: number) {
  return (await axios.get(`/api/interpolation/${interpolationId}`)).data
}
