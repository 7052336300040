import React, { Component } from 'react'
import { SketchPicker } from 'react-color'

import { Button, Popover } from 'antd'

import PropTypes from 'prop-types'

class ColorPicker extends Component {
  static propTypes = {
    valueType: PropTypes.oneOf(['string', 'object'])
  }

  static defaultProps = {
    valueType: 'string'
  }

  handlers = {
    onChange: color => {
      const { onChange, valueType } = this.props
      let newColor
      if (valueType === 'string') {
        newColor = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
      } else {
        newColor = color.rgb
      }
      this.setState(
        {
          color: newColor
        },
        () => {
          onChange(newColor)
        }
      )
    }
  }

  state = {
    color: this.props.value || 'rgba(255, 255, 255, 1)'
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      color: nextProps.value || this.state.color
    })
  }

  getBackgroundColor = color => {
    if (typeof color === 'string') {
      return color
    }
    return `rgba(${color.r},${color.g},${color.b},${color.a})`
  }

  render() {
    const { color } = this.state
    const { onChange } = this.handlers
    return (
      <Popover
        trigger="click"
        placement="bottom"
        content={<SketchPicker color={color} onChange={onChange} />}
      >
        <div
          style={{
            background: this.getBackgroundColor(color),
            height: '24px',
            width: '24px',
            borderRadius: '50%',
            border: '1px solid black'
          }}
        />
      </Popover>
    )
  }
}

export default ColorPicker
