import axios from 'axios'

import { HealthStatusResponse } from '@/interfaces/health'

const API_URL = '/api'

export async function getHealthStatus(): Promise<HealthStatusResponse> {
  const url = `${API_URL}/health/`
  const response = await axios.get<HealthStatusResponse>(url)

  return response.data
}
