import { useState } from 'react'

import { Button, Empty } from 'antd'

import i18n from 'i18next'

import Icon from '@/components/icon/Icon'
import { DataUploader } from '@/components/imagemap/left-panel/data-source/DataUploader'

interface EmptyDataSourceUploader {
  onClose: () => void
}

const EmptyDataSourceUploader = ({ onClose }: EmptyDataSourceUploader) => {
  const [isDataUploaderVisible, changeDataUploaderVisibility] = useState(false)
  const closeModal = () => {
    changeDataUploaderVisibility(false)
  }
  return (
    <>
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
          <Button
            type="link"
            onClick={() => {
              changeDataUploaderVisibility(true)
              onClose()
            }}
          >
            <Icon name="plus" />
            {i18n.t('editor.right-panel.data-source.form.addData')}
          </Button>
        }
      />
      <DataUploader
        onCancel={() => closeModal()}
        isModalVisible={isDataUploaderVisible}
        onClose={closeModal}
        onComplete={closeModal}
      />
    </>
  )
}

export default EmptyDataSourceUploader
