import { Button, Modal } from 'antd'

import i18n from 'i18next'

import CommonModal from '@/components/common/CommonModal'
import Info from '@/components/common/Info'

import { ActionProps, ActionTypes } from './types'

const Transpose = ({ onSubmit, onCancel, isVisible }: ActionProps) => {
  const submit = () => {
    onSubmit({ actionType: ActionTypes.TRANSPOSE })
  }

  const popoverContent = (
    <div>
      <strong>Function</strong>
      <p>
        Switch the columns/rows in the mapped data table, for example to use with stacked chart
        objects
      </p>
      <strong>How to use</strong>
      <p>Click confirm to switch columns and rows in the object</p>
    </div>
  )

  return (
    <CommonModal
      visible={isVisible}
      onCancel={onCancel}
      onOk={submit}
      title={
        <span>
          Switch columns & rows <Info>{popoverContent}</Info>
        </span>
      }
      footer={[
        <Button key={0} type="primary" onClick={submit}>
          {i18n.t('editor.right-panel.data-source.form.okButton')}
        </Button>,
        <Button key={1} onClick={onCancel}>
          {i18n.t('editor.right-panel.data-source.form.cancelButton')}
        </Button>
      ]}
    >
      <div>Perform a transpose action?</div>
    </CommonModal>
  )
}

export default Transpose
