import axios from 'axios'
import useSWRImmutable from 'swr/immutable'

import { IRowsNColumns } from '@services/data-service'

const useRowsNColumns = (dataTableId: number) => {
  const fetcher = async url => (await axios.get(url)).data
  const { data: labels, error } = useSWRImmutable<IRowsNColumns>(
    () => (dataTableId != null ? `/api/data_tables/${dataTableId}/labels/` : null),
    fetcher
  )
  return {
    error: error || null,
    rows: labels?.rows || [],
    columns: labels?.columns || [],
    loading: dataTableId != null && !labels && !error
  }
}

export { useRowsNColumns }
