import { useState } from 'react'

import { Checkbox, Col, Form, Input, Row, Select } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import i18n from 'i18next'

import CommonModal from '@/components/common/CommonModal'

export type VisualisationOptions = {
  visualise:
    | 'symbols'
    | 'cell-background'
    | 'colored-labels'
    | 'symbols-colored-labels'
    | 'values-colored-labels'
  symbol: 'arrow' | 'pyramid'
  significantColor: string
  insignificantColor: string
  position: 'top' | 'bottom' | 'right' | 'left'
}

export enum PositionOptions {
  right = 'right',
  left = 'left',
  top = 'top',
  bottom = 'bottom'
}

export enum VisualisationSymbols {
  arrowUp = '↑',
  arrowDown = '↓',
  pyramidUp = '▲',
  pyramidDown = '▼'
}

export type TAllvsAllSettings = {
  useBrackets: boolean
  useUppercase: boolean
}

export const sigVisualisationDefaults: VisualisationOptions = {
  visualise: 'symbols',
  symbol: 'arrow',
  position: 'right',
  significantColor: '#4c9320',
  insignificantColor: '#a02220'
}

export const allVsAllDefaults: TAllvsAllSettings = {
  useBrackets: true,
  useUppercase: false
}

function AllVsAllForm({ initialValues, visible, onCancel, onOk }) {
  const [form] = useForm()
  const onSubmit = () => {
    form.submit()
    onOk(form.getFieldsValue())
  }
  return (
    <CommonModal
      title="Visualisation settings"
      visible={visible}
      onCancel={onCancel}
      onOk={() => onSubmit()}
    >
      <Form form={form} initialValues={initialValues} layout="horizontal">
        <Form.Item name="useBrackets" valuePropName="checked" style={{ marginLeft: 10 }}>
          <Checkbox>Show brackets</Checkbox>
        </Form.Item>

        <Form.Item name="useUppercase" valuePropName="checked" style={{ marginLeft: 10 }}>
          <Checkbox>Use uppercase letters</Checkbox>
        </Form.Item>
      </Form>
    </CommonModal>
  )
}

function CommonTableForm({ initialValues, visible, onCancel, onOk }) {
  const [form] = useForm()
  const [visualise, setVisualise] = useState<string>(() => initialValues.visualise || 'symbols')
  const onSubmit = () => {
    form.submit()
    onOk(form.getFieldsValue())
  }
  return (
    <CommonModal
      title="Visualisation settings"
      visible={visible}
      onCancel={onCancel}
      onOk={() => onSubmit()}
    >
      <Form form={form} initialValues={initialValues} layout="vertical">
        <Row gutter={16}>
          <Col span={14}>
            <Form.Item
              label={i18n.t('editor.right-panel.analytics.visualisation.visualise')}
              name="visualise"
            >
              <Select<string | number, { value: string; children: string }>
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={value => setVisualise(value as string)}
              >
                <Select.Option value="cell-background">
                  {i18n.t('editor.right-panel.analytics.visualisation.cell-background')}
                </Select.Option>
                <Select.Option value="symbols">
                  {i18n.t('editor.right-panel.analytics.visualisation.only-symbols')}
                </Select.Option>
                <Select.Option value="colored-labels">
                  {i18n.t('editor.right-panel.analytics.visualisation.color-coded-labels')}
                </Select.Option>
                <Select.Option value="symbols-colored-labels">
                  {i18n.t('editor.right-panel.analytics.visualisation.symbols-and-labels')}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          {['symbols', 'symbols-colored-labels'].includes(visualise) && (
            <>
              <Col span={8}>
                <Form.Item
                  label={i18n.t('editor.right-panel.analytics.visualisation.symbols')}
                  name="symbol"
                >
                  <Select<string | number, { value: string; children: string }>
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder={i18n.t('editor.right-panel.analytics.visualisation.select-one')}
                  >
                    <Select.Option value="pyramid">▲ ▼</Select.Option>
                    <Select.Option value="arrow">↑ ↓</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label={'Position'} name="position">
                  <Select placeholder="Position">
                    {Object.keys(PositionOptions).map(
                      (position: keyof typeof PositionOptions, posIdx) => (
                        <Select.Option key={posIdx} value={position}>
                          {position}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </>
          )}
        </Row>

        <Row gutter={16} style={{ marginTop: '2rem' }}>
          <Col span={6}>
            <Form.Item
              label={i18n.t('editor.right-panel.analytics.visualisation.significant-color')}
              name="significantColor"
              style={{ alignItems: 'center' }}
            >
              <Input type="color" style={{ width: '48px' }} />
            </Form.Item>
          </Col>

          <Col span={7}>
            <Form.Item
              label={i18n.t('editor.right-panel.analytics.visualisation.insignificant-color')}
              name="insignificantColor"
              style={{ alignItems: 'center' }}
            >
              <Input type="color" style={{ width: '48px' }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </CommonModal>
  )
}

function CommonChartForm({ initialValues, visible, onCancel, onOk }) {
  const [visualise, setVisualise] = useState<string>(initialValues.visualise)
  const [form] = useForm()
  const onSubmit = () => {
    form.submit()
    onOk(form.getFieldsValue())
  }
  return (
    <CommonModal
      title="Visualisation settings"
      visible={visible}
      onCancel={onCancel}
      onOk={() => onSubmit()}
    >
      <Form form={form} initialValues={initialValues} layout="vertical">
        <Row gutter={16}>
          <Col span={14}>
            <Form.Item
              label={i18n.t('editor.right-panel.analytics.visualisation.visualise')}
              name="visualise"
            >
              <Select<string | number, { value: string; children: string }>
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={value => setVisualise(value as string)}
              >
                <Select.Option value="symbols">
                  {i18n.t('editor.right-panel.analytics.visualisation.only-symbols')}
                </Select.Option>
                <Select.Option value="colored-labels">
                  {i18n.t('editor.right-panel.analytics.visualisation.color-coded-labels')}
                </Select.Option>
                <Select.Option value="symbols-colored-labels">
                  {i18n.t('editor.right-panel.analytics.visualisation.symbols-and-labels')}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          {['symbols', 'symbols-colored-labels'].includes(visualise) && (
            <>
              <Col span={8}>
                <Form.Item
                  label={i18n.t('editor.right-panel.analytics.visualisation.symbols')}
                  name="symbol"
                >
                  <Select<string | number, { value: string; children: string }>
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder={i18n.t('editor.right-panel.analytics.visualisation.select-one')}
                  >
                    <Select.Option value="pyramid">▲ ▼</Select.Option>
                    <Select.Option value="arrow">↑ ↓</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label={'Position'} name="position">
                  <Select placeholder="Position">
                    {Object.keys(PositionOptions).map(
                      (position: keyof typeof PositionOptions, posIdx) => (
                        <Select.Option key={posIdx} value={position}>
                          {position}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </>
          )}
        </Row>

        <Row gutter={16} style={{ marginTop: '2rem' }}>
          <Col span={6}>
            <Form.Item
              label={i18n.t('editor.right-panel.analytics.visualisation.significant-color')}
              name="significantColor"
              style={{ alignItems: 'center' }}
            >
              <Input type="color" style={{ width: '48px' }} />
            </Form.Item>
          </Col>

          <Col span={7}>
            <Form.Item
              label={i18n.t('editor.right-panel.analytics.visualisation.insignificant-color')}
              name="insignificantColor"
              style={{ alignItems: 'center' }}
            >
              <Input type="color" style={{ width: '48px' }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </CommonModal>
  )
}

export function composeInitialValues(analysisType, initialValues) {
  initialValues ||= {}
  if (analysisType === 'all_vs_all') {
    return { ...allVsAllDefaults, ...initialValues }
  } else {
    return { ...sigVisualisationDefaults, ...initialValues }
  }
}

export function getVisualSettingsComponent(analysisType, shapeType) {
  if (analysisType === 'all_vs_all') {
    return AllVsAllForm
  } else if (shapeType === 'table') {
    return CommonTableForm
  } else {
    return CommonChartForm
  }
}
