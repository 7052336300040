import { useCallback, useEffect, useMemo, useState } from 'react'

import { getSlideThumbnail } from '@/services/slide-service'

import { toDataUrl } from '@/utils/file-tools'

import { isEqual } from 'lodash'

export const useThumbnails = (slideIds: number[], projectId: number) => {
  const [thumbnails, setThumbnails] = useState<{ [key: number]: string }>({})

  const currentSlideIds = useMemo(() => {
    return Array.from(Object.keys(thumbnails)).map(Number)
  }, [Object.keys(thumbnails).length])

  const fetchThumbs = useCallback(async () => {
    let acc = {}
    for (const slideId of slideIds) {
      if (!currentSlideIds.includes(slideId)) {
        try {
          const thumb = await getSlideThumbnail(projectId, slideId)
          const dataUrl = await toDataUrl(thumb)
          acc[slideId] = dataUrl
        } catch (e) {
          console.error(e.message)
        }
      }
    }
    setThumbnails({ ...thumbnails, ...acc })
  }, [projectId, slideIds.length, currentSlideIds.length, Object.keys(thumbnails).length])

  useEffect(() => {
    if (!isEqual(slideIds, currentSlideIds)) {
      fetchThumbs()
    }
  }, [fetchThumbs, slideIds.length, currentSlideIds.length])

  return {
    thumbnails: thumbnails
  }
}
