import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Form, Slider, Switch } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import { Shadow } from 'fabric/fabric-impl'
import { debounce } from 'lodash'

import { updateOneCanvasObject } from '@store/slices/shapes/actions'
import { selectCanvasSelectedObject } from '@store/slices/shapes/selectors'

import ColorPicker from '../../../common/ColorPicker'

const ShadowProperty = () => {
  const selectedItem = useSelector(selectCanvasSelectedObject)
  const [form] = useForm()
  const dispatch = useDispatch()
  const enabled = selectedItem.shadow ? (selectedItem.shadow as any).enabled || false : false

  useEffect(() => {
    form.resetFields()
  }, [form, selectedItem])

  const changeProperties = debounce((changedValues, allValues) => {
    if (allValues.enabled) {
      if ('blur' in allValues) {
        dispatch(updateOneCanvasObject({ id: selectedItem.id, changes: { shadow: allValues } }))
      } else {
        const shadow = {
          enabled: true,
          blur: 15,
          offsetX: 10,
          offsetY: 10
        }
        dispatch(updateOneCanvasObject({ id: selectedItem.id, changes: { shadow } }))
      }
    } else {
      dispatch(updateOneCanvasObject({ id: selectedItem.id, changes: { shadow: null } }))
    }
  }, 200)

  return (
    <Form form={form} onValuesChange={changeProperties}>
      <Form.Item
        valuePropName="checked"
        name="enabled"
        initialValue={enabled}
        label={"Shadow Enabled'"}
        colon={false}
      >
        <Switch size="small" />
      </Form.Item>
      {enabled ? (
        <>
          <Form.Item
            name="color"
            initialValue={(selectedItem.shadow as Shadow).color || 'rgba(0, 0, 0, 0)'}
            label={'Color'}
            colon={false}
          >
            <ColorPicker />
          </Form.Item>
          <Form.Item
            rules={[
              {
                type: 'number',
                min: 0,
                max: 100
              }
            ]}
            name="blur"
            initialValue={(selectedItem.shadow as Shadow).blur || 15}
            label={'Blur'}
            colon={false}
          >
            <Slider min={0} max={100} />
          </Form.Item>
          <Form.Item
            name="offsetX"
            rules={[
              {
                type: 'number',
                min: 0,
                max: 100
              }
            ]}
            initialValue={(selectedItem.shadow as Shadow).offsetX || 10}
            label={'Offset X'}
            colon={false}
          >
            <Slider min={0} max={100} />
          </Form.Item>
          <Form.Item
            name="offsetY"
            rules={[
              {
                type: 'number',
                min: 0,
                max: 100
              }
            ]}
            initialValue={(selectedItem.shadow as Shadow).offsetY || 10}
            label={'Offset Y'}
            colon={false}
          >
            <Slider min={0} max={100} />
          </Form.Item>
        </>
      ) : null}
    </Form>
  )
}

export default ShadowProperty
