import { useHistory } from 'react-router-dom'

import { Button, Form, Input, Layout, notification, Space } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import { MailOutlined } from '@ant-design/icons'

import { sendForgotPasswordEmail } from '@services/auth-service'

import { Navbar } from '@components/common/Navbar'

import '../styles.less'

import { useState } from 'react'

const { Header, Content } = Layout

const notify = (notificationType: 'success' | 'error') => {
  if (notificationType === 'success') {
    notification.success({
      message: 'Email sent',
      description: 'Check your email and open the link to continue',
      className: 'notification',
      onClick: () => {},
      style: {
        backgroundColor: '#f6ffed',
        borderColor: '#c6efa7'
      }
    })
  }
  if (notificationType === 'error') {
    notification.error({
      message: 'Email address not found',
      description: 'Try a different email or contact support@indicolabs.io',
      className: 'notification',
      onClick: () => {},
      style: {
        backgroundColor: '#fff2f0',
        borderColor: '#ffdeda'
      }
    })
  }
}

const ForgotPassword = () => {
  const history = useHistory()
  const [form] = useForm()
  const [isLoading, setIsLoading] = useState(false)

  const forgotPassword = async () => {
    const email = form.getFieldValue('email')
    setIsLoading(true)
    const response = await sendForgotPasswordEmail({ email })
    if (response.status === 200) {
      notify('success')
    } else {
      notify('error')
    }
    setIsLoading(false)
    form.setFieldsValue({ email: '' })
  }

  return (
    <Layout className="layout" style={{ background: 'white' }}>
      <Header style={{ background: 'white' }}>
        <Navbar
          onPage="main"
          right={<Button onClick={() => history.push('sign-up')}>Sign up</Button>}
        />
      </Header>
      <Content>
        <div className="auth__container">
          <div className="auth__card">
            <div className="card__header">
              <h1>Forgot password</h1>
              <p>Enter your email address and we'll send you a link to reset your password</p>
            </div>

            <div className="card__body">
              <Form
                form={form}
                onFinish={forgotPassword}
                layout="vertical"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                autoComplete="off"
              >
                <Space direction="vertical" size="middle">
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      { required: true, type: 'email', message: 'Please enter a valid email' }
                    ]}
                  >
                    <Input placeholder="Email" prefix={<MailOutlined />} />
                  </Form.Item>

                  <Form.Item wrapperCol={{ offset: 4, span: 16 }} style={{ marginTop: '18px' }}>
                    <Button type="primary" htmlType="submit" loading={isLoading} block>
                      Send link to email
                    </Button>
                  </Form.Item>
                </Space>
              </Form>
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default ForgotPassword
