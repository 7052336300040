import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Form, InputNumber, Space, Spin, Switch } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import i18n from 'i18next'

import { selectCurrentProjectId } from '@/store/slices/projects/selectors'
import { refreshSlide } from '@/store/slices/slides/actions'
import {
  selectCanvasSelectedObject,
  selectCanvasSelectedObjectPk
} from '@store/slices/shapes/selectors'

import { updateShape } from '@/services/shape-service'

import { FabricChartOptions } from '@/components/canvas/types/chart'

import { getNumberFormat, getValuesFromNumberFormat } from '../utils'

interface IForm {
  labels: boolean
  percent: boolean
  decimal: number
  source: boolean
}

const DataLabelProperty = () => {
  const selectedObject = useSelector(selectCanvasSelectedObject) as FabricChartOptions
  const dispatch = useDispatch()
  const projectId = useSelector(selectCurrentProjectId)
  const shapeId = useSelector(selectCanvasSelectedObjectPk)
  const [form] = useForm<IForm>()
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [decimalPlaces, setDecimalPlaces] = useState<number>(0)
  const keys = Object.keys(selectedObject.chart.plots[0].series)
  const numberFormat = selectedObject?.visualSettings?.numberFormat

  //Update form whenever new object is selected.
  useEffect(() => {
    //Set form to default values.
    const [decimalPlaces, _showPercentage] = getValuesFromNumberFormat(numberFormat)
    form.setFieldsValue({
      percent: selectedObject?.visualSettings?.numberFormat?.includes('%'),
      labels: selectedObject.chart.plots[0].series[keys[0]].dataLabels.showValue,
      decimal: decimalPlaces,
      source: false
    })
  }, [selectedObject])

  const onChangeDataLabels = async (show: boolean) => {
    const newPlots = JSON.parse(JSON.stringify(selectedObject.chart.plots[0]))
    Object.keys(newPlots.series).map(each => {
      newPlots.series[each].dataLabels.showValue = show
    })
    const payload = {
      shape_contents: { ...selectedObject.chart, plots: [newPlots] },
      visualSettings: { ...(selectedObject?.visualSettings || {}), dataLabelsVisibility: show }
    }
    setLoading(true)
    await updateShape({ projectId, shapeId, payload })
    setLoading(false)
    dispatch(refreshSlide())
  }

  const onChangeShowAsPercentage = async (percent: boolean) => {
    const { decimal } = form.getFieldsValue(true)
    const code = getNumberFormat(decimal, percent)
    const newPlots = JSON.parse(JSON.stringify(selectedObject.chart.plots[0]))

    const payload = {
      shape_contents: { ...selectedObject.chart, plots: [newPlots] },
      visualSettings: { numberFormat: code }
    }
    setLoading(true)
    await updateShape({ projectId, shapeId, payload })
    setLoading(false)
    dispatch(refreshSlide())
  }

  const onChangeDecimalPlaces = async (decimal: number) => {
    const { percent } = form.getFieldsValue(true)
    const code = getNumberFormat(decimal, percent)
    const newPlots = JSON.parse(JSON.stringify(selectedObject.chart.plots[0]))

    if (decimal !== null && decimal !== undefined) {
      setTimeout(() => {
        setDecimalPlaces(decimal)
      }, 1200)
      Object.keys(newPlots.series).map(each => {
        Object.keys(newPlots.series[each].values).map(
          value =>
            (newPlots.series[each].values[value] = (
              Math.round(newPlots.series[each].values[value] * 100) / 100
            ).toFixed(decimal))
        )
      })
      const payload = {
        shape_contents: { ...selectedObject.chart, plots: [newPlots] },
        visualSettings: { numberFormat: code }
      }
      setLoading(true)
      await updateShape({ projectId, shapeId, payload })
      setLoading(false)
      dispatch(refreshSlide())
    }
  }

  const onChangeSource = value => {
    // const { labels, percent, decimal, source } = form.getFieldsValue(true)
    // const code = getNumberFormat(decimal, percent)
    // const newPlots = JSON.parse(JSON.stringify(selectedObject.chart.plots[0]))

    if (value === true) {
      form.setFieldsValue({ labels: true })
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }

  return (
    <Spin spinning={loading}>
      <Form form={form}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form.Item
            name="labels"
            label={i18n.t('editor.right-panel.shape-settings.data-labels')}
            valuePropName="checked"
          >
            <Switch disabled={disabled} onChange={(bool: boolean) => onChangeDataLabels(bool)} />
          </Form.Item>

          {/* 
          <Form.Item
            name="percent"
            label={i18n.t('editor.right-panel.shape-settings.percent')}
            valuePropName="checked"
          >
            <Switch onChange={(bool: boolean) => onChangeShowAsPercentage(bool)} />
          </Form.Item>

          <Form.Item
            name="decimal"
            label={i18n.t('editor.right-panel.shape-settings.decimal-places')}
          >
            <InputNumber value={decimalPlaces} onChange={onChangeDecimalPlaces} step={1} min={0} />
          </Form.Item>

          <Form.Item
            name="source"
            label={i18n.t('editor.right-panel.shape-settings.linked-to-source')}
            valuePropName="checked"
          >
            <Switch onChange={onChangeSource} />
          </Form.Item>
          */}
        </Space>
      </Form>
    </Spin>
  )
}

export default DataLabelProperty
