import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Form, Radio } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import i18n from 'i18next'

import { updateOneCanvasObject } from '@store/slices/shapes/actions'
import { selectCanvasSelectedObject } from '@store/slices/shapes/selectors'

import { FabricImageOptions } from '@components/canvas/types/image'
import UrlModal from '@components/imagemap/right-panel/properties/UrlModal'

import FileUpload from '../../../common/FileUpload'

const ImageProperty = () => {
  const selectedItem = useSelector(selectCanvasSelectedObject) as FabricImageOptions
  const [form] = useForm()
  const dispatch = useDispatch()
  const imageLoadType = selectedItem.imageLoadType || 'file'

  const changeProperty = changedValues => {
    const changedKey = Object.keys(changedValues)[0]
    const changedValue = changedValues[changedKey] as File

    if (changedKey === 'file') {
      const reader = new FileReader()
      const uid = JSON.stringify(changedValue)
      reader.onload = () => {
        dispatch(
          updateOneCanvasObject({
            id: selectedItem.id,
            changes: { src: reader.result as string, file: JSON.parse(uid) }
          })
        )
      }

      reader.readAsDataURL(changedValue)
    } else {
      dispatch(
        updateOneCanvasObject({ id: selectedItem.id, changes: { [changedKey]: changedValue } })
      )
    }
  }

  useEffect(() => {
    form.resetFields()
  }, [form, selectedItem])

  return (
    <Form form={form} onValuesChange={changeProperty}>
      <Form.Item
        label={i18n.t('editor.right-panel.properties.image.image-load-type')}
        colon={false}
        name="imageLoadType"
        initialValue={imageLoadType}
      >
        <Radio.Group size="small">
          <Radio.Button value="file">
            {i18n.t('editor.right-panel.properties.image.file-upload')}
          </Radio.Button>
          <Radio.Button value="src">
            {i18n.t('editor.right-panel.properties.image.image-url')}
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
      {imageLoadType === 'file' ? (
        <Form.Item
          label={i18n.t('common.file')}
          colon={false}
          name="file"
          rules={[
            {
              required: true,
              message: i18n.t('validation.enter-property', { arg: i18n.t('common.file') })
            }
          ]}
        >
          <FileUpload accept="image/*" />
        </Form.Item>
      ) : (
        <Form.Item name="src" initialValue={selectedItem.src}>
          <UrlModal url={selectedItem.src} />
        </Form.Item>
      )}
    </Form>
  )
}

export default ImageProperty
