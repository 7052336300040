import { createEntityAdapter, createReducer } from '@reduxjs/toolkit'
import { fabric } from 'fabric'

import { FabricPoint } from '@store/slices/canvas/types'
import {
  setCanvasZoom,
  setCanvasZoomPoint,
  setCanvasZoomRatio,
  setCanvasZoomViewportTransform
} from '@store/slices/canvas/zoom/actions'

export interface ZoomState {
  zoomPoint: FabricPoint | null
  viewportTransform: number[]
  zoomRatio: number
  minZoom: number
  maxZoom: number
}

const initialState: ZoomState = {
  zoomPoint: null,
  zoomRatio: 1,
  viewportTransform: fabric.iMatrix.concat(),
  minZoom: 30,
  maxZoom: 500
}

export const zoomReducer = createReducer(initialState, builder => {
  builder.addCase(setCanvasZoomPoint, (state, { payload }) => {
    state.zoomPoint = payload
  })
  builder.addCase(setCanvasZoomRatio, (state, { payload }) => {
    state.zoomRatio = payload
  })
  builder.addCase(setCanvasZoom, (state, { payload }) => {
    state.zoomPoint = payload.point
    state.zoomRatio = payload.ratio
  })
  builder.addCase(setCanvasZoomViewportTransform, (state, { payload }) => {
    state.viewportTransform = payload
  })
})
