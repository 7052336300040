import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Button, Radio, Tag } from 'antd'

import { intersection } from 'lodash'

import { selectShapeData } from '@store/slices/shapes/selectors'

import { useLocalStorage } from '@/hooks'

const { CheckableTag } = Tag

const InputTableWrapper = ({ children, hasData, handleSelectData, rowMetaTags, setLeftIsOpen }) => {
  const { dataSelection } = useSelector(selectShapeData)
  const uniqueRowTags: string[] = useMemo(
    () => Array.from(new Set(rowMetaTags || [])),
    [rowMetaTags]
  )
  const [selectionStrategy, setSelectionStrategy] = useLocalStorage<
    'column' | 'row' | 'freeform' | 'group'
  >('selectionStrategy', 'column')
  const [activeRowTags, setActiveRowTags] = useLocalStorage<string[]>('rowTags', [])

  const disabledRows = useMemo(() => {
    return (
      rowMetaTags
        ?.map((el, idx) => {
          return activeRowTags.includes(el) ? null : idx
        })
        ?.filter(el => el !== null) || []
    )
  }, [activeRowTags, rowMetaTags])

  useEffect(() => {
    if (uniqueRowTags.length > 0 && intersection(uniqueRowTags, activeRowTags).length === 0) {
      setActiveRowTags([])
    }

    if (dataSelection?.rowTags) {
      setActiveRowTags(dataSelection.rowTags)
    } else if (activeRowTags.length === 0) {
      setActiveRowTags(uniqueRowTags)
    }

    if (dataSelection?.selectionStrategy) {
      setSelectionStrategy(
        {
          by_column_position: 'column',
          by_name: 'column',
          by_absolute_position: 'freeform',
          by_parent_group: 'group',
          by_group_inclusion: 'group',
          by_group_name: 'group'
        }[dataSelection.selectionStrategy]
      )
    }
  }, [dataSelection, uniqueRowTags])

  const selectBtnDisabled = !hasData || activeRowTags.length === 0

  const childrenProps = {
    disabledRows,
    selectionStrategy
  }

  const onCheckTag = (tag: string, checked: boolean) => {
    if (checked) {
      setActiveRowTags([...activeRowTags, tag])
    } else {
      setActiveRowTags(activeRowTags.filter(x => x !== tag))
    }
  }
  return (
    <div className="input-table-col">
      <div className="header-section">
        <h3>Input table</h3>
      </div>
      <div className="table-container">
        {hasData ? (
          children(childrenProps)
        ) : (
          <div className="centered-btn">
            <Button
              onClick={() => {
                setLeftIsOpen(true)
              }}
            >
              Select Data
            </Button>
          </div>
        )}
      </div>

      {hasData && (
        <div className="control-panel">
          <div className="control-panel-item">
            <span className="description">Row tags:</span>
            {uniqueRowTags.map((tag: string) => (
              <CheckableTag
                key={tag}
                checked={activeRowTags?.includes(tag)}
                onChange={checked => onCheckTag(tag, checked)}
              >
                {tag}
              </CheckableTag>
            ))}
          </div>

          <div className="control-panel-item">
            <span className="description">Selection mode:</span>
            <Radio.Group
              size="small"
              buttonStyle="solid"
              value={selectionStrategy}
              onChange={e => setSelectionStrategy(e.target.value)}
            >
              <Radio.Button value="column">Column</Radio.Button>
              <Radio.Button value="row">Row</Radio.Button>
              <Radio.Button value="freeform">Freeform</Radio.Button>
              <Radio.Button value="group">Group</Radio.Button>
            </Radio.Group>
          </div>
        </div>
      )}
      <div className="section">
        <div>
          {hasData ? (
            <Button
              disabled={selectBtnDisabled}
              type="default"
              onClick={() => {
                handleSelectData(activeRowTags, selectionStrategy)
              }}
            >
              Select
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default InputTableWrapper
