export enum CeleryStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  PENDING = 'PENDING',
  STARTED = 'STARTED',
  REVOKED = 'REVOKED'
}

export interface TemplateUploadResult {
  filename: string
  filetype: string
  id: number
  projectId: number
  templateJson: {
    slides: any[]
  }
  template_s3_path: string
}

export interface CeleryResponse<T = any> {
  error?: string
  status: CeleryStatus
  message?: string
  result?: T
}
