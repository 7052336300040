import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import { updateOneCanvasObject } from '@store/slices/shapes/actions'
import { selectCanvasSelectedObject } from '@store/slices/shapes/selectors'

import { FabricImageOptions } from '@components/canvas/types/image'
import UrlModal from '@components/imagemap/right-panel/properties/UrlModal'

const IframeProperty = () => {
  const selectedItem = useSelector(selectCanvasSelectedObject) as FabricImageOptions
  const dispatch = useDispatch()
  const [form] = useForm()

  const changeProperty = changedValues => {
    const changedKey = Object.keys(changedValues)[0]
    const changedValue = changedValues[changedKey]

    dispatch(updateOneCanvasObject({ id: selectedItem.id, changes: { src: changedValue } }))
  }

  useEffect(() => {
    form.resetFields()
  }, [form, selectedItem])

  return (
    <Form form={form} onValuesChange={changeProperty}>
      <Form.Item
        name="src"
        rules={[
          {
            required: true,
            message: 'Please select image'
          }
        ]}
        initialValue={selectedItem.src}
      >
        <UrlModal url={selectedItem.src} />
      </Form.Item>
    </Form>
  )
}

export default IframeProperty
