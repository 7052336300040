import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { fabric } from 'fabric'

import { RootState } from '@store/rootReducer'
import {
  selectCanvasCenterPoint,
  selectCanvasHeight,
  selectCanvasWidth
} from '@store/slices/canvas/canvas/selectors'
import { FabricPoint } from '@store/slices/canvas/types'
import { selectCanvasZoomRatio } from '@store/slices/canvas/zoom/selectors'
import { selectCanvasObjectById, selectCanvasSelectedObject } from '@store/slices/shapes/selectors'

import { fabricCanvas } from '@components/canvas/CanvasObject'

interface CanvasZoom {
  point: FabricPoint
  ratio: number
}

export const zoomOneToOne = createAsyncThunk<void, void, { state: RootState }>(
  'canvas/zoom/zoomOneToOne',
  async (_arg, { getState, dispatch }) => {
    const state = getState()
    const center = selectCanvasCenterPoint(state)
    dispatch(setCanvasZoomViewportTransform([1, 0, 0, 1, 0, 0]))
    dispatch(setCanvasZoom({ point: center, ratio: 1 }))
  }
)

//bug occurs when resize window small
//pushes objects to the side os they aren't in viewport?
export const zoomToFit = createAsyncThunk<void, void, { state: RootState }>(
  'canvas/zoom/zoomToFit',
  async (_arg, { getState, dispatch }) => {
    const state = getState()
    const center = selectCanvasCenterPoint(state)
    const workArea = selectCanvasObjectById(state, 'workarea')
    const canvasWidth = selectCanvasWidth(state)
    const canvasHeight = selectCanvasHeight(state)
    // Canvas padding
    const padding = canvasWidth / 10
    let scaleX = (canvasWidth - padding) / workArea.width
    let scaleY = (canvasHeight - padding) / workArea.height
    if (workArea.height >= workArea.width) {
      scaleX = scaleY
      if (canvasWidth < workArea.width * scaleX) {
        scaleX = scaleX * (canvasWidth / (workArea.width * scaleX))
      }
    } else {
      if (canvasHeight < workArea.height * scaleX) {
        scaleX = scaleX * (canvasHeight / (workArea.height * scaleX))
      }
    }
    let objArr = fabricCanvas.getObjects()
    //@ts-ignore
    const selection = objArr.filter(obj => obj.id === 'workarea')

    //@ts-ignore
    const selection2 = objArr.filter(obj => obj.id !== 'workarea')
    let selectObjects = new fabric.ActiveSelection(selection, {
      canvas: fabricCanvas
    })
    let selectObjects2 = new fabric.ActiveSelection(selection2, {
      canvas: fabricCanvas
    })
    const leftBefore = selectObjects.left
    const topBefore = selectObjects.top
    fabricCanvas.setActiveObject(selectObjects)
    fabricCanvas.viewportCenterObject(selectObjects)
    selectObjects.setCoords()
    fabricCanvas.discardActiveObject()

    const leftAfter = selectObjects.left
    const leftDiff = leftBefore - leftAfter

    const topAfter = selectObjects.top
    const topDiff = topBefore - topAfter
    fabricCanvas.setActiveObject(selectObjects2)
    selectObjects2.left = selectObjects2.left - leftDiff
    selectObjects2.top = selectObjects2.top - topDiff
    selectObjects2.setCoords()
    fabricCanvas.discardActiveObject()
    dispatch(setCanvasZoom({ point: center, ratio: scaleX }))
  }
)

export const zoomIn = createAsyncThunk<void, void, { state: RootState }>(
  'canvas/zoom/zoomIn',
  async (_arg, { getState, dispatch }) => {
    const state = getState()
    const zoomRatio = selectCanvasZoomRatio(state)
    const center = selectCanvasCenterPoint(state)
    dispatch(setCanvasZoom({ point: center, ratio: zoomRatio + 0.05 }))
  }
)

export const zoomOut = createAsyncThunk<void, void, { state: RootState }>(
  'canvas/zoom/zoomOut',
  async (_arg, { getState, dispatch }) => {
    const state = getState()
    const zoomRatio = selectCanvasZoomRatio(state)
    const center = selectCanvasCenterPoint(state)
    dispatch(setCanvasZoom({ point: center, ratio: zoomRatio - 0.05 }))
  }
)

export const zoomToRatio = createAsyncThunk<void, number, { state: RootState }>(
  'canvas/zoom/zoomToRatio',
  async (scalingRatio, { getState, dispatch }) => {
    const state = getState()
    const zoomRatio = selectCanvasZoomRatio(state)
    const center = selectCanvasCenterPoint(state)
    dispatch(setCanvasZoom({ point: center, ratio: zoomRatio * scalingRatio }))
  }
)

export const zoomToCenter = createAsyncThunk<void, { zoomFit?: boolean }, { state: RootState }>(
  'canvas/zoom/zoomOut',
  async ({ zoomFit }, { getState, dispatch }) => {
    const state = getState()
    const zoomRatio = selectCanvasZoomRatio(state)
    const center = selectCanvasCenterPoint(state)
    const activeObject = selectCanvasSelectedObject(state)
    const canvasWidth = selectCanvasWidth(state)
    const canvasHeight = selectCanvasHeight(state)
    if (!activeObject) {
      return
    }
    const { x: canvasLeft, y: canvasTop } = center
    const { left, top, width, height } = activeObject
    const diffTop = canvasTop - (top + height / 2)
    const diffLeft = canvasLeft - (left + width / 2)
    if (zoomFit) {
      let scaleX
      let scaleY
      scaleX = canvasWidth / width
      scaleY = canvasHeight / height
      if (height > width) {
        scaleX = scaleY
        if (canvasWidth < width * scaleX) {
          scaleX = scaleX * (canvasWidth / (width * scaleX))
        }
      } else {
        scaleY = scaleX
        if (canvasHeight < height * scaleX) {
          scaleX = scaleX * (canvasHeight / (height * scaleX))
        }
      }
      dispatch(setCanvasZoomViewportTransform([1, 0, 0, 1, diffLeft, diffTop]))
      dispatch(setCanvasZoom({ point: { x: canvasLeft, y: canvasTop }, ratio: scaleX }))
    } else {
      dispatch(setCanvasZoomViewportTransform([1, 0, 0, 1, diffLeft, diffTop]))
      dispatch(setCanvasZoom({ point: { x: canvasLeft, y: canvasTop }, ratio: zoomRatio }))
    }
  }
)

export const setCanvasZoomPoint = createAction<FabricPoint>('canvas/zoom/setCanvasZoomPoint')
export const setCanvasZoomRatio = createAction<number>('canvas/zoom/setCanvasZoomRatio')
export const setCanvasZoomViewportTransform = createAction<number[]>(
  'canvas/zoom/setCanvasZoomViewportTransform'
)
export const setCanvasZoom = createAction<CanvasZoom>('canvas/zoom/setCanvasZoom')
