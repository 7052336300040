import { createReducer } from '@reduxjs/toolkit'

import {
  setCanvasCenterPoint,
  setCanvasDimensions,
  setCanvasFilter,
  setCanvasInteractionMode,
  setCanvasLoading,
  setCanvasLoadingMessage
} from '@store/slices/canvas/canvas/actions'
import { FabricPoint } from '@store/slices/canvas/types'

import { InteractionMode } from '@components/canvas'

export interface CanvasState {
  interactionMode: InteractionMode | null
  loading: boolean
  loadingMsg: string
  width: number
  height: number
  centerPoint: FabricPoint | null
  filter: string[]
}

const initialState: CanvasState = {
  interactionMode: 'selection',
  width: 0,
  height: 0,
  centerPoint: null,
  loading: false,
  loadingMsg: '',
  filter: ['NO_SHAPE_TYPE']
}

export const canvasReducer = createReducer(initialState, builder => {
  builder
    .addCase(setCanvasInteractionMode, (state, action) => {
      state.interactionMode = action.payload
    })
    .addCase(setCanvasLoading, (state, action) => {
      state.loading = action.payload
    })
    .addCase(setCanvasLoadingMessage, (state, action) => {
      state.loadingMsg = action.payload
    })
    .addCase(setCanvasDimensions, (state, action) => {
      state.width = action.payload.width
      state.height = action.payload.height
    })
    .addCase(setCanvasCenterPoint, (state, action) => {
      state.centerPoint = action.payload
    })
    .addCase(setCanvasFilter, (state, action) => {
      state.filter = action.payload
    })
})
