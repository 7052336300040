import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fabric } from 'fabric'

import { selectCanvasInteractionMode } from '@store/slices/canvas/canvas/selectors'
import { clearCanvasSelection, selectCanvasObject } from '@store/slices/shapes/actions'
import { AppDispatch } from '@store/store'

import { FabricEvent } from '@components/canvas/types/event'

import { useCanvas } from '../../hooks/useCanvas'

function useEventsHandler() {
  const canvas = useCanvas()
  const interactionMode = useSelector(selectCanvasInteractionMode)
  const dispatch: AppDispatch = useDispatch()

  useEffect(() => {
    let panning = false
    const onSelect = ({ target }: FabricEvent) => {
      if (target && target.id && target.id !== 'workarea' && target.type !== 'activeSelection') {
        dispatch(selectCanvasObject(target))
      } else {
        dispatch(clearCanvasSelection())
      }
    }
    const onMouseDown = () => {
      if (interactionMode === 'grab') {
        panning = true
      }
    }
    const onMouseMove = event => {
      if (panning && interactionMode === 'grab') {
        const delta = new fabric.Point(event.e.movementX, event.e.movementY)
        canvas.relativePan(delta)
        canvas.requestRenderAll()
      }
    }
    const onMouseUp = () => {
      if (interactionMode === 'grab') {
        panning = false
      }
    }

    if (canvas) {
      canvas.on('mouse:down', onMouseDown)
      canvas.on('mouse:move', onMouseMove)
      canvas.on('mouse:up', onMouseUp)
      canvas.on('selection:cleared', onSelect)
      canvas.on('selection:created', onSelect)
      canvas.on('selection:updated', onSelect)
    }
    return () => {
      if (canvas) {
        canvas.off('mouse:down', onMouseDown)
        canvas.off('mouse:move', onMouseMove)
        canvas.off('mouse:up', onMouseUp)
        canvas.off('selection:cleared', onSelect)
        canvas.off('selection:created', onSelect)
        canvas.off('selection:updated', onSelect)
      }
    }
  }, [canvas, dispatch, interactionMode])
}

export default useEventsHandler
