import { ChartType } from 'chart.js'
import { Font } from 'chartjs-plugin-datalabels/types/options'
import { merge } from 'lodash'

import { applyNumberFormatting } from '@components/canvas/utils/'

export const DEFAULT_CHART_OPTIONS = {
  radar: {
    plugins: {
      datalabels: {
        display: false
      }
    }
  },
  horizontalBar: {
    plugins: {
      datalabels: {
        formatter: function (value: number, context) {
          const numberFormat: string = context.dataset?.numberFormat || 'General'
          return applyNumberFormatting(value, numberFormat)
        },
        font: {
          size: 11
        } as Font,
        color: 'black',
        offset: 0,
        anchor: 'center',
        align: 'right'
      },
      legend: {
        reverse: true
      }
    }
  },
  bar: {
    plugins: {
      datalabels: {
        formatter: function (value: number, context) {
          const numberFormat: string = context.dataset?.numberFormat || 'General'
          return applyNumberFormatting(value, numberFormat)
        },
        font: {
          size: 11
        } as Font,
        color: 'black',
        offset: 0,
        anchor: 'end',
        align: 'top'
      }
    }
  },
  // pie: {
  //   plugins: {
  //     datalabels: {
  //       formatter: function (value: number, context) {
  //         const numberFormat: string = context.dataset?.numberFormat || 'General'
  //         return applyNumberFormatting(value, numberFormat)
  //       },
  //       font: {
  //         size: 11
  //       } as Font,
  //       color: 'black',
  //       offset: 0,
  //       anchor: 'end',
  //       align: 'end'
  //     }
  //   }
  // },
  stacked: {
    plugins: {
      datalabels: {
        formatter: function (value: number, context) {
          const numberFormat: string = context.dataset?.numberFormat || 'General'
          return applyNumberFormatting(value, numberFormat)
        },
        font: {
          size: 11
        } as Font,
        color: 'black',
        offset: 0,
        anchor: 'center',
        align: 'right'
      }
    }
  },
  stacked100: {
    plugins: {
      datalabels: {
        formatter: function (value: number, context) {
          const numberFormat: string = context.dataset?.numberFormat || 'General'
          return applyNumberFormatting(value, numberFormat)
        },
        font: {
          size: 11
        } as Font,
        color: 'black',
        offset: 0,
        anchor: 'center',
        align: 'right'
      }
    }
  }
}

export const DEFAULT_CHART_DATASET_OPTIONS = {
  line: {
    datalabels: {
      align: 'end',
      anchor: 'end'
    }
  },
  horizontalBar: {
    datalabels: {
      color: 'black',
      anchor: 'center',
      align: 'center',
      offset: 0
    }
  },
  bar: {
    datalabels: {
      color: 'black',
      anchor: 'end',
      align: 'top',
      offset: 0
    }
  },
  stacked100: {
    datalabels: {
      color: 'black',
      anchor: 'center',
      align: 'center',
      offset: 0
    }
  },
  stacked: {
    datalabels: {
      color: 'black',
      anchor: 'center',
      align: 'center',
      offset: 0
    }
  },
  default: {
    fill: false,
    datalabels: {
      formatter: function (value: number, context) {
        let formatCode: string = context.dataset?.formatCode || 'General'
        let sourceLinked = context.dataset?.sourceLinked
        if (!context.dataset?.showValues) {
          formatCode = 'Unlabelled'
        }
        return applyNumberFormatting(value, formatCode, sourceLinked)
      },
      align: 'start',
      anchor: 'end',
      clamp: true
    }
  }
}

export function getDefaultChartOptions(type: ChartType) {
  return merge({}, DEFAULT_CHART_OPTIONS[type])
}

export function getDefaultChartDatasetOptions(type: ChartType, modifier: string) {
  return merge(
    {},
    DEFAULT_CHART_DATASET_OPTIONS.default,
    DEFAULT_CHART_DATASET_OPTIONS[type],
    DEFAULT_CHART_DATASET_OPTIONS[modifier]
  )
}

export const BACKGROUND_COLORS = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
  'rgba(85, 239, 196,1)',
  'rgba(129, 236, 236,1)',
  'rgba(116, 185, 255,1)',
  'rgba(162, 155, 254,1)',
  'rgba(255, 234, 167,1)',
  'rgba(255, 234, 167,1)',
  'rgba(250, 177, 160,1)',
  'rgba(255, 118, 117,1)',
  'rgba(253, 121, 168,1)'
]

export const CHART_EVENTS = {
  mousemove: 'mousemove',
  mousedown: 'click',
  mouseout: 'mouseout',
  touchstart: 'touchstart',
  touchmove: 'touchmove'
}
