import { createReducer } from '@reduxjs/toolkit'
import _ from 'lodash'

import {
  addManySlides,
  addSlide,
  clearAllSlides,
  deleteManySlidesById,
  deleteSlideById,
  setAllSlides,
  setCurrentSlideId,
  setSlideObjectState
} from '@store/slices/slides/actions'

import { ISlideList } from '@/interfaces/slide'

type SlideCollection = {
  [key: number]: { shapes: number[]; template: number }
}

interface InitialState {
  entities: SlideCollection
  allIds: number[]
  current: number
  slidesObjectState: any
}

const initialState: InitialState = {
  entities: [],
  allIds: [],
  current: null,
  slidesObjectState: {}
}

function formatSlideList(slides: ISlideList['objects']): SlideCollection {
  const entries = slides.map(object => {
    return [object.id, { shapes: object.shapes, template: object.templateId }]
  })
  return Object.fromEntries(entries)
}

export const slidesReducer = createReducer(initialState, builder => {
  builder.addCase(clearAllSlides, state => {
    state.entities = []
    state.allIds = []
    state.current = null
    state.slidesObjectState = {}
  })
  builder.addCase(setAllSlides, (state, { payload }) => {
    state.entities = { ...state.entities, ...formatSlideList(payload.objects) }
    state.allIds = payload.order
    state.current = payload.order[0]
  })
  builder.addCase(setCurrentSlideId, (state, { payload }) => {
    state.current = payload
  })
  builder.addCase(setSlideObjectState, (state, { payload }) => {
    state.slidesObjectState = payload
  })
  builder.addCase(deleteSlideById, (state, { payload }) => {
    let { [payload]: _omitted, ...rest } = state.entities
    state.entities = rest
    state.allIds = state.allIds.filter(id => id !== payload)
  })
  builder.addCase(deleteManySlidesById, (state, { payload }) => {
    state.entities = _.omit(state.entities, payload)
    state.allIds = state.allIds.filter(id => !payload.includes(id))
  })
  builder.addCase(addSlide, (state, { payload }) => {
    state.entities[payload.object.id] = {
      shapes: payload.object.shapes,
      template: payload.object.templateId
    }
    state.allIds = payload.order
  })
  builder.addCase(addManySlides, (state, { payload }) => {
    state.entities = { ...state.entities, ...formatSlideList(payload.objects) }
    state.allIds = payload.order
  })
})
