import axios, { AxiosResponse } from 'axios'

import {
  ForgotPasswordParams,
  GetCurrentUserResponse,
  MFASignInParams,
  MFASignInResponse,
  RefreshTokenParams,
  RefreshTokenResponse,
  ReSendActivationEmailParams,
  ResetPasswordParams,
  SignInParams,
  SignUpParams,
  SignUpResponse
} from '@/interfaces/auth'

const API_URL = '/api'

export async function signUp(params: SignUpParams): Promise<SignUpResponse> {
  const url = `${API_URL}/account/`
  const response = await axios.post<SignUpResponse>(url, params)
  return response.data
}

export async function mfaSignIn(params: MFASignInParams): Promise<MFASignInResponse> {
  const url = `${API_URL}/auth/mfa-code/`
  const response = await axios.post<MFASignInResponse>(url, params)
  return response.data
}

export async function getCurrentUser(): Promise<GetCurrentUserResponse> {
  const url = `${API_URL}/auth/get-user/`
  const response = await axios.post<GetCurrentUserResponse>(url)
  return response.data
}

export async function signIn(params: SignInParams): Promise<MFASignInResponse> {
  const url = `${API_URL}/auth/sign-in/`
  const response = await axios.post<MFASignInResponse>(url, params)
  return response.data
}

export async function sendForgotPasswordEmail(
  params: ForgotPasswordParams
): Promise<AxiosResponse<string>> {
  const url = `${API_URL}/auth/reset-password/`
  return await axios.post<string>(url, params)
}

export async function resetPassword({
  newPassword,
  repeatPassword,
  token,
  uid
}: ResetPasswordParams): Promise<AxiosResponse<string>> {
  const url = `${API_URL}/auth/reset-password/${uid}/`
  return await axios.put<string>(url, { newPassword, repeatPassword, token })
}

export async function getMfaCode(params) {
  const url = `${API_URL}/auth/mfa-code/`
  const response = await axios.post<string>(url, params)
  return response.data
}

export async function refreshToken(params: RefreshTokenParams): Promise<RefreshTokenResponse> {
  const url = `${API_URL}/token/refresh/`
  const response = await axios.post<RefreshTokenResponse>(url, params)
  return response.data
}

export async function reSendActivationEmail(params: ReSendActivationEmailParams): Promise<string> {
  const url = `${API_URL}/auth/activate/`
  const response = await axios.post<string>(url, params)
  return response.data
}
