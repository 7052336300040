import { UploadFile } from 'antd/lib/upload/interface'

import { createAction } from '@reduxjs/toolkit'
import axios, { CancelToken } from 'axios'
import isNil from 'lodash/isNil'

import {
  selectDataSourceAddStatus,
  selectUploadDataSourceCeleryTaskId
} from '@store/slices/data/selectors'
import { DataSourceAddStatus, DataSourceAddStatuses } from '@store/slices/data/types'
import { selectCurrentProjectId } from '@store/slices/projects/selectors'
import { selectSlide } from '@store/slices/slides/actions'
import { selectCurrentSlideId } from '@store/slices/slides/selectors'

import { cancelCeleryTask, getCeleryTaskStatus } from '@services/celery-service'
import {
  deleteProjectDataSource,
  getDataSourceSections,
  getRawData,
  updateProjectDataSource
} from '@services/data-service'
import { getProjectByID, uploadProjectFile } from '@services/project-service'

import { CeleryStatus } from '@/interfaces/celery'
import {
  CheckProjectDataSourceCompatibilityResponse,
  DataSectionDto,
  DataSourceDto
} from '@/interfaces/data'
import { IParser } from '@/interfaces/interfaces'
import { ProjectDto, ProjectFileTypes } from '@/interfaces/projects'

import { getContentType } from '@/utils/contentType'
import { delayPromise } from '@utils/promise'

export const setDataSources = createAction<DataSourceDto[]>('data/setDataSources')
export const addDataSourceUploadStatus = createAction<DataSourceAddStatus>(
  'data/addDataSourceUploadStatus'
)
export const clearDataSourceUpload = createAction('data/clearDataSourceUpload')
export const setDataSourceUploadCompatibility =
  createAction<CheckProjectDataSourceCompatibilityResponse>('data/setDataSourceUploadCompatibility')
export const setDataSourceUploadDataSource = createAction<DataSourceDto>(
  'data/setDataSourceUploadDataSource'
)
export const setDataSourceUploadCeleryTaskId = createAction<string>(
  'data/setDataSourceUploadCeleryTaskId'
)
export const setDataSourceUploadDataSections = createAction<DataSectionDto[]>(
  'data/setDataSourceUploadDataSections'
)
export const setDataSourceUploadRawData = createAction<string[]>('data/setDataSourceUploadRawData')

export const addDataSource =
  (
    { file, parser, projectId }: { file: UploadFile; parser: IParser; projectId: number },
    cancelToken?: CancelToken
  ) =>
  async (dispatch, getState): Promise<void> => {
    try {
      const state = getState()
      // const projectId = selectCurrentProjectId(state)
      dispatch(addDataSourceUploadStatus(CeleryStatus.PENDING))
      const { url, data: newDataSource } = await uploadProjectFile(
        {
          fileName: file.name,
          projectId,
          type: ProjectFileTypes.DATA,
          parserId: parser.id
        },
        cancelToken
      )
      let contentType = getContentType(file.name)
      await axios.put(url, file, {
        headers: { 'Content-Type': contentType },
        cancelToken
      })

      if (parser && parser.hasSections) {
        const { taskId } = await getDataSourceSections(
          { projectId, dataId: newDataSource.id },
          cancelToken
        )
        dispatch(setDataSourceUploadCeleryTaskId(taskId))
        await new Promise<DataSectionDto[]>(async (resolve, reject) => {
          try {
            const getAddDataSourceStatus = async () => {
              const response = await getCeleryTaskStatus<DataSectionDto[]>(
                { taskId: taskId },
                cancelToken
              )

              if (response.status === CeleryStatus.SUCCESS) {
                dispatch(setDataSourceUploadDataSections(response.result))
                resolve(response.result)
              } else if (response.status === CeleryStatus.FAILURE) {
                dispatch(addDataSourceUploadStatus(CeleryStatus.FAILURE))
                reject()
              } else {
                await delayPromise(1000)
                await getAddDataSourceStatus()
              }
            }

            await getAddDataSourceStatus()
          } catch (e) {
            console.error(e)
            dispatch(addDataSourceUploadStatus(CeleryStatus.FAILURE))
            reject(e)
          }
        })
        dispatch(addDataSourceUploadStatus(DataSourceAddStatuses.SECTIONS_SELECTION))
        dispatch(
          setDataSourceUploadDataSource({
            ...newDataSource
          })
        )
      } else if (parser?.name === 'SPSS') {
        const { taskId } = await getRawData({ projectId, dataId: newDataSource.id }, cancelToken)
        dispatch(setDataSourceUploadCeleryTaskId(taskId))
        await new Promise<string[]>(async (resolve, reject) => {
          try {
            const getAddDataSourceStatus = async () => {
              const response = await getCeleryTaskStatus<string[]>({ taskId: taskId }, cancelToken)

              if (response.status === CeleryStatus.SUCCESS) {
                dispatch(setDataSourceUploadRawData(response.result))
                resolve(response.result)
              } else if (response.status === CeleryStatus.FAILURE) {
                dispatch(addDataSourceUploadStatus(CeleryStatus.FAILURE))
                reject()
              } else {
                await delayPromise(1000)
                await getAddDataSourceStatus()
              }
            }

            await getAddDataSourceStatus()
          } catch (e) {
            console.error(e)
            dispatch(addDataSourceUploadStatus(CeleryStatus.FAILURE))
            reject(e)
          }
        })
        dispatch(addDataSourceUploadStatus(DataSourceAddStatuses.RAW_DATA_SELECTION))
        dispatch(
          setDataSourceUploadDataSource({
            ...newDataSource
          })
        )
      } else {
        await dispatch(
          updateDataSource(
            {
              projectId,
              dataSourceId: newDataSource.id,
              dataSource: { parserId: parser.id, filename: file.name }
            },
            cancelToken
          )
        )
      }
    } catch (e) {
      dispatch(addDataSourceUploadStatus(CeleryStatus.FAILURE))
      throw new Error(e?.response?.data?.error?.message || e.result)
    }
  }

export const updateDataSource =
  (
    {
      dataSourceId,
      dataSource,
      compatibility,
      projectId
    }: {
      dataSourceId: number
      dataSource: Partial<DataSourceDto>
      compatibility?: boolean
      projectId: number
    },
    cancelToken?: CancelToken
  ) =>
  async (dispatch, getState): Promise<DataSourceDto[]> => {
    try {
      const state = getState()
      const selectedSlide = selectCurrentSlideId(state)
      dispatch(addDataSourceUploadStatus(CeleryStatus.PENDING))
      const { taskId } = await updateProjectDataSource(
        {
          projectId,
          dataId: dataSourceId,
          newData: dataSource,
          compatibility
        },
        cancelToken
      )
      dispatch(setDataSourceUploadCeleryTaskId(taskId))
      return new Promise<DataSourceDto[]>(async (resolve, reject) => {
        try {
          const getUpdateDataSourceStatus = async () => {
            const response = await getCeleryTaskStatus<DataSourceDto>(
              { taskId: taskId },
              cancelToken
            )
            if (response.result && typeof response.result !== 'object') {
              throw response
            }
            dispatch(addDataSourceUploadStatus(response.status))

            if (response.status === CeleryStatus.SUCCESS) {
              let dataSources = [...state.data.dataSources, response.result]
              dispatch(setDataSources(dataSources))
              if (!isNil(selectedSlide) && !isNil(compatibility)) {
                await dispatch(selectSlide(selectedSlide))
              }
              resolve(dataSources)
            } else if (response.status === CeleryStatus.FAILURE) {
              reject()
            } else {
              await delayPromise(1000)
              await getUpdateDataSourceStatus()
            }
          }

          await getUpdateDataSourceStatus()
        } catch (e) {
          dispatch(addDataSourceUploadStatus(CeleryStatus.FAILURE))
          reject(e?.response?.data?.error?.message || e.result)
        }
      })
    } catch (e) {
      dispatch(addDataSourceUploadStatus(CeleryStatus.FAILURE))
      throw e
    }
  }

export const deleteDataSource = (dataSourceId: number) => async (dispatch, getState) => {
  try {
    const state = getState()
    const projectId = selectCurrentProjectId(state)
    const selectedSlide = selectCurrentSlideId(state)
    await deleteProjectDataSource(projectId, dataSourceId)
    const projectDto: ProjectDto = await getProjectByID({ projectId })
    dispatch(setDataSources(projectDto.dataSources || []))
    if (!isNil(selectedSlide)) {
      await dispatch(selectSlide(selectedSlide))
    }
  } catch (e) {}
}

export const cancelDataSourceUpload = () => async (_dispatch, getState) => {
  try {
    const state = getState()
    const status = selectDataSourceAddStatus(state)
    const celeryTaskId = selectUploadDataSourceCeleryTaskId(state)
    if (
      !isNil(celeryTaskId) &&
      status !== CeleryStatus.FAILURE &&
      status !== CeleryStatus.SUCCESS
    ) {
      await cancelCeleryTask({ taskId: celeryTaskId })
    }
  } catch (e) {}
}
