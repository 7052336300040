import { zip } from 'lodash'

const createTableContext = (data, rows, columns, meta) => {
  const x = data.length > 0 ? data[0].length + 1 : 0
  const y = meta.length + columns.length + data.length
  const metaRange = [
    [0, 0],
    [meta.length - 1, 0]
  ]
  const columnsRange = [
    [metaRange[1][0] + 1, 1],
    [metaRange[1][0] + columns.length, x - 1]
  ]
  const rowsRange = [
    [columnsRange[1][0] + 1, 0],
    [columnsRange[1][0] + rows.length, 0]
  ]
  const dataRange = [
    [rowsRange[0][0], 1],
    [rowsRange[1][0], x - 1]
  ]
  return {
    x,
    y,
    meta: metaRange,
    header: {
      row: rowsRange,
      col: columnsRange
    },
    data: dataRange
  }
}

const createRow = (values, context, shift = 0) => {
  if (values.length + shift > context.x) {
    throw new Error('A new row cannot be created: improper `context.x` value')
  }
  const array1 = Array(shift).fill('')
  const array2 = Array(context.x - values.length).fill('')
  return array1.concat(values).concat(array2)
}

const mergeData = (data, rowHeaders, colHeaders, tableMeta, context) => {
  let mergedData = []

  for (const metaRow of tableMeta) {
    const value = metaRow[metaRow.length - 1].value
    mergedData.push(createRow([value], context))
  }

  for (const colRow of colHeaders) {
    const unwrappedCol = []
    for (const cell of colRow) {
      let i = 0
      while (cell.span > i) {
        unwrappedCol.push(cell.name)
        i++
      }
    }
    mergedData.push(createRow(unwrappedCol, context, 1))
  }

  for (const [name, values] of zip(rowHeaders, data)) {
    mergedData.push(createRow([name, ...(values as string[])], context))
  }
  return mergedData
}

const getMergeCells = (colHeaders, context) => {
  const mergeCells = []
  let i = 0
  for (const colRow of colHeaders) {
    let j = 0
    for (const cell of colRow) {
      if (cell.span > 1) {
        mergeCells.push({
          row: i + context.header.col[0][0],
          col: j + 1,
          colspan: cell.span,
          rowspan: 1
        })
        j += cell.span
      } else {
        j++
      }
    }
    i++
  }
  return mergeCells
}

export { createTableContext, mergeData, getMergeCells }
