import { Button, Progress, Result, Space } from 'antd'

import { ICompatibilityReport } from './SwitchData'

interface DataSourceCompatibilityProps {
  report: ICompatibilityReport
  onConfirm: () => void
  onCancel: () => void
}

const DataSourceCompatibility = ({ report, onConfirm, onCancel }: DataSourceCompatibilityProps) => {
  let { compatibility } = report
  const reportStatus = compatibility === 0 ? 'fail' : compatibility === 1 ? 'success' : 'partial'
  const states = {
    success: {
      icon: <Progress type="circle" percent={compatibility * 100} status={'success'} />,
      title: 'No compatibility issues found',
      subTitle: 'Operation can be performed without data loss',
      extra: [
        <Button type="primary" key="perform" onClick={onConfirm}>
          Perform
        </Button>,
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>
      ]
    },
    partial: {
      icon: <Progress type="circle" percent={compatibility * 100} status={'normal'} />,
      title: 'Some compatibility issues found',
      subTitle: 'Operation can be performed but some data will be lost. See the report for details',
      extra: [
        <Button type="primary" key="perform" onClick={onConfirm}>
          Perform
        </Button>,
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>
      ]
    },
    fail: {
      icon: <Progress type="circle" percent={compatibility * 100} status={'exception'} />,
      title: 'Selected data sources are found incompatible',
      subTitle: 'All mapped data entries have compatibility issues',
      extra: [
        <Button type="primary" key="perform" onClick={onConfirm}>
          Perform
        </Button>,
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>
      ]
    }
  }
  return (
    <Space size="middle" direction="vertical" align="center" style={{ width: '100%' }}>
      <Result {...states[reportStatus]} />
    </Space>
  )
}

export default DataSourceCompatibility
