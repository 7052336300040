import ReactDOM from 'react-dom'

import { ConfigProvider } from 'antd'
import enUS from 'antd/lib/locale-provider/en_US'

import reportWebVitals from '@/reportWebVitals'

import Container from './containers/Container'

import './index.less'
import './i18n/index'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Provider } from 'react-redux'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { PersistGate } from 'redux-persist/integration/react'

import store, { persistor } from '@/store/store'

Chart.plugins.register(ChartDataLabels)

Sentry.init({
  dsn: 'https://4ac16ec4bf294095bd819add1cf451c7@o1009339.ingest.sentry.io/5998699',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={enUS}>
      <PersistGate loading={null} persistor={persistor}>
        <Container />
      </PersistGate>
    </ConfigProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
