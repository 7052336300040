import { combineReducers } from '@reduxjs/toolkit'

import { authReducer } from '@/store/slices/auth/reducer'
import { projectsReducer } from '@/store/slices/projects/reducer'
import { slidesReducer } from '@/store/slices/slides/reducer'
import { canvasReducer } from '@store/slices/canvas/canvas/reducer'
import { zoomReducer } from '@store/slices/canvas/zoom/reducer'
import { dataReducer } from '@store/slices/data/reducer'
import { healthReducer } from '@store/slices/health/reducer'
import { shapesReducer } from '@store/slices/shapes/reducer'
import { templateReducer } from '@store/slices/template/reducer'

const rootReducer = combineReducers({
  canvas: combineReducers({
    canvas: canvasReducer,
    zoom: zoomReducer
  }),
  projects: projectsReducer,
  slides: slidesReducer,
  shapes: shapesReducer,
  template: templateReducer,
  data: dataReducer,
  auth: authReducer,
  health: healthReducer
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
