import { createReducer } from '@reduxjs/toolkit'

import { setHealthStatus } from '@store/slices/health/actions'
import { getHealthStatus } from '@store/slices/health/thunks'
import { IHealthState } from '@store/slices/health/types'

const initialState: IHealthState = {
  healthStatus: null,
  getHealthStatusReq: {
    loading: false,
    error: null
  }
}

export const healthReducer = createReducer(initialState, builder => {
  builder.addCase(setHealthStatus, (state, { payload }) => {
    state.healthStatus = payload
  })
  builder.addCase(getHealthStatus.pending, state => {
    if (!state.getHealthStatusReq.loading) {
      state.getHealthStatusReq.loading = true
      state.getHealthStatusReq.error = null
    }
  })
  builder.addCase(getHealthStatus.fulfilled, (state, { payload }) => {
    if (state.getHealthStatusReq.loading) {
      state.getHealthStatusReq.loading = false
      state.getHealthStatusReq.error = null
    }
  })
  builder.addCase(getHealthStatus.rejected, (state, { payload }) => {
    if (state.getHealthStatusReq.loading) {
      state.getHealthStatusReq.loading = false
      state.getHealthStatusReq.error = payload
    }
  })
})
