import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Button, Space, Tag } from 'antd'

import { HotTable, HotTableProps } from '@handsontable/react'
import Handsontable from 'handsontable/base'
import { registerAllModules } from 'handsontable/registry'
import { isEqual, range } from 'lodash'

import { ITableViewData } from '@services/data-service'

import { applyNumberFormatting } from '@/components/canvas'
import CommonModal from '@/components/common/CommonModal'

import { DataTransformationDto, IInterpolation } from '@/interfaces/data-transformations'

import TableMetaDataView from './TableMetaDataView'

import './tableView.css'

import Info from '@/components/common/Info'

registerAllModules()

const { CheckableTag } = Tag

const hotSettings: HotTableProps = {
  data: null,
  colHeaders: true,
  rowHeaders: true,
  width: 'auto',
  height: 'auto',
  columnHeaderHeight: 68,
  rowHeaderWidth: 200,
  selectionMode: 'multiple',
  licenseKey: 'non-commercial-and-evaluation',
  outsideClickDeselects: false
}

type HotData = (string | number)[][]
type SelectedCells = { [key: number]: [number[], number[]] }
type OnFinishProps = {
  rows?: number[]
  columns?: number[]
  sourceType?: 'meta' | 'data'
  rowTags?: string[]
}
type ColNestedHeaders = { label: string; colspan: number }[][]

type ColHeadersType = { label: string; colspan: number }[][]

/**
 * Converts `DataFrame` to the format acceptable for `HotTable`.
 * Uses number format code if available.
 * Return value example:
 * [
 *   ["", "Tesla", "Volvo", "Toyota", "Honda"],
 *   ["2020", 10, 11, 12, 13],
 *   ["2021", 20, 11, 14, 13],
 *   ["2022", 30, 15, 12, 13]
 * ]
 * @param {ITableViewData} tableDataFrame - DataFrame.to_dict('tight') format
 * with extra meta data from excel parser
 * @returns {HotData}
 */
function convertData(tableDataFrame: ITableViewData): HotData {
  if (tableDataFrame == null) {
    return null
  }
  let rows = tableDataFrame.index
  let values = tableDataFrame.data.map((row, rowIndex) =>
    row.map((value, colIndex) => {
      const formatCode = tableDataFrame.tableMeta?.numberFormat?.[rowIndex]?.[colIndex]
      if (formatCode) {
        return applyNumberFormatting(value, formatCode)
      }
      return value
    })
  )
  let ret = []
  if (rows) {
    rows.forEach((_row, idx) => ret.push([...values[idx]]))
  }
  return ret
}

function findOverlappings(spannings) {
  let i = 0
  let baddies = []
  while (i < Object.keys(spannings).length - 1) {
    let p = 0
    let q = 0
    let accVal = 0

    while (q < spannings[i + 1].length) {
      accVal += spannings[i + 1][p]
      if (accVal < spannings[i][p]) {
        // pass
      } else if (accVal === spannings[i][p]) {
        accVal = 0
        p++
      } else {
        baddies.push(i + 1)
        break
      }
      q++
    }
    i++
  }
  return baddies
}

/**
 * Get ColHeaders from `DataFrame`
 * Return value example:
 *   ["Tesla", "Volvo", "Toyota", "Honda"]
 * @param {ITableViewData} tableDataFrame - DataFrame.to_dict('tight') format
 * @returns {string[]}
 */
function getColHeaders(tableDataFrame: ITableViewData): ColHeadersType {
  if (tableDataFrame == null || !tableDataFrame?.headers) {
    return null
  }
  let spannings = {}
  let headers = tableDataFrame.headers.map((header, index) => {
    spannings[index] = []
    return header.map(cell => {
      let cellName = cell.name.trim().substring(0, 40)
      spannings[index].push(cell.span)
      return { label: cellName, colspan: cell.span }
    })
  })
  const overlappings = findOverlappings(spannings)
  headers = headers.filter((_, idx) => !overlappings.includes(idx))
  return headers
}

/** From handsontable instance selections to col/row lists of ids. */
function formatSelections(selections: SelectedCells): { columns: number[]; rows: number[] } {
  let rows = []
  let columns = []
  Object.values(selections).forEach(selection => {
    rows = [...rows, ...selection[0]]
    columns = [...columns, ...selection[1]]
  })
  return {
    rows: rows.filter((item, index, arr) => {
      return arr.indexOf(item) === index && item >= 0
    }), // remove duplicates
    columns: columns.filter((item, index, arr) => {
      return arr.indexOf(item) === index && item >= 0
    })
  }
}

/** List of row and column ids to ranges [start, end].
 * Example: [1, 2, 4, 5, 6, 7] => [1, 2], [4, 7]
 */
function sequenceToRange(ids: number[]) {
  let ret = []
  let temp = []
  let i = 0
  do {
    temp.push(ids[i])
    if (ids[i] + 1 !== ids[i + 1]) {
      if (temp.length === 1) {
        ret.push([temp[0], temp[0]])
      } else {
        ret.push([temp[0], temp[temp.length - 1]])
      }
      temp = []
    }
    i++
  } while (i < ids.length)
  return ret
}

function getSelectedCells(dataTransformation): [number, number, number, number][] {
  if (dataTransformation.rows.length === 0 || dataTransformation.columns.length === 0) {
    return []
  }
  let cols = sequenceToRange(dataTransformation.columns)
  let rows = sequenceToRange(dataTransformation.rows)
  let selectionsAcc = []
  rows.forEach(rowEntry => {
    cols.forEach(colEntry => {
      let selection = [rowEntry[0], colEntry[0], rowEntry[1], colEntry[1]]
      selectionsAcc.push(selection)
    })
  })
  return selectionsAcc
}

type ShowTableViewProps = {
  show: boolean
  tableDataFrame: ITableViewData
  dataTransformation?: DataTransformationDto
  onFinish: (value: OnFinishProps, newSelection?: boolean) => void
  onCancel: () => void
  setActiveRowTags: (value: string[]) => void
  activeRowTags: string[]
  interpolation?: IInterpolation
  selectionMode?: 'multiple' | 'single'
  resetSelection?: boolean
}

const TableView = ({
  show,
  tableDataFrame,
  dataTransformation,
  onFinish,
  onCancel,
  interpolation,
  activeRowTags,
  setActiveRowTags,
  selectionMode = 'multiple',
  resetSelection
}: ShowTableViewProps) => {
  const selectedCells = useRef<SelectedCells>(null)
  const firstRender = useRef(true)
  // component state
  const [cleared, setCleared] = useState(false)
  const [clearBool, setClearBool] = useState(false)
  const [metaPayload, setMetaPayload] = useState<{ col: number; row: number }>(null)
  const [resize, setResize] = useState(-1)
  const [sourceType, setSourceType] = useState<'data' | 'meta'>(null)
  const [multipleSelection, setMultipleSelection] = useState(false)

  // computed / derived
  const rowsMetaTags = useMemo(() => tableDataFrame?.rowsMeta || [], [tableDataFrame])
  const uniqueRowTags = Array.from(new Set(rowsMetaTags))
  const colHeaders = useMemo(() => getColHeaders(tableDataFrame), [tableDataFrame])
  const rowHeaders = useMemo(() => {
    return (tableDataFrame?.index || []).filter((_, idx) => {
      if (activeRowTags.length > 0) {
        return activeRowTags.includes(rowsMetaTags[idx])
      } else {
        return true
      }
    })
  }, [rowsMetaTags, activeRowTags, tableDataFrame])

  const data = useMemo(() => {
    const convertedData = convertData(tableDataFrame) || []
    return convertedData.filter((_, index) => {
      if (activeRowTags.length > 0) {
        return activeRowTags.includes(rowsMetaTags[index])
      } else {
        return true
      }
    })
  }, [tableDataFrame, rowsMetaTags, activeRowTags])

  const onOk = async () => {
    if (metaPayload) {
      selectedCells.current = null
      setSourceType(null)
      onFinish({
        rows: [metaPayload?.row],
        columns: [metaPayload?.col],
        sourceType: 'meta'
      })
    }
    if (selectedCells.current) {
      let ret = formatSelections(selectedCells.current)
      ret['rowTags'] = activeRowTags
      ret['sourceType'] = 'data'
      // turn row names into a range then filter out the row indices that are excluded by the selected meta tags
      if (activeRowTags.length > 0) {
        let rowIndices = range(tableDataFrame.index.length).filter((_, index) =>
          activeRowTags.includes(rowsMetaTags[index])
        )
        ret['rows'] = rowIndices.filter((_x, index) => ret['rows'].includes(index))
      }
      let updateHappened = dataTransformation
        ? !isEqual(ret, {
            rows: dataTransformation.rows,
            columns: dataTransformation.columns,
            sourceType: 'data'
          })
        : true

      onFinish(ret, updateHappened)
    }
  }

  const handleAfterSelection = (...[row, col, row2, col2, index]: number[]) => {
    let prevSelection = selectedCells.current || {}
    let selection: [number[], number[]] = [range(row, row2 + 1), range(col, col2 + 1)]
    // -1 in the selection means a banner cell was clicked
    if (col2 === -1 || row2 === -1) {
      selection = [[row], [col]]
    }
    let obj = { [index]: selection }
    if (Object.keys(prevSelection).includes(index.toString())) {
      // new selection
      selectedCells.current = obj
      setMultipleSelection(false)
    } else {
      // multiple selections
      selectedCells.current = { ...prevSelection, ...obj }
      setMultipleSelection(true)
    }
    setMetaPayload(null)
    setSourceType('data')
  }

  const hotRef = useCallback(
    node => {
      const shouldDeselect =
        cleared ||
        sourceType === 'meta' ||
        (sourceType === 'data' && !dataTransformation && !interpolation && !firstRender.current)
      if (!node) return

      if (data) {
        node.hotInstance.render()
      }

      if (multipleSelection) {
        node.hotInstance.selectCells(getSelectedCells(formatSelections(selectedCells.current)))
        setMultipleSelection(false)
      }

      if (dataTransformation && !cleared && resetSelection && firstRender.current) {
        let selectedCells = getSelectedCells(dataTransformation)
        const filteredRowIndices = rowsMetaTags
          .map((tag, index) => {
            return activeRowTags.includes(tag) ? index : null
          })
          .filter(x => x != null)

        if (activeRowTags.length > 0) {
          selectedCells = selectedCells.map(arr => {
            return [
              filteredRowIndices.indexOf(arr[0]),
              arr[1],
              filteredRowIndices.indexOf(arr[2]),
              arr[3]
            ]
          })
        }
        node.forceUpdate()
        node.hotInstance.selectCells(selectedCells)
        firstRender.current = false
      } else if (
        interpolation &&
        interpolation.sourceType === 'data' &&
        !sourceType &&
        interpolation.row != null &&
        interpolation.column != null
      ) {
        node.forceUpdate()
        node.hotInstance.selectCell(interpolation.row, interpolation.column)
      } else if (shouldDeselect) {
        node.forceUpdate()
        node.hotInstance.deselectCell()
        setCleared(false)
      } else if (resize > -1) {
        node.hotInstance.render()
      }
    },
    [
      dataTransformation,
      data,
      interpolation,
      sourceType,
      resize,
      activeRowTags,
      rowsMetaTags,
      cleared,
      resetSelection,
      multipleSelection
    ]
  )

  const handleClick = () => {
    setSourceType(null)
    selectedCells.current = null
    setCleared(true)
    setClearBool(!clearBool)
  }

  const handleMetaAfterSelection = payload => {
    setMetaPayload(payload)
    setSourceType('meta')
  }

  const onCheckTag = (tag: string, checked: boolean) => {
    if (checked) {
      setActiveRowTags([...activeRowTags, tag])
    } else {
      setActiveRowTags(activeRowTags.filter(x => x !== tag))
    }
  }

  const refreshActiveRowTags = useCallback(() => {
    if (tableDataFrame) {
      if (!interpolation && !dataTransformation) {
        setActiveRowTags(uniqueRowTags)
      }

      if (activeRowTags.some(tag => !uniqueRowTags.includes(tag))) {
        setActiveRowTags(uniqueRowTags)
      }
    }
  }, [interpolation, dataTransformation, tableDataFrame])

  useEffect(() => {
    refreshActiveRowTags()
  }, [refreshActiveRowTags])

  // additional Hot settings
  const tableHeight = useMemo(() => {
    if (!tableDataFrame) {
      return 'auto'
    }
    // Estimate height of the table with meta data
    const colHeadersHeight =
      tableDataFrame.headers.length * (hotSettings.columnHeaderHeight as number)
    const metaTableHeight = (tableDataFrame.tableMeta?.tags?.length || 0) * 24
    const spaceLeft = window.innerHeight - metaTableHeight - colHeadersHeight - 250

    // 300 is minimal height of view with data values
    if (spaceLeft > 300) {
      const rowHeadersHeight = rowHeaders
        .map(name => {
          // very rough estimation if row name takes 1 or 2 lines
          if (name.length * 12 > hotSettings.rowHeaderWidth) {
            return 38
          } else {
            return 25
          }
        })
        .reduce((v1, v2) => v1 + v2, 0)
      if (rowHeadersHeight < spaceLeft) {
        return rowHeadersHeight + colHeadersHeight
      }
      return spaceLeft + colHeadersHeight
    }
    return 'auto'
  }, [tableDataFrame, rowHeaders])

  const modalTopPosition = useMemo(() => {
    const metaTableHeight = (tableDataFrame?.tableMeta?.tags?.length || 0) * 24
    if (tableDataFrame && tableHeight !== 'auto') {
      return (window.innerHeight - tableHeight - metaTableHeight - 250) / 2
    }
    return '20%'
  }, [tableHeight, tableDataFrame])

  const popoverContent = (
    <div>
      <strong>Function</strong>
      <p>
        This loads a visual of the data table selected for mapping.
        <br />
        Any cells selected on the data table will be inserted into the object
      </p>
      <strong>How to use</strong>
      <div>1: Select the row tag type(s) of the data to insert into the object</div>
      <div>
        2: Select the entire column or row you wish to map into the object. <br />
        NOTE: You may also select individual cells by holding the CTRL/SHIFT keys
      </div>
      <p>3: Click “OK” when your selection is complete</p>

      <strong>NOTES</strong>
      <p>
        <em>
          Data is inserted into objects using index values. All cells that match selected index
          values will be inserted.
        </em>
      </p>
      <p>
        <em>
          For example, selecting cells A2 and C4 includes column indexes 1 and 3,
          <br />
          and row indexes 2 and 4 (A2 = column index 1/row index 2, C4 = column index 3, row index
          4).
          <br />
          As a result, cells A4 and C2 will be automatically selected as these contain the same
          column
          <br />
          and row indexes (A4 = column index 1/row index 4, C2 = column index 3, row index 2)
        </em>
      </p>
    </div>
  )

  return (
    <CommonModal
      title={
        <>
          {tableDataFrame?.name ? tableDataFrame?.name : 'Select data'}{' '}
          <Info>{popoverContent}</Info>
        </>
      }
      visible={show}
      onOk={onOk}
      onCancel={onCancel}
      onResize={num => setResize(num)}
      tableRender={resize}
      useWindowWidth={true}
      resizable={true}
      height={600}
      style={{ top: modalTopPosition }}
    >
      <div>
        <TableMetaDataView
          interpolation={interpolation}
          data={tableDataFrame}
          onSelect={handleMetaAfterSelection}
          sourceType={sourceType}
        />
        <HotTable
          {...hotSettings}
          data={data}
          rowHeaders={rowHeaders}
          nestedHeaders={colHeaders}
          ref={hotRef}
          afterSelectionEnd={handleAfterSelection}
          selectionMode={selectionMode}
          style={{
            whiteSpace: 'pre-line'
            //maxWidth: "100px"/* enter here your max header width */
          }}
          cells={function (_props) {
            return { editor: false }
          }}
        />
        <Space style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
          <div>
            {uniqueRowTags.length > 0 && <span style={{ paddingRight: '8px' }}>Data type:</span>}
            {uniqueRowTags.map(tag => (
              <CheckableTag
                key={tag}
                checked={activeRowTags.includes(tag)}
                onChange={checked => onCheckTag(tag, checked)}
              >
                {tag}
              </CheckableTag>
            ))}
          </div>
          <Button onClick={() => handleClick()}>Clear data selections</Button>
        </Space>
      </div>
    </CommonModal>
  )
}

export default memo(TableView)
