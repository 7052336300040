import axios, { CancelToken } from 'axios'

import { CeleryResponse } from '@/interfaces/celery'

const API_URL = '/api/celery'

export async function getCeleryTaskStatus<T = any>(
  { taskId }: { taskId: string },
  cancelToken?: CancelToken
): Promise<CeleryResponse<T>> {
  const response = await axios.get<CeleryResponse>(`${API_URL}/task/${taskId}/`, { cancelToken })
  return response.data
}

export async function cancelCeleryTask({
  taskId
}: {
  taskId: string
}): Promise<CeleryResponse<never>> {
  try {
    const response = await axios.delete<CeleryResponse<never>>(`${API_URL}/task/${taskId}/`)
    return response.data
  } catch (err) {
    //if we get an error it'll generally be because the celery task got auto-deleted on an error
    console.log('tried to cancel a celery task, but the task no longer exists')
  }
}
