import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'antd'

import i18n from 'i18next'

import { zoomIn, zoomOneToOne, zoomOut, zoomToFit } from '@store/slices/canvas/zoom/actions'
import { selectCanvasZoomRatio } from '@store/slices/canvas/zoom/selectors'

import CommonButton from '../../common/CommonButton'

const FooterToolbar = () => {
  const dispatch = useDispatch()
  const zoom = useSelector(selectCanvasZoomRatio)
  const zoomValue = parseInt((zoom * 100).toFixed(2), 10)
  return (
    <>
      <div className="rde-editor-footer-toolbar-zoom" id="footer-toolbar">
        <Button.Group>
          <CommonButton
            style={{ borderBottomLeftRadius: '8px', borderTopLeftRadius: '8px' }}
            onClick={() => {
              dispatch(zoomOut())
            }}
            icon="search-minus"
            tooltipTitle={i18n.t('action.zoom-out')}
            id="footer-toolbar-zoom-out"
          />
          {/* <CommonButton
            onClick={() => {
              dispatch(zoomOneToOne())
            }}
            tooltipTitle={i18n.t('action.one-to-one')}
          >
            {`${zoomValue}%`}
          </CommonButton> */}
          <CommonButton
            onClick={() => {
              dispatch(zoomToFit())
            }}
            tooltipTitle={i18n.t('action.fit')}
            icon="expand"
            id="footer-toolbar-fit"
          />
          <CommonButton
            style={{ borderBottomRightRadius: '8px', borderTopRightRadius: '8px' }}
            onClick={() => {
              dispatch(zoomIn())
            }}
            icon="search-plus"
            id="footer-toolbar-zoom-in"
            tooltipTitle={i18n.t('action.zoom-in')}
          />
        </Button.Group>
      </div>
    </>
  )
}

export default FooterToolbar
