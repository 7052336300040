import GeneralProperty from '@components/imagemap/right-panel/properties/GeneralProperty'
import IframeProperty from '@components/imagemap/right-panel/properties/IframeProperty'
import ImageFilterProperty from '@components/imagemap/right-panel/properties/ImageFilterProperty'
import ImageProperty from '@components/imagemap/right-panel/properties/ImageProperty'
import LinkProperty from '@components/imagemap/right-panel/properties/LinkProperty'
import MarkerProperty from '@components/imagemap/right-panel/properties/MarkerProperty'
import ShadowProperty from '@components/imagemap/right-panel/properties/ShadowProperty'
import StyleProperty from '@components/imagemap/right-panel/properties/StyleProperty'
import TableProperty from '@components/imagemap/right-panel/properties/TableProperty'
// import TextProperty from '@components/imagemap/right-panel/properties/TextProperty'
import TooltipProperty from '@components/imagemap/right-panel/properties/TooltipProperty'

import AnimationProperty from './AnimationProperty'
import ChartProperty from './ChartProperty'
import DataLabelProperty from './DataLabelProperty'
import InterpolationProperty from './InterpolationProperty'
import AutomationProperty from './AutomationProperty'

export const PropertyDefinition = {
  group: {
    general: {
      title: 'General',
      component: <GeneralProperty />
    },
    shadow: {
      title: 'Shadow',
      component: <ShadowProperty />
    }
  },
  'i-text': {
    general: {
      title: 'General',
      component: <GeneralProperty />
    },
    marker: {
      title: 'Marker',
      component: <MarkerProperty />
    },
    link: {
      title: 'Link',
      component: <LinkProperty />
    },
    tooltip: {
      title: 'Tooltip',
      component: <TooltipProperty />
    },
    style: {
      title: 'Style',
      component: <StyleProperty />
    },
    shadow: {
      title: 'Shadow',
      component: <ShadowProperty />
    },
    animation: {
      title: 'Animation',
      component: <AnimationProperty />
    }
    // trigger: {
    // 	title: 'Trigger',
    // 	component: TriggerProperty,
    // },
    // userProperty: {
    //   title: 'User Property',
    //   component: UserProperty
    // }
  },
  textbox: {
    automation: {
      title: 'Automation settings',
      component: <AutomationProperty />
    },
    interpolation: {
      title: 'Interpolation Settings',
      component: <InterpolationProperty />
    }
    // trigger: {
    // 	title: 'Trigger',
    // 	component: TriggerProperty,
    // },
    // userProperty: {
    //   title: 'User Property',
    //   component: UserProperty
    // }
  },
  image: {
    general: {
      title: 'General',
      component: <GeneralProperty />
    },
    image: {
      title: 'Image',
      component: <ImageProperty />
    },
    filter: {
      title: 'Filter',
      component: <ImageFilterProperty />
    },
    link: {
      title: 'Link',
      component: <LinkProperty />
    },
    tooltip: {
      title: 'Tooltip',
      component: <TooltipProperty />
    },
    style: {
      title: 'Style',
      component: <StyleProperty />
    },
    shadow: {
      title: 'Shadow',
      component: <ShadowProperty />
    },
    animation: {
      title: 'Animation',
      component: <AnimationProperty />
    }
    // trigger: {
    // 	title: 'Trigger',
    // 	component: TriggerProperty,
    // },
    // userProperty: {
    //   title: 'User Property',
    //   component: UserProperty
    // }
  },
  triangle: {
    general: {
      title: 'General',
      component: <GeneralProperty />
    },
    link: {
      title: 'Link',
      component: <LinkProperty />
    },
    tooltip: {
      title: 'Tooltip',
      component: <TooltipProperty />
    },
    style: {
      title: 'Style',
      component: <StyleProperty />
    },
    shadow: {
      title: 'Shadow',
      component: <ShadowProperty />
    },
    animation: {
      title: 'Animation',
      component: <AnimationProperty />
    }
    // trigger: {
    // 	title: 'Trigger',
    // 	component: TriggerProperty,
    // },
    // userProperty: {
    //   title: 'User Property',
    //   component: UserProperty
    // }
  },
  rect: {
    general: {
      title: 'General',
      component: <GeneralProperty />
    },
    link: {
      title: 'Link',
      component: <LinkProperty />
    },
    tooltip: {
      title: 'Tooltip',
      component: <TooltipProperty />
    },
    style: {
      title: 'Style',
      component: <StyleProperty />
    },
    shadow: {
      title: 'Shadow',
      component: <ShadowProperty />
    },
    animation: {
      title: 'Animation',
      component: <AnimationProperty />
    }
    // trigger: {
    // 	title: 'Trigger',
    // 	component: TriggerProperty,
    // },
    // userProperty: {
    //   title: 'User Property',
    //   component: UserProperty
    // }
  },
  circle: {
    general: {
      title: 'General',
      component: <GeneralProperty />
    },
    link: {
      title: 'Link',
      component: <LinkProperty />
    },
    tooltip: {
      title: 'Tooltip',
      component: <TooltipProperty />
    },
    style: {
      title: 'Style',
      component: <StyleProperty />
    },
    shadow: {
      title: 'Shadow',
      component: <ShadowProperty />
    },
    animation: {
      title: 'Animation',
      component: <AnimationProperty />
    }
    // trigger: {
    // 	title: 'Trigger',
    // 	component: TriggerProperty,
    // },
    // userProperty: {
    //   title: 'User Property',
    //   component: UserProperty
    // }
  },
  polygon: {
    general: {
      title: 'General',
      component: <GeneralProperty />
    },
    link: {
      title: 'Link',
      component: <LinkProperty />
    },
    tooltip: {
      title: 'Tooltip',
      component: <TooltipProperty />
    },
    style: {
      title: 'Style',
      component: <StyleProperty />
    },
    shadow: {
      title: 'Shadow',
      component: <ShadowProperty />
    },
    animation: {
      title: 'Animation',
      component: <AnimationProperty />
    }
    // trigger: {
    // 	title: 'Trigger',
    // 	component: TriggerProperty,
    // },
    // userProperty: {
    //   title: 'User Property',
    //   component: UserProperty
    // }
  },
  line: {
    general: {
      title: 'General',
      component: <GeneralProperty />
    },
    link: {
      title: 'Link',
      component: <LinkProperty />
    },
    tooltip: {
      title: 'Tooltip',
      component: <TooltipProperty />
    },
    style: {
      title: 'Style',
      component: <StyleProperty />
    },
    shadow: {
      title: 'Shadow',
      component: <ShadowProperty />
    },
    animation: {
      title: 'Animation',
      component: <AnimationProperty />
    }
    // trigger: {
    // 	title: 'Trigger',
    // 	component: TriggerProperty,
    // },
    // userProperty: {
    //   title: 'User Property',
    //   component: UserProperty
    // }
  },
  arrow: {
    general: {
      title: 'General',
      component: <GeneralProperty />
    },
    link: {
      title: 'Link',
      component: <LinkProperty />
    },
    tooltip: {
      title: 'Tooltip',
      component: <TooltipProperty />
    },
    style: {
      title: 'Style',
      component: <StyleProperty />
    },
    shadow: {
      title: 'Shadow',
      component: <ShadowProperty />
    },
    animation: {
      title: 'Animation',
      component: <AnimationProperty />
    }
    // trigger: {
    // 	title: 'Trigger',
    // 	component: TriggerProperty,
    // },
    // userProperty: {
    //   title: 'User Property',
    //   component: UserProperty
    // }
  },
  element: {
    general: {
      title: 'General',
      component: <GeneralProperty />
    }
    // 	video: {
    // 		title: 'Element',
    // 		component: ElementProperty,
    // 	},
  },
  iframe: {
    general: {
      title: 'General',
      component: <GeneralProperty />
    },
    video: {
      title: 'Iframe',
      component: <IframeProperty />
    }
  },
  svg: {
    general: {
      title: 'General',
      component: <GeneralProperty />
    },
    link: {
      title: 'Link',
      component: <LinkProperty />
    },
    tooltip: {
      title: 'Tooltip',
      component: <TooltipProperty />
    },
    style: {
      title: 'Style',
      component: <StyleProperty />
    },
    shadow: {
      title: 'Shadow',
      component: <ShadowProperty />
    },
    animation: {
      title: 'Animation',
      component: <AnimationProperty />
    }
    // trigger: {
    // 	title: 'Trigger',
    // 	component: TriggerProperty,
    // },
    // userProperty: {
    //   title: 'User Property',
    //   component: UserProperty
    // }
  },
  chart: {
    automation: {
      title: 'Automation settings',
      component: <AutomationProperty />
    },
    general: {
      title: 'General',
      component: <GeneralProperty />
    },
    chartOption: {
      title: 'Chart Option',
      component: <ChartProperty />
    },
    datalabel: {
      title: 'Chart Settings',
      component: <DataLabelProperty />
    }
  },
  table: {
    automation: {
      title: 'Automation settings',
      component: <AutomationProperty />
    },
    general: {
      title: 'General',
      component: <GeneralProperty />
    },
    chartOption: {
      title: 'Table Settings',
      component: <TableProperty />
    }
  }
}
