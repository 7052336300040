import { useState } from 'react'

import { Select, Space, Spin, Tooltip } from 'antd'

import i18n from 'i18next'

import { ActionTypes } from './types'

const TextTransformation = ({ children, loading, onSelect }) => {
  const [transformVisible, setTransformVisible] = useState(false)

  const tooltips = {
    [ActionTypes.SORTBY]: 'Set the order of rows or columns by ascending or descending order.'
  }
  const actionMapping = {
    [ActionTypes.SORTBY]: 'Sort by'
  }

  return (
    <Spin spinning={loading}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Select<string | number, { value: string; children: string }>
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          dropdownMatchSelectWidth={false}
          dropdownAlign={{
            points: ['tr', 'tl'], //align dropdown's top-right to top-left of input element
            offset: [-5, 0], //align offset
            overflow: {
              adjustX: 0,
              adjustY: 0 //do not auto flip in y axis
            }
          }}
          dropdownStyle={{
            display: transformVisible ? 'inline-block' : 'none',
            width: 'fit-content',
            maxWidth: '33%',
            minWidth: '15%'
          }}
          onDropdownVisibleChange={open => setTransformVisible(open)}
          placeholder={i18n.t(
            'editor.right-panel.data-source.form.data-transformation.placeholder'
          )}
          onChange={onSelect}
          style={{ width: '100%' }}
        >
          {Object.values(ActionTypes)
            .filter(type => type === ActionTypes.SORTBY)
            .map(actionType => (
              <Select.Option value={actionType} key={actionType}>
                <Tooltip title={tooltips[actionType]}>{actionMapping[actionType]}</Tooltip>
              </Select.Option>
            ))}
        </Select>
      </Space>
      {children}
    </Spin>
  )
}

export default TextTransformation
