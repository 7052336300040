import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Alert, Button, Divider, Form, Input, notification, Space, Switch, Typography } from 'antd'

import { setMfaEnabled } from '@/store/slices/auth/actions'
import { selectCurrentUser } from '@/store/slices/auth/selectors'
import { AppDispatch } from '@/store/store'

import { updateTwoFactorAuthentication, updateUserPassword } from '@/services/account-service'

const { Title, Text } = Typography
const ChangePassword = () => {
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const currentUser = useSelector(selectCurrentUser)
  const dispatch: AppDispatch = useDispatch()

  useEffect(() => {
    setTwoFactorEnabled(currentUser.is_mfa_activated)
  }, [currentUser])

  const [form] = Form.useForm()
  const onFinish = async (values: any) => {
    const response = await updateUserPassword(
      currentUser.id,
      values.current,
      values.password,
      values.confirm
    )
    if (response.status == 200) {
      notification['success']({
        message: 'Success',
        description: 'Your password has been successfully changed.'
      })
      setErrorMsg('')
      form.resetFields()
    } else {
      setErrorMsg(response.data.detail)
    }
  }

  const onChangeTwoFactor = async (checked: boolean) => {
    setTwoFactorEnabled(checked)
    const response = await updateTwoFactorAuthentication(currentUser.id, checked)
    if (response.status == 200) {
      dispatch(setMfaEnabled(response?.data?.is_mfa_activated))
      notification['success']({
        message: 'Success',
        description: checked
          ? 'Two-factor authentication is now enabled.'
          : 'Two-factor authentication is now disabled.'
      })
      setErrorMsg('')
    } else {
      setErrorMsg(response?.data?.error?.message)
    }
  }

  return (
    <div>
      <Space direction="vertical" style={{ marginBottom: '20px' }}>
        <Title level={3}>Change Password</Title>
        <Text type="secondary">Choose a unique password to protect your account</Text>
      </Space>
      <Form form={form} layout="vertical" onFinish={onFinish} scrollToFirstError>
        <Space direction="vertical" size="middle" style={{ width: '420px' }}>
          <Alert
            style={{ margin: '10px 0', display: errorMsg ? '' : 'none' }}
            message={errorMsg}
            type="error"
            closable
            showIcon
          />
          <Form.Item
            label="Type your CURRENT password"
            name="current"
            rules={[
              {
                required: true,
                message: 'Please enter your current password'
              }
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Type your NEW password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please enter a new password'
              }
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Retype your NEW password"
            name="confirm"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your new password.'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('Passwords did not match.'))
                }
              })
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Space>
      </Form>
      <Divider />
      <Title level={3}>Two-factor authentication</Title>
      <Text type="secondary">
        Two-factor authentication is a layer of security on your account. By turning this off you
        will only use your password to sign in.
      </Text>
      <br />
      <br />
      <Switch checked={twoFactorEnabled} onChange={onChangeTwoFactor} />
    </div>
  )
}

export default ChangePassword
