import { fabric } from 'fabric'

import { AbstractTable, factoryManager } from './factory'
import { FabricTableOptions, TableDefinition, TableVisualSettings } from './types'

export class TableObject extends fabric.Object {
  static type = 'Table'
  editable: boolean
  table: TableDefinition
  visualSettings: TableVisualSettings
  factory: AbstractTable
  element: any

  public _set(key: string, value: any) {
    return super._set(key, value)
  }

  // Can't get `constructor` to work, using this method instead
  // for similar purposes @LazerRaptor
  private _initialize() {
    this.factory = factoryManager(this.table, this.data, this.visualSettings)
    this.element = this.factory.create()
  }

  public initialize(props: any) {
    super.initialize(props)
    this.on('added', () => {
      if (!this.element) {
        this._initialize()
      }
    })
    this.on('update', function () {
      if (!this.element) {
        return
      }
      // remove data-grid element
      this._initialize()
    })
    return this
  }

  // Execute the drawing operation for an object on a specified context
  public drawObject(ctx: CanvasRenderingContext2D) {
    this._render(ctx)
  }

  // function that actually render something on the context.
  public _render(ctx: CanvasRenderingContext2D) {
    if (this.element) {
      const container = document.getElementById('root')
      container.appendChild(this.element)
      ctx.drawImage(
        this.element.canvas,
        -this.width! / 2,
        -this.height! / 2,
        this.width!,
        this.height!
      )
      container.removeChild(this.element)
    }
    super._render(ctx)
  }

  static fromObject(options: FabricTableOptions, callback: Function) {
    return callback && callback(new fabric.Table(options))
  }
}

fabric.Table = fabric.util.createClass(TableObject, {
  type: TableObject.type
})

fabric.Table.fromObject = TableObject.fromObject

declare module 'fabric' {
  namespace fabric {
    class Table extends TableObject {
      constructor(options: FabricTableOptions)
    }
  }
}
