import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Button, Form, Select, Space, Typography } from 'antd'

import { ReloadOutlined } from '@ant-design/icons'

import { selectDataSources } from '@/store/slices/data/selectors'
import { selectCurrentProjectId } from '@/store/slices/projects/selectors'

import { useTables } from '@/components/imagemap/right-panel/hooks'
import EmptyDataSourceUploader from '@components/imagemap/right-panel/data-source/EmptyDataSourceUploader'

import { useLocalStorage } from '@/hooks'

const { Title } = Typography

type Props = {
  handleFormSubmit: (values) => void
  dataSourceId: number
  dataSectionId: number
  dataTableId: number
}

const DataSelection = ({ handleFormSubmit, dataSourceId, dataSectionId, dataTableId }: Props) => {
  const projectId = useSelector(selectCurrentProjectId)
  const dataSources = useSelector(selectDataSources)
  const [form] = Form.useForm()
  const [sections, setSections] = useState([])
  const [sectionId, setSectionId] = useState<number>(null)
  const [tableId, setTableId] = useState<number>(null)
  const [dataId, setDataId] = useState<number>(null)
  const [uploaderVisible, setUploaderVisible] = useState(false)
  const [lastInsertData, setLastInsertData] = useLocalStorage<{
    dataSourceId: number
    sectionId: number
    tableId: number
  }>(projectId.toString(), null)

  const { tables } = useTables(sectionId)

  useEffect(() => {
    if (dataTableId != null) {
      setDataId(dataSourceId)
      setSectionId(dataSectionId)
      setTableId(dataTableId)
      form.setFieldsValue({
        dataSourceId,
        tableId: dataTableId,
        sectionId: dataSectionId
      })
    }
  }, [dataSourceId, dataSectionId, dataTableId, form])

  useEffect(() => {
    const sects = dataSources.find(source => source.id === dataId)?.dataSections
    if (sects) {
      setSections(sects)
    }
  }, [dataId, dataSources])

  const onChange = (key: string, value: number | number[] | string[]) => {
    switch (key) {
      case 'dataSourceId':
        form.resetFields(['sectionId', 'tableId'])
        setDataId(value as number)
        const sections = dataSources.find(source => source.id === value)?.dataSections
        setSections(sections)
        if (sections.length === 1) {
          setSectionId(sections[0].id)
          form.setFieldsValue({ sectionId: sections[0].id })
        } else {
          setSectionId(null)
          setTableId(null)
        }
        break
      case 'sectionId':
        setTableId(null)
        form.resetFields(['tableId'])
        setSectionId(value as number)
        break
      case 'tableId':
        setTableId(value as number)
        break
    }
  }

  const onFormFinish = async formValues => {
    handleFormSubmit(formValues)
    setLastInsertData(formValues)
  }

  const onReloadLastTable = () => {
    if (lastInsertData) {
      setDataId(lastInsertData.dataSourceId)
      setSectionId(lastInsertData.sectionId)
      setTableId(lastInsertData.tableId)
      form.setFieldsValue(lastInsertData)
    }
  }

  const disabledSubmitButton = !!dataId && !!sectionId && !!tableId
  const disabledReloadLastTableButton = !lastInsertData

  return (
    <Form form={form} onFinish={onFormFinish} layout="vertical">
      <Space direction="vertical" style={{ width: '100%' }}>
        <Title level={4}>Data Selections</Title>
        <Form.Item name="dataSourceId" rules={[{ required: true }]} label="Data Source">
          <Select<string | number, { value: string; children: string }>
            showSearch
            placeholder="Search data sources"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(id: number) => onChange('dataSourceId', id)}
            dropdownStyle={{
              display: uploaderVisible ? 'inline-block' : 'none',
              width: 'fit-content',
              maxWidth: '33%',
              minWidth: '15%',
              maxHeight: '50vh'
            }}
            onDropdownVisibleChange={open => setUploaderVisible(open)}
            notFoundContent={<EmptyDataSourceUploader onClose={() => setUploaderVisible(false)} />}
          >
            {dataSources.slice(0).map((source, index) => (
              <Select.Option value={source.id} key={source.id}>
                {`${index + 1}. ${source.filename}`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          shouldUpdate={(prevValues, curValues) =>
            prevValues.dataSourceId !== curValues.dataSourceId
          }
          noStyle
        >
          <Form.Item
            name="sectionId"
            rules={[{ required: true }]}
            label="Sheet"
            // hidden={sections.length <= 1}
          >
            <Select<string | number, { value: string; children: string }>
              showSearch
              placeholder="Search sections"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(id: number) => onChange('sectionId', id)}
            >
              {sections.map(({ id, name }) => (
                <Select.Option value={id} key={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form.Item>

        <Form.Item name="tableId" rules={[{ required: true }]} label="Table Title">
          <Select<string | number, { value: string; children: string }>
            showSearch
            placeholder="Search tables"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(index: number) => onChange('tableId', index)}
          >
            {tables.map(({ id, name }) => (
              <Select.Option value={id} key={id}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item style={{ marginTop: 8 }}>
          <Button onClick={onReloadLastTable} type="text" disabled={disabledReloadLastTableButton}>
            <ReloadOutlined /> Reload last table
          </Button>
        </Form.Item>

        <Form.Item style={{ marginTop: 8 }}>
          <Button
            disabled={!disabledSubmitButton}
            onClick={() => {}}
            type="primary"
            htmlType="submit"
            block
          >
            Submit
          </Button>
        </Form.Item>
      </Space>
    </Form>
  )
}

export default DataSelection
