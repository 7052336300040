import { ConditionTypes } from '@/interfaces/data-overlays'

import { VisualisationSymbols } from '../sig-testing/VisualisationSettings'
import { ThresholdSettings } from './types'

import './styles.less'

const VisualisationToken = (props: ThresholdSettings) => {
  const { visualise, color } = props
  const showValue = visualise === 'symbols-colored-labels' || visualise === 'colored-labels'
  const showSymbol = visualise === 'symbols' || visualise === 'symbols-colored-labels'

  return (
    <span className="symbol-container">
      {showSymbol && <span style={{ color }}>{VisualisationSymbols[props.symbol]}</span>}

      {showValue && <span style={{ color, fontSize: '13px' }}>123</span>}
      {visualise === 'cell-background' && (
        <span className="cell-background-symbol" style={{ background: color }}></span>
      )}
      {visualise === 'values-colored-labels' && (
        <span style={{ color, fontSize: '10px' }}>(123)</span>
      )}
    </span>
  )
}

type Props = {
  settings: ThresholdSettings[]
}

const IndexingRules = ({ settings }: Props) => {
  return settings.length > 0 ? (
    <>
      <h3 className="indexing-rules-title">Indexing Rules:</h3>
      <ul>
        {settings.map((settings: ThresholdSettings, index) => (
          <li key={index} className="indexing-rules">
            <div className="container symbol">
              <VisualisationToken {...settings} />
            </div>
            <div className="container">
              <div className="condition-type">{ConditionTypes[settings.thresholdType]}:</div>
              <div>{settings.thresholdValue}</div>
            </div>
            <div className="container">
              {settings.position && (
                <>
                  <div>Position:</div>
                  <div>{settings.position}</div>
                </>
              )}
            </div>
          </li>
        ))}
      </ul>
    </>
  ) : (
    <div>( No conditions yet. )</div>
  )
}

export default IndexingRules
