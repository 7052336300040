import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Form, InputNumber, Select, Space, Spin, Switch } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import i18n from 'i18next'

import { selectCurrentProjectId } from '@/store/slices/projects/selectors'
import { updateShapeVisualSettings } from '@/store/slices/shapes/actions'
import { selectCanvasSelectedObject } from '@store/slices/shapes/selectors'

import { updateProjectUpdateTime } from '@/services/project-service'

import { getNumberFormat, getValuesFromNumberFormat } from '../utils'
import { IInterpolation } from './types'

interface IForm {
  tag: string
  percent: boolean
  places: number
}

const InterpolationProperty = () => {
  const selectedObject = useSelector(selectCanvasSelectedObject)
  const dispatch = useDispatch()
  const projectId = useSelector(selectCurrentProjectId)
  const interpolation: IInterpolation = selectedObject.data?.interpolation || {}
  const [form] = useForm<IForm>()
  const [currentTag, setCurrentTag] = useState(null)
  const [loading, setLoading] = useState(false)
  const [decimalPlaces, setDecimalPlaces] = useState<number>(0)
  const [showAsPercentge, setShowAsPercentage] = useState<boolean>(false)
  const prevDecimalPlacesValueRef = useRef<number>(null)
  const numberFormat = selectedObject?.visualSettings?.numberFormat

  const updateNumberFormat = async (numberFormat: string) => {
    const { tag } = form.getFieldsValue(true)

    const payload = {
      ...selectedObject?.visualSettings,
      interpolation: {
        [tag]: {
          numberFormat: numberFormat
        }
      }
    }
    setLoading(true)
    updateProjectUpdateTime(projectId)
    dispatch(updateShapeVisualSettings(selectedObject.id, payload))
    setLoading(false)
  }

  const onChangeTag = value => {
    setCurrentTag(value)
  }

  const onChangeShowAsPercentage = async (bool: boolean) => {
    let numberFormat = getNumberFormat(decimalPlaces, bool)
    await updateNumberFormat(numberFormat)
  }

  const onChangeDecimalPlaces = (value: number) => {
    if (value !== null && value !== undefined) {
      prevDecimalPlacesValueRef.current = value
      setTimeout(() => {
        setDecimalPlaces(value)
      }, 1200)
    }
  }

  useEffect(() => {
    if (currentTag === null) {
      let tags = Object.keys(interpolation)
      if (tags.length > 0) {
        setCurrentTag(tags.find(el => el === selectedObject.text.replace(/[{}]/g, '')) || tags[0])
      }
    }
  }, [])

  useEffect(() => {
    let interpolation = selectedObject?.visualSettings?.interpolation
    const numberFormat =
      interpolation && interpolation[currentTag] ? interpolation[currentTag].numberFormat : null
    const [decimalPlaces, _showPercentage] = getValuesFromNumberFormat(numberFormat)

    form.setFieldsValue({
      tag: currentTag,
      places: decimalPlaces,
      percent: _showPercentage
    })
  }, [currentTag, selectedObject?.visualSettings?.interpolation, form])

  useEffect(() => {
    if (numberFormat) {
      const [places, show] = getValuesFromNumberFormat(numberFormat)
      setDecimalPlaces(places)
      setShowAsPercentage(show)
      form.setFieldsValue({
        percent: show,
        places: places
      })
    }
  }, [numberFormat])

  useEffect(() => {
    if (prevDecimalPlacesValueRef.current === decimalPlaces) {
      let numberFormat = getNumberFormat(decimalPlaces, showAsPercentge)
      updateNumberFormat(numberFormat)
    }
  }, [decimalPlaces])

  return (
    <Spin spinning={loading}>
      <Form form={form}>
        <Space direction="vertical" style={{ maxWidth: '100%' }}>
          <Form.Item
            name="tag"
            label={i18n.t('editor.right-panel.shape-settings.select-interpolation')}
            required={true}
          >
            <Select onChange={onChangeTag} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {Object.entries(interpolation).map(([id, item]) => (
                <Select.Option value={id} key={id}>
                  {id} - {item.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="percent"
            label={i18n.t('editor.right-panel.shape-settings.percent')}
            valuePropName="checked"
          >
            <Switch onChange={(bool: boolean) => onChangeShowAsPercentage(bool)} />
          </Form.Item>

          <Form.Item name="places" label={i18n.t('editor.right-panel.shape-settings.places')}>
            <InputNumber onChange={onChangeDecimalPlaces} step={1} min={0} />
          </Form.Item>
        </Space>
      </Form>
    </Spin>
  )
}

export default InterpolationProperty
