import { DataGridOptions, StyleOptions } from './types'

export const cellOptions = {
  fontSize: 10,
  lineHeight: 14,
  offsetX: 4,
  offsetY: 4
}

export const defaultDataGridOptions: Partial<DataGridOptions> = {
  showColumnHeaders: false,
  showRowHeaders: false
}

export const defaultStyleSettings: Partial<StyleOptions> = {
  cellFont: '10px sans-serif',
  activeCellFont: '10px sans-serif',
  columnHeaderCellFont: '10px sans-serif',
  rowHeaderCellFont: '10px sans-serif',
  cellBorderColor: '#000000',
  columnHeaderCellBorderColor: '#000000',
  activeCellBorderColor: '#000000',
  activeCellOverlayBorderColor: '#000000',
  columnHeaderCellCapBorderColor: '#000000',
  gridBorderColor: '#000000'
}
