import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Button, Form, Input, notification, Space } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import { selectMFASignInError, selectMFASignInLoading } from '@/store/slices/auth/selectors'
import { AppDispatch } from '@/store/store'
import { clearMfaSignInError } from '@store/slices/auth/actions'
import { mfaSignIn, signIn } from '@store/slices/auth/thunks'

const CodeSIgnIn = ({ credentials }) => {
  const dispatch: AppDispatch = useDispatch()
  const error = useSelector(selectMFASignInError)
  const isLoading = useSelector(selectMFASignInLoading)
  const [form] = useForm()
  const history = useHistory()
  const [hideResendVerificationEmail, setHideResendVerificationEmail] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const onFinish = () => {
    dispatch(
      mfaSignIn({
        email: credentials.email!,
        mfaCode: form.getFieldValue('verification-code')
      })
    ).then(response => {
      if (response.meta.requestStatus !== 'rejected') {
        setErrorMessage('')
        history.push('/projects')
      } else {
        setErrorMessage('Verification code is invalid')
      }
    })
    form.setFieldsValue({
      verification_code: ''
    })
  }

  const reSendMFAcode = () => {
    //this is hidden for now until we want to implement it properly
    // setHideResendVerificationEmail(true)
    // setTimeout(() => {
    //   setHideResendVerificationEmail(false)
    // }, 120000)
    dispatch(signIn({ ...credentials })).then(() => {
      openNotification('Check your email, the code should arrive shortly')
    })
  }

  const openNotification = message => {
    notification.open({
      message: 'Verification code has been sent',
      type: 'info',
      description: message,
      onClick: () => {}
    })
  }

  const onValueChange = () => {
    if (error) {
      dispatch(clearMfaSignInError())
    }
  }

  useEffect(() => {
    dispatch(clearMfaSignInError())
  }, [dispatch])

  return (
    <div className="auth__card">
      <div className="card__header">
        <h3>Two-factor authentication</h3>
      </div>

      <div className="card__body">
        <Form
          form={form}
          layout="vertical"
          onValuesChange={onValueChange}
          onFinish={onFinish}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Space direction="vertical">
            <Form.Item
              name="verification-code"
              label="Verification code"
              rules={[{ required: true, message: 'Please enter the verification code' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 4, span: 16 }} style={{ marginTop: '18px' }}>
              <Button type="primary" htmlType="submit" block loading={isLoading}>
                Sign In
              </Button>
            </Form.Item>
          </Space>
        </Form>
        {!!error && <div>{error}</div>}
      </div>

      {errorMessage && <p style={{ color: 'red', textAlign: 'center' }}> {errorMessage} </p>}

      <div className="card__footer">
        {!hideResendVerificationEmail && (
          <Button type="link" loading={isLoading} onClick={reSendMFAcode} block>
            Resend Verification Code
          </Button>
        )}
      </div>
    </div>
  )
}

export default CodeSIgnIn
