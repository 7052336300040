import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Tabs, Tooltip } from 'antd'

import i18n from 'i18next'

import { selectAllSlideIds } from '@store/slices/slides/selectors'

import SlidePanel from '@/components/imagemap/left-panel/slide'
import Icon from '@components/icon/Icon'
import DataSources from '@components/imagemap/left-panel/data-source'

import './EditorLeftPanel.less'

const EditorLeftPanel = () => {
  const slides = useSelector(selectAllSlideIds)
  const [activeTab, setActiveTab] = useState(slides.length ? 'slides' : 'templates')

  useEffect(() => {
    setActiveTab('slides')
  }, [slides])

  return (
    <div className="left-panel" id="editor-left-panel">
      <Tabs
        tabPosition="left"
        style={{ height: '100%' }}
        tabBarStyle={{ backgroundColor: '#fff', marginTop: 12 }}
        activeKey={activeTab}
        onTabClick={activeKey => setActiveTab(activeKey)}
        id="editor-left-panel-tabs"
      >
        <Tabs.TabPane
          tab={
            <Tooltip
              placement="right"
              id="editor-left-panel-tab-pane-slides-title"
              title={i18n.t('editor.left-panel.slides.title')}
            >
              <span>
                <Icon name="clone" id="editor-left-panel-tab-pane-clone" prefix="far" size={0.85} />
              </span>
            </Tooltip>
          }
          id="editor-left-panel-tab-pane-2"
          key="slides"
          style={{ paddingLeft: 0, paddingBottom: 30, overflow: 'hidden' }}
        >
          <SlidePanel />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <Tooltip
              placement="right"
              id="editor-left-panel-tab-pane-data-source-title"
              title={i18n.t('editor.left-panel.data-sources.title')}
            >
              <span>
                <Icon
                  name="database"
                  id="editor-left-panel-tab-pane-database"
                  prefix="fas"
                  size={0.85}
                />
              </span>
            </Tooltip>
          }
          id="editor-left-panel-tab-pane"
          key="data-sources"
          style={{ paddingLeft: 0 }}
        >
          <DataSources />
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

export default React.memo(EditorLeftPanel)
