import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Form, Switch } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import i18n from 'i18next'

import { updateOneCanvasObject } from '@store/slices/shapes/actions'
import { selectCanvasSelectedObject } from '@store/slices/shapes/selectors'

import { defaultOption } from '@components/canvas/constants/defaults'

const TooltipProperty = () => {
  const selectedItem = useSelector(selectCanvasSelectedObject)
  const dispatch = useDispatch()
  const [form] = useForm()

  const changeProperty = (changedValues, allValues) => {
    dispatch(
      updateOneCanvasObject({
        id: selectedItem.id,
        changes: { tooltip: { ...defaultOption.tooltip, ...allValues } }
      })
    )
  }

  useEffect(() => {
    form.resetFields()
  }, [form, selectedItem])

  return (
    <Form form={form} onValuesChange={changeProperty}>
      <Form.Item
        name="enabled"
        rules={[{ type: 'boolean' }]}
        valuePropName="checked"
        initialValue={selectedItem.tooltip.enabled}
        label={i18n.t('editor.right-panel.properties.tooltip.tooltip-enabled')}
        colon={false}
      >
        <Switch size="small" />
      </Form.Item>
    </Form>
  )
}

export default TooltipProperty
