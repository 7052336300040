import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Col, Form, InputNumber, Row, Select, Slider, Switch } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import i18n from 'i18next'
import { debounce } from 'lodash'

import { updateOneCanvasObject } from '@store/slices/shapes/actions'
import { selectCanvasSelectedObject } from '@store/slices/shapes/selectors'

import { defaultOption } from '@components/canvas/constants/defaults'
import Icon from '@components/icon/Icon'

import ColorPicker from '../../../common/ColorPicker'

const AnimationProperty = () => {
  const selectedItem = useSelector(selectCanvasSelectedObject)
  const dispatch = useDispatch()
  const [form] = useForm()
  const type = selectedItem.animation.type || 'none'

  // @Todo check animation bug when refactoring animation handler

  useEffect(() => {
    form.resetFields()
  }, [form, selectedItem])

  const changeProperty = debounce((changedValues, allValues) => {
    const animation = Object.assign({}, defaultOption.animation, allValues)
    dispatch(updateOneCanvasObject({ id: selectedItem.id, changes: { animation: animation } }))
  }, 200)

  const getComponentType = () => {
    let component
    if (type === 'fade') {
      component = (
        <Form.Item
          label={i18n.t('common.opacity')}
          colon={false}
          name="opacity"
          rules={[
            {
              type: 'number',
              min: 0,
              max: 1
            }
          ]}
          initialValue={selectedItem.animation.opacity || 0}
        >
          <Slider min={0} max={1} step={0.1} />
        </Form.Item>
      )
    } else if (type === 'bounce') {
      component = (
        <>
          <Form.Item
            label={i18n.t('editor.right-panel.properties.animation.bounce-type')}
            colon={false}
            name="bounce"
            initialValue={selectedItem.animation.bounce || 'hotizontal'}
          >
            <Select<string | number, { value: string; children: string }>
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Select.Option value="hotizontal">Horizontal</Select.Option>
              <Select.Option value="vertical">Vertical</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={i18n.t('common.offset')}
            colon={false}
            name="offset"
            rules={[
              {
                type: 'number',
                min: 1,
                max: 10
              }
            ]}
            initialValue={selectedItem.animation.offset || 1}
          >
            <Slider min={1} max={10} step={1} />
          </Form.Item>
        </>
      )
    } else if (type === 'shake') {
      component = (
        <React.Fragment>
          <Form.Item
            label={i18n.t('editor.right-panel.properties.animation.shake-type')}
            colon={false}
            name="shake"
            initialValue={selectedItem.animation.shake || 'hotizontal'}
          >
            <Select<string | number, { value: string; children: string }>
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Select.Option value="hotizontal">{i18n.t('common.horizontal')}</Select.Option>
              <Select.Option value="vertical">{i18n.t('common.vertical')}</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={i18n.t('common.offset')}
            colon={false}
            name="offset"
            rules={[
              {
                type: 'number',
                min: 1,
                max: 10
              }
            ]}
            initialValue={selectedItem.animation.offset || 1}
          >
            <Slider min={1} max={10} step={1} />
          </Form.Item>
        </React.Fragment>
      )
    } else if (type === 'scaling') {
      component = (
        <Form.Item
          label={i18n.t('editor.right-panel.properties.animation.scaling')}
          colon={false}
          name="scale"
          rules={[
            {
              type: 'number',
              min: 1,
              max: 5
            }
          ]}
          initialValue={selectedItem.animation.scale || 2}
        >
          <Slider min={1} max={5} step={0.1} />
        </Form.Item>
      )
    } else if (type === 'rotation') {
      component = (
        <Form.Item
          label={i18n.t('common.angle')}
          colon={false}
          name="angle"
          rules={[
            {
              type: 'number',
              min: 0,
              max: 360
            }
          ]}
          initialValue={selectedItem.animation.angle || 360}
        >
          <Slider min={0} max={360} />
        </Form.Item>
      )
    } else if (type === 'flash') {
      component = (
        <Row>
          <Col span={12}>
            <Form.Item
              label={i18n.t('editor.right-panel.properties.style.fill-color')}
              colon={false}
              name="fill"
              initialValue={selectedItem.animation.fill || (selectedItem as any).fill}
            >
              <ColorPicker />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={i18n.t('editor.right-panel.properties.style.stroke-color')}
              colon={false}
              name="stroke"
              initialValue={selectedItem.animation.stroke || selectedItem.stroke}
            >
              <ColorPicker />
            </Form.Item>
          </Col>
        </Row>
      )
    } else {
      component = (
        <Row>
          <Col span={12}>
            <Form.Item
              label={i18n.t('common.value')}
              colon={false}
              name="value"
              rules={[
                {
                  type: 'number',
                  min: 0,
                  max: 10
                }
              ]}
              initialValue={(selectedItem.animation as any).value || 1}
            >
              <InputNumber min={1} max={10} />
            </Form.Item>
          </Col>
        </Row>
      )
    }
    return component
  }
  return (
    <Form onValuesChange={changeProperty} form={form}>
      <Form.Item
        label={i18n.t('editor.right-panel.properties.animation.animation-type')}
        name="type"
        initialValue={type}
        colon={false}
      >
        <Select<string | number, { value: string; children: string }>
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Select.Option value="none">
            {i18n.t('editor.right-panel.properties.animation.none')}
          </Select.Option>
          <Select.Option value="fade">
            {i18n.t('editor.right-panel.properties.animation.fade')}
          </Select.Option>
          <Select.Option value="bounce">
            {i18n.t('editor.right-panel.properties.animation.bounce')}
          </Select.Option>
          <Select.Option value="shake">
            {i18n.t('editor.right-panel.properties.animation.shake')}
          </Select.Option>
          <Select.Option value="scaling">
            {i18n.t('editor.right-panel.properties.animation.scaling')}
          </Select.Option>
          <Select.Option value="rotation">
            {i18n.t('editor.right-panel.properties.animation.rotation')}
          </Select.Option>
          <Select.Option value="flash">
            {i18n.t('editor.right-panel.properties.animation.flash')}
          </Select.Option>
        </Select>
      </Form.Item>
      {type === 'none' ? null : (
        <React.Fragment>
          <Row>
            <Col span={12}>
              <Form.Item
                label={i18n.t('editor.right-panel.properties.animation.auto-play')}
                colon={false}
                name="autoplay"
                rules={[{ type: 'boolean' }]}
                valuePropName="checked"
                initialValue={selectedItem.animation.autoplay}
              >
                <Switch size="small" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={i18n.t('common.loop')}
                colon={false}
                name="loop"
                rules={[{ type: 'boolean' }]}
                valuePropName="checked"
                initialValue={selectedItem.animation.loop}
              >
                <Switch size="small" />
              </Form.Item>
            </Col>
          </Row>
          {type !== 'shake' ? (
            <Row>
              <Col span={12}>
                <Form.Item
                  label={i18n.t('common.delay')}
                  colon={false}
                  name="delay"
                  rules={[
                    {
                      type: 'number',
                      min: 0,
                      max: 5000
                    }
                  ]}
                  initialValue={selectedItem.animation.delay || 0}
                >
                  <Slider min={0} max={5000} step={100} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={i18n.t('common.duration')}
                  colon={false}
                  name="duration"
                  rules={[
                    {
                      type: 'number',
                      min: 100,
                      max: 5000
                    }
                  ]}
                  initialValue={selectedItem.animation.duration || 100}
                >
                  <Slider min={100} max={5000} step={100} />
                </Form.Item>
              </Col>
            </Row>
          ) : null}
          {getComponentType()}
          <Form.Item
            label={i18n.t('editor.right-panel.properties.animation.playback')}
            colon={false}
          >
            <Row>
              <Col span={8}>
                <Button block size="small" onClick={() => {}}>
                  <Icon name="play" style={{ marginRight: 8 }} />
                  {i18n.t('action.start')}
                </Button>
              </Col>
              <Col span={8}>
                <Button block size="small" onClick={() => {}}>
                  <Icon name="pause" style={{ marginRight: 8 }} />
                  {i18n.t('action.pause')}
                </Button>
              </Col>
              <Col span={8}>
                <Button block size="small" onClick={() => {}}>
                  <Icon name="stop" style={{ marginRight: 8 }} />
                  {i18n.t('action.stop')}
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </React.Fragment>
      )}
    </Form>
  )
}

export default AnimationProperty
