import { DataFrameDefinition } from '@/interfaces/data'

export function transposeDataFrame(frame: DataFrameDefinition) {
  if (!frame) {
    return null
  }
  const data = frame.columns.map((_, colIndex) => {
    return frame.index.map((_, rowIndex) => {
      return frame.data[rowIndex][colIndex]
    })
  })
  return {
    index: frame.columns,
    columns: frame.index,
    data: data
  }
}
