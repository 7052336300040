/**
 * Get `Content-Type` by file extension.
 * @param {string} filename
 */
export function getContentType(filename: string) {
  const ext = filename.split('.').at(-1).toLowerCase()
  const mapping = {
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    txt: 'text/plain',
    mtd: 'application/vnd.omammsg-mtd+xml',
    xml: 'application/xml',
    xte: 'application/xte',
    sav: 'application/x-spss-sav',
    csv: 'text/csv'
  }
  return mapping[ext]
}
