import { createAsyncThunk } from '@reduxjs/toolkit'

import { RootState } from '@store/rootReducer'
import { setProjectSlidesOrder } from '@store/slices/projects/actions'

import * as api from '@services/slide-service'
import { addSlides } from '@services/slide-service'

import { ISlideDetail, ISlideList } from '@/interfaces/slide'

import { setCanvasEmptyWorkArea } from '../canvas/canvas/actions'
import { removeAllCanvasObjects } from '../shapes/actions'
import {
  addSlide,
  clearAllSlides,
  deleteManySlidesById,
  deleteSlideById,
  selectSlide,
  setAllSlides
} from './actions'

export const duplicateSlide = createAsyncThunk<ISlideDetail, number, { rejectValue: string }>(
  'slides/duplicate',
  async (slideId, { getState, dispatch, rejectWithValue }) => {
    const state = getState() as RootState
    const projectId = state?.projects?.currentProject?.id
    try {
      const response = await api.duplicateSlide({ projectId, slideId })
      dispatch(addSlide(response))
      dispatch(setProjectSlidesOrder(response.order))
    } catch (error) {
      return rejectWithValue(error.response?.data?.error?.message || null)
    }
  }
)

export const deleteSlide = createAsyncThunk<number[], number, { rejectValue: string }>(
  'slides/delete',
  async (slideId, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState
    const projectId = state?.projects?.currentProject?.id
    const allSlideIds = state.slides.allIds
    try {
      const response = await api.deleteSlide({ projectId, slideId })
      if (state.slides.current === slideId) {
        let index = allSlideIds.findIndex((id: number) => id === slideId)
        if (allSlideIds.length === 1) {
          // clear canvas if the last slide was removed
          dispatch(clearAllSlides())
          dispatch(removeAllCanvasObjects())
          dispatch(setCanvasEmptyWorkArea())
        } else {
          //let slideIdToSelect = index > 0 ? allSlideIds[index - 1] : allSlideIds[index + 1]
          let slideIdToSelect = allSlideIds[0]
          dispatch(selectSlide(slideIdToSelect))
        }
      }
      dispatch(deleteSlideById(Number(slideId)))
      dispatch(
        setProjectSlidesOrder(
          state?.projects?.currentProject?.slideOrder.filter(id => id !== slideId)
        )
      )
      return response
    } catch (error) {
      return rejectWithValue(error.response?.data?.error?.message || null)
    }
  }
)

export const deleteMultipleSlides = createAsyncThunk<number[], number[], { rejectValue: string }>(
  'slides/deleteMultiple',
  async (slideIds, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState
    const projectId = state?.projects?.currentProject?.id
    const allSlideIds = state.slides.allIds

    try {
      const response = await api.deleteMultipleSlides({ projectId, slideIdsList: slideIds })

      if (allSlideIds.length === slideIds.length) {
        dispatch(clearAllSlides())
        dispatch(removeAllCanvasObjects())
        dispatch(setCanvasEmptyWorkArea())
      } else {
        const newIds = allSlideIds.filter(id => !slideIds.includes(id))
        dispatch(selectSlide(Number(newIds[0])))
      }

      dispatch(deleteManySlidesById(slideIds))
      dispatch(
        setProjectSlidesOrder(
          state?.projects?.currentProject?.slideOrder.filter(id => !slideIds.includes(id))
        )
      )

      return response.data
    } catch (error) {
      return rejectWithValue(error.response?.data?.error?.message || null)
    }
  }
)

type TAddSlidePayload = {
  projectId: number
  templateId: number
  slideIndex: number[]
  thumbnails?: { [key: number]: Blob }
}

export const addSlidesToProject = createAsyncThunk<
  ISlideList,
  TAddSlidePayload,
  { rejectValue: string }
>(
  'slides/add',
  async ({ projectId, templateId, slideIndex, thumbnails }, { dispatch, rejectWithValue }) => {
    try {
      const response = await addSlides({ projectId, templateId, slideIndex, thumbnails })
      dispatch(setProjectSlidesOrder(response.order))
      dispatch(setAllSlides(response))
      dispatch(selectSlide(response.order[0]))
    } catch (error) {
      return rejectWithValue(error.response?.data?.error?.message || null)
    }
  }
)
