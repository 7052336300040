import React, { useCallback } from 'react'

import { fabric } from 'fabric'

import { CanvasComponentProps } from '@components/canvas/types/component'

import { useTableHandler, useVisibilityHandler } from '../handlers/objects'
import { FabricTableOptions } from '../objects/Table/types'
import { useFabricObject } from '../utils/useFabricObject'

const tableFactory = (options: FabricTableOptions): fabric.Table => {
  return new fabric.Table(options)
}

const Table = ({ id, options }: CanvasComponentProps<FabricTableOptions>) => {
  const factory = useCallback(() => tableFactory(options), [])
  const object = useFabricObject(factory, id, options)

  useVisibilityHandler(object)
  useTableHandler(object)
  return <></>
}

export default React.memo(Table)
