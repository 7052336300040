import { createAction } from '@reduxjs/toolkit'

import {
  selectCanvasHeight,
  selectCanvasInteractionMode,
  selectCanvasWidth
} from '@store/slices/canvas/canvas/selectors'
import { FabricPoint } from '@store/slices/canvas/types'
import {
  addOneCanvasObject,
  clearCanvasSelection,
  setAllCanvasObjets,
  updateManyCanvasObject
} from '@store/slices/shapes/actions'
import { selectCanvasAllObjects } from '@store/slices/shapes/selectors'
import { selectCurrentSlideId, selectSlidesObjectState } from '@store/slices/slides/selectors'

import { InteractionMode } from '@components/canvas'
import { workareaOption } from '@components/canvas/constants/defaults'
import { CanvasObjectOptions } from '@components/canvas/types/object'
import { FabricWorkAreaOptions } from '@components/canvas/types/workArea'

import { IShape } from '@/interfaces/shape'

export const setCanvasInteractionMode = createAction<InteractionMode>(
  'canvas/setCanvasInteractionMode'
)
export const setCanvasLoading = createAction<boolean>('canvas/setLoading')
export const setCanvasLoadingMessage = createAction<string>('canvas/setLoadingMessage')
export const setCanvasDimensions = createAction<{ width: number; height: number }>(
  'canvas/setCanvasDimensions'
)
export const setCanvasCenterPoint = createAction<FabricPoint>('canvas/setCanvasCenterPoint')
export const setCanvasFilter = createAction<string[]>('canvas/setCanvasFilter')

export function lockCanvas() {
  return (dispatch, getState) => {
    const state = getState()
    const objects = selectCanvasAllObjects(state)
    dispatch(clearCanvasSelection())
    const objectToChange = objects.filter(obj => obj.id !== 'grid' && obj.id !== 'workarea')
    const changes = objectToChange.map(({ id }) => ({
      id,
      changes: { selectable: false, evented: false }
    }))

    dispatch(updateManyCanvasObject(changes))
  }
}

export function setCanvasEmptyWorkArea() {
  return (dispatch, getState) => {
    const state = getState()
    const canvasWidth = selectCanvasWidth(state)
    const canvasHeight = selectCanvasHeight(state)

    const workAreaLeft = canvasWidth / 2 - workareaOption.width * workareaOption.scaleX
    const workAreaTop = canvasHeight / 2 - workareaOption.height * workareaOption.scaleY

    dispatch(
      addOneCanvasObject({
        ...workareaOption,
        top: workAreaTop,
        left: workAreaLeft
      })
    )
  }
}

export function loadCanvasObjects(entities: IShape[]) {
  return (dispatch, getState) => {
    const state = getState()
    const canvasWidth = selectCanvasWidth(state)
    const canvasHeight = selectCanvasHeight(state)
    const interactionMode = selectCanvasInteractionMode(state)
    const workArea = Object.values(entities).find(
      obj => obj.id === 'workarea'
    ) as FabricWorkAreaOptions
    const previousWorkAreaLeft = workArea.left
    const previousWorkAreaTop = workArea.top
    const workAreaLeft = canvasWidth / 2 - (workArea.width * workArea.scaleX) / 2
    const workAreaTop = canvasHeight / 2 - (workArea.height * workArea.scaleY) / 2
    const getObjectVisibleState = selectSlidesObjectState(state)
    let getObjectVisibleStateBySlide = []
    if (getObjectVisibleState[selectCurrentSlideId(state)]) {
      getObjectVisibleStateBySlide = getObjectVisibleState[selectCurrentSlideId(state)]
    }
    const newObjects = Object.keys(entities).map(key => {
      let obj = entities[key]
      if (obj.id === 'workarea') {
        return {
          ...obj,
          pk: obj.pk,
          top: workAreaTop,
          left: workAreaLeft,
          hasControls: false,
          hasBorders: false,
          selectable: false
        }
      }

      getObjectVisibleStateBySlide.forEach(function (o) {
        if (o['id'] === obj.id) {
          obj['visible'] = o['visibility']
        }
      })

      if (['textbox', 'chart', 'table'].includes(obj.type)) {
        obj.objectCaching = false
      }

      return {
        ...obj,
        pk: obj.pk,
        left: obj.left + workAreaLeft - previousWorkAreaLeft,
        top: obj.top + workAreaTop - previousWorkAreaTop,
        hasControls: false,
        hasBorders: true,
        selectable: interactionMode === 'selection',
        evented: interactionMode === 'selection',
        lockMovementX: true,
        lockMovementY: true,
        hoverCursor: 'auto'
      }
    })
    dispatch(setAllCanvasObjets(newObjects))

    return Promise.resolve(newObjects)
  }
}
