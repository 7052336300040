import React, { useCallback } from 'react'

import { fabric } from 'fabric'

import { useAngleHandler } from '@components/canvas/handlers/objects/useAngleHandler'
import { useCommonPropsHandler } from '@components/canvas/handlers/objects/useCommonPropsHandler'
import { useShadowHandler } from '@components/canvas/handlers/objects/useShadowHandler'
import { useVisibilityHandler } from '@components/canvas/handlers/objects/useVisibilityHandler'
import { CanvasComponentProps } from '@components/canvas/types/component'

import { useFabricObject } from '../utils/useFabricObject'

const rectFactory = (options: fabric.IRectOptions): fabric.Rect => {
  return new fabric.Rect(options)
}

const Rect = ({ id, options }: CanvasComponentProps<fabric.IRectOptions>) => {
  const factory = useCallback(() => rectFactory(options), [])
  const object = useFabricObject(factory, id, options)

  useCommonPropsHandler(object)
  useAngleHandler(object)
  useShadowHandler(object)
  useVisibilityHandler(object)

  return <></>
}

export default React.memo(Rect)
