import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import isNil from 'lodash/isNil'

import { RootState } from '@store/rootReducer'
import { selectCanvasObjectProperty } from '@store/slices/shapes/selectors'

import { useCanvas } from '@components/canvas/hooks/useCanvas'
import { FabricObject } from '@components/canvas/types/object'

export function useVisibilityHandler(object: FabricObject) {
  const selectObjProperty = useMemo(() => selectCanvasObjectProperty, [])
  const objectVisible = useSelector((s: RootState) => selectObjProperty(s, object?.id, 'visible'))
  const canvas = useCanvas()

  useEffect(() => {
    if (isNil(object) || isNil(objectVisible)) {
      return
    }

    if (objectVisible !== object.visible) {
      object.set('visible', objectVisible)
      canvas.requestRenderAll()
      const el = document.getElementById(`${object.id}_container`)

      if (object.superType === 'element' && el) {
        el.style.display = objectVisible ? 'block' : 'none'
      }
    }
  }, [canvas, object, objectVisible])
}
