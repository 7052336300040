import { DataFrameLike } from './data'
import { Optional } from './utils'

export enum ConditionTypes {
  gt = 'greater than',
  gte = 'greater than or equal',
  lt = 'less than',
  lte = 'less than or equal'
}

export type Threshold = {
  thresholdType: keyof typeof ConditionTypes
  thresholdValue: number
}

export interface IPercentageIndexingResponse {
  id: number
  axis: 'columns' | 'rows'
  controlGroup: number
  testGroup: number[]
  thresholds: Threshold[]
  useFormattedData?: boolean
}

export type IPercentageIndexingPayload = Optional<IPercentageIndexingResponse, 'id' | 'thresholds'>

export interface IPercentageIndexing {
  id: number
  response: DataFrameLike<number[]>
  dataFrame: DataFrameLike<number>
}
