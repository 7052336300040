import { FC } from 'react'

import { DndContext } from '@dnd-kit/core'
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import './SortableList.less'

export interface SortableItemDetail {
  id: string
  text: string
}

interface SortableItemProps {
  id: string
  text: string
  className: string
}

const SortableItem = (props: SortableItemProps) => {
  const { transform, transition, setNodeRef, listeners, attributes } = useSortable({ id: props.id })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
    <div className={props.className} style={style} ref={setNodeRef} {...listeners} {...attributes}>
      {props.text}
    </div>
  )
}

interface SortableListProps {
  value?: SortableItemDetail[]
  onChange?: (e: SortableItemDetail[]) => void
  className?: string
}

const SortableList: FC<SortableListProps> = ({
  value,
  onChange,
  className = 'sortable-list-item'
}) => {
  const handleDragEnd = event => {
    const { active, over } = event
    try {
      const newValues = value.slice(0)
      const oldIndex = newValues.findIndex(({ id }) => id === active.id)
      const newIndex = newValues.findIndex(({ id }) => id === over.id)
      onChange(arrayMove(newValues, oldIndex, newIndex))
    } catch (error) {
      console.error(error.message)
    }
  }

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <SortableContext items={value.map(({ id }) => id)}>
        {value.map(({ id, text }) => (
          <SortableItem key={id} id={id} text={text} className={className} />
        ))}
      </SortableContext>
    </DndContext>
  )
}

export default SortableList
