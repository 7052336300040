import { createAction } from '@reduxjs/toolkit'

import { Token, User } from '@store/slices/auth/types'

export const setAuthenticated = createAction<boolean>('auth/setAuthenticated')
export const setAccessToken = createAction<Token>('auth/setAccessToken')
export const setRefreshToken = createAction<Token>('auth/setRefreshToken')
export const setCurrentUser = createAction<User>('auth/getCurrentUser')
export const setMfaEnabled = createAction<boolean>('auth/setMfaEnabled')
export const clearAccessToken = createAction('auth/clearAccessToken')
export const clearRefreshToken = createAction('auth/clearRefreshToken')
export const clearSignInError = createAction('auth/clearSignInError')
export const clearMfaSignInError = createAction('auth/clearMfaSignInError')
export const clearSignUpError = createAction('auth/clearSignUpError')
export const clearLoading = createAction('auth/clearLoading')
