import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { Button, Form, Input, Space } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'

import { signUp } from '@/store/slices/auth/thunks'
import { AppDispatch } from '@/store/store'
import { clearSignUpError } from '@store/slices/auth/actions'
import { selectSignUpError, selectSignUpLoading } from '@store/slices/auth/selectors'

// import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import '../styles.less'

type Props = {
  onSuccess: (created: boolean, username: string, email: string, password: string) => void
}

export default function SignUpForm({ onSuccess }: Props) {
  const dispatch: AppDispatch = useDispatch()
  const error = useSelector(selectSignUpError)
  const isLoading = useSelector(selectSignUpLoading)
  const [form] = useForm()
  const [token, setToken] = useState<string | undefined>(null)

  const signUpUser = async () => {
    try {
      const email = form.getFieldValue('email').toLowerCase()
      const password = form.getFieldValue('password')
      const username = form.getFieldValue('username')
      unwrapResult(
        await dispatch(
          signUp({
            username: username,
            email: email,
            password: password,
            tokenId: token
          })
        )
      )
      onSuccess(true, username, email, password)

      form.setFieldsValue({
        username: '',
        email: '',
        password: ''
      })
    } catch (err) {}
  }

  const onValueChange = () => {
    if (error) {
      dispatch(clearSignUpError())
    }
  }

  useEffect(() => {
    dispatch(clearSignUpError())
  }, [dispatch])

  return (
    <div className="auth__card">
      {/* <GoogleReCaptcha
        onVerify={token => {
          setToken(token)
        }}
      /> */}
      <div className="card__header">
        <h1>Sign up</h1>
        <p>
          Already have an account? <Link to="sign-in">Sign in</Link>
        </p>
      </div>

      <div className="card__body">
        <Form
          name="signUp"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          layout="vertical"
          style={{ width: '85%' }}
          form={form}
          onValuesChange={onValueChange}
          onFinish={signUpUser}
        >
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <Form.Item
              label="Name"
              name="username"
              rules={[{ required: true, message: 'Please, enter name' }]}
            >
              <Input prefix={<UserOutlined />} autoComplete="off" />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Please enter your email.', type: 'email' }]}
            >
              <Input type="email" prefix={<MailOutlined />} autoComplete="on" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please, enter password' }]}
            >
              <Input.Password prefix={<LockOutlined />} />
            </Form.Item>

            <Form.Item
              label="Retype password"
              name="confirm"
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password.'
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('Passwords did not match.'))
                  }
                })
              ]}
            >
              <Input.Password prefix={<LockOutlined />} />
            </Form.Item>

            <p style={{ textAlign: 'center' }}>
              By signing up, you agree to our terms of service and privacy policy.
            </p>

            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
              <Button type="primary" htmlType="submit" loading={isLoading} block>
                Sign up
              </Button>
            </Form.Item>

            <Form.Item>
              <p style={{ color: 'red', textAlign: 'center' }}>{error ? error : null}</p>
            </Form.Item>
          </Space>
        </Form>
      </div>
    </div>
  )
}
