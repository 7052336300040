import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { message, Button, Form, Progress, Select, Space, Typography, Upload } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { UploadFile } from 'antd/lib/upload/interface'

import { selectCurrentProjectId } from '@store/slices/projects/selectors'
import { FileAddOutlined } from '@ant-design/icons'
import axios, { CancelTokenSource } from 'axios'
import i18n from 'i18next'

import {
  addDataSource,
  cancelDataSourceUpload,
  clearDataSourceUpload,
  deleteDataSource,
  updateDataSource
} from '@store/slices/data/action'
import {
  selectDataSectionsAddStatus,
  selectDataSourceAddStatus,
  selectNewDataSource
} from '@store/slices/data/selectors'
import { DataSourceAddStatuses } from '@store/slices/data/types'
import { AppDispatch } from '@store/store'

import { getCompanyParsers } from '@/services/data-service'

import CommonModal from '@/components/common/CommonModal'
import MultiSelectDropdown from '@/components/common/MultiSelectDropdown'
import { Flex } from '@components/flex'

import { CeleryStatus } from '@/interfaces/celery'
import { IParser } from '@/interfaces/interfaces'

import RawDataInterface from './raw-data/RawDataInterface'

const { Text, Paragraph, Link } = Typography
const { Dragger } = Upload

export interface DataUploaderProps {
  isModalVisible: boolean
  dataSourceId?: number
  onClose: () => void
  onComplete: () => void
  onCancel: () => void
}

export const DataUploader = ({
  isModalVisible,
  onClose,
  onComplete,
  onCancel
}: DataUploaderProps) => {
  const projectId = useSelector(selectCurrentProjectId)
  const [file, setFile] = useState<UploadFile | null>(null)
  const [cancelToken, setCancelToken] = useState<CancelTokenSource>(axios.CancelToken.source())
  const [fileType, setFileType] = useState<string>('.xlsx')
  const [parserError, setParserError] = useState<string>(null)
  const [parsers, setParsers] = useState<IParser[]>([])
  const [parser, setParser] = useState<string>('')
  const dataSourceAddStatus = useSelector(selectDataSourceAddStatus)
  const dataSectionsAddStatus = useSelector(selectDataSectionsAddStatus)
  const newDataSource = useSelector(selectNewDataSource)
  const dispatch: AppDispatch = useDispatch()
  const [form] = useForm()
  const [sheetsVisible, setSheetsVisible] = useState<Boolean>(false)

  const onModalClose = () => {
    cancelToken.cancel()
    dispatch(cancelDataSourceUpload())
    dispatch(clearDataSourceUpload())
    setFile(null)
    form.resetFields()
    onComplete()
  }

  const onRetry = () => {
    dispatch(clearDataSourceUpload())
    setFile(null)
    setParserError(null)
    form.resetFields()
  }

  useEffect(() => {
    const getParsers = async () => {
      try {
        const parsers = await getCompanyParsers()
        setParsers(parsers)
      } catch (e) {
        console.error(e.message)
      }
    }
    getParsers()
  }, [])

  const addProjectDataSource = async file => {
    try {
      const parserId = form.getFieldValue('parser')
      const parser = parsers.find(({ id }) => id === parserId)
      await dispatch(addDataSource({ file, parser, projectId }, cancelToken.token))
    } catch (e) {
      console.log('error', e)
      setParserError(e.message)
    }
  }

  const onParserChange = () => {
    setFileType(getFileType())
  }

  const getFileType = () => {
    const parserId = form.getFieldValue('parser')
    const parser = parsers.find(({ id }) => id === parserId)
    setParser(parserId)
    if (
      ['Default', 'PDR', 'DisplayR', 'Decipher', 'Longitude', 'WinCross'].includes(parser?.name)
    ) {
      return '.xlsx, .xls'
    } else if (['Ruby_XtabML', 'TabsML'].includes(parser?.name)) {
      return '.xml'
    } else if (['MarketSight_XtabML', 'Q_XtabML'].includes(parser?.name)) {
      return '.xte'
    } else if ('Quantum' === parser?.name) {
      return '.txt'
    } else if ('Dimensions_Mtd' === parser?.name) {
      return '.mtd'
    } else if ('SPSS' === parser?.name) {
      return '.sav'
    } else if (['Mercury', 'QPSMR'].includes(parser?.name)) {
      return '.csv'
    }
    return '.xlsx, .xls, .txt, .mtd, .xte, .xml'
  }

  const onSubmit = () => {
    addProjectDataSource(file)
  }
  const onFileChange = file => {
    if (file.size / 1024 / 1024 > 25) {
      message.error('Sorry, file size must be under 25 MB')
      return false
    }
    setFile(file)
    return false
  }

  const updateProjectDataSource = async formData => {
    try {
      const sections = formData.sections
      await dispatch(
        updateDataSource({
          projectId: projectId,
          dataSourceId: newDataSource.id,
          dataSource: {
            dataSections: sections as any,
            parserId: form.getFieldValue('parser'),
            filename: file.name
          }
        })
      )
    } catch (e) {
      setParserError(e)
      console.error(e)
      dispatch(deleteDataSource(newDataSource.id))
    }
  }

  const onDataSourceOK = async (columns, rows) => {
    try {
      await dispatch(
        updateDataSource({
          projectId,
          dataSourceId: newDataSource.id,
          dataSource: {
            columns: columns,
            questions: rows,
            parserId: form.getFieldValue('parser'),
            filename: file.name
          }
        })
      )
    } catch (e) {
      console.error(e.message)
      dispatch(deleteDataSource(newDataSource.id))
    }
  }

  useEffect(() => {
    if (dataSourceAddStatus === CeleryStatus.SUCCESS) {
      setTimeout(() => {
        dispatch(clearDataSourceUpload())
        setFile(null)
        form.resetFields()
        onComplete()
      }, 1000)
    }
  }, [dataSourceAddStatus, dispatch])

  useEffect(() => {
    if (isModalVisible) {
      setCancelToken(axios.CancelToken.source())
    }
  }, [isModalVisible])

  const draggerProps = {
    name: 'file',
    beforeUpload: onFileChange,
    accept: fileType,
    multiple: false,
    maxCount: 1
  }
  return (
    <CommonModal
      afterClose={onModalClose}
      title={i18n.t('editor.left-panel.data-sources.upload-data-source')}
      visible={isModalVisible}
      onCancel={onClose}
      footer={null}
      className="data-uploader"
      style={{
        minWidth:
          dataSourceAddStatus === DataSourceAddStatuses.RAW_DATA_SELECTION ? '1000px' : 'auto'
      }}
    >
      {
        {
          [CeleryStatus.FAILURE]: (
            <>
              <Flex justifyContent="center">
                <Space direction="vertical" size="middle">
                  <Typography>
                    <Text style={{ textAlign: 'center' }}>
                      {i18n.t('editor.left-panel.data-sources.error')}
                    </Text>
                    <Paragraph>
                      <pre>
                        <Text style={{ display: 'block' }}>Filename: {file?.name}</Text>
                        <Text style={{ display: 'block' }}>Error: {parserError}</Text>
                        <Text style={{ display: 'block' }}>
                          Table reader: {form.getFieldValue('parser')}
                        </Text>
                      </pre>
                    </Paragraph>
                    <Text style={{ textAlign: 'center' }}>
                      For help, please contact <Link copyable>support@indicolabs.io</Link>
                    </Text>
                  </Typography>
                </Space>
              </Flex>
              <Flex flexDirection="row" justifyContent="flex-end" style={{ paddingTop: '1rem' }}>
                <Button type="primary" onClick={onRetry}>
                  {i18n.t('editor.left-panel.templates.try-again')}
                </Button>
              </Flex>
            </>
          ),
          [CeleryStatus.SUCCESS]: (
            <Flex flex="1" justifyContent="center" flexDirection="column">
              <div style={{ textAlign: 'center' }}>
                {i18n.t('editor.left-panel.data-sources.file')}
                <strong>{file?.name}</strong>
                {i18n.t('editor.left-panel.data-sources.successfully')}
              </div>
              <Progress percent={100} status="success" showInfo={false} />
            </Flex>
          ),
          [CeleryStatus.PENDING]: (
            <Flex flex="1" justifyContent="center" flexDirection="column">
              <div style={{ textAlign: 'center' }}>
                {i18n.t('editor.left-panel.templates.pending-file')}
                <strong>{file?.name}</strong>
              </div>
              <Progress percent={100} status="active" showInfo={false} />
            </Flex>
          ),
          [CeleryStatus.STARTED]: (
            <Flex flex="1" justifyContent="center" flexDirection="column">
              <div style={{ textAlign: 'center' }}>
                {i18n.t('editor.left-panel.templates.started-file')}
                <strong>{file?.name}</strong>
              </div>
              <Progress percent={100} status="active" showInfo={false} />
            </Flex>
          ),
          [DataSourceAddStatuses.SECTIONS_SELECTION]: (
            <Form
              layout="vertical"
              style={{ margin: '0 20px' }}
              form={form}
              onFinish={updateProjectDataSource}
            >
              <Form.Item
                label={i18n.t('editor.left-panel.data-sources.sections.title')}
                name="sections"
                rules={[{ required: true }]}
                style={{ padding: '5px 0', width: '100%' }}
              >
                <MultiSelectDropdown
                  visible={sheetsVisible}
                  setVisible={setSheetsVisible}
                  dropdownSelect={() =>
                    form.setFieldsValue({ sections: dataSectionsAddStatus.map(({ id }) => id) })
                  }
                  dropdownClear={() => form.setFieldsValue({ sections: [] })}
                  dropdownOk={() => setSheetsVisible(false)}
                  dropdownCancel={() => {
                    form.setFieldsValue({ sections: [] })
                    setSheetsVisible(false)
                  }}
                >
                  {dataSectionsAddStatus.map(({ id, name }) => (
                    <Select.Option value={id} key={id}>
                      {name}
                    </Select.Option>
                  ))}
                </MultiSelectDropdown>
              </Form.Item>
              <Form.Item style={{ padding: '5px 0' }}>
                <Flex flex="1" justifyContent="center">
                  <Button type="primary" htmlType="submit">
                    {i18n.t('editor.left-panel.data-sources.sections.update')}
                  </Button>
                </Flex>
              </Form.Item>
            </Form>
          ),
          [DataSourceAddStatuses.RAW_DATA_SELECTION]: (
            <RawDataInterface onDataSourceOK={onDataSourceOK} />
          ),
          null: (
            <>
              <Flex justifyContent="center">
                <Form layout="vertical" form={form} onFinish={onSubmit}>
                  <Space size="small" direction="vertical">
                    <Form.Item label="Table reader" name="parser" required>
                      <Select onChange={onParserChange}>
                        {parsers.map(({ id, name }) => (
                          <Select.Option value={id} key={id}>
                            {name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item className="upload-template-file-item" required label={'Choose file'}>
                      <Flex style={{ height: '13rem' }} justifyContent="center">
                        <Space size="small" direction="vertical">
                          <Dragger showUploadList={true} {...draggerProps}>
                            <Flex
                              justifyContent="center"
                              alignItems="center"
                              style={{ width: '100%', height: '80px' }}
                            >
                              <FileAddOutlined style={{ fontSize: '24px' }} />
                            </Flex>
                            <p style={{ width: '70%', marginLeft: '15%', fontSize: '14px' }}>
                              Click or drag file to this area to upload
                            </p>
                          </Dragger>
                          {/* <span />
                      <span />
                      <Flex alignItems="center" justifyContent="center" flexDirection="column">
                        <p>or</p>

                        <Upload showUploadList={false} {...draggerProps}>
                          <Button>Choose file</Button>
                        </Upload>
                      </Flex> */}
                        </Space>
                      </Flex>
                    </Form.Item>
                  </Space>
                </Form>
              </Flex>
              <Flex flexDirection="row" justifyContent="flex-end" style={{ paddingTop: '1rem' }}>
                <Space size="small" direction="horizontal">
                  <Button
                    disabled={file == null || parser === ''}
                    type="primary"
                    onClick={() => form.submit()}
                    key="submit"
                    htmlType="submit"
                  >
                    {i18n.t('editor.left-panel.slides.upload-btn')}
                  </Button>

                  <Button onClick={onCancel} key="cancel">
                    Cancel
                  </Button>
                </Space>
              </Flex>
            </>
          )
        }[dataSourceAddStatus]
      }
    </CommonModal>
  )
}
