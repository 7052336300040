import React from 'react'

const CreateFile: React.FC = () => {
  return (
    <div className="slide__text">
      <div>
        <h1 className="slide__title">Create your presentation</h1>
        <p className="slide__description">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        </p>
      </div>
      <div>
        <img
          style={{ marginTop: '0.1rem' }}
          src="/images/success-tick-2008cf146a.gif"
          alt="success gif"
        />
      </div>
    </div>
  )
}

export default CreateFile
