import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { selectCanvasSelectedObject } from '@store/slices/shapes/selectors'

import DefaultDataView from './components/DefaultDataView'
import InterpolationView from './components/InterpolationView'

import './InsertData.less'

function InsertData(props) {
  const history = useHistory()
  const selectedObject = useSelector(selectCanvasSelectedObject)

  useEffect(() => {
    if (!selectedObject) {
      history.goBack()
    }
  }, [selectedObject, history])

  if (!selectedObject) {
    return null
  }
  return selectedObject.type === 'textbox' ? (
    <InterpolationView {...props.location.state} />
  ) : (
    <DefaultDataView />
  )
}

export default InsertData
