import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '@store/rootReducer'
import { selectCanvasObjectById } from '@store/slices/shapes/selectors'

import { useCanvas } from '@components/canvas/hooks/useCanvas'
import { FabricObject } from '@components/canvas/types/object'

import { CalculateTableDimensions } from '../../objects/Table/utils'

const watchedProperties = ['data', 'visualSettings', 'visible', 'width', 'height']

export function useTableHandler(object: FabricObject) {
  const canvas = useCanvas()
  const selectObj = useMemo(() => selectCanvasObjectById, [])
  const selectedObject = useSelector((s: RootState) => selectObj(s, object?.id))
  const { data, visualSettings } = selectedObject || {}

  useEffect(() => {
    if (!object || !selectedObject) {
      return
    }
    let args = { ...selectedObject }
    if (args.data) {
      let instance = new CalculateTableDimensions(args.table, args.data, args.visualSettings)
      args['width'] = instance.getWidth()
      args['height'] = instance.getHeight()
    }
    if (selectedObject.id === object.id) {
      watchedProperties.forEach((propName: string) => {
        object.set(propName, args[propName])
      })
      object.fire('update')
      canvas.requestRenderAll()
    }
  }, [data, visualSettings])
}
