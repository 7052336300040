import { UploadFile } from 'antd/lib/upload/interface'

import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { CancelToken } from 'axios'

import { uploadProjectFile } from '@/services/project-service'
import { addProjectTemplate, appendProjectTemplate } from '@/services/template-service'
import { cancelCeleryTask, getCeleryTaskStatus } from '@services/celery-service'

import { CeleryStatus, TemplateUploadResult } from '@/interfaces/celery'
import { ProjectFileTypes } from '@/interfaces/projects'

import { delayPromise } from '@/utils/promise'

import { addTemplateStatus, resetTemplate, setTemplateUploadProgress } from './actions'

const pollCelery = async (taskId: string, cancelToken: CancelToken, attemptsLimit = 40) => {
  let counter = 1
  try {
    const response = await getCeleryTaskStatus<TemplateUploadResult>({ taskId }, cancelToken)
    if (response.status === CeleryStatus.SUCCESS || response.status === CeleryStatus.FAILURE) {
      return response.result
    } else if (counter > attemptsLimit) {
      throw new Error('The limit of attempts for the celery task polling has been exceeded.')
    } else {
      counter += 1
      await delayPromise(1000)
      return await pollCelery(taskId, cancelToken)
    }
  } catch (error) {
    cancelCeleryTask({ taskId })
    throw error
  }
}

export const uploadTemplate = createAsyncThunk<
  TemplateUploadResult,
  { projectId: number; file: UploadFile; cancelToken: CancelToken }
>(
  'template/uploadTemplate',
  async ({ file, projectId, cancelToken }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(
        addTemplateStatus({
          status: CeleryStatus.PENDING,
          error: ''
        })
      )
      const { url } = await uploadProjectFile(
        {
          fileName: file.name,
          projectId: projectId,
          type: ProjectFileTypes.TEMPLATE
        },
        cancelToken
      )
      await axios.put(url, file, {
        headers: { 'Content-Type': file.type, cancelToken },
        onUploadProgress: progressEvent => {
          let progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
          dispatch(setTemplateUploadProgress(progress))
        }
      })
      const { taskId } = await addProjectTemplate({ projectId, fileName: file.name }, cancelToken)
      return await pollCelery(taskId, cancelToken)
    } catch (error) {
      dispatch(resetTemplate())
      return rejectWithValue(error.response.data.error.message)
    }
  }
)

export const appendTemplate = createAsyncThunk<
  TemplateUploadResult,
  { projectId: number; file: UploadFile; cancelToken: CancelToken }
>(
  'template/appendTemplate',
  async ({ file, projectId, cancelToken }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(
        addTemplateStatus({
          status: CeleryStatus.PENDING,
          error: ''
        })
      )
      const { url } = await uploadProjectFile(
        {
          fileName: file.name,
          projectId: projectId,
          type: ProjectFileTypes.TEMPLATE
        },
        cancelToken
      )

      await axios.put(url, file, {
        headers: { 'Content-Type': file.type, cancelToken },
        onUploadProgress: progressEvent => {
          let progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
          dispatch(setTemplateUploadProgress(progress))
        }
      })
      const { taskId } = await appendProjectTemplate(
        { projectId, fileName: file.name, actionType: 'append' },
        cancelToken
      )
      return await pollCelery(taskId, cancelToken)
    } catch (error) {
      dispatch(resetTemplate())
      console.error(error)
      return rejectWithValue(error.response.data.error.message)
    }
  }
)
