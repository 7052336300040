import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Form, Modal, Select, Space } from 'antd'
import { useForm } from 'antd/es/form/Form'

import { AppDispatch } from '@/store/store'
import { selectDataSources } from '@store/slices/data/selectors'
import { downloadAppendixReport } from '@store/slices/template/actions'

import CommonModal from '@/components/common/CommonModal'
import { Flex } from '@/components/flex'

import EmptyDataSourceUploader from '../right-panel/data-source/EmptyDataSourceUploader'
import { useRowsNColumns, useTables } from '../right-panel/hooks'

interface Props {
  isVisible: boolean
  setIsVisible: (bool: boolean) => void
}

type FormValues = {
  dataSourceId: number
  columnIndex: number
}

const AppendixReportExportConfirguration = ({ isVisible, setIsVisible }: Props) => {
  const dispatch: AppDispatch = useDispatch()

  const [form] = useForm<FormValues>()

  const [dataId, setDataId] = useState<number>(null)
  const [columnIndex, setColumnIndex] = useState<number>(null)
  const [uploaderVisible, setUploaderVisible] = useState<boolean>(true)

  const dataSources = useSelector(selectDataSources)
  const sections = dataSources.find(source => source.id === dataId)?.dataSections || []
  const tables = useTables(sections[0]?.id)
  const { columns, loading: loadingLabels } = useRowsNColumns(tables.tables[0]?.id)

  const onChange = (key: string, id: number) => {
    switch (key) {
      case 'dataSourceId':
        form.resetFields()
        setDataId(id)
        break
      case 'columnIndex':
        setColumnIndex(id)
    }
    form.setFieldsValue({ [key]: id })
  }

  const onFinish = async (formValues: FormValues) => {
    // const { dataSourceId, columnIndex } = formValues
    dispatch(downloadAppendixReport(dataId, columnIndex))
  }

  return (
    <CommonModal
      title="Appendix report export"
      visible={isVisible}
      onCancel={() => setIsVisible(false)}
      footer={null}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form.Item name="dataSourceId" rules={[{ required: true }]} label="Data source">
            <Select
              onChange={(id: number) => onChange('dataSourceId', id)}
              dropdownStyle={{
                display: uploaderVisible ? 'inline-block' : 'none'
              }}
              onDropdownVisibleChange={open => setUploaderVisible(open)}
              notFoundContent={
                <EmptyDataSourceUploader
                  onClose={() => {
                    setUploaderVisible(false)
                  }}
                />
              }
            >
              {dataSources.map(source => (
                <Select.Option value={source.id} key={source.id}>
                  {source.filename}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="columnIndex" rules={[{ required: true }]} label="Column">
            <Select loading={loadingLabels} onChange={(id: number) => onChange('columnIndex', id)}>
              {columns.map(column => (
                <Select.Option value={column.index} key={column.name}>
                  {column.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Flex flexDirection="row" justifyContent="flex-end" style={{ marginTop: '5%' }}>
              <Button
                disabled={dataId === null || columnIndex === null}
                onClick={() => form.submit()}
                type="primary"
                style={{ marginRight: '2%' }}
              >
                Download appendix
              </Button>
              <Button onClick={() => setIsVisible(false)}>Cancel</Button>
            </Flex>
          </Form.Item>
        </Space>
      </Form>
    </CommonModal>
  )
}

export default AppendixReportExportConfirguration
