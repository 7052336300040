import { Space } from 'antd'

import { DeleteFilled, EditOutlined } from '@ant-design/icons'

import { TextTransformation, TextTransformationTypes } from '@/interfaces/data-actions'

import './styles.less'

export interface Props {
  onEdit: (id: TextTransformation) => void
  onDelete: (id: number) => void
  queue: TextTransformation[]
}

const TextTranformationList = ({ onEdit, onDelete, queue }: Props) => {
  const actionMapping = { [TextTransformationTypes.SORTBY]: 'Sort by' }

  return (
    <ul style={{ listStyle: 'none' }}>
      {queue.map((action, index) => (
        <li key={action.id} className="action-list-item">
          <span>{actionMapping[action.type]}</span>
          <Space>
            <EditOutlined onClick={() => onEdit(action)} />
            <DeleteFilled style={{ color: '#D0342C' }} onClick={() => onDelete(action.id)} />
          </Space>
        </li>
      ))}
    </ul>
  )
}

export default TextTranformationList
