import { round } from 'lodash'

/** Checks if the value can be converted into a valid number */
function isNumeric(value: string | number | undefined | null): boolean {
  if (value == null) {
    return false
  } else if (isNaN(parseInt(value.toString(), 10))) {
    return false
  } else {
    return true
  }
}

function addCommaDelimiter(value: string): string {
  function f(val: string) {
    let i = val.length - 1
    while (i > 0) {
      if ((i + 1) % 3 === 1) {
        val = val.substring(0, val.length - i) + ',' + val.substring(val.length - i)
      }
      i -= 1
    }
    return val
  }
  let parts = value.split('.')
  if (parts.length > 1) {
    return [f(parts[0]), parts[1]].join('.')
  } else {
    return f(parts[0])
  }
}

/**
 * Creates a string representation of the value accordingly to the format code
 * References:
 * https://support.microsoft.com/en-us/office/number-format-codes-5026bbd6-04bc-48cd-bf33-80f18b4eae68
 * https://openpyxl.readthedocs.io/en/stable/_modules/openpyxl/styles/numbers.html
 * @param value - a numeric type or `str` that can be converted into `float`
 * @param code - a number format code (see the ref)
 * @param sourceLinked - if data is coming from the source, it may need to be handled differently
 */
class NumberFormatManager {
  formattedValue: string
  sourceLinked: boolean = null
  private _value: string | number | null
  private _code: string | null

  constructor(value: string | number | null, code: string | null, sourceLinked: boolean = false) {
    this._value = value
    this._code = code
    this.sourceLinked = sourceLinked
    this.formattedValue = isNumeric(value) ? String(value) : ''
  }

  get value(): string {
    let value = String(this._value).trim()
    const isPercentageCode = this.code === 'Percentage' || this.code.endsWith('%')
    if (this.sourceLinked && isPercentageCode) {
      return String(Number(value) * 100)
    }
    return value
  }

  get code(): string {
    if (!this._code) {
      return 'General'
    }
    return this._code
  }

  get postfix(): string {
    let match = this.code.search(/%|\$$/)
    if (match > 0) {
      return this.code.substring(match)
    }
    return ''
  }

  apply() {
    let ret = this.handleSpecialCases()
    if (ret === null) {
      ret = this.handleGeneralCase()
    }
    if (ret) ret += this.postfix
    return ret
  }

  private handleGeneralCase(): string {
    this.formatDecimalPart()
    this.formatIntegerPart()
    return this.formattedValue
  }

  /**
   * Sets this.formattedValue to fixed decimal places if there are
   * zeros and sharps in the format code. Otherwise, simply rounds it.
   */
  private formatDecimalPart(): string {
    let zerosNumber = 0
    let sharpsNumber = 0
    let codeParts = this.code.split('.')

    if (codeParts.length < 2) {
      this.formattedValue = String(Math.round(Number(this.value)))
      return this.formattedValue
    }
    let codeDec = codeParts[1]
    let match = codeDec.match(/0+/)
    if (match) {
      zerosNumber = match[0].length
    }
    match = codeDec.match(/#+/)
    if (match) {
      sharpsNumber = match[0].length
    }

    let value = String(round(Number(this.value), zerosNumber + sharpsNumber))
    let parts = value.split('.')
    if (parts.length < 2) {
      if (zerosNumber > 0) {
        value = value + '.' + '0'.repeat(zerosNumber)
      }
    } else {
      let len = value.length - parts[1].length + zerosNumber
      value = value.padEnd(len, '0')
    }
    this.formattedValue = value
    return this.formattedValue
  }

  private formatIntegerPart() {
    let codeInt = this.code.split('.')[0]

    // pad value with zeros
    let match = codeInt.match(/^0+/)
    if (match) {
      let len =
        match[0].length + this.formattedValue.length - this.formattedValue.split('.')[0].length
      this.formattedValue = this.formattedValue.padEnd(len, '0')
    }

    // prepend a number of zeros
    if (codeInt.match(/"0+"#$/)) {
      match = codeInt.match(/0+/)
      this.formattedValue = match[0] + this.formattedValue
    }

    // add a comma as a thousands delimiter
    if (codeInt.match(/(?<=(#,))#+/)) {
      this.formattedValue = addCommaDelimiter(this.formattedValue)
    }
  }

  private handleSpecialCases() {
    if (this._value == null) {
      return ''
    }
    if (!isNumeric(this._value)) {
      return String(this._value).trim()
    }
    if (this.code === 'Unlabelled') {
      return ''
    }
    if (this.code == null) {
      return this.value
    }
    if (this.code === 'Percentage') {
      return this.value + '%'
    }
    if (this.code === 'General') {
      return this.value
    }
    if (this.code === '#,') {
      return String(Math.round(Number(this.value) / 1000))
    }
    return null
  }
}

export function applyNumberFormatting(
  value: number | string | null,
  code: string | null,
  isLinked: boolean | null = null
): string {
  const instance = new NumberFormatManager(value, code, isLinked)
  return instance.apply()
}
