import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { fabric } from 'fabric'
import isEqual from 'lodash/isEqual'

import { RootState } from '@store/rootReducer'
import { selectCanvasObjectProperty } from '@store/slices/shapes/selectors'

import { FILTER_TYPES, IFilter } from '@components/canvas'
import { useCanvas } from '@components/canvas/hooks/useCanvas'
import { FabricImageObject } from '@components/canvas/types/image'

const SHARPEN_MATRIX = [0, -1, 0, -1, 5, -1, 0, -1, 0]
const EMBOSS_MATRIX = [1, 1, 1, 1, 0.7, -1, -1, -1, -1]

export function useFiltersHandler(object: FabricImageObject) {
  const selectObjProperty = useMemo(() => selectCanvasObjectProperty, [])
  const filters = useSelector((s: RootState) => selectObjProperty(s, object?.id, 'filters'))
  const canvas = useCanvas()

  const createFilter = (filter: IFilter) => {
    const { type: filterType, ...other } = filter
    const type = filterType.toLowerCase()
    if (type === 'grayscale') {
      return new fabric.Image.filters.Grayscale(other)
    } else if (type === 'invert') {
      return new fabric.Image.filters.Invert()
      // } else if (type === 'remove-color') {
      //     return new fabric.Image.filters.RemoveColor(other);
    } else if (type === 'sepia') {
      return new fabric.Image.filters.Sepia()
      // } else if (type === 'brownie') {
      //     return new fabric.Image.filters.Brownie();
    } else if (type === 'brightness') {
      return new fabric.Image.filters.Brightness({ brightness: other.brightness })
    } else if (type === 'contrast') {
      return new fabric.Image.filters.Contrast(other)
    } else if (type === 'saturation') {
      return new fabric.Image.filters.Saturation(other)
    } else if (type === 'noise') {
      return new fabric.Image.filters.Noise({ noise: other.noise })
      // } else if (type === 'vintage') {
      //     return new fabric.Image.filters.Vintage();
    } else if (type === 'pixelate') {
      return new fabric.Image.filters.Pixelate(other)
      // } else if (type === 'blur') {
      //     return new fabric.Image.filters.Blur(other);
    } else if (type === 'sharpen') {
      return new fabric.Image.filters.Convolute({
        matrix: SHARPEN_MATRIX
      })
    } else if (type === 'emboss') {
      return new fabric.Image.filters.Convolute({
        matrix: EMBOSS_MATRIX
      })
      // } else if (type === 'technicolor') {
      //     return new fabric.Image.filters.Technicolor();
      // } else if (type === 'polaroid') {
      //     return new fabric.Image.filters.Polaroid();
    } else if (type === 'blend-color') {
      return new fabric.Image.filters.BlendColor(other)
      // } else if (type === 'gamma') {
      //     return new fabric.Image.filters.Gamma(other);
      // } else if (type === 'kodachrome') {
      //     return new fabric.Image.filters.Kodachrome();
      // } else if (type === 'blackwhite') {
      //     return new fabric.Image.filters.BlackWhite();
    } else if (type === 'blend-image') {
      return new fabric.Image.filters.BlendImage(other)
      // } else if (type === 'hue') {
      //     return new fabric.Image.filters.HueRotation(other);
    } else if (type === 'resize') {
      return new fabric.Image.filters.Resize(other)
    } else if (type === 'tint') {
      return new fabric.Image.filters.Tint(other)
    } else if (type === 'mask') {
      return new fabric.Image.filters.Mask({
        channel: other.channel,
        mask: other.mask
      })
    } else if (type === 'multiply') {
      return new fabric.Image.filters.Multiply({
        color: other.color
      })
    } else if (type === 'sepia2') {
      return new fabric.Image.filters.Sepia2(other)
    }
    return false
  }

  useEffect(() => {
    if (!object || !filters) {
      return
    }

    if (filters !== object.filters) {
      const newFilters = filters.reduce((prev, filter) => {
        let type = filter.type
        if (type.toLowerCase() === 'convolute' && isEqual(filter.matrix, SHARPEN_MATRIX)) {
          type = 'sharpen'
        } else if (type.toLowerCase() === 'convolute' && isEqual(filter.matrix, EMBOSS_MATRIX)) {
          type = 'emboss'
        }
        const findIndex = FILTER_TYPES.findIndex(filterType => type.toLowerCase() === filterType)
        if (findIndex > -1) {
          prev[findIndex] = createFilter({
            ...filter,
            type
          })
        }
        return prev
      }, [])
      object.set({
        filters: newFilters
      })
      object.applyFilters()
      canvas.renderAll()
    }
  }, [canvas, filters, object])
}
