import useSWRImmutable from 'swr/immutable'

import { getAllTables } from '@services/data-service'

import { ITable } from '@/interfaces/interfaces'

export function useTables<T extends number | number[] = number>(dataSectionId: T = null) {
  const { data: tables, error } = useSWRImmutable<ITable[]>(
    () => (dataSectionId != null ? [dataSectionId] : null),
    getAllTables
  )
  return {
    error,
    tables: tables || [],
    loading: !tables && !error
  }
}
