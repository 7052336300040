import React from 'react'

import classNames from 'classnames'

import Icon from '@/components/icon/Icon'

type Props = {
  rightIsOpen: boolean
  leftIsOpen: boolean
  setLeftIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleFormSubmit: (values) => void
  children: React.ReactChild
}

const LeftSidePanel = ({
  rightIsOpen,
  leftIsOpen,
  setLeftIsOpen,
  handleFormSubmit,
  children
}: Props) => {
  return (
    <div className={classNames('left-sidebar', { 'lower-z-index': rightIsOpen })}>
      <div
        className="icon-container"
        onClick={() => {
          setLeftIsOpen(!leftIsOpen)
        }}
      >
        <Icon className="icon" name={leftIsOpen ? 'angle-left' : 'angle-right'} />
      </div>

      <div className={classNames('form', { 'is-open': leftIsOpen })}>{children}</div>
    </div>
  )
}

export default LeftSidePanel
