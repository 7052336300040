import { DataTransformationDto } from '@/interfaces/data-transformations'
import { IColumn, IRow } from '@/interfaces/interfaces'

const getBoundRowsnColumns = (
  rows: IRow[],
  columns: IColumn[],
  dataTransformation: DataTransformationDto
): [IRow[], IColumn[]] => {
  const transposed = dataTransformation?.transposed
  const boundColumns = columns.filter(({ index }) => dataTransformation?.columns.includes(index))
  const boundRows = rows.filter(({ index }) => dataTransformation?.rows.includes(index))
  return transposed ? [boundColumns, boundRows] : [boundRows, boundColumns]
}

type ReturnValues = [IRow[], IColumn[]]

/**
 * Get columns and rows of the data frame that is a result of data selections + data actions
 */
const getResultingRowsnColumns = (
  rows: IRow[],
  columns: IColumn[],
  dataTransformation: DataTransformationDto
): ReturnValues => {
  let resultingRows = dataTransformation?.resultingRows.map((idx: number) => {
    if (dataTransformation.transposed) {
      let name = columns[idx]?.name
      let index = columns[idx]?.index
      return { name, index }
    }
    let name = rows[idx]?.name
    let index = rows[idx]?.index
    return { name, index }
  })
  let resultingColumns = dataTransformation?.resultingColumns.map((idx: number) => {
    if (dataTransformation.transposed) {
      let name = rows[idx]?.name
      let index = rows[idx]?.index
      return { name, index }
    }
    let name = columns[idx]?.name
    let index = columns[idx]?.index
    return { name, index }
  })
  return [resultingRows, resultingColumns]
}

export { getBoundRowsnColumns, getResultingRowsnColumns }
export * from './tableUtils'
