import { range } from 'lodash'

import { fabricCanvas } from '@components/canvas/CanvasObject'

import { SigTestTypes } from '@/interfaces/analytics'
import { IShapeData } from '@/interfaces/shape'

import { applyNumberFormatting } from '../../utils'
import { cellOptions } from './defaults'
import { TestResult } from './overlay'
import {
  IndexingVisualisation,
  SigTestVisualisation,
  SymbolPosition,
  TableDefinition,
  TableVisualSettings,
  VisualisationSymbols
} from './types'

type Positions = {
  top: [number, number][]
  bottom: [number, number][]
  left: [number, number][]
  right: [number, number][]
}

export type CellValuesPositions = {
  dataValue: [number, number]
  positions: Positions
}[][]

/**
 * Splits text into multiple pieces where each piece should fit the specified max width
 */
export function splitTextIntoLines(
  ctx: CanvasRenderingContext2D,
  text: string,
  maxWidth: number
): string[] {
  let words = text.split(' ')
  let currentRun = []
  let runs: string[] = []
  while (words.length > 0) {
    currentRun.push(words.shift())
    if (words.length > 0) {
      // if the next word doesn't fit in the same line, create a new run
      let w = ctx.measureText([...currentRun, words[0]].join(' ')).width
      if (w > maxWidth) {
        runs.push(currentRun.join(' '))
        currentRun = []
      }
    } else {
      runs.push(currentRun.join(' '))
    }
  }
  return runs
}

export class CalculateTableDimensions {
  rows: string[]
  columns: string[]
  data: number[][]
  table: TableDefinition
  sigTestResult: TestResult<string>
  indexingResult: TestResult<number[]>
  indexingDataFrame: TestResult<number>
  sigTestVisualSettings: SigTestVisualisation
  sigTestType: SigTestTypes
  indexingVisualSettings: IndexingVisualisation[]
  tableVisualSettings: TableVisualSettings['table']
  rowHeaderWidth?: number
  colHeaderHeight?: number
  numberFormat?: string
  rowHeights?: number[]
  ctx: CanvasRenderingContext2D

  constructor(table: TableDefinition, data: IShapeData, visualSettings: TableVisualSettings) {
    this.table = table
    this.rows = data.dataFrame.index
    this.columns = data.dataFrame.columns
    this.data = data.dataFrame.data
    this.indexingResult = data?.indexing?.response
    this.indexingDataFrame = data?.indexing?.dataFrame
    this.sigTestResult = data?.sigTesting?.response?.values
    this.sigTestVisualSettings = visualSettings?.significanceTest
    this.sigTestType = data?.sigTesting?.analysisType
    this.indexingVisualSettings = visualSettings?.percentageIndexing || []
    this.tableVisualSettings = visualSettings?.table
    this.numberFormat = visualSettings?.numberFormat || '0'
    this.ctx = fabricCanvas.getContext()
  }

  getWidth() {
    let width = this.calculateColumnWidths().reduce((x, y) => x + y, 0)
    if (this.tableVisualSettings?.showRowHeaders === false) {
      width -= this.rowHeaderWidth
    }
    return width
  }

  getHeight() {
    let height = this.calculateRowHeights().reduce((x, y) => x + y, 0)
    if (this.tableVisualSettings?.showColHeaders === false) {
      height -= this.colHeaderHeight
    }
    return height
  }

  /**
   * Finds what width takes a given significance test visualisation symbol.
   * @param row row name
   * @param col column name
   * @returns position, width that takes a given symbol (or set of chars)
   */
  private getSigTestSymbolsWidths(
    row: string,
    col: string,
    padded: 0 | 1 = 1,
    defaultPosition: SymbolPosition = 'right'
  ): [SymbolPosition, number] {
    const value = this.sigTestResult?.[row]?.[col]
    if (value && this.sigTestVisualSettings) {
      if (this.sigTestType === 'all_vs_all') {
        return [
          this.sigTestVisualSettings.position || defaultPosition,
          this.ctx.measureText(`(${value})`).width + cellOptions.offsetX * Number(padded)
        ]
      }
      const symbol = VisualisationSymbols[this.sigTestVisualSettings.symbol]
      return [
        this.sigTestVisualSettings.position || defaultPosition,
        this.ctx.measureText(symbol).width + cellOptions.offsetX * Number(padded)
      ]
    }
    return null
  }

  /**
   * Finds what width takes a given percentage indexing visualisation symbol.
   * @param row row name
   * @param col column name
   * @returns array of tuples where the 1st value is position, 2nd is width.
   */
  private getIndexingSymbolsWidths(
    row: string,
    col: string,
    padded: 0 | 1 = 1,
    defaultPosition: SymbolPosition = 'right'
  ): [SymbolPosition, number][] | null {
    if (!this.indexingVisualSettings || !this.indexingResult) {
      return null
    }
    const value = this.indexingResult?.[row]?.[col]
    if (value) {
      return this.indexingVisualSettings
        .filter((_, index) => value.includes(index))
        .map(threshold => {
          let width =
            this.ctx.measureText(VisualisationSymbols[threshold.symbol]).width +
            cellOptions.offsetX * padded
          return [threshold.position || defaultPosition, width]
        })
    }
    return null
  }

  /** Calculates coordinates for data values and symbols within each cell. */
  calculateCellValuesCoordinates(): CellValuesPositions {
    if (!this.rowHeights) {
      throw new Error('this.rowHeights is not defined, call `.calculateRowHeights()` method first.')
    }
    return this.rows.map((rowName, rowIndex) => {
      return this.columns.map((columnName, columnIndex) => {
        const rowHeight = this.rowHeights[rowIndex + 1]
        const formattedValue = applyNumberFormatting(
          this.data?.[rowIndex]?.[columnIndex],
          this.numberFormat
        )
        let valueWidth = this.ctx.measureText(formattedValue).width
        const x = 0
        const y = rowHeight / 2
        let top: [number, number] = [x, y - cellOptions.lineHeight]
        let bottom: [number, number] = [x, y + cellOptions.lineHeight]
        let left: [number, number] = [x, y]
        let right: [number, number] = [x + valueWidth + cellOptions.offsetX, y]
        let valueCoords: [number, number] = [x, y]
        let positions: Positions = {
          top: [],
          bottom: [],
          left: [],
          right: []
        }
        const temp1 = this.getSigTestSymbolsWidths(rowName, columnName)
        const temp2 = this.getIndexingSymbolsWidths(rowName, columnName)
        let entries = []
        if (temp1) entries.push(temp1)
        if (temp2) entries.push(...temp2)
        const sortOrder: SymbolPosition[] = ['left', 'top', 'right', 'bottom']
        entries = entries.sort((a, b) => sortOrder.indexOf(a[0]) - sortOrder.indexOf(b[0]))
        entries.forEach(entry => {
          const [position, width] = entry
          let coords: [number, number] = [x, y]
          switch (position) {
            case 'top':
              coords = [valueCoords[0] + top[0], y - cellOptions.lineHeight]
              top[0] += width
              positions.top.push(coords)
              break
            case 'bottom':
              coords = [valueCoords[0] + bottom[0], y + cellOptions.lineHeight]
              bottom[0] += width
              positions.bottom.push(coords)
              break
            case 'left':
              valueCoords[0] += width
              coords = [left[0], y]
              positions.left.push(coords)
              left[0] += width
              break
            case 'right':
              coords = [valueCoords[0] + right[0], y]
              positions.right.push(coords)
              right[0] += width
              break
          }
        })
        return {
          dataValue: valueCoords,
          positions: positions
        }
      })
    })
  }

  /**
   * Adjusts cells height according to presence of visualisation symbols in top
   * and bottom positions.
   * @param avgHeight value that needs to be adjusted
   * @returns array of cells height from top to bottom
   */
  private getAdjustedRowHeights(
    avgHeight: number,
    lineHeight: number = cellOptions.lineHeight,
    padding: number = cellOptions.offsetY * 2
  ): number[] {
    let acc = [avgHeight]
    this.colHeaderHeight = avgHeight
    this.rows.forEach(rowName => {
      let top = 0
      let bottom = 0
      this.columns.forEach(colName => {
        if (this.indexingResult?.[rowName]?.[colName]) {
          const values = this.indexingResult[rowName][colName]
          values.forEach(index => {
            const position = this.indexingVisualSettings?.[index]?.['position']
            if (position === 'top') {
              top = lineHeight
            }
            if (position === 'bottom') {
              bottom = lineHeight
            }
          })
        }
        if (this.sigTestResult?.[rowName]?.[colName]) {
          const position = this.sigTestVisualSettings?.['position']
          if (position === 'top') {
            top = lineHeight
          }
          if (position === 'bottom') {
            bottom = lineHeight
          }
        }
      })
      acc.push(avgHeight + top + bottom + padding)
    })
    return acc
  }

  private calculateRowHeaderHeights() {
    return this.rows.map(rowName => {
      const columnWidth = this.calculateColumnWidths()[0]
      const pieces = splitTextIntoLines(this.ctx, rowName, columnWidth - cellOptions.offsetX)
      return pieces.length * cellOptions.lineHeight + cellOptions.offsetY * 2
    })
  }

  private calculateColumnHeaderHeight() {
    const heights = this.columns.map((colName, index) => {
      const columnWidth = this.calculateColumnWidths()[index + 1]
      const pieces = splitTextIntoLines(this.ctx, colName, columnWidth - cellOptions.offsetX)
      return pieces.length * cellOptions.lineHeight + cellOptions.offsetY * 2
    })
    return Math.max(...heights)
  }

  calculateRowHeights() {
    const { rowsHeights } = this.table
    let avgRowHeight = 0
    if (rowsHeights.length === 1) {
      avgRowHeight = rowsHeights[0]
    } else {
      avgRowHeight = rowsHeights.slice(1).reduce((x, y) => x + y) / (rowsHeights.length - 1)
    }

    const adjustedRowHeights = this.getAdjustedRowHeights(avgRowHeight, cellOptions.lineHeight)
    const rowHeaderHeights = this.calculateRowHeaderHeights()
    const colHeaderHeight = this.calculateColumnHeaderHeight()
    this.rowHeights = adjustedRowHeights.map((height, index) => {
      if (index === 0) {
        return Math.max(height, colHeaderHeight)
      }
      return Math.max(height, rowHeaderHeights[index - 1])
    })
    return this.rowHeights
  }

  calculateColumnWidths() {
    const { columnsWidths } = this.table
    let avgColumnWidth = 0
    if (columnsWidths.length === 1) {
      avgColumnWidth = columnsWidths[0]
    } else {
      avgColumnWidth = columnsWidths.slice(1).reduce((x, y) => x + y) / (columnsWidths.length - 1)
    }
    this.rowHeaderWidth = columnsWidths[0]
    const cellWidths = range(this.columns.length).map(() => {
      return avgColumnWidth
    })
    cellWidths.unshift(columnsWidths[0])
    return cellWidths
  }
}
