import { createAction } from '@reduxjs/toolkit'
import isNil from 'lodash/isNil'

import {
  loadCanvasObjects,
  setCanvasEmptyWorkArea,
  setCanvasInteractionMode,
  setCanvasLoading
} from '@store/slices/canvas/canvas/actions'
import { selectCurrentProject, selectCurrentProjectId } from '@store/slices/projects/selectors'
import { clearCanvasSelection, removeAllCanvasObjects } from '@store/slices/shapes/actions'
import { selectCanvasAllObjects } from '@store/slices/shapes/selectors'

import { getSlideById, getSlides } from '@services/slide-service'

import { ISlideDetail, ISlideList, ISlides } from '@/interfaces/slide'

import { zoomToFit } from '../canvas/zoom/actions'
import { setProjectSlidesOrder } from '../projects/actions'

export const clearAllSlides = createAction('slides/clearAll')
export const setAllSlides = createAction<ISlideList>('slides/setAll')
export const setCurrentSlideId = createAction<number>('slides/setCurrentId')
export const setSlideObjectState = createAction<ISlides<number[]>>('slides/setSlideObjectState')
export const addSlide = createAction<ISlideDetail>('slides/add')
export const addManySlides = createAction<ISlideList>('slides/addMany')
export const deleteSlideById = createAction<number>('slide/delete')
export const deleteManySlidesById = createAction<number[]>('slides/deleteMany')

/** Request all slides for the given project */
export function fetchAllSlides() {
  return async (dispatch, getState) => {
    const state = getState()
    const projectId = selectCurrentProjectId(state)
    dispatch(setCanvasLoading(true))
    try {
      const data = await getSlides({ projectId: projectId })
      dispatch(setProjectSlidesOrder(data.order))
      await dispatch(setAllSlides(data))
      let currentUrlParams = new URLSearchParams(window.location.search)
      const curSlide = parseInt(currentUrlParams.get('slide'))
      const firstSlideId = curSlide && data.order.includes(curSlide) ? curSlide : data.order[0]
      await dispatch(selectSlide(firstSlideId))
    } catch (e) {
      console.error(e.message)
    }
    dispatch(setCanvasLoading(false))
  }
}

export function selectSlide(slideId: number) {
  return async (dispatch, getState) => {
    const state = getState()
    const project = selectCurrentProject(state)
    await dispatch(setCanvasLoading(true))
    await dispatch(setCanvasInteractionMode('selection'))
    await dispatch(setCurrentSlideId(slideId))
    const response = await getSlideById({ projectId: project.id, slideId })
    await dispatch(removeAllCanvasObjects())
    await dispatch(loadCanvasObjects(response.shapes))
    await dispatch(clearCanvasSelection())
    await dispatch(zoomToFit())
    await dispatch(setCanvasLoading(false))
  }
}

export function clearCurrentSlide() {
  return dispatch => {
    //dispatch(setCurrentSlideId(null))
    dispatch(removeAllCanvasObjects())
    dispatch(setCanvasEmptyWorkArea())
  }
}

export function clearCurrentSlideWithoutWorkarea() {
  return dispatch => {
    dispatch(setCurrentSlideId(null))
    dispatch(removeAllCanvasObjects())
  }
}

export function refreshSlide() {
  return async (dispatch, getState) => {
    const state = getState()
    const project = selectCurrentProject(state)
    const slideId = state.slides.current

    if (project && !isNil(slideId)) {
      dispatch(setCanvasLoading(true))
      const response = await getSlideById({ projectId: project.id, slideId })
      await dispatch(loadCanvasObjects(response.shapes))
      dispatch(setCanvasLoading(false))
    }
  }
}

export const downloadSlideJson = () => async (dispatch, getState) => {
  const state = getState()
  const objects = selectCanvasAllObjects(state)
  dispatch(setCanvasLoading(true))
  const anchorEl = document.createElement('a')
  anchorEl.href = `data:text/json;charset=utf-8,${encodeURIComponent(
    JSON.stringify(objects, null, '\t')
  )}`
  anchorEl.download = 'slide.json'
  document.body.appendChild(anchorEl)
  anchorEl.click()
  anchorEl.remove()
  dispatch(setCanvasLoading(false))
}

export const uploadSlideJson = () => async dispatch => {
  const inputEl = document.createElement('input')
  inputEl.accept = '.json'
  inputEl.type = 'file'
  inputEl.hidden = true
  inputEl.onchange = (event: any) => {
    dispatch(setCanvasLoading(true))
    const reader = new FileReader()
    reader.onload = async (e: any) => {
      const shapes = JSON.parse(e.target.result)

      await dispatch(loadCanvasObjects(shapes))
      dispatch(setCanvasLoading(false))
    }
    reader.onabort = () => {
      dispatch(setCanvasLoading(false))
    }
    reader.onloadend = () => {
      dispatch(setCanvasLoading(false))
    }
    reader.onerror = () => {
      dispatch(setCanvasLoading(false))
    }
    reader.readAsText(event.target.files[0])
  }
  document.body.appendChild(inputEl)
  inputEl.click()
  inputEl.remove()
}
