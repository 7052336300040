import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import axios from 'axios'

import { selectAccessToken } from '@store/slices/auth/selectors'
import { signOut } from '@store/slices/auth/thunks'

const blackListUrls = ['/api/auth/sign-up/', '/api/auth/sign-in/']

export function useTokenInterceptor() {
  const accessToken = useSelector(selectAccessToken)
  const history = useHistory()
  const dispatch = useDispatch()
  const [isLoaded, setLoaded] = useState(false)

  useEffect(() => {
    if (!isLoaded) {
      setLoaded(true)
    }
    const authInterceptor = axios.interceptors.request.use(request => {
      if (accessToken && request.url.includes('/api') && !blackListUrls.includes(request.url)) {
        request.headers.Authorization = `Bearer ${accessToken.key}`
      }

      return request
    })

    return () => {
      axios.interceptors.request.eject(authInterceptor)
    }
  }, [accessToken])

  useEffect(() => {
    const authInterceptor = axios.interceptors.response.use(
      response => response,
      async error => {
        if (error?.response?.status === 401) {
          dispatch(signOut())
          history.push('/sign-in')
        }
        return Promise.reject(error)
      }
    )

    return () => {
      axios.interceptors.response.eject(authInterceptor)
    }
  }, [history, dispatch])

  return isLoaded
}
