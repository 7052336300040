import { useEffect, useState } from 'react'

import { Form, Radio, Space } from 'antd'
import { useForm } from 'antd/es/form/Form'

import i18n from 'i18next'

import CommonModal from '@/components/common/CommonModal'
import Info from '@/components/common/Info'
import SortableList from '@components/form/SortableList'

import { ActionProps, ActionTypes, ReorderAction } from './types'

export type FormValues = {
  axis: 0 | 1
  rows: { id: number | string; text: string }[]
  columns: { id: number | string; text: string }[]
}

const Reorder = ({
  columns,
  rows,
  onSubmit,
  onCancel,
  isVisible,
  initialValues,
  clearOptions
}: ActionProps<ReorderAction>) => {
  const [form] = useForm<FormValues>()
  const initial = {
    axis: 0,
    columns: [],
    rows: [],
    ...initialValues
  }

  const [axisValue, setAxisValue] = useState(initial.axis)

  useEffect(() => {
    if (clearOptions === true) {
      form.resetFields()
      //@ts-ignore
      form.setFieldsValue(initial)
    }
  }, [clearOptions, initialValues])

  const submitForm = () => {
    form.submit()
    const formValues = form.getFieldsValue(true)
    const { axis } = formValues
    const payload = {
      actionType: ActionTypes.REORDER,
      axis,
      by: {
        rows: axis === 0 ? formValues?.rows?.map(({ id }) => id - 1) : [], // NOTE previously we added + 1 so all ids > 0, that caused an issue with dnd-kit
        columns: axis === 1 ? formValues?.columns.map(({ id }) => id - 1) : []
      }
    }
    onSubmit(payload)
  }

  useEffect(() => {
    setAxisValue(form.getFieldValue('axis'))
  })

  useEffect(() => {
    if (columns && rows) {
      form.setFieldsValue({
        columns: columns.map(column => ({ text: column?.name, id: column?.index + 1 })),
        rows: rows.map(row => ({ text: row?.name, id: row?.index + 1 }))
      })
    }
  }, [columns, rows, axisValue])

  const onAxisChange = e => {
    form.resetFields()
    //@ts-ignore
    form.setFieldsValue(initial)
    setAxisValue(e.target.value)
    form.setFieldsValue({ axis: e.target.value })
  }

  const popoverContent = (
    <div>
      <strong>Function</strong>
      <p>Set the order of rows and columns manually</p>
      <strong>How to use</strong>
      <p>Drag the tiles to a new position to set a new order</p>
    </div>
  )

  return (
    <CommonModal
      title={
        <span>
          Reorder <Info>{popoverContent}</Info>
        </span>
      }
      visible={isVisible}
      bodyStyle={{ maxHeight: '60vh', overflow: 'auto' }}
      onCancel={onCancel}
      onOk={submitForm}
      centered
    >
      <Form form={form} initialValues={initial} layout="vertical">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form.Item
            name="axis"
            rules={[{ required: true }]}
            label={i18n.t(
              'editor.right-panel.data-source.form.data-transformation.sortBy.orientation'
            )}
          >
            <Radio.Group onChange={onAxisChange} value={0}>
              <Space direction="horizontal">
                <Radio value={0}>{i18n.t('editor.right-panel.data-source.form.rows')}</Radio>
                <Radio value={1}>{i18n.t('editor.right-panel.data-source.form.columns')}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>

          <Form.Item hidden={axisValue === 1} name="rows" rules={[{ required: true }]}>
            <SortableList />
          </Form.Item>

          <Form.Item hidden={axisValue === 0} name="columns" rules={[{ required: true }]}>
            <SortableList />
          </Form.Item>
        </Space>
      </Form>
    </CommonModal>
  )
}

export default Reorder
