import axios from 'axios'

const API_URL = '/api/account'

export async function updateUserPassword(
  accountId: number,
  currentPassword: string,
  newPassword: string,
  repeatPassword: string
) {
  const response = await axios
    .put(`${API_URL}/${accountId}/`, { currentPassword, newPassword, repeatPassword })
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })

  return response
}

export async function updateTwoFactorAuthentication(accountId: number, is_mfa_activated: boolean) {
  const response = await axios
    .put(`${API_URL}/${accountId}/`, { is_mfa_activated })
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })

  return response
}
