import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { fabric } from 'fabric'

import {
  selectCanvasMaxZoom,
  selectCanvasMinZoom,
  selectCanvasViewportTransform,
  selectCanvasZoomPoint,
  selectCanvasZoomRatio
} from '@store/slices/canvas/zoom/selectors'

import { useCanvas } from '@components/canvas/hooks/useCanvas'

export function useZoomHandler() {
  const zoomPoint = useSelector(selectCanvasZoomPoint)
  const zoomRatio = useSelector(selectCanvasZoomRatio)
  const minZoom = useSelector(selectCanvasMinZoom)
  const maxZoom = useSelector(selectCanvasMaxZoom)
  const viewportTransform = useSelector(selectCanvasViewportTransform)
  const canvas = useCanvas()
  useEffect(() => {
    canvas.setViewportTransform(viewportTransform)
  }, [viewportTransform])

  useEffect(() => {
    if (zoomPoint && zoomRatio) {
      let newZoom = zoomRatio
      if (zoomRatio <= minZoom / 100) {
        newZoom = minZoom / 100
      } else if (zoomRatio >= maxZoom / 100) {
        newZoom = maxZoom / 100
      }
      canvas.zoomToPoint(new fabric.Point(zoomPoint.x, zoomPoint.y), newZoom)
      canvas.requestRenderAll()
      canvas.fire('zoom:changed', zoomRatio)
    }
  }, [zoomPoint, zoomRatio, minZoom, maxZoom])
}
