import axios, { CancelToken } from 'axios'
import { v4 } from 'uuid'

import { IShape } from '@/interfaces/shape'
import { ISlide, ISlideDetail, ISlideList, ISlides } from '@/interfaces/slide'

const API_URL = '/api/projects'

/** Get slides added to the project */
export function getSlides({ projectId }): Promise<ISlideList> {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${projectId}/slides/`)
      .then(res => {
        const slide = res.data
        resolve(slide)
      })
      .catch(err => {
        reject(err?.response?.data)
      })
  })
}

/** Get all slides from the project's template */
export async function getTemplateSlides({
  projectId,
  templateId
}: {
  projectId: number
  templateId: number
}): Promise<ISlides<IShape[]>> {
  const url = `${API_URL}/${projectId}/template/${templateId}`
  const response = await axios.get(url)
  return response.data
}

export function getSlideById({
  projectId,
  slideId
}: {
  projectId: number
  slideId: number
}): Promise<ISlide<IShape[]>> {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/${projectId}/slides/${slideId}/`)
      .then(res => {
        const item = res.data
        resolve(item)
      })
      .catch(err => {
        reject(err?.response?.data)
      })
  })
}

export function updateSlide({ projectId, slideId, shapes }): Promise<any> {
  return new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/${projectId}/slides/${slideId}/`, { shapes })
      .then(res => {
        const item = res.data
        resolve(item)
      })
      .catch(err => {
        reject(err?.response?.data)
      })
  })
}

export async function addSlides({
  projectId,
  templateId,
  slideIndex,
  thumbnails
}): Promise<ISlideList> {
  const url = `${API_URL}/${projectId}/slides/`
  const payload = { templateId, slideIndex, thumbnails }
  const response = await axios.post(url, payload)
  return response.data
}

export async function duplicateSlide({ projectId, slideId }): Promise<ISlideDetail> {
  const url = `${API_URL}/${projectId}/slides/${slideId}/duplicate/`
  const response = await axios.post(url)
  return response.data
}

export async function deleteSlide({ projectId, slideId }) {
  const url = `${API_URL}/${projectId}/slides/${slideId}`
  const response = await axios.delete(url)
  return response.data
}

export async function deleteMultipleSlides({ projectId, slideIdsList }) {
  const url = `${API_URL}/${projectId}/slides/delete_multiple`
  const response = await axios.delete(url, {
    data: {
      slideIdsList: slideIdsList
    }
  })
  return response
}

export async function uploadThumbnailFile(
  { projectId, file },
  cancelToken?: CancelToken
): Promise<{ url: string; key: string }> {
  const response = await axios.post(
    `${API_URL}/${projectId}/upload/`,
    { type: 'image', uuid: v4() },
    { cancelToken }
  )
  await axios.put(response.data.url, file, {
    headers: { 'Content-Type': file.type, cancelToken }
  })
  return response.data
}

export async function getSlideThumbnail(projectId: number, slideId: number) {
  const response = await axios.get(`${API_URL}/${projectId}/slides/${slideId}/thumbnail`, {
    responseType: 'blob'
  })
  return response.data
}

export async function downloadPGAPayload(projectId: number, slideId: number) {
  const response = await axios.get(`${API_URL}/${projectId}/slides/${slideId}/pga_payload`)
  return response.data
}

interface RepeatSlideArgs {
  projectId: number
  slideId: number
  dataSelectionPks: number[]
  interpolationPks: number[]
  dataTablePks: number[]
  copyTransformations?: boolean
  copySignificanceTest?: boolean
  copyIndexing?: boolean
  reindexingStrategy?: 'by_name' | 'by_parent_group' | 'by_group_inclusion' | null
  globalStrategy: boolean
  indexMatching?: boolean
  specifiedIndex?: number[]
  repeatType?: 'data_tables' | 'columns'
}

export async function repeatSlide({
  projectId,
  slideId,
  dataSelectionPks,
  interpolationPks,
  dataTablePks,
  copyTransformations = true,
  copySignificanceTest = true,
  copyIndexing = true,
  reindexingStrategy = null,
  indexMatching = true,
  specifiedIndex = [],
  globalStrategy = false,
  repeatType = 'data_tables'
}: RepeatSlideArgs) {
  const response = await axios.post(`${API_URL}/${projectId}/slides/${slideId}/repeat/`, {
    dataSelectionPks,
    interpolationPks,
    dataTablePks,
    copyTransformations,
    copySignificanceTest,
    copyIndexing,
    reindexingStrategy,
    globalStrategy,
    indexMatching,
    specifiedIndex,
    repeatType
  })
  return response.data
}
