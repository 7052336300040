import { cellOptions, defaultDataGridOptions, defaultStyleSettings } from './defaults'
import { SymbolsOverlay } from './overlay'
import {
  DataGridData,
  DataGridEvent,
  DataGridOptions,
  DataGridSchema,
  StyleOptions,
  TableUserSettings
} from './types'
import { CellValuesPositions, splitTextIntoLines } from './utils'

export const CELL_OFFSET_X = 5
export const CELL_OFFSET_Y = 5
export const CELL_PADDING_X = 5
export const CELL_FONT_SIZE = 10

export class DataGrid {
  data: DataGridData
  schema: DataGridSchema
  styleOptions: StyleOptions
  options: Partial<DataGridOptions>
  showRowHeaders?: boolean
  showColumnHeaders?: boolean
  element: any

  constructor(data: DataGridData, options?: TableUserSettings) {
    if (!options) {
      options = {}
    }
    this.data = data
    this.options = {
      showColumnHeaders: options.showCoordinates,
      showRowHeaders: options.showCoordinates
    }
    this.showColumnHeaders = options.showColHeaders !== false ? true : false
    this.showRowHeaders = options.showRowHeaders !== false ? true : false
    this.initialize()
  }

  /** Create a `canvas-datagrid` web component. */
  private initialize() {
    let options: DataGridOptions = {
      ...defaultDataGridOptions,
      data: this.data,
      style: defaultStyleSettings
      // ...this.options
    }
    // @ts-ignore
    this.element = canvasDatagrid(options)
    this.element?.setColumnWidth()
    if (!this.showColumnHeaders) {
      this.element.hideRows(0, 0)
    }
    if (!this.showRowHeaders) {
      this.element.hideColumns(0, 0)
    }
    return this.element
  }

  /**
   * Set cell background color while cell rendering.
   * @param {string[][]} backgroundColors - 2D array of background colors for each cell.
   */
  public renderCell(backgroundColors: string[][]): void {
    this.element.addEventListener('rendercell', (e: DataGridEvent) => {
      const rowIndex = this.adjustRowIndex(e.cell.rowIndex)
      e.ctx.fillStyle = backgroundColors[rowIndex][e.cell.columnIndex]
    })
  }

  /**
   * Set cell font color while text rendering.
   * @param {string[][]} valueColors - 2D array of colors to use for cell data values.
   */
  public renderText(valueColors: string[][], coords: CellValuesPositions, columnWidths: number[]) {
    this.element.addEventListener('rendertext', (e: DataGridEvent) => {
      e.preventDefault()
      const rowIndex = e.cell.rowIndex + 1 * Number(!this.showColumnHeaders)
      const colIndex = e.cell.columnIndex
      if (colIndex === 0 || rowIndex === 0) {
        let lines = splitTextIntoLines(
          e.ctx,
          e.cell.value,
          columnWidths[colIndex] - cellOptions.offsetX
        )
        lines.forEach((textLine, index) => {
          let x = e.cell.x + cellOptions.offsetX
          let y = e.cell.y + cellOptions.lineHeight * (index + 1)
          // give it center alignment
          y = y + (e.cell.height - cellOptions.lineHeight * lines.length) / 2 - cellOptions.offsetY
          e.ctx.fillText(textLine, x, y)
        })
      } else {
        e.ctx.fillStyle = valueColors[rowIndex][colIndex]
        let x = e.cell.x + cellOptions.offsetX
        let y = e.cell.y + cellOptions.offsetY + e.cell.height / 2
        if (rowIndex > 0 && colIndex > 0) {
          x += coords[rowIndex - 1][colIndex - 1]['dataValue'][0]
        }
        e.ctx.fillText(e.cell.value, x, y)
      }
    })
  }

  /**
   * Draw symbols to visualise sigtesting and percentage indexing.
   * @param {SymbolOverlayValue[][][]} overlay - is a 2D array where each value
   * is a also an array that holds a collection of data that is required to draw
   * symbols whithin the cell. Example:
   * ```
   * [{ symbol: ↑, color: 'green', position: 'left' }, { symbol: ▼, color: 'red', position: 'right' }][][]
   * ```
   */
  public afterRenderCell(overlay: SymbolsOverlay, coords: CellValuesPositions): void {
    this.element.addEventListener('afterrendercell', (e: DataGridEvent) => {
      let x = e.cell.x + cellOptions.offsetX
      let y = e.cell.y + cellOptions.offsetY
      const rowIndex = this.adjustRowIndex(e.cell.rowIndex)
      const columnIndex = e.cell.columnIndex
      let values = overlay[rowIndex][columnIndex] || []
      let counter = 0
      let prevPosition = null
      // reorder values so we'll interate through each position sequentially, otherwise
      // `counter` will be reset randomly.
      const order = ['left', 'top', 'right', 'bottom']
      values = values.sort((a, b) => order.indexOf(a.position) - order.indexOf(b.position))
      values
        .filter(value => value)
        .forEach(value => {
          const { symbol, color, position } = value
          // reset counter when position changes
          if (prevPosition !== position) {
            prevPosition = position
            counter = 0
          }
          e.ctx.fillStyle = color
          // all vs all headers
          if (e.cell.rowIndex === 0 || e.cell.columnIndex === 0) {
            e.ctx.fillText(
              symbol,
              x + e.ctx.measureText(e.cell.value).width + cellOptions.offsetX,
              y + e.cell.height / 2
            )
          } else {
            const offset =
              coords?.[rowIndex - 1]?.[columnIndex - 1]?.['positions']?.[position][counter]
            counter += 1
            let [offsetX, offsetY] = offset
            e.ctx.fillText(symbol, x + offsetX, y + offsetY)
          }
        })
    })
  }

  private adjustRowIndex(index: number) {
    return index + 1 * Number(!this.showColumnHeaders)
  }
}
