import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import i18n from 'i18next'

import { selectCurrentProjectId } from '@/store/slices/projects/selectors'
import { updateShapeData, updateShapeVisualSettings } from '@/store/slices/shapes/actions'
import { selectCanvasSelectedObject } from '@store/slices/shapes/selectors'
import { useEffect, useState } from 'react'

import { Button, Form, Select, Space, Typography } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import { ReloadOutlined, TableOutlined } from '@ant-design/icons'

import {
  createTextInterpolation,
  getTextInterpolation,
  updateTextInterpolation
} from '@/services/data-transfomations-service'
import { resetShape } from '@/services/shape-service'

import { CanvasObjectOptions } from '@/components/canvas/types/object'
import { selectCurrentUser } from '@/store/slices/auth/selectors'
import { Flex } from '@/components/flex'
import Icon from '@components/icon/Icon'
import EmptyElement from '@components/imagemap/right-panel/EmptyElement'

import { IInterpolation } from '@/interfaces/data-transformations'
import { IShapeData } from '@/interfaces/shape'

import { useLocalStorage } from '@/hooks'

import { useDataTable } from '../hooks'
import { getFormatCodeFromDataTable } from '../utils'
import SelectDataFields from './SelectDataFields'
import TableView from './TableView'

import { InterpolationPane } from './DataPane'

const { Text, Link } = Typography

export function extractInterpolationKeys(text: string): string[] {
  let pattern = /(?<=\{\{\s*)([^\s{}]+\s*)+(?=\s*\}\})/g
  let match = text.match(pattern) || []
  match = match.map((tag: string) => tag.replaceAll(/\{+|\}+/g, ''))
  return match
}

const TextDataSelector = () => {
  const dispatch = useDispatch()
  const selectedObject = useSelector(selectCanvasSelectedObject)
  const experimentalFeaturesEnabled = useSelector(selectCurrentUser)?.experimentalFeaturesEnabled
  const projectId = useSelector(selectCurrentProjectId)
  const dataKeys = useMemo(() => {
    return extractInterpolationKeys(selectedObject?.text || '')
  }, [selectedObject])
  const selectedShape = useSelector(selectCanvasSelectedObject)

  // component state
  const [form] = useForm()
  const [selectedDataKey, setSelectedDataKey] = useState<string | null>(null)
  const [showTableView, setShowTableView] = useState(false)
  const [interpolation, setInterpolation] = useState(null) // interpolation data from selected shape
  const [textInterpolationObject, setTextInterpolationObject] = useState<IInterpolation>(null) // interpolation db object
  const [tableId, setTableId] = useState(null)
  const [sectionId, setSectionId] = useState<number>(null)
  const { data } = useDataTable(tableId)
  const [activeRowTags, setActiveRowTags] = useLocalStorage<string[]>('metaTags', [])
  const [lastInsertData, setLastInsertData] = useLocalStorage<{
    dataSourceId: number
    sectionId: number
    tableId: number
  }>(projectId.toString(), null)

  const onFinish = async payload => {
    const { tableId, sectionId, dataSourceId } = form.getFieldsValue(true)
    setLastInsertData({ tableId, sectionId, dataSourceId })
    setShowTableView(false)
    let interpolationData = {
      tag: selectedDataKey,
      row: payload?.rows?.[0],
      column: payload?.columns?.[0],
      dataTable: tableId,
      shape: selectedShape.pk,
      sourceType: payload.sourceType
    }

    const numberFormat = getFormatCodeFromDataTable(payload.rows, payload.columns, data)

    if (payload?.metaKey?.length === 2) {
      interpolationData['metaRow'] = payload.metaKey[0]
      interpolationData['metaColumn'] = payload.metaKey[1]
    }

    if (!selectedShape?.data?.interpolation?.[selectedDataKey]) {
      let response = await createTextInterpolation(interpolationData)
      setTextInterpolationObject(response)
      const { dataTable, tag, value, id } = response
      const payload: IShapeData = {
        interpolation: {
          ...selectedShape.data?.interpolation,
          [tag]: {
            id: id,
            dataSourceId: form.getFieldValue('dataSourceId'),
            dataSectionId: form.getFieldValue('sectionId'),
            tableId: dataTable,
            value: value
          }
        }
      }
      dispatch(updateShapeData(selectedShape.id, payload))
      dispatch(
        updateShapeVisualSettings(selectedShape.id, {
          interpolation: {
            ...selectedShape?.visualSettings?.interpolation,
            [tag]: {
              numberFormat
            }
          }
        })
      )
    } else {
      let response = await updateTextInterpolation(
        selectedShape.data.interpolation[selectedDataKey].id,
        interpolationData
      )
      const { dataTable, tag, value, id } = response
      const payload: IShapeData = {
        interpolation: {
          ...selectedShape.data?.interpolation,
          [tag]: {
            id: id,
            dataSourceId: form.getFieldValue('dataSourceId'),
            dataSectionId: form.getFieldValue('sectionId'),
            tableId: dataTable,
            value: value
          }
        }
      }
      setTextInterpolationObject(response)
      dispatch(updateShapeData(selectedShape.id, payload))
      dispatch(
        updateShapeVisualSettings(selectedShape.id, {
          interpolation: {
            ...selectedShape?.visualSettings?.interpolation,
            [tag]: {
              numberFormat
            }
          }
        })
      )
    }
  }

  const onChangeTag = (value: string) => {
    setSelectedDataKey(value)
  }

  const deleteAllInterpolations = async () => {
    await resetShape({ projectId, shapeId: selectedShape.pk })
    form.resetFields()
    setTextInterpolationObject(null)
    setInterpolation(null)
    setTableId(null)
    dispatch(updateShapeData(selectedShape.id, null))
  }

  useEffect(() => {
    if ((dataKeys.length > 0 && !selectedDataKey) || !dataKeys.includes(selectedDataKey)) {
      const selectedInterpolation = selectedShape?.data?.interpolation?.[dataKeys[0]]
      if (!selectedInterpolation) {
        form.resetFields()
      }
      setSelectedDataKey(dataKeys[0])
    }
  }, [dataKeys])

  const fetchInterpolation = async (id: number) => {
    try {
      const obj = await getTextInterpolation(id)
      setTextInterpolationObject(obj)
    } catch (e) {
      console.error(e.message)
    }
  }
  useEffect(() => {
    const selectedInterpolation = selectedShape?.data?.interpolation?.[selectedDataKey]
    // reset fields so update happens in the `SelectDataFields` component
    form.resetFields()
    if (selectedInterpolation) {
      fetchInterpolation(selectedInterpolation.id)
      setInterpolation(selectedInterpolation)
    } else {
      setInterpolation(null)
      setTextInterpolationObject(null)
      setTableId(null)
    }
  }, [selectedDataKey, selectedShape.id])

  if (dataKeys.length === 0) {
    return <EmptyElement title={i18n.t('editor.right-panel.data-source.text.no-interpolation')} />
  }

  const onReloadLastTable = () => {
    if (lastInsertData) {
      // setDataId(lastInsertData.dataSourceId)
      setSectionId(lastInsertData.sectionId)
      setTableId(lastInsertData.tableId)
      form.setFieldsValue({
        dataSourceId: lastInsertData.dataSourceId,
        sectionId: lastInsertData.sectionId,
        tableId: lastInsertData.tableId
      })
    }
  }

  const disabledReloadLastTableButton = !lastInsertData

  return experimentalFeaturesEnabled ? (
    <InterpolationPane dataKeys={dataKeys} onRemoveDataClick={deleteAllInterpolations} />
  ) : (
    <div className="data-selector">
      <div className="ant-tabs-tabpane-header">
        {' '}
        {i18n.t('editor.right-panel.data-source.title')}{' '}
      </div>
      <div className="form">
        <div className="ant-col ant-form-item-label">
          <label title="interpolation" style={{ fontSize: 12 }}>
            {i18n.t('editor.right-panel.data-source.text.interpolation-select')}
          </label>
        </div>

        <Select<string | number, { value: string; children: string }>
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={onChangeTag}
          value={selectedDataKey}
          placeholder={i18n.t('editor.right-panel.data-source.text.interpolation-select')}
          style={{ display: 'block', marginRight: '22px', marginBottom: '10px' }}
        >
          {dataKeys?.map((text, index) => (
            <Select.Option value={text} key={index}>
              {text}
            </Select.Option>
          ))}
        </Select>

        <Form form={form} layout="vertical">
          <SelectDataFields
            form={form}
            onTableSelect={setTableId}
            sectionId={sectionId}
            setSectionId={setSectionId}
            interpolation={interpolation}
          />
        </Form>

        <Space>
          <Text style={{ fontSize: 13 }}>Show table view</Text>
          {data ? (
            <Link>
              <TableOutlined onClick={() => setShowTableView(true)} />
            </Link>
          ) : (
            <TableOutlined />
          )}
        </Space>

        <Form.Item>
          <Button
            style={{ fontSize: 13, paddingLeft: 0 }}
            onClick={onReloadLastTable}
            type="text"
            disabled={disabledReloadLastTableButton}
          >
            <ReloadOutlined /> Reload last table
          </Button>
        </Form.Item>

        <Flex justifyContent="center" flexDirection="column" className="form-button-group">
          <Button style={{ marginTop: '0.6em' }} type="ghost" onClick={deleteAllInterpolations}>
            <Icon name="sync-alt" style={{ marginRight: '2%' }} />
            {i18n.t('editor.right-panel.data-source.form.clear-data')}
          </Button>
        </Flex>

        <TableView
          show={showTableView}
          tableDataFrame={data}
          onFinish={onFinish}
          onCancel={() => setShowTableView(false)}
          activeRowTags={activeRowTags}
          setActiveRowTags={setActiveRowTags}
          interpolation={textInterpolationObject}
          selectionMode="single"
        />
      </div>
    </div>
  )
}

export default TextDataSelector
