import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Spin } from 'antd'

import i18n from 'i18next'
import {
  IPercentageIndexingPayload,
  IPercentageIndexingResponse
} from 'src/interfaces/data-overlays'

import { updateShapeData, updateShapeVisualSettings } from '@/store/slices/shapes/actions'
import { selectCanvasSelectedObject } from '@store/slices/shapes/selectors'

import {
  createPercentageIndexing,
  deletePercentageIndexing,
  retrievePercentageIndexing,
  updatePercentageIndexing
} from '@services/percentage-indexing-service'

import PercentageIndexingForm from '@/components/imagemap/right-panel/percentage-indexing/PercentageIndexingForm'

import { IShapeData } from '@/interfaces/shape'
import { Nullable } from '@/interfaces/utils'

import EmptyElement from '../EmptyElement'
import { ThresholdSettings } from './types'

const PercentageIndexing = () => {
  //@ts-ignore
  const selectedShape = useSelector(selectCanvasSelectedObject)
  const transformationId = selectedShape.data?.definition?.transformationId
  const [loading, setLoading] = useState<boolean>(false)
  const [percentageIndexing, setPercentageIndexing] =
    useState<Nullable<IPercentageIndexingResponse>>(null)
  const indexingId = selectedShape.data?.indexing?.id
  const dispatch = useDispatch()

  const updateIndexingObject = (
    indexing: Nullable<IPercentageIndexingResponse>,
    shapeData: Partial<IShapeData>,
    thresholds: ThresholdSettings[]
  ): void => {
    setPercentageIndexing(indexing)
    const payload = {
      ...selectedShape.visualSetting,
      percentageIndexing: thresholds
    }
    try {
      dispatch(updateShapeData(selectedShape.id, shapeData))
      dispatch(updateShapeVisualSettings(selectedShape.id, payload))
    } catch (error) {
      console.error(error.message)
    }
  }

  const applyIndexing = async (
    payload: IPercentageIndexingPayload,
    thresholds: ThresholdSettings[]
  ) => {
    // split thresholds property to create instances in the db and update shape's userSettings property
    const thresholdData = thresholds.map(({ thresholdType, thresholdValue }) => {
      return {
        thresholdValue,
        thresholdType
      }
    })
    if (percentageIndexing?.id) {
      try {
        const indexingPayload = { ...payload, thresholds: thresholdData }
        const { shapeData, ...obj } = await updatePercentageIndexing({
          indexingId,
          payload: indexingPayload
        })
        updateIndexingObject(obj, { indexing: shapeData }, thresholds)
      } catch (e) {
        console.error(e.message)
      }
    } else {
      try {
        // indexing object must hold non-empty thresholds property
        const indexingPayload = {
          ...payload,
          dataSelection: transformationId,
          thresholds: thresholdData
        }
        const { shapeData, ...obj } = await createPercentageIndexing({
          payload: indexingPayload
        })
        updateIndexingObject(obj, { indexing: shapeData }, thresholds)
      } catch (e) {
        console.error(e.message)
      }
    }
  }

  const undoIndexing = async () => {
    setLoading(true)
    try {
      await deletePercentageIndexing({
        indexingId
      })
      const { indexing, ...rest } = selectedShape.data
      updateIndexingObject(null, rest, [])
    } catch (e) {
      console.error(e.message)
    }
    setLoading(false)
  }

  const getPercentageIndexing = useCallback(async () => {
    if (indexingId != null) {
      try {
        const response = await retrievePercentageIndexing({
          indexingId
        })
        const indexing = {
          ...response,
          controlGroup: Number(response.controlGroup)
        }
        setPercentageIndexing(indexing)
      } catch (e) {
        console.error(e.message)
      }
    }
  }, [indexingId])

  useEffect(() => {
    getPercentageIndexing()
  }, [getPercentageIndexing])

  return selectedShape?.data ? (
    <Spin spinning={loading}>
      <PercentageIndexingForm
        initial={percentageIndexing}
        onFinish={applyIndexing}
        onDelete={undoIndexing}
      />
    </Spin>
  ) : (
    <EmptyElement title={i18n.t('editor.right-panel.data-transformation.no-data-selection')} />
  )
}

export default PercentageIndexing
