import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { HotTable, HotTableProps } from '@handsontable/react'

import { selectCanvasSelectedObject } from '@/store/slices/shapes/selectors'

import { ITableViewData } from '@/services/data-service'

import { IInterpolation } from '@/interfaces/data-transformations'

const hotSettings: HotTableProps = {
  data: null,
  colHeaders: false,
  rowHeaders: false,
  width: 'auto',
  height: 'auto',
  licenseKey: 'non-commercial-and-evaluation',
  outsideClickDeselects: false
}

type Props = {
  data: ITableViewData
  sourceType: 'meta' | 'data'
  onSelect: (payload: { row: number; col: number }) => void
  interpolation?: IInterpolation
}

const TableMetaDataView = ({ data, sourceType, onSelect, interpolation }: Props) => {
  const selectedShape = useSelector(selectCanvasSelectedObject)
  const metaData = data?.tableMeta?.tags
    ? data?.tableMeta?.tags.map(row => row.map(entry => entry.value))
    : null
  const handleAfterSelection = (row: number, col: number) => {
    onSelect({ row, col })
  }

  const hotRef = useCallback(
    node => {
      if (!node) return
      if (
        !sourceType &&
        interpolation?.sourceType === 'meta' &&
        interpolation?.column != null &&
        interpolation?.row != null
      ) {
        node.hotInstance.selectCell(interpolation.row, interpolation.column)
      } else if (sourceType !== 'meta') {
        node.hotInstance.deselectCell()
      }
    },
    [interpolation, sourceType]
  )

  return metaData ? (
    <div style={{ marginBottom: '10px' }}>
      <HotTable
        {...hotSettings}
        ref={hotRef}
        data={metaData}
        selectionMode="single"
        afterSelection={handleAfterSelection}
        disableVisualSelection={selectedShape?.type !== 'textbox'}
        cells={function (_row, col, _props) {
          if (col === 0) {
            return { editor: false, readOnly: true, disableVisualSelection: true }
          }
          return { editor: false }
        }}
      />
    </div>
  ) : null
}

export default TableMetaDataView
