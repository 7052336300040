import { Layout } from 'antd'

import { verifyUserAgent } from '@/utils/userAgent'

const { Content } = Layout

const UnsupportedBrowserWarning = () => {
  const [isSupported, browser] = verifyUserAgent()
  return !isSupported ? (
    <>
      <p
        className="paragraph"
        style={{ fontFamily: 'Inter', fontSize: '18px', lineHeight: 1.6, margin: '1rem 0' }}
      >
        It appears that you are using {browser}, which may not work with our application
      </p>
      <p
        className="paragraph"
        style={{ fontFamily: 'Inter', fontSize: '18px', lineHeight: 1.6, margin: '1rem 0' }}
      >
        Please update to a newer version or try another browser such as Chrome or Firefox
      </p>
    </>
  ) : null
}

//note unlike error screen we don't call the logger here as the user is unauthorised
const UnauthErrorScreen = ({ error, resetErrorBoundary }) => {
  return (
    <Layout className="layout" style={{ height: '100vh' }}>
      <Content style={{ padding: '0 50px', height: '100%', backgroundColor: 'white' }}>
        <div
          className="home-section-intro"
          style={{
            minHeight: '100vh',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <section
            className="top-section"
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '5%'
            }}
          >
            <div className="flex-align-center" style={{ backgroundColor: 'white' }}>
              <h1
                className="title-line1"
                style={{ fontSize: '48px', fontWeight: 700, lineHeight: '56px', marginBottom: 0 }}
              >
                SORRY
              </h1>
              <h1
                className="title-line2"
                style={{ fontSize: '48px', fontWeight: 700, lineHeight: '56px', color: '#135FF6' }}
              >
                WE ENCOUNTERED A PROBLEM
              </h1>
              <UnsupportedBrowserWarning />
              <p
                className="paragraph"
                style={{ fontFamily: 'Inter', fontSize: '18px', lineHeight: 1.6, margin: '1rem 0' }}
              >
                <strong>Details of the problem have been sent to our engineers</strong> <br />
                {error.message}
              </p>
            </div>
            <div className="flex-align-center" style={{ backgroundColor: 'white' }}>
              <div className="brand-image-wrapper">
                <img
                  src="/images/report-analysis-flatline.svg"
                  alt="Happy researcher"
                  style={{ height: '560', width: 560 }}
                />
              </div>
            </div>
          </section>
        </div>
      </Content>
    </Layout>
  )
}

export default UnauthErrorScreen
