import { useCallback, useEffect, useReducer } from 'react'

import { isEqual } from 'lodash'

import { applyNumberFormatting } from '@/components/canvas'

import { mergeSelections, toRanges } from './helpers'

const initialArgs = {
  data: null,
  rowHeaders: null,
  colHeaders: null,
  formatCode: '0'
}

const outputDataReducer = (state, action) => {
  switch (action.type) {
    case 'set_data':
      if (!action.data) {
        return initialArgs
      }
      if (state.data != null) {
        return state
      }
      const transformedData = action.data.map(row =>
        row.map(value => applyNumberFormatting(value, action.formatCode))
      )
      return {
        data: transformedData,
        rowHeaders: action.rowHeaders,
        colHeaders: action.colHeaders,
        formatCode: action.formatCode
      }
    case 'reset':
      return initialArgs
    case 'transform':
      let { data, ...rest } = action
      data = data.map(row =>
        row.map(value => applyNumberFormatting(value, action?.formatCode || state.formatCode))
      )
      return {
        ...state,
        data,
        ...rest
      }
  }
}

const useOutputData = (dataFrame, formatCode = null) => {
  return useReducer(outputDataReducer, initialArgs)
}

type ReturnValue = [
  (row: number, col: number, row2: number, col2: number, index: number) => [number[], number[]],
  () => void
]

const reducer = (state, action) => {
  switch (action.type) {
    case 'add':
      return {
        rows: action.rows,
        columns: action.columns
      }
    case 'reset':
      return {
        rows: [],
        columns: []
      }
  }
}

const restrictSelection = (strategy, coords, context) => {
  switch (strategy) {
    case 'row':
    case 'column':
    case 'freeform':
    case 'data':
      if (coords.col < context.data[0][1]) {
        coords.col = context.data[0][1]
      }

      if (coords.row < context.data[0][0]) {
        coords.row = context.data[0][0]
      }
      break
    case 'group':
      if (coords.row < context.header.col[0][0]) {
        coords.row = context.header.col[0][0]
      }
      if (coords.row >= context.header.col[1][0]) {
        coords.row = context.header.col[1][0] - 1
      }
      if (coords.col < context.header.col[0][1]) {
        coords.col = context.header.col[0][1]
      }
      if (coords.col > context.header.col[1][1]) {
        coords.col = context.header.col[1][1]
      }
      break
    case 'meta':
      if (coords.col > context.meta[1][1]) {
        coords.col = context.meta[1][1]
      }

      if (coords.row > context.meta[1][0]) {
        coords.row = context.meta[1][0]
      }
      break
    case 'columnName':
      if (coords.row <= context.header.col[1][0]) {
        if (coords.row < context.header.col[0][0]) {
          coords.row = context.header.col[0][0]
        }
        if (coords.col < context.header.col[0][1]) {
          coords.col = context.header.col[0][1]
        }
        if (coords.col > context.header.col[1][1]) {
          coords.col = context.header.col[1][1]
        }
      }
      if (coords.row > context.header.col[1][0]) {
        coords.row = context.header.col[1][0]
        if (coords.col < context.header.col[0][1]) {
          coords.col = context.header.col[0][1]
        }
      }
      break
    case 'rowName':
      if (coords.row < context.header.row[0][0]) {
        coords.row = context.header.row[0][0]
      }
      if (coords.row >= context.header.row[0][0]) {
        if (coords.col > context.header.row[1][1]) {
          coords.col = context.header.row[1][1]
        }
      }
      if (coords.col > context.header.row[1][1]) {
        coords.colr = context.header.row[1][1]
      }
      break
  }
}

const useSelectCells = (instance, strategy, context): ReturnValue => {
  const [state, dispatch] = useReducer(reducer, {
    rows: [],
    columns: []
  })

  const onSelectionStart = useCallback(
    (e, coords, target, controller) => {
      restrictSelection(strategy, coords, context)

      switch (strategy) {
        case 'row':
          coords.col = context.header.col[0][1]
          break
        case 'column':
          coords.row = context.header.row[0][0]
          break
      }
    },
    [strategy, context]
  )

  const onSelectionEnd = useCallback(
    coords => {
      restrictSelection(strategy, coords, context)

      switch (strategy) {
        case 'row':
          coords.col = context.header.col[1][1]
          break
        case 'column':
        case 'group':
          coords.row = context.header.row[1][0]
          break
      }
    },
    [strategy, context]
  )

  useEffect(() => {
    if (instance) {
      instance.addHook('beforeOnCellMouseDown', onSelectionStart)
      instance.addHook('beforeSetRangeEnd', onSelectionEnd)
    }
    return () => {
      if (instance && !instance.isDestroyed) {
        instance.removeHook('beforeOnCellMouseDown', onSelectionStart)
        instance.removeHook('beforeSetRangeEnd', onSelectionEnd)
      }
    }
  }, [instance, strategy, onSelectionStart, onSelectionEnd])

  const _adjustSelection = (rows: number[], columns: number[]) => {
    switch (strategy) {
      case 'freeform':
      case 'data':
      case 'column':
      case 'row':
        rows = rows.map(idx => {
          return idx - context.data[0][0]
        })
        columns = columns.map(idx => {
          return idx - context.data[0][1]
        })
        break
      case 'group':
        rows = rows.map(idx => idx - context.data[0][0]).filter(idx => idx >= 0)
        columns = columns.map(idx => idx - context.data[0][1])
        break
      case 'meta':
        rows = rows.map(idx => {
          return idx - context.meta[0][0]
        })
        columns = columns.map(idx => {
          return idx - context.meta[0][1]
        })
        break
      case 'rowName':
        rows = rows.map(idx => {
          return idx - context.header.row[0][0]
        })
        columns = columns.map(idx => {
          return idx - context.header.row[0][1]
        })
        break
      case 'columnName':
        rows = rows.map(idx => {
          return idx - context.header.col[0][0]
        })
        columns = columns.map(idx => {
          return idx - context.header.col[0][1]
        })
        break
    }
    return [rows, columns] as [number[], number[]]
  }

  const afterSelection = (
    row1: number,
    col1: number,
    row2: number,
    col2: number,
    index: number
  ): [number[], number[]] => {
    const from: [number, number] = [row1, col1]
    const to: [number, number] = [row2, col2]
    let rows, columns
    if (index > 0) {
      ;[rows, columns] = mergeSelections(from, to, state.rows, state.columns)
    } else {
      ;[rows, columns] = mergeSelections(from, to, [], [])
    }
    dispatch({ rows, columns, type: 'add' })
    return _adjustSelection(rows, columns)
  }

  const alignSelections = useCallback(() => {
    if (instance && state.rows.length > 0 && state.columns.length > 0) {
      const currentSelection = instance.getSelected()
      const ranges = toRanges(state.rows, state.columns)
      if (!isEqual(currentSelection, ranges)) {
        try {
          instance.selectCells(ranges)
        } catch (e) {
          console.error(e)
        }
      }
    }
  }, [instance, state.columns, state.rows])

  useEffect(() => {
    alignSelections()
  }, [alignSelections])

  const onDeselect = () => {
    dispatch({ type: 'reset' })
  }

  return [afterSelection, onDeselect]
}

export { useSelectCells, useOutputData }
