import React, { Component } from 'react'

import { Layout, Modal, Space, Spin } from 'antd'

import PropTypes from 'prop-types'

interface IProps {
  leftSider?: React.ReactNode
  content?: React.ReactNode
  rightSider?: React.ReactNode
  className?: string
  loading?: boolean
  loadingMsg?: string
}

class Container extends Component<IProps> {
  static propTypes = {
    leftSider: PropTypes.any,
    content: PropTypes.any,
    rightSider: PropTypes.any,
    className: PropTypes.string,
    loading: PropTypes.bool,
    loadingMsg: PropTypes.string
  }

  static defaultProps = {
    className: 'rde-content-layout-main',
    loading: false
  }

  render() {
    const { leftSider, content, rightSider, className, loading, loadingMsg, children } = this.props
    const msgArray = loadingMsg.split('|')
    return (
      <>
        <Modal
          closable={false}
          keyboard={false}
          visible={loading && loadingMsg.length > 0}
          footer={null}
        >
          <div
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              margin: '20px'
            }}
          >
            <Space direction="vertical" size="middle">
              <img
                style={{ marginBottom: '0.1rem' }}
                src="\images\download-arrow-animated.74cfbc4ffb.gif"
              />
              {msgArray.map((msg, i) => (
                <p key={i}>{msg}</p>
              ))}
            </Space>
          </div>
        </Modal>

        <Layout className="rde-content-layout">
          <Layout
            style={{
              overflowY: 'auto',
              overflowX: 'hidden',
              minHeight: `calc(100vh - 64px)`,
              height: `calc(100vh - 64px)`
            }}
            className={className}
          >
            {leftSider}
            {content || children}
            {rightSider}
          </Layout>
        </Layout>
      </>
    )
  }
}

export default Container
