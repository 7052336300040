import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { FormInstance } from 'antd'

import { selectCanvasSelectedObject } from '@/store/slices/shapes/selectors'

const useResetModalForm = ({ form, visible }: { form: FormInstance; visible: boolean }) => {
  const prevVisibleRef = useRef<boolean>()
  useEffect(() => {
    prevVisibleRef.current = visible
  }, [visible])
  const prevVisible = prevVisibleRef.current

  useEffect(() => {
    if (!visible && prevVisible) {
      form.resetFields()
    }
  }, [visible])
}

/**
 * Select the pre-defined values in a form list according to the shape type
 * and the selected condition type (greater than, less than, etc). To be used with
 * data overlays. Make sure the field values are consistent.
 */
const useDefaultFieldValues = ({ form, formListName, colorFieldName, applyToFieldName }) => {
  const defaultGreen = '#8ff0a4'
  const defaultRed = '#f66151'
  const defaultBlue = '#99c1f1'
  const [index, setIndex] = useState(null)
  const [conditionValue, setConditionValue] = useState(null)
  const selectedShape = useSelector(selectCanvasSelectedObject)

  const setDefaultValues = () => {
    let formList = form.getFieldValue(formListName)
    let fields = formList ? formList[index] : null
    if (fields) {
      let color = fields[colorFieldName]
      let applyTo = fields[applyToFieldName]
      if (!applyTo || !color || [defaultGreen, defaultRed, defaultBlue].includes(color)) {
        applyTo = selectedShape.type === 'table' ? 'cell-background' : 'colored-labels'
        switch (conditionValue) {
          case 'gte':
          case 'gt':
            color = defaultGreen
            break
          case 'lte':
          case 'lt':
            color = defaultRed
            break
          default:
            color = defaultBlue
        }
      }
      fields = { ...fields, [colorFieldName]: color, [applyToFieldName]: applyTo }
      formList.splice(index, 1, fields)
      form.setFieldsValue({
        [formListName]: formList
      })
    }
  }

  useEffect(() => {
    setDefaultValues()
  }, [index, conditionValue])

  return [setIndex, setConditionValue]
}

export { useResetModalForm, useDefaultFieldValues }
