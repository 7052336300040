import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Button, Input, Select, Space } from 'antd'

import { updateShapeData } from '@/store/slices/shapes/actions'
import {
  selectCanvasSelectedObject,
  selectInterpolationData,
  selectShapeData
} from '@store/slices/shapes/selectors'

import {
  updateDataTransformation,
  updateTextInterpolation
} from '@/services/data-transfomations-service'

import Icon from '@components/icon/Icon'

import '../styles.less'

const strategies = [
  { label: 'By column position', value: 'by_column_position' },
  { label: 'By absolute position', value: 'by_absolute_position' },
  { label: 'By column name', value: 'by_name' },
  { label: 'By column position in group', value: 'by_parent_group' },
  { label: 'All columns by group position', value: 'by_group_inclusion' },
  { label: 'All columns by group name', value: 'by_group_name' }
]

const interpolationStrategies = [
  { label: 'By absolute position', value: 'by_absolute_position' },
  { label: 'By column position', value: 'by_column_position' },
  { label: 'By column name', value: 'by_name' },
  { label: 'By column position in group', value: 'by_parent_group' }
]

const DefaultPane = ({ onRemoveDataClick }) => {
  const history = useHistory()
  const { dataSelection, dataSource, dataSection, dataTable } = useSelector(selectShapeData)
  const dispatch = useDispatch()
  const selectedObject = useSelector(selectCanvasSelectedObject)
  const [selectionStrategy, setSelectionStrategy] = useState('freeform')
  const [strategyLoading, setStrategyLoading] = useState(false)
  const currentSelectionStrategy = useMemo(() => dataSelection?.selectionStrategy, [dataSelection])
  const hasData = dataSource != null

  const onStrategyChange = async value => {
    setStrategyLoading(true)
    try {
      await updateDataTransformation(dataSelection.id, { selectionStrategy: value })
      dispatch(
        updateShapeData(selectedObject.id, { ...selectedObject.data, selectionStrategy: value })
      )
      setSelectionStrategy(value)
    } catch (e) {
      console.error('Failed to change selection strategy. Log:', e)
    }
    setStrategyLoading(false)
  }

  useEffect(() => {
    setSelectionStrategy(currentSelectionStrategy)
  }, [currentSelectionStrategy])

  const onSetDataClick = () => history.push('/data-view')
  return (
    <div className="data-selector">
      <div className="ant-tabs-tabpane-header">Data Selection</div>
      <div className="form">
        {hasData ? (
          <Space direction="vertical" style={{ width: '100%' }}>
            <Input disabled value={dataSource.name} bordered className="input" />
            <Input disabled value={dataSection?.name} bordered className="input" />

            <Input value={dataTable?.name} disabled bordered className="input" />

            <Button type="primary" onClick={onSetDataClick} block>
              Edit data selection
            </Button>

            <Button type="primary" onClick={onRemoveDataClick} block danger>
              <Icon name="sync-alt" style={{ marginRight: '2%' }} />
              Clear selection
            </Button>

            {/**
            <label style={{ display: 'block', padding: '15px 0' }}>
              <span style={{ display: 'inline-block', paddingBottom: '6px' }}>
                Change data selection strategy:
              </span>
              <Select
                placeholder="Change strategy"
                onChange={onStrategyChange}
                value={strategies.find(strat => strat.value === selectionStrategy)}
                style={{ width: '100%' }}
                loading={strategyLoading}
                disabled={strategyLoading}
                options={strategies}
              />
            </label> 
            */}
          </Space>
        ) : (
          <Button type="primary" onClick={onSetDataClick} block>
            Insert data
          </Button>
        )}
      </div>
    </div>
  )
}

const InterpolationPane = ({ dataKeys, onRemoveDataClick }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const selectedObject = useSelector(selectCanvasSelectedObject)
  const [selectedDataKey, setSelectedDataKey] = useState(() => dataKeys?.[0] || '')
  const [reorderingStrategy, setReorderingStrategy] = useState('by_absolute_position')
  const [strategyLoading, setStrategyLoading] = useState(false)
  const { dataTable, dataSource, dataSection, dataSelection } = useSelector(state =>
    selectInterpolationData(state, selectedDataKey)
  )
  const currentReorderingStrategy = useMemo(() => {
    return dataSelection?.reorderingStrategy
  }, [dataSelection?.reorderingStrategy])

  const hasData = dataSource != null
  const onChangeTag = value => setSelectedDataKey(value)
  const onSetDataClick = () =>
    history.push({
      pathname: '/data-view',
      state: { type: 'interpolation', dataKey: selectedDataKey }
    })

  const onStrategyChange = async value => {
    setStrategyLoading(true)
    try {
      await updateTextInterpolation(dataSelection.id, { reorderingStrategy: value })
      dispatch(
        updateShapeData(selectedObject.id, {
          ...selectedObject.data,
          interpolation: {
            ...selectedObject.data.interpolation,
            [selectedDataKey]: {
              ...selectedObject.data.interpolation[selectedDataKey],
              reorderingStrategy: value
            }
          }
        })
      )
      setReorderingStrategy(value)
    } catch (e) {
      console.error('Failed to change selection strategy. Log:', e)
    }
    setStrategyLoading(false)
  }

  useEffect(() => {
    setReorderingStrategy(currentReorderingStrategy)
  }, [currentReorderingStrategy])

  useEffect(() => {
    setSelectedDataKey(dataKeys?.[0] || '')
  }, [dataKeys])

  return (
    <div className="data-selector">
      <div className="ant-tabs-tabpane-header">Data Selection</div>
      <div className="form">
        <Space direction="vertical" style={{ width: '100%' }}>
          <label style={{ fontSize: '12px' }}>Select an interpolation parameter:</label>
          <Select<string | number, { value: string; children: string }>
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={onChangeTag}
            value={selectedDataKey}
            style={{ display: 'block' }}
          >
            {dataKeys?.map((text, index) => (
              <Select.Option value={text} key={index}>
                {text}
              </Select.Option>
            ))}
          </Select>
          {hasData ? (
            <>
              <Input disabled value={dataSource?.name} bordered className="input" />
              <Input disabled value={dataSection?.name} bordered className="input" />

              <Input value={dataTable?.name} disabled bordered className="input" />

              <Button type="primary" onClick={onSetDataClick} block>
                Edit data selection
              </Button>

              <Button type="primary" onClick={onRemoveDataClick} block danger>
                <Icon name="sync-alt" style={{ marginRight: '2%' }} />
                Clear selection
              </Button>
              {/*
                            <label style={{ display: 'block', padding: '15px 0' }}>
                                <span style={{ display: 'inline-block', paddingBottom: '6px' }}>
                                    Change data selection strategy:
                                </span>
                                <Select
                                    placeholder="Change strategy"
                                    onChange={onStrategyChange}
                                    value={strategies.find(strat => strat.value === reorderingStrategy)}
                                    style={{ width: '100%' }}
                                    loading={strategyLoading}
                                    disabled={strategyLoading}
                                    options={interpolationStrategies}
                                />
                            </label>
                                **/}
            </>
          ) : (
            <>
              <Button type="primary" onClick={onSetDataClick} block>
                Insert data
              </Button>
            </>
          )}
        </Space>
      </div>
    </div>
  )
}

export { DefaultPane, InterpolationPane }
