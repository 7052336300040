import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Form, InputNumber, notification, Select, Space, Spin } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import i18n from 'i18next'

import {
  updateOneCanvasObject,
  updateShapeData,
  updateShapeVisualSettings
} from '@/store/slices/shapes/actions'
import {
  selectCanvasSelectedObject,
  selectCanvasSelectedObjectDataDefinition
} from '@/store/slices/shapes/selectors'
import { DefaultDataDefinition } from '@/store/slices/shapes/types'

import {
  createSignificanceTesting,
  deleteSignificanceTesting,
  getSignificanceTesting,
  updateSignificanceTesting
} from '@services/analytics-service'

import MultiSelectDropdown from '@/components/common/MultiSelectDropdown'
import { Switcher } from '@/components/form/UseFormattedDataSwitch'
import Icon from '@components/icon/Icon'

import { AxisValues, SigTestTypes, TSigTesting } from '@/interfaces/analytics'

import { useDataTransformation, useRowsNColumns } from '../hooks'
import { getResultingRowsnColumns } from '../utils'
import { composeInitialValues, getVisualSettingsComponent } from './VisualisationSettings'

const SigTesting = () => {
  const [analysisType, setAnalysisType] = useState<SigTestTypes | null>(null)
  const [form] = useForm<TSigTesting<SigTestTypes>>()
  const [loading, setLoading] = useState(false)
  const [showVisualSettings, setShowVisualSettings] = useState(false)
  const [visualSettings, setVisualSettings] = useState(null)
  const selectedShape = useSelector(selectCanvasSelectedObject)
  const sigTestId = selectedShape.data?.sigTesting?.id
  const dispatch = useDispatch()
  const dataDefinition = useSelector(
    selectCanvasSelectedObjectDataDefinition
  ) as DefaultDataDefinition
  const { dataTransformation } = useDataTransformation(dataDefinition.transformationId)
  const { tableId } = useSelector(selectCanvasSelectedObjectDataDefinition) as DefaultDataDefinition
  let { rows, columns } = useRowsNColumns(tableId)
  const [resultingRows, resultingColumns] = getResultingRowsnColumns(
    rows,
    columns,
    dataTransformation
  )
  const [testBetweenVisible, setTestBetweenVisible] = useState(false)
  const [analysisTypeVisible, setAnalysisTypeVisible] = useState(false)
  const [controlGroupVisible, setControlGroupVisible] = useState(false)
  const [testGroupsVisible, setTestGroupsVisible] = useState(false)
  const [allVsAllVisible, setAllVsAllVisible] = useState(false)
  const [confidenceVisible, setConfidenceVisible] = useState(false)
  const [axisType, setAxisType] = useState<AxisValues>(
    resultingColumns.length > 1 ? AxisValues.COLUMNS : AxisValues.ROWS
  )
  const defaultValues = {
    baseMinimum: 0,
    axis: resultingColumns.length > 1 ? AxisValues.COLUMNS : AxisValues.ROWS,
    analysisType: null,
    confidence: 0.95,
    ...{
      [SigTestTypes.allVsAll]: {
        excludeAxisValues: []
      },
      [SigTestTypes.controlGroup]: {
        controlGroup: [],
        testGroup: []
      }
    }[analysisType]
  }
  const [initialValues, setInitialValues] = useState<TSigTesting<SigTestTypes>>(defaultValues)

  const [useFormattedValues, setUseFormattedValues] = useState(false)

  // if the user presses "select all" button, we set the number of items selected as a value
  // to check if it's still the same when we are creating a request
  const [selectAll, setSelectAll] = useState(0)

  const VisualSettingsComponent = getVisualSettingsComponent(analysisType, selectedShape.type)

  const initialVisualSettings = composeInitialValues(
    analysisType,
    selectedShape?.visualSettings?.significanceTest
  )

  const onVisualSettingsFormSubmit = values => {
    setShowVisualSettings(false)
    setVisualSettings(values)
  }

  const onCloseVisualSettings = () => {
    setShowVisualSettings(false)
    setVisualSettings(null)
  }

  const updateVisualSettings = (payload = null) => {
    dispatch(updateShapeVisualSettings(selectedShape.id, { significanceTest: payload }))
  }

  const performSigTesting = async formData => {
    const payload = {
      dataSelection: dataTransformation.id,
      analysisType: formData.analysisType,
      axis: formData.axis,
      baseMinimum: formData.baseMinimum,
      confidence: formData.confidence,
      useFormattedData: useFormattedValues,
      ...{
        [SigTestTypes.allVsAll]: {
          excludeAxisValues: formData.excludeAxisValues
        },
        [SigTestTypes.controlGroup]: {
          controlGroup: formData.controlGroup,
          testGroup: formData.testGroup
        }
      }[formData.analysisType]
    }

    if (formData.analysisType === SigTestTypes.controlGroup) {
      if (formData.testGroup.length === selectAll) {
        payload.selectAll = true
      } else {
        payload.selectAll = false
      }
    }

    if (!sigTestId) {
      try {
        // create significance test object
        const response = await createSignificanceTesting<SigTestTypes>({
          testing: payload
        })
        if (response.shapeData?.response.isSignificant === false) {
          notify(true)
        }
        updateVisualSettings(visualSettings || initialVisualSettings)
        dispatch(updateShapeData(selectedShape.id, { sigTesting: response.shapeData }))
      } catch (error) {
        const errorMessage = error?.response?.data?.nonFieldErrors
        notify(false, true, errorMessage)
      }
    } else {
      try {
        const response = await updateSignificanceTesting({
          significanceId: sigTestId,
          testing: payload
        })

        if (response.shapeData?.response.isSignificant === false) {
          notify(true)
        }
        updateVisualSettings(visualSettings || initialVisualSettings)
        dispatch(updateShapeData(selectedShape.id, { sigTesting: response.shapeData }))
      } catch (error) {
        const errorMessage = error?.response?.data?.nonFieldErrors
        notify(false, true, errorMessage)
      }
    }
  }

  const undoSigTesting = async () => {
    if (sigTestId != null) {
      await deleteSignificanceTesting({
        significanceId: sigTestId
      })
      dispatch(
        updateOneCanvasObject({
          id: selectedShape.id,
          changes: {
            visualSettings: { ...selectedShape.visualSettings, sigTesting: null },
            data: { ...selectedShape.data, sigTesting: null }
          }
        })
      )
      dispatch(updateShapeData(selectedShape.id, { sigTesting: null }))
      setInitialValues(defaultValues)
      form.resetFields()
      setAxisType(defaultValues.axis)
      setUseFormattedValues(false)
    }
  }

  const onAxisChange = (value: AxisValues) => {
    form.setFieldsValue({ ...defaultValues, axis: value })
    setAxisType(value)
  }

  const onAnalysisTypeChange = (value: SigTestTypes) => {
    form.setFieldsValue({ analysisType: value })
    if (value === "control_group") {
      form.setFieldsValue({ testGroup: [] })
    }
    setAnalysisType(value)
  }

  const notify = (nonSignificant = false, invalidData = false, message = '') => {
    if (nonSignificant) {
      notification.info({
        message: `Significance test`,
        description: `No significance difference found`,
        top: 60
      })
    }

    if (invalidData) {
      notification.warning({
        message: `Significance test`,
        description: message,
        top: 60
      })
    }
  }

  const onSelectAll = options => {
    setSelectAll(options.length)
    form.setFieldsValue({ testGroup: options.map(({ index }) => index) })
  }

  const getInitialValues = async () => {
    setLoading(true)
    try {
      const sigTest = await getSignificanceTesting({
        significanceId: sigTestId
      })
      let options = []
      if (dataTransformation.transposed) {
        options = sigTest.axis === 'columns' ? resultingRows : resultingColumns
      } else {
        options = sigTest.axis === 'columns' ? resultingColumns : resultingRows
      }
      options = options.map(item => item.index)
      //@ts-ignore
      if (sigTest?.testGroup) {
        //@ts-ignore
        sigTest.testGroup = sigTest.testGroup.filter(index => options.includes(index))
      }
      form.setFieldsValue(sigTest)
      setInitialValues(sigTest)
      setAxisType(sigTest.axis)
      setAnalysisType(sigTest.analysisType)
    } catch (e) {
      console.error(e.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (sigTestId != null) {
      getInitialValues()
    }
  }, [sigTestId])

  return (
    <Spin spinning={loading}>
      <Form
        layout="vertical"
        form={form}
        initialValues={initialValues}
        onFinish={performSigTesting}
        style={{ width: '100%' }}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form.Item
            label={i18n.t('editor.right-panel.analytics.sig-testing.base-limit')}
            name="baseMinimum"
            rules={[{ required: true }]}
          >
            <InputNumber style={{ width: '100%' }} min={0} />
          </Form.Item>

          <Form.Item
            label={i18n.t('editor.right-panel.analytics.sig-testing.axis-type')}
            name="axis"
            rules={[{ required: true }]}
          >
            <Select<string | number, { value: string; children: string }>
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              dropdownMatchSelectWidth={false}
              dropdownAlign={{
                points: ['tr', 'tl'], //align dropdown's top-right to top-left of input element
                offset: [-5, 0] //align offset
              }}
              dropdownStyle={{
                display: testBetweenVisible ? 'inline-block' : 'none',
                width: 'fit-content',
                maxWidth: '33%',
                minWidth: '15%'
              }}
              onDropdownVisibleChange={open => setTestBetweenVisible(open)}
              onChange={(value: AxisValues) => onAxisChange(value)}
              placeholder={i18n.t('editor.right-panel.analytics.sig-testing.select-one')}
            >
              <Select.Option value={AxisValues.COLUMNS}>
                {i18n.t('editor.right-panel.data-source.form.columns')}
              </Select.Option>
              <Select.Option value={AxisValues.ROWS}>
                {i18n.t('editor.right-panel.data-source.form.rows')}
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={i18n.t('editor.right-panel.analytics.sig-testing.analytics-type.name')}
            name="analysisType"
            rules={[{ required: true }]}
          >
            <Select<string | number, { value: string; children: string }>
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              dropdownMatchSelectWidth={false}
              dropdownAlign={{
                points: ['tr', 'tl'], //align dropdown's top-right to top-left of input element
                offset: [-5, 0] //align offset
              }}
              dropdownStyle={{
                display: analysisTypeVisible ? 'inline-block' : 'none',
                width: 'fit-content',
                maxWidth: '33%',
                minWidth: '15%'
              }}
              onDropdownVisibleChange={open => setAnalysisTypeVisible(open)}
              onChange={(value: SigTestTypes) => onAnalysisTypeChange(value)}
              placeholder={i18n.t('editor.right-panel.analytics.sig-testing.select-one')}
            >
              <Select.Option value={SigTestTypes.allVsAll}>
                {i18n.t('editor.right-panel.analytics.sig-testing.analytics-type.all-vs-all')}
              </Select.Option>
              <Select.Option value={SigTestTypes.controlGroup}>
                {i18n.t('editor.right-panel.analytics.sig-testing.analytics-type.control-group')}
              </Select.Option>
              <Select.Option value={SigTestTypes.nextDataPoint}>
                {i18n.t('editor.right-panel.analytics.sig-testing.analytics-type.next-data-point')}
              </Select.Option>
              <Select.Option value={SigTestTypes.previousDataPoint}>
                {i18n.t(
                  'editor.right-panel.analytics.sig-testing.analytics-type.previous-data-point'
                )}
              </Select.Option>
            </Select>
          </Form.Item>
          {
            {
              [SigTestTypes.allVsAll]: (
                <Form.Item
                  label={i18n.t('editor.right-panel.analytics.sig-testing.exclude')}
                  name="excludeAxisValues"
                >
                  <MultiSelectDropdown
                    align="left"
                    dropdownSelect={() =>
                      form.setFieldsValue({
                        //@ts-ignore
                        excludeAxisValues:
                          axisType === AxisValues.COLUMNS
                            ? resultingColumns.map(({ index }) => index)
                            : resultingRows.map(({ index }) => index)
                      })
                    }
                    //@ts-ignore
                    dropdownClear={() => form.setFieldsValue({ excludeAxisValues: [] })}
                    dropdownOk={() => setAllVsAllVisible(false)}
                    dropdownCancel={() => {
                      //@ts-ignore
                      form.setFieldsValue({ excludeAxisValues: [] })
                      setAllVsAllVisible(false)
                    }}
                    visible={allVsAllVisible}
                    setVisible={setAllVsAllVisible}
                    placeholder={`Select ${axisType === AxisValues.COLUMNS ? 'columns' : 'rows'
                      } to exclude`}
                  >
                    {dataTransformation.transposed
                      ? axisType === AxisValues.ROWS
                        ? resultingColumns?.map(({ index, name }) => (
                          <Select.Option value={index} key={index}>
                            {name}
                          </Select.Option>
                        ))
                        : resultingRows?.map(({ index, name }) => (
                          <Select.Option value={index} key={index}>
                            {name}
                          </Select.Option>
                        ))
                      : axisType === AxisValues.COLUMNS
                        ? resultingColumns?.map(({ index, name }) => (
                          <Select.Option value={index} key={index}>
                            {name}
                          </Select.Option>
                        ))
                        : resultingRows?.map(({ index, name }) => (
                          <Select.Option value={index} key={index}>
                            {name}
                          </Select.Option>
                        ))}
                  </MultiSelectDropdown>
                </Form.Item>
              ),
              [SigTestTypes.controlGroup]: (
                <>
                  <Form.Item
                    label={i18n.t('editor.right-panel.analytics.sig-testing.control-group')}
                    name="controlGroup"
                    rules={[{ required: true }]}
                  >
                    <Select<string | number, { value: string; children: string }>
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      dropdownMatchSelectWidth={false}
                      dropdownAlign={{
                        points: ['tr', 'tl'], //align dropdown's top-right to top-left of input element
                        offset: [-5, 0] //align offset
                      }}
                      dropdownStyle={{
                        display: controlGroupVisible ? 'inline-block' : 'none',
                        width: 'fit-content',
                        maxWidth: '33%',
                        minWidth: '15%'
                      }}
                      onDropdownVisibleChange={open => setControlGroupVisible(open)}
                      placeholder={i18n.t('editor.right-panel.analytics.sig-testing.select-one')}
                    >
                      {axisType === AxisValues.COLUMNS
                        ? columns.map(({ index, name }) => (
                          <Select.Option value={index} key={`${name}-${index}`}>
                            {name}
                          </Select.Option>
                        ))
                        : rows.map(({ name, index }) => (
                          <Select.Option value={index} key={`${name}-${index}`}>
                            {name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.controlGroup !== curValues.controlGroup
                    }
                  >
                    {({ getFieldValue }) => {
                      let controlGroupValue = getFieldValue('controlGroup')
                      let options = dataTransformation.transposed
                        ? axisType === AxisValues.COLUMNS
                          ? resultingRows?.filter(({ index }) => index !== controlGroupValue)
                          : resultingColumns?.filter(({ index }) => index !== controlGroupValue)
                        : axisType === AxisValues.COLUMNS
                          ? resultingColumns?.filter(({ index }) => index !== controlGroupValue)
                          : resultingRows?.filter(({ index }) => index !== controlGroupValue)
                      return (
                        <Form.Item
                          label={i18n.t('editor.right-panel.analytics.sig-testing.test-groups')}
                          name="testGroup"
                          rules={[{ required: true }]}
                        >
                          <MultiSelectDropdown
                            align="left"
                            dropdownSelect={() => onSelectAll(options)}
                            dropdownClear={() => form.setFieldsValue({ testGroup: [] })}
                            dropdownOk={() => setTestGroupsVisible(false)}
                            dropdownCancel={() => {
                              form.setFieldsValue({ testGroup: [] })
                              setTestGroupsVisible(false)
                            }}
                            visible={testGroupsVisible}
                            setVisible={setTestGroupsVisible}
                            placeholder={i18n.t(
                              'editor.right-panel.analytics.sig-testing.select-many'
                            )}
                          >
                            {options.map(({ index, name }) => (
                              <Select.Option value={index} key={`${name}-${index}`}>
                                {name}
                              </Select.Option>
                            ))}
                          </MultiSelectDropdown>
                        </Form.Item>
                      )
                    }}
                  </Form.Item>
                </>
              )
            }[analysisType]
          }

          <Form.Item
            label={i18n.t('editor.right-panel.analytics.sig-testing.significance-confidence')}
            name="confidence"
            rules={[{ required: true }]}
          >
            <Select<string | number, { value: string; children: string }>
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              dropdownMatchSelectWidth={false}
              dropdownAlign={{
                points: ['tr', 'tl'], //align dropdown's top-right to top-left of input element
                offset: [-5, 0] //align offset
              }}
              dropdownStyle={{
                display: confidenceVisible ? 'inline-block' : 'none',
                width: 'fit-content',
                maxWidth: '33%',
                minWidth: '15%'
              }}
              onDropdownVisibleChange={open => setConfidenceVisible(open)}
            >
              <Select.Option value={0.9}>90%</Select.Option>
              <Select.Option value={0.95}>95%</Select.Option>
              <Select.Option value={0.99}>99%</Select.Option>
            </Select>
          </Form.Item>

          <Switcher
            style={{ paddingTop: 10 }}
            onToggle={value => setUseFormattedValues(value)}
            defaultChecked
            checked={useFormattedValues}
          />

          <Form.Item style={{ paddingTop: '1rem' }}>
            <Button disabled={!analysisType} onClick={() => setShowVisualSettings(true)} block>
              Visualisation settings
            </Button>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="form-submit-button" block>
              {i18n.t('editor.right-panel.analytics.sig-testing.apply')}
            </Button>
          </Form.Item>

          <Form.Item>
            <Button
              style={{ whiteSpace: 'normal', height: 'auto' }}
              onClick={undoSigTesting}
              type="primary"
              block
              danger
              disabled={!sigTestId}
            >
              <Icon name="sync-alt" style={{ marginRight: '2%' }} />
              {i18n.t('editor.right-panel.analytics.sig-testing.delete')}
            </Button>
          </Form.Item>
        </Space>
      </Form>

      <VisualSettingsComponent
        key={analysisType}
        visible={showVisualSettings}
        onCancel={() => onCloseVisualSettings()}
        onOk={onVisualSettingsFormSubmit}
        initialValues={initialVisualSettings}
      />
    </Spin>
  )
}

export default SigTesting
