import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Shadow } from 'fabric/fabric-impl'

import { RootState } from '@store/rootReducer'
import { selectCanvasObjectProperty } from '@store/slices/shapes/selectors'

import { useCanvas } from '@components/canvas/hooks/useCanvas'
import { FabricObject } from '@components/canvas/types/object'

export function useShadowHandler(object: FabricObject) {
  const selectObjProperty = useMemo(() => selectCanvasObjectProperty, [])
  const shadow = useSelector((s: RootState) => selectObjProperty(s, object?.id, 'shadow'))
  const canvas = useCanvas()

  useEffect(() => {
    if (!object || !shadow) {
      return
    }

    if (shadow !== (object.shadow as Shadow)?.toObject()) {
      object.set('shadow', shadow)
      canvas.renderAll()
    }
  }, [canvas, object, shadow])
}
