import React, { useState } from 'react'

import CanvasWrapper from '@components/canvas/CanvasWrapper'
import FooterToolbar from '@components/imagemap/main/FooterToolbar'
import HeaderToolbar from '@components/imagemap/main/HeaderToolbar'

const EditorMainPanel = () => {
  return (
    <div className="rde-editor-canvas-container" id="rde-editor-canvas-container">
      <div className="rde-editor-header-toolbar" id="rde-editor-header-toolbar">
        <HeaderToolbar />
      </div>
      <div className="rde-editor-canvas" id="rde-editor-canvas">
        <CanvasWrapper />
      </div>
      <div className="rde-editor-footer-toolbar" id="rde-editor-footer-toolbar">
        <FooterToolbar />
      </div>
    </div>
  )
}

export default React.memo(EditorMainPanel)
