import { fabric } from 'fabric'

import { applyNumberFormatting } from '..'

const TEXTAREA_TYPE = 'Textarea'

class TextareaObject extends fabric.Textbox {
  static type = TEXTAREA_TYPE
  private keyValues: { key: string; value: string | number }[]
  private visualSettings

  initialize(options: TextareaOptions) {
    this.keyValues = []
    this.visualSettings = null
    let { text } = options

    //@ts-ignore
    super.initialize(text, options)
    this.on('selected', () => {
      this.text = this.mapValuesToTags(this.text)
    })
    this.on('deselected', () => {
      this.text = this.mapTagsToValues(this.text)
    })
    this.on('editing:exited', () => {
      this.onFinishEditing(text)
    })
    this.on('afterUpdate', () => {
      this.text = this.mapTagsToValues(this.text)
    })
    this.on('beforeUpdate', () => (this.keyValues = []))
    return this
  }

  _set(key, value) {
    if (key === 'data') {
      this.onDataUpdate(value)
    } else {
      return super._set(key, value)
    }
  }

  onDataUpdate(value) {
    if (value !== null) {
      Object.keys(value?.interpolation || {}).forEach(k => {
        const data = value?.interpolation[k]
        const v = data.value
        this.keyValues.push({
          key: `{{${k}}}`,
          value: `${v}`
        })
      })
    } else {
      this.keyValues = []
    }
  }

  /** A hook that fires when the user finishes editing. */
  onFinishEditing(previousValue: string): void {
    // we need to preserve interpolation tags so we map values back to tags,
    // call a func that saves the text on the backend (useTextboxPropsHandler hook) by firing 'text:updated' event,
    // then render interpolation tags as values on the frontend.
    this.text = this.mapValuesToTags(this.text)
    if (this.text.trim() !== previousValue.trim()) {
      this.fire('text:updated')
    }
  }

  /**
   * Map interpolation tags to values.
   * @param {string} text
   * @returns {string}
   */
  private mapTagsToValues(text: string): string {
    let rv = text

    this.keyValues.forEach(({ key, value }) => {
      let formattedValue = this.formatValue(key, value)
      let strippedKey = key.replace(/(\{\{)|(\}\})/g, '')
      let pattern = `\\{\\{\\s*${strippedKey}\\s*\\}\\}` // gotta escape backslashes
      let exp = new RegExp(pattern, 'g')
      rv = rv.replace(exp, formattedValue)
    })
    return rv
  }

  /**
   * Map values back to interpolation tags.
   * @param {string} text interpolation
   * @returns {string}
   */
  private mapValuesToTags(text: string): string {
    // Heads up: same values may be mapped incorrectly
    let rv = text
    this.keyValues.forEach(({ key, value }) => {
      let formattedValue = this.formatValue(key, value)
      rv = rv.replace(formattedValue, key)
    })
    return rv
  }

  /**
   * Format value according to the `numberFormat` value.
   * @param {string} key interpolation tag
   * @param {string|number} value value that is mapped to the interpolation tag
   * @returns {string} formatted value
   */
  private formatValue(key: string, value: string | number): string {
    // match anything that is not whitespace or curly brace within double curly braces, e.g.
    // {{ [value_a !@#]  }}, {{ [XX__ZZ]}} - matches are in square brackets
    let [tag] = key.match(/(?<=\{\{\s*)[^\s\{\}]+(?=\s*\}\})/) || []
    let formatCode = '0'
    if (this.visualSettings?.interpolation && this.visualSettings?.interpolation[tag]) {
      formatCode = this.visualSettings?.interpolation[tag].numberFormat
    }
    // check if value is not number convertible
    if (!/^\d+(\.\d+)?$/.test(value.toString())) {
      return value.toString()
    }
    return applyNumberFormatting(value, formatCode)
  }

  toObject(propertiesToInclude = []) {
    return super.toObject(propertiesToInclude)
  }
  toJSON(propertiesToInclude = []) {
    return super.toObject(propertiesToInclude)
  }
  static fromObject(options: TextareaOptions, callback: Function) {
    return callback && callback(new fabric.Textarea(options))
  }
}

fabric.Textarea = fabric.util.createClass(TextareaObject, {
  type: TextareaObject.type
})
fabric.Textarea.fromObject = TextareaObject.fromObject

type TextareaOptions = fabric.ITextboxOptions & { text: string; visualSettings?: any }

declare module 'fabric' {
  namespace fabric {
    class Textarea extends TextareaObject {
      constructor(options: TextareaOptions)
    }
  }
}
