import useSWR from 'swr'

import { getTableDataFrame } from '@services/data-service'

const useDataTable = (dataTableId: number) => {
  const url = `/api/data_tables/${dataTableId}/table_view_data`
  const { data, error } = useSWR(() => (dataTableId != null ? url : null), getTableDataFrame)
  return {
    error,
    data: data || null,
    loading: !data && !error
  }
}

export { useDataTable }
