import { useEffect, useState } from 'react'
import axios, { CancelTokenSource } from 'axios'

import { message, Button, Form, Progress, Select, Space, Typography, Upload } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { UploadFile } from 'antd/lib/upload/interface'
import { getContentType } from '@/utils/contentType'
import { ProjectFileTypes } from '@/interfaces/projects'
import { uploadProjectFile } from '@services/project-service'

import { FileAddOutlined } from '@ant-design/icons'
import i18n from 'i18next'

import { DataSourceAddStatuses } from '@store/slices/data/types'
import { getCeleryTaskStatus } from '@services/celery-service'
import { getDataSourceSections, updateProjectDataSource } from '@services/data-service'

import { getCompanyParsers } from '@/services/data-service'

import MultiSelectDropdown from '@/components/common/MultiSelectDropdown'
import { Flex } from '@components/flex'

import { CeleryStatus } from '@/interfaces/celery'
import { IParser } from '@/interfaces/interfaces'

import CommonDetail from '@components/common/CommonDetail'

const { Text, Paragraph, Link } = Typography
const { Dragger } = Upload

const sleep = () => new Promise(resolve => setTimeout(resolve, 1000))

const requestData = async (taskId: string) => {
  let status = CeleryStatus.STARTED
  while (status === CeleryStatus.STARTED) {
    const response = await getCeleryTaskStatus({ taskId })
    status = response.status
    if (status === CeleryStatus.SUCCESS) {
      return response.result
    } else {
      await sleep()
    }
  }
}

const updateDataSource = async ({ dataSourceId, dataSource, projectId }) => {
  const { taskId } = await updateProjectDataSource({
    projectId,
    dataId: dataSourceId,
    newData: dataSource
  })
  return requestData(taskId)
}

const addDataSourceWithSheets = async ({ file, parser, projectId }, cancelToken) => {
  const { url, data } = await uploadProjectFile(
    {
      fileName: file.name,
      projectId,
      type: ProjectFileTypes.DATA,
      parserId: parser.id
    },
    cancelToken
  )

  const contentType = getContentType(file.name)
  await axios.put(url, file, {
    headers: { 'Content-Type': contentType },
    cancelToken
  })

  const { taskId } = await getDataSourceSections({ projectId, dataId: data.id }, cancelToken)
  const sections = await requestData(taskId)
  return { ...data, sections }
}

const addDataSourceWithoutSheets = async ({ file, parser, projectId }, cancelToken) => {
  const { data, url } = await uploadProjectFile(
    {
      fileName: file.name,
      projectId,
      type: ProjectFileTypes.DATA,
      parserId: parser.id
    },
    cancelToken
  )

  const contentType = getContentType(file.name)
  await axios.put(url, file, {
    headers: { 'Content-Type': contentType },
    cancelToken
  })

  return updateDataSource({
    projectId,
    dataSource: { parserId: parser.id, filename: file.name },
    dataSourceId: data.id
  })
}

export const ProjectDataUploader = ({ projectId, onComplete, onCancel, Layout }) => {
  const [file, setFile] = useState<UploadFile | null>(null)
  const cancelToken: CancelTokenSource = axios.CancelToken.source()
  const [fileType, setFileType] = useState<string>('.xlsx')
  const [parserError, setParserError] = useState<string>(null)
  const [parsers, setParsers] = useState<IParser[]>([])
  const [dataSourceAddStatus, setDataSourceAddStatus] = useState(null)
  const [form] = useForm()
  const [sheetsVisible, setSheetsVisible] = useState<Boolean>(false)
  const [newDataSource, setNewDataSource] = useState(null)
  const [sheets, setSheets] = useState([])
  const [loading, setLoading] = useState(false)

  const cancel = () => {
    cancelToken.cancel()
    setFile(null)
    setNewDataSource(null)
    form.resetFields()
    onCancel()
  }

  const onRetry = () => {
    setFile(null)
    setNewDataSource(null)
    setParserError(null)
    form.resetFields()
  }

  useEffect(() => {
    const getParsers = async () => {
      try {
        const parsers = await getCompanyParsers()
        setParsers(parsers)
      } catch (e) {
        console.error(e.message)
      }
    }
    getParsers()
  }, [])

  const addProjectDataSource = file => {
    setLoading(true)
    const parserId = form.getFieldValue('parser')
    const parser = parsers.find(({ id }) => id === parserId)
    if (parser.hasSections) {
      addDataSourceWithSheets({ file, parser, projectId }, cancelToken.token)
        .then(({ sections, ...newDataSource }) => {
          setSheets(sections)
          setNewDataSource(newDataSource)
          setDataSourceAddStatus(DataSourceAddStatuses.SECTIONS_SELECTION)
          setLoading(false)
        })
        .catch(e => setParserError(e.message))
        .finally(() => setLoading(false))
    } else {
      addDataSourceWithoutSheets({ file, parser, projectId }, cancelToken.token)
        .then(({ id }) => {
          setDataSourceAddStatus(CeleryStatus.SUCCESS)
          setTimeout(() => {
            onComplete(id)
            setLoading(false)
          }, 1000)
        })
        .catch(e => setParserError(e.message))
        .finally(() => setLoading(false))
    }
  }

  const updateProjectDataSource = formData => {
    setLoading(true)
    updateDataSource({
      projectId: projectId,
      dataSourceId: newDataSource?.id,
      dataSource: {
        dataSections: formData.sections,
        parserId: form.getFieldValue('parser'),
        filename: file.name
      }
    })
      .then(() => {
        setDataSourceAddStatus(CeleryStatus.SUCCESS)
        setTimeout(() => {
          onComplete(newDataSource?.id)
          setLoading(false)
        }, 1000)
      })
      .finally(() => setLoading(false))
  }

  const onParserChange = () => {
    setFileType(getFileType())
  }

  const getFileType = () => {
    const parserId = form.getFieldValue('parser')
    const parser = parsers.find(({ id }) => id === parserId)
    if (
      ['Default', 'PDR', 'DisplayR', 'Decipher', 'Longitude', 'WinCross'].includes(parser?.name)
    ) {
      return '.xlsx, .xls'
    } else if (['Ruby_XtabML', 'TabsML'].includes(parser?.name)) {
      return '.xml'
    } else if (['MarketSight_XtabML', 'Q_XtabML'].includes(parser?.name)) {
      return '.xte'
    } else if ('Quantum' === parser?.name) {
      return '.txt'
    } else if ('Dimensions_Mtd' === parser?.name) {
      return '.mtd'
    } else if ('SPSS' === parser?.name) {
      return '.sav'
    } else if (['Mercury', 'QPSMR'].includes(parser?.name)) {
      return '.csv'
    }
    return '.xlsx, .xls, .txt, .mtd, .xte, .xml'
  }

  const onFileChange = file => {
    if (file.size / 1024 / 1024 > 25) {
      message.error('Sorry, file size must be under 25 MB')
      return false
    }
    setFile(file)
    return false
  }

  const draggerProps = {
    name: 'file',
    beforeUpload: onFileChange,
    accept: fileType,
    multiple: false,
    maxCount: 1
  }
  const content = (
    <CommonDetail
      className="data-uploader"
      style={{
        minWidth: 'auto'
      }}
    >
      {
        {
          [CeleryStatus.FAILURE]: (
            <>
              <Flex justifyContent="center">
                <Space direction="vertical" size="middle">
                  <Typography>
                    <Text style={{ textAlign: 'center' }}>
                      {i18n.t('editor.left-panel.data-sources.error')}
                    </Text>
                    <Paragraph>
                      <pre>
                        <Text style={{ display: 'block' }}>Filename: {file?.name}</Text>
                        <Text style={{ display: 'block' }}>Error: {parserError}</Text>
                        <Text style={{ display: 'block' }}>
                          Table reader: {form.getFieldValue('parser')}
                        </Text>
                      </pre>
                    </Paragraph>
                    <Text style={{ textAlign: 'center' }}>
                      For help, please contact <Link copyable>support@indicolabs.io</Link>
                    </Text>
                  </Typography>
                </Space>
              </Flex>
              <Flex flexDirection="row" justifyContent="flex-end" style={{ paddingTop: '1rem' }}>
                <Button type="primary" onClick={onRetry}>
                  {i18n.t('editor.left-panel.templates.try-again')}
                </Button>
              </Flex>
            </>
          ),
          [CeleryStatus.SUCCESS]: (
            <Flex flex="1" justifyContent="center" flexDirection="column">
              <div style={{ textAlign: 'center' }}>
                {i18n.t('editor.left-panel.data-sources.file')}
                <strong>{file?.name}</strong>
                {i18n.t('editor.left-panel.data-sources.successfully')}
              </div>
              <Progress percent={100} status="success" showInfo={false} />
            </Flex>
          ),
          [CeleryStatus.PENDING]: (
            <Flex flex="1" justifyContent="center" flexDirection="column">
              <div style={{ textAlign: 'center' }}>
                {i18n.t('editor.left-panel.templates.pending-file')}
                <strong>{file?.name}</strong>
              </div>
              <Progress percent={100} status="active" showInfo={false} />
            </Flex>
          ),
          [CeleryStatus.STARTED]: (
            <Flex flex="1" justifyContent="center" flexDirection="column">
              <div style={{ textAlign: 'center' }}>
                {i18n.t('editor.left-panel.templates.started-file')}
                <strong>{file?.name}</strong>
              </div>
              <Progress percent={100} status="active" showInfo={false} />
            </Flex>
          ),
          [DataSourceAddStatuses.SECTIONS_SELECTION]: (
            <Form
              layout="vertical"
              style={{ margin: '0 20px' }}
              form={form}
              onFinish={updateProjectDataSource}
            >
              <Form.Item
                label={i18n.t('editor.left-panel.data-sources.sections.title')}
                name="sections"
                rules={[{ required: true }]}
                style={{ padding: '5px 0', minWidth: '30rem' }}
              >
                <MultiSelectDropdown
                  visible={sheetsVisible}
                  setVisible={setSheetsVisible}
                  dropdownSelect={() =>
                    form.setFieldsValue({ sections: sheets.map(({ id }) => id) })
                  }
                  dropdownClear={() => form.setFieldsValue({ sections: [] })}
                  dropdownOk={() => setSheetsVisible(false)}
                  dropdownCancel={() => {
                    form.setFieldsValue({ sections: [] })
                    setSheetsVisible(false)
                  }}
                >
                  {sheets.map(({ id, name }) => (
                    <Select.Option value={id} key={id}>
                      {name}
                    </Select.Option>
                  ))}
                </MultiSelectDropdown>
              </Form.Item>
            </Form>
          ),
          null: (
            <>
              <div className="slide__text">
                <h1 className="slide__title">Upload your data</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
              </div>
              <Flex justifyContent="center">
                <Form layout="vertical" form={form}>
                  <Space size="small" direction="vertical">
                    <Form.Item label="Table reader" name="parser" required>
                      <Select onChange={onParserChange}>
                        {parsers.map(({ id, name }) => (
                          <Select.Option value={id} key={id}>
                            {name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item className="upload-template-file-item" required label={'Choose file'}>
                      <Flex style={{ height: '15rem' }} justifyContent="center">
                        <Space size="small" direction="vertical">
                          <Dragger showUploadList={true} {...draggerProps}>
                            <Flex
                              justifyContent="center"
                              alignItems="center"
                              style={{ width: '100%', height: '80px' }}
                            >
                              <FileAddOutlined style={{ fontSize: '24px' }} />
                            </Flex>
                            <p style={{ width: '70%', marginLeft: '15%', fontSize: '14px' }}>
                              Click or drag file to this area to upload
                            </p>
                          </Dragger>
                        </Space>
                      </Flex>
                    </Form.Item>
                  </Space>
                </Form>
              </Flex>
            </>
          )
        }[dataSourceAddStatus]
      }
    </CommonDetail>
  )
  return (
    <Layout
      previous={<Button onClick={() => cancel()}>Cancel</Button>}
      next={
        newDataSource == null ? (
          <Button loading={loading} type="primary" onClick={() => addProjectDataSource(file)}>
            Upload File
          </Button>
        ) : (
          <Button loading={loading} type="primary" onClick={() => form.submit()}>
            Submit
          </Button>
        )
      }
    >
      {content}
    </Layout>
  )
}
