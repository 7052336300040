import React, { useCallback } from 'react'

import { fabric } from 'fabric'

import { useAngleHandler } from '@components/canvas/handlers/objects/useAngleHandler'
import { useCommonPropsHandler } from '@components/canvas/handlers/objects/useCommonPropsHandler'
import { useShadowHandler } from '@components/canvas/handlers/objects/useShadowHandler'
import { useVisibilityHandler } from '@components/canvas/handlers/objects/useVisibilityHandler'
import { FabricChartObject, FabricChartOptions } from '@components/canvas/types/chart'
import { CanvasComponentProps } from '@components/canvas/types/component'

import { useFabricObject } from '../utils/useFabricObject'

const chartFactory = (options: FabricChartOptions): FabricChartObject => {
  return new fabric.Chart(options) as FabricChartObject
}

const Chart = ({ id, options }: CanvasComponentProps<FabricChartOptions>) => {
  const factory = useCallback(() => chartFactory(options), [])
  const object = useFabricObject(factory, id, options)

  useCommonPropsHandler(object)
  useAngleHandler(object)
  useShadowHandler(object)
  useVisibilityHandler(object)

  return <></>
}

export default React.memo(Chart)
