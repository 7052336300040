import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Form, Select, Space, Spin, Typography } from 'antd'
import { useForm } from 'antd/es/form/Form'

import { ReloadOutlined, TableOutlined } from '@ant-design/icons'
import i18n from 'i18next'
import { uniq } from 'lodash'

import {
  updateOneCanvasObject,
  updateShapeData,
  updateShapeVisualSettings
} from '@/store/slices/shapes/actions'
import {
  selectCanvasSelectedObject,
  selectCanvasSelectedObjectPk
} from '@/store/slices/shapes/selectors'
import { selectDataSources } from '@store/slices/data/selectors'
import { selectCurrentProjectId } from '@store/slices/projects/selectors'

import { getDataActions } from '@/services/data-actions-service'
import { resetShape } from '@/services/shape-service'
import {
  createDataTransformation,
  updateDataTransformation
} from '@services/data-transfomations-service'

import CommonModal from '@/components/common/CommonModal'
import MultiSelectDropdown from '@/components/common/MultiSelectDropdown'
import { Flex } from '@/components/flex'
import Icon from '@components/icon/Icon'

import { useLocalStorage } from '@/hooks'
import { hasEmpty } from '@/utils'

import { useDataTable, useDataTransformation, useRowsNColumns, useTables } from '../hooks'
import DropDownMenu from './DropDownMenu'
import EmptyDataSourceUploader from './EmptyDataSourceUploader'
import TableView from './TableView'

const { Text, Link } = Typography

interface DataSourceSelectorProps {
  dataSourceId?: number
  dataSectionId?: number
  dataTableId?: number
  transformationId?: number
  selectColumnsMode?: 'multiple' | 'tags' | ''
  selectRowsMode?: 'multiple' | 'tags'
  onComplete?: () => void
}

interface DataSourceForm {
  dataSourceId: number
  sectionId: number
  tableId: number
  columns: number[]
  rows: number[]
  tableMetaId: string
  row_meta_value: number[]
  rowTags?: string[]
}

const DataSourceSelector = ({
  dataSourceId = null,
  dataSectionId,
  dataTableId,
  transformationId,
  selectColumnsMode,
  selectRowsMode
}: DataSourceSelectorProps) => {
  const dispatch = useDispatch()

  const projectId = useSelector(selectCurrentProjectId)
  const shapeId = Number(useSelector(selectCanvasSelectedObjectPk))
  const dataSources = useSelector(selectDataSources)
  const selectedObject = useSelector(selectCanvasSelectedObject)

  const [tableId, setTableId] = useState<number>(null)
  const [sections, setSections] = useState([])
  const [uploaderVisible, setUploaderVisible] = useState(false)
  const [sectionsVisible, setSectionsVisible] = useState(false)
  const [dataId, setDataId] = useState<number>(null)
  const [rowsDropdownVisible, setRowsDropdownVisible] = useState(false)
  const [columnsDropdownVisible, setColumnsDropdownVisible] = useState(false)
  const [tablesDropdownVisible, setTablesDropdownVisible] = useState(false)
  const [isWarningModalVisible, setIsWarningModalVisible] = useState(false)
  const [rowTagsDropdownVisible, setRowTagsDropdownVisible] = useState(false)
  const [showTableView, setShowTableView] = useState(false)
  const [sectionId, setSectionId] = useState<number>(null)
  const [tableViewDisabled, setTableViewDisabled] = useState(true)
  const [lastInsertData, setLastInsertData] = useLocalStorage<{
    dataSourceId: number
    sectionId: number
    tableId: number
  }>(projectId.toString(), null)

  const { dataTransformation, mutate } = useDataTransformation(transformationId)
  const { data: tableDataFrame } = useDataTable(tableId)
  const { tables } = useTables(sectionId)
  const { rows, columns, loading: loadingLabels } = useRowsNColumns(tableId)
  const [activeRowTags, setActiveRowTags] = useLocalStorage<string[]>('metaTags', [])

  const shouldFetchData = !hasEmpty(dataSourceId, dataSectionId, dataTableId, transformationId)
  const isInitialState = dataId === null && sectionId === null && tableId === null
  const isLoading = transformationId != null ? loadingLabels : false
  const filteredRows = rows.filter((row, rowIndex) => {
    if (!tableDataFrame?.rowsMeta) {
      return true
    }
    return activeRowTags.includes(tableDataFrame.rowsMeta[rowIndex])
  })
  const [form] = useForm<DataSourceForm>()

  useEffect(() => {
    if (tableId != null) {
      setTableViewDisabled(false)
    } else {
      setTableViewDisabled(true)
    }
  }, [tableId])
  // is called before `onFormFinish()` to show a warning if needed
  const onFormSubmit = async () => {
    let actions = []
    if (transformationId) {
      actions = await getDataActions(transformationId)
    }
    if (selectedObject?.data?.indexing || selectedObject?.data?.analytics) {
      setIsWarningModalVisible(true)
    } else {
      form.submit()
    }
  }

  /** Returns number format used in Excel file to be used for a table shape */
  const getFormatCodeFromDataTable = (rows: number[], columns: number[]): string | undefined => {
    if (selectedObject.type !== 'table') {
      return
    }
    const numberFormat2DArray: string[][] = tableDataFrame?.tableMeta?.numberFormat
    if (!numberFormat2DArray) {
      return
    }
    let formatCode = ''
    let isConsistent = true
    rows.forEach(row => {
      if (!isConsistent) {
        return
      }
      columns.forEach(col => {
        let currentCode = numberFormat2DArray?.[row]?.[col]
        // @ symbol is used for cells that have no values, we ignore them
        if (currentCode !== '@') {
          // if formatCode changes across the data set we ignore it
          if (formatCode && currentCode !== formatCode) {
            isConsistent = false
            return
          }
          formatCode = currentCode
        }
      })
    })
    return isConsistent ? formatCode : null
  }

  // this function is called by form.submit()
  const onFormFinish = async (formValues: DataSourceForm) => {
    const { tableId, sectionId, dataSourceId, columns, rows, rowTags } = formValues
    setLastInsertData({ tableId, sectionId, dataSourceId })
    // columns and rows must be arrays which is not the case when Select's mode is not defined
    const colArray = Array.isArray(columns) ? columns : [columns]
    const rowArray = Array.isArray(rows) ? rows : [rows]
    const payload = {
      data_table: tableId,
      columns: colArray.sort(),
      rows: rowArray.sort(),
      rowTags
    }
    const numberFormat = getFormatCodeFromDataTable(payload.rows, payload.columns)
    if (transformationId) {
      try {
        const response = await updateDataTransformation(transformationId, payload)
        dispatch(updateShapeData(selectedObject.id, response.shapeData, false))
        const significanceTest = {
          significanceTest: selectedObject.visualSettings?.significanceTest || {}
        }
        if (numberFormat) {
          dispatch(
            updateShapeVisualSettings(
              selectedObject.id,
              { numberFormat, ...significanceTest },
              false
            )
          )
        } else {
          dispatch(updateShapeVisualSettings(selectedObject.id, significanceTest))
        }
        mutate()
      } catch (error) {
        console.error(error.message)
      }
    } else {
      try {
        const response = await createDataTransformation({ ...payload, shape: shapeId })
        dispatch(updateShapeData(selectedObject.id, response.shapeData))
        if (numberFormat) {
          dispatch(updateShapeVisualSettings(selectedObject.id, { numberFormat }, false))
        } else if (selectedObject.visualSettings) {
          dispatch(updateShapeVisualSettings(selectedObject.id, null))
        }
      } catch (error) {
        console.error(error.message)
      }
    }
  }

  const onChange = (key: string, value: number | number[] | string[]) => {
    switch (key) {
      case 'dataSourceId':
        form.resetFields(['sectionId', 'tableId', 'columns', 'rows', 'rowTags'])
        setDataId(value as number)
        const sections = dataSources.find(source => source.id === value)?.dataSections
        setSections(sections)
        if (sections.length === 1) {
          setSectionId(sections[0].id)
          form.setFieldsValue({ sectionId: sections[0].id })
        } else {
          setSectionId(null)
          setTableId(null)
        }
        break
      case 'sectionId':
        setTableId(null)
        form.resetFields(['tableId', 'columns', 'rows', 'rowTags'])
        setSectionId(value as number)
        break
      case 'tableId':
        form.resetFields(['columns', 'rows', 'table_meta_value', 'row_meta_value', 'rowTags'])
        setTableId(value as number)
        break
      case 'rowTags':
        form.setFieldsValue({ rowTags: value as string[] })
        form.resetFields(['columns', 'rows'])
        setActiveRowTags(value as string[])
        break
      case 'columns':
        if (selectColumnsMode !== 'multiple') {
          form.setFieldsValue({ [key]: [value] })
        } else {
          const sortedIds = (value as number[]).sort(function (a, b) {
            return a - b
          })
          form.setFieldsValue({ [key]: sortedIds })
        }
        break
      case 'rows':
        const sortedIds = (value as number[]).sort(function (a, b) {
          return a - b
        })
        form.setFieldsValue({ [key]: sortedIds })
        break
    }
  }

  const clearDataSelections = async () => {
    try {
      await resetShape({ projectId, shapeId })
      form.resetFields()
      dispatch(
        updateOneCanvasObject({
          id: selectedObject.id,
          changes: { data: null, visualSettings: null }
        })
      )
    } catch (e) {
      console.error(`An error occurred while attempting to clear data selections: ${e.message}`)
    }
  }

  const onWarningModalOk = () => {
    form.submit()
    setIsWarningModalVisible(false)
  }

  /**
   * Reset form-related local states to inital values
   * HEADS UP: it works in conjunction with the `useEffect()` that sets form values for shapes with mapped data
   */
  useEffect(() => {
    setDataId(null)
    setSectionId(null)
    setTableId(null)
    form.resetFields()
  }, [transformationId, form, selectedObject])

  useEffect(() => {
    // run only when data is not mapped
    if (transformationId == null && isInitialState && dataSources.length > 0) {
      let dataSourceId = dataSources[dataSources.length - 1].id
      onChange('dataSourceId', dataSourceId)
    }
  }, [isInitialState, dataSources, shapeId, transformationId])

  // set fields value for shapes with mapped data
  useEffect(() => {
    if (dataTransformation && shouldFetchData && isInitialState) {
      setDataId(dataSourceId)
      setSectionId(dataSectionId)
      setTableId(dataTableId)
      setActiveRowTags(dataTransformation?.rowTags)
      form.setFieldsValue({
        dataSourceId,
        tableId: dataTableId,
        sectionId: dataSectionId,
        columns: dataTransformation.columns,
        rows: dataTransformation.rows,
        rowTags: dataTransformation.rowTags
      })
    }
  }, [
    dataTransformation,
    shouldFetchData,
    isInitialState,
    dataSourceId,
    dataSectionId,
    dataTableId,
    form
  ])

  const onCreateOrUpdateFromTable = (values, updateHappened: boolean) => {
    if (updateHappened) {
      if (selectColumnsMode === 'multiple') {
        form.setFieldsValue({ columns: values['columns'], rows: values['rows'] })
        form.setFieldsValue({ rowTags: values.rowTags })
      } else {
        form.setFieldsValue({ columns: values['columns'][0], rows: values['rows'] })
        form.setFieldsValue({ rowTags: values.rowTags })
      }
      onFormSubmit()
    }
    setShowTableView(false)
  }

  useEffect(() => {
    const sects = dataSources.find(source => source.id === dataId)?.dataSections
    if (sects) {
      setSections(sects)
    }
  }, [dataId, dataSources])

  const onReloadLastTable = () => {
    if (lastInsertData) {
      setDataId(lastInsertData.dataSourceId)
      setSectionId(lastInsertData.sectionId)
      setTableId(lastInsertData.tableId)
      form.setFieldsValue({
        dataSourceId: lastInsertData.dataSourceId,
        tableId: lastInsertData.tableId,
        sectionId: lastInsertData.sectionId
      })
    }
  }

  const disabledReloadLastTableButton = !lastInsertData

  return (
    <Spin spinning={isLoading}>
      <Form form={form} onFinish={onFormFinish} layout="vertical">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form.Item
            name="dataSourceId"
            rules={[{ required: true }]}
            label={i18n.t('editor.right-panel.data-source.form.data-sources')}
          >
            <Select<string | number, { value: string; children: string }>
              showSearch
              placeholder="Search data sources"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option!.children[option!.children.length - 1]
                  ?.toLowerCase()
                  ?.indexOf(input.toLowerCase()) >= 0
              }
              dropdownMatchSelectWidth={false}
              dropdownStyle={{
                display: uploaderVisible ? 'inline-block' : 'none',
                width: 'fit-content',
                maxWidth: '33%',
                minWidth: '15%',
                maxHeight: '50vh'
              }}
              dropdownAlign={{
                points: ['tr', 'tl'], //align dropdown's top-right to top-left of input element
                offset: [-5, 0] //align offset
              }}
              onDropdownVisibleChange={open => setUploaderVisible(open)}
              onChange={(id: number) => onChange('dataSourceId', id)}
              notFoundContent={
                <EmptyDataSourceUploader onClose={() => setUploaderVisible(false)} />
              }
            >
              {dataSources.slice(0).map((source, index) => (
                <Select.Option value={source.id} key={source.id}>
                  {index + 1}. {source.filename}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.dataSourceId !== curValues.dataSourceId
            }
            noStyle
          >
            <Form.Item
              name="sectionId"
              rules={[{ required: true }]}
              label={i18n.t('editor.right-panel.data-source.form.sheet')}
              hidden={sections.length <= 1}
            >
              <Select<string | number, { value: string; children: string }>
                showSearch
                placeholder="Search sections"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(id: number) => onChange('sectionId', id)}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{
                  display: sectionsVisible ? 'inline-block' : 'none',
                  width: 'fit-content',
                  maxWidth: '33%',
                  minWidth: '15%'
                }}
                dropdownAlign={{
                  points: ['tr', 'tl'], //align dropdown's top-right to top-left of input element
                  offset: [-5, 0] //align offset
                }}
                onDropdownVisibleChange={open => setSectionsVisible(open)}
              >
                {sections.map(({ id, name }) => (
                  <Select.Option value={id} key={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form.Item>

          <Form.Item
            name="tableId"
            rules={[{ required: true }]}
            label={i18n.t('editor.right-panel.data-source.form.tables')}
          >
            <Select<string | number, { value: string; children: string }>
              showSearch
              placeholder="Search tables"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option!.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
              }
              onChange={(index: number) => onChange('tableId', index)}
              dropdownMatchSelectWidth={false}
              dropdownAlign={{
                points: ['tr', 'tl'], //align dropdown's top-right to top-left of input element
                offset: [-5, 0] //align offset
              }}
              dropdownStyle={{
                display: tablesDropdownVisible ? 'inline-block' : 'none',
                width: 'fit-content',
                maxWidth: '33%',
                minWidth: '15%'
              }}
              onDropdownVisibleChange={bool => setTablesDropdownVisible(bool)}
            >
              {tables.map(({ id, name }) => (
                <Select.Option value={id} key={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Space>
            <Text style={{ fontSize: 13 }}>Show table view</Text>
            {tableViewDisabled ? (
              <Text disabled>
                <TableOutlined />
              </Text>
            ) : (
              <Link>
                <TableOutlined onClick={() => setShowTableView(true)} />
              </Link>
            )}
          </Space>

          <Form.Item>
            <Button
              style={{ fontSize: 13, paddingLeft: 0 }}
              onClick={onReloadLastTable}
              type="text"
              disabled={disabledReloadLastTableButton}
            >
              <ReloadOutlined /> Reload last table
            </Button>
          </Form.Item>

          <Form.Item name="rowTags" label="Data type" hidden={!tableDataFrame?.rowsMeta}>
            <MultiSelectDropdown
              onChange={(values: string[]) => onChange('rowTags', values)}
              dropdownRender={menu =>
                selectRowsMode ? (
                  <DropDownMenu
                    menu={menu}
                    onSelect={() => form.setFieldsValue({ rows: rows.map(({ index }) => index) })}
                    onClear={() => form.setFieldsValue({ rows: [] })}
                    onOk={() => setRowTagsDropdownVisible(false)}
                    onCancel={() => {
                      form.setFieldsValue({ rows: [] })
                      setRowTagsDropdownVisible(false)
                    }}
                  />
                ) : (
                  menu
                )
              }
              align="left"
              visible={rowTagsDropdownVisible}
              setVisible={setRowTagsDropdownVisible}
              placeholder="Filter row tags"
              mode={selectRowsMode}
            >
              {(uniq(tableDataFrame?.rowsMeta) || []).map((tag, index) => (
                <Select.Option
                  value={tag}
                  key={index}
                  style={{
                    whiteSpace: 'normal',
                    height: 'auto',
                    wordWrap: 'break-word',
                    wordBreak: 'break-all'
                  }}
                >
                  {tag}
                </Select.Option>
              ))}
            </MultiSelectDropdown>
          </Form.Item>

          <Form.Item
            name="columns"
            rules={[{ required: true }]}
            label={i18n.t('editor.right-panel.data-source.form.columns')}
            shouldUpdate
          >
            <MultiSelectDropdown
              onChange={(id: number) => onChange('columns', id)}
              mode={selectColumnsMode}
              optionFilterProp="children"
              dropdownRender={menu =>
                selectColumnsMode ? (
                  <DropDownMenu
                    menu={menu}
                    onSelect={() =>
                      form.setFieldsValue({ columns: columns.map(({ index }) => index) })
                    }
                    onClear={() => form.setFieldsValue({ columns: [] })}
                    onOk={() => setColumnsDropdownVisible(false)}
                    onCancel={() => {
                      form.setFieldsValue({ columns: [] })
                      setColumnsDropdownVisible(false)
                    }}
                  />
                ) : (
                  menu
                )
              }
              align="left"
              visible={columnsDropdownVisible}
              setVisible={setColumnsDropdownVisible}
              placeholder="Search columns"
            >
              {columns.map(column => (
                <Select.Option
                  style={{ whiteSpace: 'normal', height: 'auto' }}
                  value={column.index}
                  key={column.name}
                >
                  {column.name}
                </Select.Option>
              ))}
            </MultiSelectDropdown>
          </Form.Item>

          <Form.Item
            name="rows"
            rules={[{ required: true }]}
            label={i18n.t('editor.right-panel.data-source.form.rows')}
          >
            <MultiSelectDropdown
              onChange={(id: number) => onChange('rows', id)}
              dropdownRender={menu =>
                selectRowsMode ? (
                  <DropDownMenu
                    menu={menu}
                    onSelect={() =>
                      form.setFieldsValue({ rows: filteredRows.map(({ index }) => index) })
                    }
                    onClear={() => form.setFieldsValue({ rows: [] })}
                    onOk={() => setRowsDropdownVisible(false)}
                    onCancel={() => {
                      form.setFieldsValue({ rows: [] })
                      setRowsDropdownVisible(false)
                    }}
                  />
                ) : (
                  menu
                )
              }
              align="left"
              visible={rowsDropdownVisible}
              setVisible={setRowsDropdownVisible}
              placeholder="Search rows"
              mode={selectRowsMode}
            >
              {filteredRows.map(row => (
                <Select.Option
                  value={row.index}
                  key={row.name}
                  style={{
                    whiteSpace: 'normal',
                    height: 'auto',
                    wordWrap: 'break-word',
                    wordBreak: 'break-all'
                  }}
                >
                  {row.name}
                </Select.Option>
              ))}
            </MultiSelectDropdown>
          </Form.Item>
        </Space>

        <Flex justifyContent="center" flexDirection="column" className="form-button-group">
          <Button style={{ marginTop: '0.6em' }} onClick={() => onFormSubmit()} type="primary">
            {i18n.t('editor.right-panel.data-source.form.submit')}
          </Button>
          <Button style={{ marginTop: '0.6em' }} type="ghost" onClick={clearDataSelections}>
            <Icon name="sync-alt" style={{ marginRight: '2%' }} />
            {i18n.t('editor.right-panel.data-source.form.clear-data')}
          </Button>
        </Flex>
      </Form>

      <CommonModal
        title="Update shape data"
        visible={isWarningModalVisible}
        okText="Ok"
        onOk={() => onWarningModalOk()}
        onCancel={() => setIsWarningModalVisible(false)}
      >
        All analytics and data transformations that were applied to the shape will be lost. Are you
        sure you want to continue?
      </CommonModal>

      {showTableView && (
        <TableView
          show={showTableView}
          tableDataFrame={tableDataFrame}
          dataTransformation={dataTransformation}
          activeRowTags={activeRowTags}
          setActiveRowTags={setActiveRowTags}
          resetSelection={dataTableId === tableId}
          onFinish={(values, updateHappened) => onCreateOrUpdateFromTable(values, updateHappened)}
          onCancel={() => setShowTableView(false)}
        />
      )}
    </Spin>
  )
}

export default DataSourceSelector
