import { Space, Tooltip } from 'antd'

import { DeleteFilled, EditOutlined, WarningOutlined } from '@ant-design/icons'
import i18n from 'i18next'

import { Action, ActionTypes } from './types'

import './styles.less'

export interface ActionListProps {
  onEdit: (action: Action) => void
  onDelete: (id: number, actionType: string) => void
  queue: Action[]
}

const ActionList = ({ onEdit, onDelete, queue }: ActionListProps) => {
  const actionMapping = {
    [ActionTypes.FILTER]: i18n.t(
      'editor.right-panel.data-source.form.data-transformation.actions.filter'
    ),
    [ActionTypes.REORDER]: i18n.t(
      'editor.right-panel.data-source.form.data-transformation.actions.reorder'
    ),
    [ActionTypes.REVERSE]: i18n.t(
      'editor.right-panel.data-source.form.data-transformation.actions.reverse'
    ),
    [ActionTypes.SORTBY]: i18n.t(
      'editor.right-panel.data-source.form.data-transformation.actions.sort-by'
    ),
    [ActionTypes.TRANSPOSE]: i18n.t(
      'editor.right-panel.data-source.form.data-transformation.actions.transpose'
    ),
    [ActionTypes.TOP_AND_BOTTOM]: i18n.t(
      'editor.right-panel.data-source.form.data-transformation.actions.trim'
    ),
    [ActionTypes.COPY_ORDER]: i18n.t(
      'editor.right-panel.data-source.form.data-transformation.actions.rearrange'
    ),
    [ActionTypes.STRING_FILTER]: i18n.t(
      'editor.right-panel.data-source.form.data-transformation.actions.string_filter'
    ),
    [ActionTypes.SHIFT_LABELS]: i18n.t(
      'editor.right-panel.data-source.form.data-transformation.actions.shift_labels'
    )
  }

  const handleOnDelete = (id: number, actionType: string) => {
    onDelete(id, actionType)
  }

  return (
    <ul style={{ listStyle: 'none' }}>
      {queue.map((action, index) => (
        <li key={action.id} className="action-list-item">
          <span>{actionMapping[action.actionType]}</span>
          <Space>
            {action.valid === false && (
              <Tooltip title="This transformation has invalid parameters and will not take effect until edited">
                <WarningOutlined style={{ cursor: 'pointer' }} />
              </Tooltip>
            )}
            {action.actionType !== ActionTypes.TRANSPOSE &&
              action.actionType !== ActionTypes.COPY_ORDER && (
                <EditOutlined onClick={() => onEdit(action)} />
              )}
            <DeleteFilled
              style={{ color: '#D0342C' }}
              onClick={() => handleOnDelete(action.id, action.actionType)}
            />
          </Space>
        </li>
      ))}
    </ul>
  )
}

export default ActionList
