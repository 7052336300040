import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import axios, { CancelTokenSource } from 'axios'
import { fabric } from 'fabric'

import { selectCurrentProjectId } from '@store/slices/projects/selectors'
import { selectTemplateIds } from '@store/slices/template/selectors'

import { getTemplateAsset } from '@services/template-service'

import { useAngleHandler } from '@components/canvas/handlers/objects/useAngleHandler'
import { useCommonPropsHandler } from '@components/canvas/handlers/objects/useCommonPropsHandler'
import { useFiltersHandler } from '@components/canvas/handlers/objects/useFiltersHandler'
import { useShadowHandler } from '@components/canvas/handlers/objects/useShadowHandler'
import { useSourceHandler } from '@components/canvas/handlers/objects/useSouceHandler'
import { useVisibilityHandler } from '@components/canvas/handlers/objects/useVisibilityHandler'
import { useCanvas } from '@components/canvas/hooks/useCanvas'
import { CanvasComponentProps } from '@components/canvas/types/component'
import { FabricImageObject, FabricImageOptions } from '@components/canvas/types/image'

import { useFabricObject } from '../utils/useFabricObject'

const imageFactory = (options: FabricImageOptions): FabricImageObject => {
  const image = new Image(
    options.width / (options.width / options.scaleX / 960 / 1.5),
    options.height / (options.height / options.scaleY / 540 / 1.5)
  )
  return new fabric.Image(image, options as any) as FabricImageObject
}

//note much of the logic in the app assumed a single template
//because of that there is still a single primary template in the backend
//the first one that was uploaded
//therefore we use that for stuff such as downloading and getting image assets

const ImageComp = ({ id, options }: CanvasComponentProps<FabricImageOptions>) => {
  const factory = useCallback(() => imageFactory(options), [])
  const object = useFabricObject<FabricImageObject, FabricImageOptions>(factory, id, options)
  const [cancelToken] = useState<CancelTokenSource>(axios.CancelToken.source())
  const projectId = useSelector(selectCurrentProjectId)
  const templateIds = useSelector(selectTemplateIds)
  const canvas = useCanvas()

  useFiltersHandler(object)
  useSourceHandler(object)
  useCommonPropsHandler(object)
  useAngleHandler(object)
  useShadowHandler(object)
  useVisibilityHandler(object)

  useEffect(() => {
    return () => {
      cancelToken.cancel()
    }
  }, [])

  const processImage = async () => {
    const imageData = await loadImage()
    if (!imageData) return
    const reader = new FileReader()
    reader.readAsDataURL(imageData)
    reader.onloadend = function () {
      var base64data = reader.result
      fabric.util.loadImage(base64data as string, image => {
        object.setElement(image, options as any)
        object.set('height', options.height / options.scaleY)
        object.set('width', options.width / options.scaleX)
        object.set('top', options.top)
        object.set('left', options.left)
        object.set('fill', '')
        canvas.renderAll()
      })
    }
  }

  const loadImage = async () => {
    try {
      return await getTemplateAsset(projectId, templateIds[0], object.src, cancelToken?.token)
    } catch (e) {
      console.error(e.message)
    }
  }

  useEffect(() => {
    if (object && object.src) {
      processImage()
    }
  }, [options, object])

  return <></>
}

export default React.memo(ImageComp)
