import { useState } from 'react'

import { Button, Radio, Space, Checkbox, Typography } from 'antd'

import { Flex } from '@/components/flex'

const { Paragraph } = Typography

const WarningMessage = ({ onConfirm }) => {
  const [checked, setChecked] = useState(false)
  const [value, setValue] = useState(1)

  const onChange = e => {
    setValue(e.target.value)
  }

  const onOk = () => {
    onConfirm(value)
  }

  return (
    <>
      <Paragraph>
        <strong>Caution:</strong> You are about to replace data from one file with another. This
        will overwrite the old data, and you won't be able to get it back.
      </Paragraph>
      <Paragraph>We suggest making a copy of your project before you proceed.</Paragraph>
      <Paragraph>Do you want to proceed?</Paragraph>
      <Flex flexDirection="column" style={{ padding: '0.6rem 0', rowGap: '0.5rem' }}>
        <Checkbox onChange={e => setChecked(e.target.checked)}>
          I understand and want to proceed
        </Checkbox>
        <Space direction="vertical" size="large" style={{ paddingTop: '0.5rem' }}>
          <Radio.Group onChange={onChange} value={value} disabled={!checked}>
            <Space direction="vertical">
              <Radio value={1}>
                <strong>Label-based</strong>: Replace data-tables by matching table names
              </Radio>
              <Radio value={0}>
                <strong>Index-based</strong>: Replace data-tables based on table position
              </Radio>
            </Space>
          </Radio.Group>
        </Space>
        <Flex justifyContent="center" style={{ paddingTop: '20px' }}>
          <Button disabled={!checked} type="primary" onClick={onOk}>
            Select file for switch data
          </Button>
        </Flex>
      </Flex>
    </>
  )
}

export default WarningMessage
