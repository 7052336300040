import 'antd/dist/antd.css'

import './CommonModal.css'
import React from 'react'

const CommonDetail = ({ className, style, children }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div
        className={`${className}`}
        style={{ height: 'fit-content', overflow: 'hidden', ...style }}
      >
        {children}
      </div>
    </div>
  )
}

export default CommonDetail
