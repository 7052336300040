import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '@store/rootReducer'
import { updateOneCanvasObject } from '@store/slices/shapes/actions'
import { selectCanvasObjectProperty } from '@store/slices/shapes/selectors'

import { useCanvas } from '@components/canvas/hooks/useCanvas'
import { FabricObject } from '@components/canvas/types/object'

export function useAngleHandler(object: FabricObject) {
  const selectObjProperty = useMemo(() => selectCanvasObjectProperty, [])
  const selectedObjectAngle = useSelector((s: RootState) =>
    selectObjProperty(s, object?.id, 'angle')
  )
  const canvas = useCanvas()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!object || !selectedObjectAngle) {
      return
    }

    if (selectedObjectAngle !== object.angle) {
      object.set('rotation', selectedObjectAngle)
      object.rotate(selectedObjectAngle)
      object.setCoords()
      dispatch(
        updateOneCanvasObject({ id: object.id, changes: { top: object.top, left: object.left } })
      )
      canvas.requestRenderAll()
    }
  }, [canvas, dispatch, object, selectedObjectAngle])
}
