import React from 'react'

import { Button, Space } from 'antd'

import i18n from 'i18next'

import '../DataSource.less'

type ButtonEventType = React.MouseEventHandler<HTMLElement>

interface DropDownMenuProps {
  menu: any
  onSelect: ButtonEventType
  onClear: ButtonEventType
  onOk?: ButtonEventType
  onCancel?: ButtonEventType
}

const DropDownMenu: React.FC<DropDownMenuProps> = ({ menu, onSelect, onClear, onOk, onCancel }) => {
  return (
    <div className="data-selector">
      <div className="form__dropdown_container top">
        <Button onClick={onSelect}>
          {i18n.t('editor.right-panel.data-source.form.selectAll')}
        </Button>
        <Button onClick={onClear}>{i18n.t('editor.right-panel.data-source.form.clearAll')}</Button>
      </div>
      {menu}
      <div className="form__dropdown_container bottom">
        <Space size="small" direction="horizontal">
          <Button onClick={onOk} type="primary">
            {i18n.t('editor.right-panel.data-source.form.okButton')}
          </Button>
          <Button onClick={onCancel}>
            {i18n.t('editor.right-panel.data-source.form.cancelButton')}
          </Button>
        </Space>
      </div>
    </div>
  )
}

export default DropDownMenu
