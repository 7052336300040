import React from 'react'

import { Space, Typography } from 'antd'

import classNames from 'classnames'

import Icon from '@/components/icon/Icon'

const { Title } = Typography

type Props = {
  rightIsOpen: boolean
  leftIsOpen: boolean
  setRightIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  children: React.ReactNode
}

const RightSidePanel = ({ leftIsOpen, setRightIsOpen, rightIsOpen, children }: Props) => {
  return (
    <div className={classNames('right-sidebar', { 'lower-z-index': leftIsOpen })}>
      <div
        className={classNames('icon-container', {
          disabled: false
        })}
        onClick={() => {
          setRightIsOpen(!rightIsOpen)
        }}
      >
        <Icon
          className={classNames('icon', {
            disabled: false
          })}
          name={rightIsOpen ? 'angle-right' : 'angle-left'}
        />
      </div>
      <div className={classNames('form', { 'is-open': rightIsOpen })}>
        {rightIsOpen && (
          <Space direction="vertical" className="data-actions-wrapper">
            <Title level={4}>Data Transformations</Title>
            {children}
          </Space>
        )}
      </div>
    </div>
  )
}

export default RightSidePanel
