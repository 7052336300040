import { cloneDeep } from 'lodash'

import { ThresholdSettings } from '@/components/imagemap/right-panel/percentage-indexing/types'
import {
  VisualisationOptions,
  VisualisationSymbols
} from '@/components/imagemap/right-panel/sig-testing/VisualisationSettings'

export function addHeadersToDataGrid(
  data: number[][],
  columns: string[] = null,
  rows: string[] = null
) {
  let rv = []
  if (columns !== null) {
    data.forEach((a, idx) => {
      let b = [rows[idx], ...a]
      rv.push(b)
    })
  }
  if (rows !== null) {
    rv.unshift([' ', ...columns])
  }
  if (rows === null && columns === null) {
    return data
  }
  return rv
}

export function removeHeadersFromDataGrid(
  data: (number | string)[][],
  columns = true,
  rows = true
) {
  let c = cloneDeep(data)
  if (columns) {
    c.splice(0, 1)
  }
  if (rows) {
    c = c.map(a => {
      a.splice(0, 1)
      return a
    })
  }
  return c
}

export function getSigTestVisualisationSettings(
  settings: VisualisationOptions,
  sign: string
): {
  tokens: string
  background: string
  color: string
  coloredValue: boolean
  position: 'top' | 'bottom' | 'right' | 'left'
} {
  if (!settings) {
    return { tokens: null, background: null, color: null, coloredValue: null, position: 'right' }
  }
  let token: string = null
  let color: string = '#666'
  let position: 'top' | 'bottom' | 'right' | 'left' = settings.position

  if (sign === null || sign === '') {
    return { tokens: null, background: null, color: null, coloredValue: false, position: null }
  }

  if (settings.symbol === 'arrow') {
    if (sign === '+') token = '↑'
    if (sign === '-') token = '↓'
  }

  if (settings.symbol === 'pyramid') {
    if (sign === '+') token = '▲'
    if (sign === '-') token = '▼'
  }

  if (sign === '+') {
    color = settings.significantColor
  }

  if (sign === '-') {
    color = settings.insignificantColor
  }

  switch (settings.visualise) {
    case 'cell-background':
      return {
        tokens: null,
        background: color,
        color: null,
        coloredValue: false,
        position: position
      }

    case 'symbols':
      return { tokens: token, background: null, color, coloredValue: false, position: position }

    case 'colored-labels':
      return { tokens: null, background: null, color, coloredValue: true, position: position }

    case 'symbols-colored-labels':
      return { tokens: token, background: null, color, coloredValue: true, position: position }

    default:
      return { tokens: null, background: null, color: null, coloredValue: null, position: position }
  }
}

export function getIndexingVisualisationSettings(
  settings: ThresholdSettings,
  indexingResult: number
) {
  let tokens = null

  let { symbol, visualise, color = '#666' } = settings
  tokens = VisualisationSymbols[symbol]

  switch (visualise) {
    case 'cell-background':
      return { tokens: null, background: color, color: null, coloredValue: false }

    case 'symbols':
      return { tokens, background: null, color, coloredValue: false }

    case 'colored-labels':
      return { tokens: null, background: null, color, coloredValue: true }

    case 'symbols-colored-labels':
      return { tokens, background: null, color, coloredValue: true }

    case 'values-colored-labels':
      return {
        tokens: indexingResult === null ? null : `(${Math.round(indexingResult)})`,
        background: null,
        color,
        coloredValue: false
      }
  }
}

export function zoomIt(canvas, factor) {
  canvas.setHeight(canvas.getHeight() * factor)
  canvas.setWidth(canvas.getWidth() * factor)
  if (canvas.backgroundImage) {
    // Need to scale background images as well
    var bi = canvas.backgroundImage
    bi.width = bi.width * factor
    bi.height = bi.height * factor
  }
  var objects = canvas.getObjects()
  for (var i in objects) {
    var scaleX = objects[i].scaleX
    var scaleY = objects[i].scaleY
    var left = objects[i].left
    var top = objects[i].top

    var tempScaleX = scaleX * factor
    var tempScaleY = scaleY * factor
    var tempLeft = left * factor
    var tempTop = top * factor

    objects[i].scaleX = tempScaleX
    objects[i].scaleY = tempScaleY
    objects[i].left = tempLeft
    objects[i].top = tempTop

    objects[i].setCoords()
  }
  canvas.renderAll()
  canvas.calcOffset()
}
