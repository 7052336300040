import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Layout, Menu, Spin } from 'antd'

import { selectCurrentUser } from '@/store/slices/auth/selectors'
import { AppDispatch } from '@/store/store'
import { getCurrentUser } from '@store/slices/auth/thunks'

import { Navbar } from '@/components/common/Navbar'

import LoginAndSecurity from './LoginAndSecurity'

const { Header, Content, Sider } = Layout

const AccountSettings = () => {
  const [loading, setLoading] = useState(false)
  const currentUser = useSelector(selectCurrentUser)
  const dispatch: AppDispatch = useDispatch()

  useEffect(() => {
    const getUser = async () => {
      if (currentUser === null) {
        await dispatch(getCurrentUser())
      }
    }
    getUser()
  }, [getCurrentUser])

  return currentUser !== null ? (
    <Layout className="layout" style={{ height: '100%', overflowY: 'auto' }}>
      <Spin spinning={loading}>
        <Navbar onPage="account-settings" />
        <Layout style={{ height: '100%', background: 'white', minHeight: 'calc(100vh - 64px)' }}>
          <Sider width={250} style={{ height: '100%' }}>
            <Menu
              mode="inline"
              style={{ height: '100%', paddingTop: '2rem' }}
              defaultSelectedKeys={['1']}
            >
              <Menu.Item key="1">Login and Security</Menu.Item>
            </Menu>
          </Sider>
          <Layout style={{ padding: '1rem 2rem' }}>
            <Content style={{ padding: '2rem 0 0 2rem', background: 'white' }}>
              <LoginAndSecurity />
            </Content>
          </Layout>
        </Layout>
      </Spin>
    </Layout>
  ) : null
}

export default AccountSettings
