import { useSelector } from 'react-redux'

import axios from 'axios'
import useSWR from 'swr'

import { selectCanvasSelectedObjectDataDefinition } from '@/store/slices/shapes/selectors'
import { DefaultDataDefinition } from '@/store/slices/shapes/types'

import { DataTransformationDto } from '@/interfaces/data-transformations'

const useDataTransformation = (transformationId: number) => {
  const dataDefinition = useSelector(
    selectCanvasSelectedObjectDataDefinition
  ) as DefaultDataDefinition
  const dataTransformationId =
    transformationId !== null ? transformationId : dataDefinition?.transformationId
  const fetcher = async url => (await axios.get(url)).data
  const {
    data: dataTransformation,
    error,
    mutate
  } = useSWR<DataTransformationDto>(
    () => (dataTransformationId != null ? `/api/data_selection/${dataTransformationId}` : null),
    fetcher
  )
  return {
    dataTransformation,
    mutate,
    loading: transformationId != null && !dataTransformation && !error,
    error: error
  }
}

export { useDataTransformation }
