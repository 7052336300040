import { RootState } from '@store/rootReducer'

export function selectCanvasInteractionMode(state: RootState) {
  return state.canvas.canvas.interactionMode
}

export function selectCanvasLoading(state: RootState) {
  return state.canvas.canvas.loading
}

export function selectCanvasLoadingMessage(state: RootState) {
  return state.canvas.canvas.loadingMsg
}

export function selectCanvasCenterPoint(state: RootState) {
  return state.canvas.canvas.centerPoint
}

export function selectCanvasWidth(state: RootState) {
  return state.canvas.canvas.width
}

export function selectCanvasHeight(state: RootState) {
  return state.canvas.canvas.height
}

export function selectCanvasFilter(state: RootState) {
  return state.canvas.canvas.filter
}
