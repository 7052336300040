import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'

import { CheckCircleOutlined, CloseCircleOutlined, WarningOutlined } from '@ant-design/icons'

import { selectCurrentProjectId } from '@/store/slices/projects/selectors'

import { updateTextInterpolation } from '@/services/data-transfomations-service'
import { updateDataTransformation } from '@services/data-transfomations-service'
import { resetShape } from '@/services/shape-service'

import CommonModal from '@/components/common/CommonModal'
import Info from '@/components/common/Info'

import { IInterpolation } from '@/interfaces/data-transformations'

import { useLocalStorage } from '@/hooks'

import TableView from '../right-panel/data-source/TableView'
import { useDataTable, useDataTransformation } from '../right-panel/hooks'

export interface ReportEntry {
  entryType: 'data_selection' | 'interpolalation'
  dataTableId: number
  dataTableName: number
  index: number
  compatible: 0 | 1 | 2
  slideId: number
  slideIndex: number
  shapeId?: number
  shapeName: string
  id?: number
}

const createTableColumns = (
  editable: boolean,
  onEdit: (reportEntry: ReportEntry) => void,
  onDelete: (reportEntry: ReportEntry) => void,
  filteredValue: number[]
): ColumnsType<ReportEntry> => {
  return [
    {
      key: 0,
      title: 'Slide Index',
      render: (_, record) => record.slideIndex + 1
    },
    { key: 1, title: 'Table Name', dataIndex: 'dataTableName' },
    {
      key: 2,
      title: 'Compatible',
      filters: [
        { text: 'Compatible', value: 1 },
        { text: 'Incompatible', value: 0 },
        { text: 'Partially compatible', value: 2 }
      ],
      filteredValue: filteredValue,
      onFilter: (value: number, record) => {
        return record.compatible === value
      },
      render: (_, record) =>
        record.compatible === 1 ? (
          <CheckCircleOutlined style={{ fontSize: '18px', color: '#52c41a' }} />
        ) : record.compatible === 0 ? (
          <CloseCircleOutlined style={{ fontSize: '18px', color: '#f5222d' }} />
        ) : (
          <WarningOutlined style={{ fontSize: '18px', color: '#ffc069' }} />
        )
    },
    {
      key: 3,
      title: 'Shape Name',
      render: (_, record) => <span>{record.shapeName}</span>
    },
    {
      key: 4,
      title: 'Reconcile',
      render: (_, record) =>
        (editable && record.compatible === 0) || record.compatible === 2 ? (
          <>
            <span onClick={() => onEdit(record)} style={{ cursor: 'pointer', color: '#1890ff' }}>
              Edit
            </span>
            <span
              onClick={() => onDelete(record)}
              style={{ cursor: 'pointer', color: '#1890ff', marginLeft: '8px' }}
            >
              Delete
            </span>
          </>
        ) : null
    }
  ]
}

type UpdataDataPayload = {
  rows: number[]
  columns: number[]
  rowTags: string[]
  sourceType?: 'data' | 'meta'
}

type ReportProps = {
  report: Record<number, ReportEntry> | null
  visible: boolean
  onDeleteEntry: (id: number, tableId: number) => void
  onEditEntry: (id: number, tableId: number) => void
  onFinish: () => void
  editable?: boolean
  rowTags?: string[]
}

const Report = ({
  report,
  onEditEntry,
  onDeleteEntry,
  onFinish,
  visible,
  editable = true,
  rowTags = null
}: ReportProps) => {
  rowTags = rowTags || null
  const dispatch = useDispatch()
  const projectId = useSelector(selectCurrentProjectId)
  const [showTableView, setShowTableView] = useState(false)
  const [dataTableId, setDataTableId] = useState<number>(null)
  const [entryId, setEntryId] = useState<number>(null)
  const [entryType, setEntryType] = useState<string>()
  const [activeRowTags, setActiveRowTags] = useState<string[]>([])
  const [filterColumnValue, setFilterColumnValue] = useLocalStorage<number[]>(
    'filterColumnValue',
    []
  )
  const { data: dataTable, loading: tableLoading } = useDataTable(dataTableId)
  const { dataTransformation, loading: dataSelectionLoading } = useDataTransformation(
    entryType === 'data_selection' ? entryId : null
  )
  const loading = dataSelectionLoading || tableLoading

  const dataSource: ReportEntry[] = Object.values(report || {})
    .flat()
    .sort((a, b) => a.slideIndex - b.slideIndex)

  const onClickEdit = (record: ReportEntry) => {
    setDataTableId(record.dataTableId)
    setEntryId(record.id || null)
    setEntryType(record.entryType)
    setActiveRowTags(rowTags)
  }

  const onClickDelete = (record: ReportEntry) => {
    resetShape({ projectId: projectId, shapeId: record.shapeId })
    onDeleteEntry(record.id, record.dataTableId)
  }

  const onTableChange = (_, filtersValue) => {
    setFilterColumnValue(filtersValue[2])
  }

  const columns = createTableColumns(editable, onClickEdit, onClickDelete, filterColumnValue)

  const onCleanup = () => {
    setDataTableId(null)
    setEntryId(null)
    setActiveRowTags([])
  }

  const updateInterpolation = async (payload: Partial<IInterpolation>) => {
    if (entryId) {
      await updateTextInterpolation(entryId, {
        column: payload.column,
        row: payload.row,
        sourceType: payload.sourceType
      })
      onEditEntry(entryId, dataTableId)
    }
    setShowTableView(false)
    onCleanup()
  }

  const updateData = (payload: UpdataDataPayload) => {
    if (entryType === 'interpolation') {
      updateInterpolation({
        row: payload.rows[0],
        column: payload.columns[0],
        sourceType: payload.sourceType
      })
    }
    if (entryType === 'data_selection') {
      updateDataSelection(payload)
    }
  }

  const updateDataSelection = async (payload: UpdataDataPayload) => {
    if (entryId && dataTransformation) {
      await updateDataTransformation(entryId, {
        columns: payload.columns,
        rows: payload.rows,
        rowTags: payload.rowTags || []
      })
      onEditEntry(entryId, dataTableId)
    }
    setShowTableView(false)
    onCleanup()
  }

  const onCloseTableView = () => {
    setShowTableView(false)
    onCleanup()
  }

  const popoverContent = (
    <div>
      <h4>
        <strong>Function</strong>
      </h4>
      <p>A report detailing the outcome of the Repeat function</p>
      <h4>
        <strong>How to use</strong>
      </h4>
      <p>
        Slide index: The number of the slide(s) as it will appear in the left-hand thumbnail column
        <br />
        Table name: The name of the data table used on the duplicated slide(s)
        <br />
        Compatible: A green tick indicates the object has been mapped into the duplicated slide
        successfully. Red crosses and orange triangles indicate errors to be fixed
        <br />
        Shape Name: The specific object on a slide
        <br />
        Reconcile: Clicking “Edit” will allow the user to re-select the correct data to insert into
        the mapped object. Clicking “Delete” will remove any mapping from this object on that slide
      </p>
      <p>Click OK to confirm the function</p>
    </div>
  )

  useEffect(() => {
    if (entryId && !loading) {
      setShowTableView(true)
    }
  }, [entryId, loading])

  return (
    <>
      <CommonModal
        title={
          <>
            Compatibility Report <Info>{popoverContent}</Info>
          </>
        }
        visible={visible}
        footer={[<Button onClick={() => onFinish()}>Ok</Button>]}
        width={1000}
        onCancel={() => onFinish()}
      >
        <Table dataSource={dataSource} columns={columns} onChange={onTableChange} />
      </CommonModal>
      <TableView
        show={showTableView}
        tableDataFrame={dataTable}
        dataTransformation={dataTransformation}
        onFinish={updateData}
        onCancel={onCloseTableView}
        activeRowTags={activeRowTags || []}
        setActiveRowTags={setActiveRowTags}
        resetSelection={true}
      />
    </>
  )
}

export default Report
