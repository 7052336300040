import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { fabric } from 'fabric'

import { RootState } from '@store/rootReducer'
import { selectCanvasObjectProperty } from '@store/slices/shapes/selectors'

import { useCanvas } from '@components/canvas/hooks/useCanvas'
import { FabricImageObject } from '@components/canvas/types/image'

export function useSourceHandler(object: FabricImageObject) {
  const selectObjProperty = useMemo(() => selectCanvasObjectProperty, [])
  const src = useSelector((s: RootState) => selectObjProperty(s, object?.id, 'src'))
  const file = useSelector((s: RootState) => selectObjProperty(s, object?.id, 'file'))
  const canvas = useCanvas()

  useEffect(() => {
    if (!object || !src) {
      return
    }

    const loadImage = (obj: FabricImageObject, src: string) => {
      let url = src
      if (!url) {
        url = './images/sample/transparentBg.png'
      }
      fabric.util.loadImage(url, source => {
        obj.setElement(source)
        canvas.renderAll()
      })
    }

    if (src !== object.src) {
      loadImage(object, src)
      object.set('src', src)
    }
  }, [canvas, object, src])

  useEffect(() => {
    if (!object || !file) {
      return
    }

    if (file !== object.file) {
      object.set('file', file)
    }
  }, [object, file])
}
