import { useHistory } from 'react-router-dom'

import { Button, Layout } from 'antd'

import { Navbar } from '../../components/common/Navbar'

const { Header, Content } = Layout

const Home = () => {
  const history = useHistory()
  return (
    <Layout className="layout" style={{ height: '100vh' }}>
      <Header>
        <Navbar onPage="main" />
      </Header>
      <Content style={{ padding: '0 50px', height: '100%', backgroundColor: 'white' }}>
        <div
          className="home-section-intro"
          style={{
            minHeight: '100vh',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <section
            className="top-section"
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '5%'
            }}
          >
            <div className="flex-align-center" style={{ backgroundColor: 'white' }}>
              <h1
                className="title-line1"
                style={{ fontSize: '48px', fontWeight: 700, lineHeight: '56px', marginBottom: 0 }}
              >
                TURN DATA TO
              </h1>
              <h1
                className="title-line2"
                style={{ fontSize: '48px', fontWeight: 700, lineHeight: '56px', color: '#135FF6' }}
              >
                REPORTS IN SECONDS
              </h1>
              <p
                className="paragraph"
                style={{ fontFamily: 'Inter', fontSize: '18px', lineHeight: 1.6, margin: '1rem 0' }}
              >
                <strong>Fast track your PowerPoint reports.</strong> <br />
                Focus on insights, leave the grunt work to us.
              </p>
              <Button onClick={() => history.push('/projects')} type="primary" size="large">
                Get started
              </Button>
            </div>
            <div className="flex-align-center" style={{ backgroundColor: 'white' }}>
              <div className="brand-image-wrapper">
                <img
                  src="/images/report-analysis-flatline.svg"
                  alt="Happy researcher"
                  style={{ height: '560', width: 560 }}
                />
              </div>
            </div>
          </section>
        </div>
      </Content>
    </Layout>
  )
}

export default Home
