import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import { HotTable } from '@handsontable/react'
import { textRenderer } from 'handsontable/renderers'

import { useSelectCells } from '../hooks'
import { createTableContext, getMergeCells, mergeData } from '../setup'
import styles from './Table.module.css'

type Props = {
  data: (string | number)[][]
  sendSelections?: (values: [number[], number[]]) => void
  colHeaders: { name: string; span: number }[][]
  rowHeaders: string[]
  tableMeta?: object[][]
  disabledRows?: number[]
  rowHeaderWidth?: number
  width?: string
  height?: string
  selectionMode?: 'multiple' | 'range' | 'single'
  selectionStrategy?: 'column' | 'row' | 'freeform' | 'group'
  autoRowSize?: boolean
}

function colHeaderRenderer(instance, td, row, col, prop, value, cellProperties) {
  textRenderer.apply(this, arguments)
  td.style.background = '#f3f3f3'
}

function rowHeaderRenderer(instance, td, row, col, prop, value, cellProperties) {
  textRenderer.apply(this, arguments)
  td.style.background = '#f3f3f3'
}

function disabledRenderer(instance, td, row, col, prop, value, cellProperties) {
  textRenderer.apply(this, arguments)
  td.style.opacity = 0.4
  td.style.color = '#a1a1a1'
  td.style.background = '#f9f9f9'
}

const Table = (props: Props) => {
  const {
    sendSelections,
    data = [],
    colHeaders = [],
    rowHeaders = [],
    tableMeta = [],
    disabledRows = [],
    width = '100%',
    height = '100%',
    autoRowSize = true,
    selectionMode = 'multiple',
    selectionStrategy = 'freeform'
  } = props
  const tableRef = useRef(null)
  const context = useMemo(() => {
    return createTableContext(data, rowHeaders, colHeaders, tableMeta)
  }, [data, rowHeaders, colHeaders, tableMeta])
  const mergedCells = useMemo(() => {
    return getMergeCells(colHeaders, context)
  }, [colHeaders, context])

  const [afterSelectionEnd, onDeselect] = useSelectCells(
    tableRef?.current?.hotInstance,
    selectionStrategy,
    context
  )
  const afterSelection = (...args) => {
    //@ts-ignore
    let [rows, columns] = afterSelectionEnd(...args)
    rows = rows.filter(idx => !disabledRows.includes(idx))
    sendSelections([rows, columns])
  }

  const updateData = useCallback(() => {
    const mergedData = mergeData(data, rowHeaders, colHeaders, tableMeta, context)
    try {
      tableRef.current.hotInstance.updateSettings({ data: mergedData, mergeCells: mergedCells })
    } catch (e) {
      console.log(e)
    }
  }, [data, rowHeaders, colHeaders, tableMeta, context, mergedCells])

  useEffect(() => updateData(), [updateData])

  const renderCells = (row, col, ...rest) => {
    if (row >= context.header.col[0][0] && row <= context.header.col[1][0] && col !== 0) {
      return { renderer: colHeaderRenderer }
    }
    if (row >= context.header.row[0][0] && row <= context.header.row[1][0] && col === 0) {
      return { renderer: rowHeaderRenderer }
    }
    if (disabledRows.includes(row - context.data[0][0])) {
      return { renderer: disabledRenderer }
    }
  }

  return (
    <HotTable
      readOnly={true}
      readOnlyCellClassName={styles.readonly}
      ref={tableRef}
      width={width}
      height={height}
      autoRowSize={autoRowSize}
      licenseKey="non-commercial-and-evaluation"
      cells={renderCells}
      selectionMode={selectionMode}
      afterSelectionEnd={afterSelection}
      afterDeselect={onDeselect}
    />
  )
}

export default Table
