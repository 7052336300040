import { createAction } from '@reduxjs/toolkit'

import { setCanvasLoading, setCanvasLoadingMessage } from '@store/slices/canvas/canvas/actions'
import { selectCurrentProject } from '@store/slices/projects/selectors'
import { selectCurrentSlideId } from '@store/slices/slides/selectors'
import { selectTemplates } from '@store/slices/template/selectors'

import { getCeleryTaskStatus } from '@services/celery-service'
import {
  downloadProjectTemplate,
  downloadReport,
  downloadSlideProjectTemplate
} from '@services/template-service'

import { CeleryStatus } from '@/interfaces/celery'
import { TemplateDto } from '@/interfaces/template'

import { delayPromise } from '@utils/promise'

import { TemplateAddStatus } from './reducer'

export const resetTemplate = createAction('template/reset')
export const addTemplateStatus = createAction<TemplateAddStatus>('template/addTemplateStatus')
export const clearTemplateStatus = createAction('template/clearTemplateStatus')
export const setTemplate = createAction<TemplateDto[] | null>('template/setTemplate')
export const setTemplateUploadProgress = createAction<number | null>(
  'template/setTemplateUploadProgress'
)
export const removeTemplate = createAction<number>('template/removeTemplate')

export const downloadTemplate = () => async (dispatch, getState) => {
  try {
    const state = getState()
    const project = selectCurrentProject(state)
    const templates = selectTemplates(state)
    const templateId = templates[0]?.id
    if (templateId) {
      dispatch(setCanvasLoading(true))
      if (project.slideOrder.length >= 20) {
        dispatch(
          setCanvasLoadingMessage(
            "That's a large file. Please wait.| It will take a few moments to prepare your PowerPoint file."
          )
        )
      } else {
        dispatch(
          setCanvasLoadingMessage(
            "Please wait.| We are preparing your PowerPoint file.| It'll just take a moment."
          )
        )
      }
      const { taskId } = await downloadProjectTemplate(project.id, templateId)
      try {
        const getDownloadTemplateStatus = async () => {
          const response = await getCeleryTaskStatus({ taskId: taskId })
          if (response.status === CeleryStatus.SUCCESS) {
            if (response.result?.url) {
              const link = document.createElement('a')
              link.href = response.result.url
              link.download = 'myfile.pptx'
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            }
            dispatch(setCanvasLoading(false))
            dispatch(setCanvasLoadingMessage(''))
          } else if (response.status === CeleryStatus.FAILURE) {
            dispatch(setCanvasLoading(false))
            dispatch(setCanvasLoadingMessage(''))
          } else {
            await delayPromise(1000)
            await getDownloadTemplateStatus()
          }
          if (response.result?.corruptedSlidesObject?.length) {
            throw {
              error: new Error('There are corrupted sldies in the export.'),
              corruptedSlidesObject: response.result?.corruptedSlidesObject
            }
          }
        }

        await getDownloadTemplateStatus()
      } catch (e) {
        dispatch(setCanvasLoading(false))
        dispatch(setCanvasLoadingMessage(''))
        throw e
      }
    }
  } catch (e) {
    dispatch(setCanvasLoading(false))
    dispatch(setCanvasLoadingMessage(''))
    throw e
  }
}

export const downloadSlideTemplate = (slideIDs: number[]) => async (dispatch, getState) => {
  try {
    const state = getState()
    const project = selectCurrentProject(state)
    const templates = selectTemplates(state)
    const templateId = templates[0]?.id
    if (templateId) {
      dispatch(setCanvasLoading(true))
      dispatch(
        setCanvasLoadingMessage(
          "Please wait.| We are preparing your PowerPoint file.| It'll just take a moment."
        )
      )
      const { taskId } = await downloadSlideProjectTemplate(project.id, templateId, slideIDs)
      try {
        const getDownloadTemplateStatus = async () => {
          const response = await getCeleryTaskStatus<{ url: string }>({ taskId: taskId })
          if (response.status === CeleryStatus.SUCCESS) {
            if (response.result?.url) {
              const link = document.createElement('a')
              link.href = response.result.url
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            }
            dispatch(setCanvasLoading(false))
            dispatch(setCanvasLoadingMessage(''))
          } else if (response.status === CeleryStatus.FAILURE) {
            dispatch(setCanvasLoading(false))
            dispatch(setCanvasLoadingMessage(''))
          } else {
            await delayPromise(1000)
            await getDownloadTemplateStatus()
          }
        }

        await getDownloadTemplateStatus()
      } catch (e) {
        dispatch(setCanvasLoading(false))
        dispatch(setCanvasLoadingMessage(''))
      }
    }
  } catch (e) {
    dispatch(setCanvasLoading(false))
    dispatch(setCanvasLoadingMessage(''))
  }
}

export const downloadAppendixReport =
  (dataSourceId: number, columnIndex: number) => async (dispatch, getState) => {
    try {
      const state = getState()
      const project = selectCurrentProject(state)
      const templates = selectTemplates(state)
      const templateId = templates[0]?.id
      if (templateId) {
        dispatch(setCanvasLoading(true))
        const { taskId } = await downloadReport(project.id, templateId, dataSourceId, columnIndex)
        try {
          const getDownloadReportStatus = async () => {
            const response = await getCeleryTaskStatus<{ url: string }>({ taskId: taskId })
            if (response.status === CeleryStatus.SUCCESS) {
              if (response.result?.url) {
                const link = document.createElement('a')
                link.href = response.result.url
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              }
              dispatch(setCanvasLoading(false))
            } else if (response.status === CeleryStatus.FAILURE) {
              dispatch(setCanvasLoading(false))
            } else {
              await delayPromise(1000)
              await getDownloadReportStatus()
            }
          }

          await getDownloadReportStatus()
        } catch (e) {
          dispatch(setCanvasLoading(false))
        }
      }
    } catch (e) {
      dispatch(setCanvasLoading(false))
    }
  }
