import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import { Button, Divider, Dropdown, Menu, Tooltip } from 'antd'

import { LeftOutlined, ProjectOutlined, UserOutlined } from '@ant-design/icons'

import { selectCurrentUser, selectIsAuthenticated } from '@/store/slices/auth/selectors'
import { signOut } from '@/store/slices/auth/thunks'
import { setCurrentProjectName } from '@/store/slices/projects/actions'
import { selectCurrentProject } from '@/store/slices/projects/selectors'
import { AppDispatch } from '@/store/store'

import { editProjectName } from '@/services/project-service'

import './Navbar.less'

type Props = {
  onPage?: 'main' | 'editor' | 'auth' | 'projects' | 'account-settings' | 'insert-data'
  left?: ReactElement | React.FC
  right?: ReactElement | React.FC
  setCloseInsertData?: () => void
}

export const Navbar = ({ onPage, right, setCloseInsertData }: Props) => {
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const currentUser = useSelector(selectCurrentUser)
  const dispatch: AppDispatch = useDispatch()
  const removeSession = () => {
    dispatch(signOut())
  }
  const history = useHistory()
  const project = useSelector(selectCurrentProject)
  const [projectName, setProjectName] = useState('')
  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (project.id && projectName) {
      try {
        const response = await editProjectName(project.id, projectName)
        dispatch(setCurrentProjectName(response.name))
      } catch (e) {
        console.error(e.message)
      }
    }
  }
  const onNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    setProjectName(e.currentTarget.value)
    document.getElementById('project-name-span').innerHTML = e.currentTarget.value.replace(
      / /g,
      '&nbsp;'
    )
    document.getElementById('project-name-input').style.width =
      document.getElementById('project-name-span').offsetWidth + 'px'
  }

  const onDropdownClick = async ({ key }) => {
    if (key === 'logout') {
      removeSession()
    }
    if (key === 'download-user-guide') {
      const link = document.createElement('a')
      link.target = '_blank'
      link.href =
        'https://drive.google.com/file/d/1-LtMgT-_bP-VTpbZxhp1OCbqyDxkGTJJ/view?usp=drive_link'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
    if (key === 'account-settings') {
      history.push('/account-settings')
    }
    if (key === 'admin') {
      window.location.href = '/admin/'
    }
  }

  const onKeyDownSubmit = event => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      onSubmit(event)
      event.target.blur()
    }
  }

  useEffect(() => {
    if (project) {
      setProjectName(project.name)
      let spanEl = document.getElementById('project-name-span')
      let inputEl = document.getElementById('project-name-input')
      if (spanEl && inputEl) {
        spanEl.innerHTML = project?.name?.replace(/ /g, '&nbsp;')
        inputEl.style.width = document.getElementById('project-name-span').offsetWidth + 'px'
      }
    }
  }, [project])

  const dropdown = (
    <Menu onClick={onDropdownClick}>
      {currentUser && isAuthenticated ? (
        <>
          <div className="menu__username">
            <span>{currentUser.email}</span>
          </div>
          <Menu.Item key="account-settings">
            <a className="menu_link">Account settings</a>
          </Menu.Item>
          <Menu.Item key="download-user-guide">
            <a className="menu_link">Download User Guide</a>
          </Menu.Item>
          <Menu.Item key="logout">
            <a className="menu_link">Logout</a>
          </Menu.Item>
          {currentUser.is_superuser && (
            <Menu.Item key="admin">
              <a className="admin">Admin</a>
            </Menu.Item>
          )}
        </>
      ) : (
        <>
          <Menu.Item key="signUp">
            <Link className="menu_link" to="sign-up">
              Sign up
            </Link>
          </Menu.Item>
          <Menu.Item key="signIn">
            <Link className="menu_link" to="sign-in">
              Sign in
            </Link>
          </Menu.Item>
        </>
      )}
    </Menu>
  )

  return (
    <nav className={onPage !== 'main' ? `navbar navbar__editor` : `navbar navbar__signin`}>
      <div className="navbar__group--left">
        {(onPage === 'main' || onPage === 'projects') && (
          <img src="/images/indico_long_rec.png" className="navbar__logo"></img>
        )}
        {(onPage === 'editor' || onPage === 'account-settings' || onPage === 'insert-data') && (
          <>
            <img src="/images/indico_short.png" className="navbar__logo-short"></img>
            <Button
              type="link"
              style={{ color: 'white' }}
              icon={<LeftOutlined />}
              onClick={() => {
                if (onPage === 'editor') {
                  history.push('/projects')
                } else if (onPage === 'account-settings') {
                  history.goBack()
                } else if (onPage) {
                  setCloseInsertData()
                }
              }}
            >
              {onPage === 'editor' ? 'Projects' : 'Back'}
            </Button>
          </>
        )}
      </div>
      <div className="navbar__group--centre">
        {onPage === 'editor' && (
          <div className="navbar__item">
            <form className="navbar__form">
              <div className="form__field">
                <span id="project-name-span"></span>
                <input
                  id="project-name-input"
                  type="text"
                  className="form__input inline__edit"
                  onChange={onNameChange}
                  onBlur={onSubmit}
                  onKeyDown={onKeyDownSubmit}
                  value={projectName}
                />
              </div>
            </form>
          </div>
        )}
      </div>
      <div className="navbar__group--right">
        {right ? (
          <>{right}</>
        ) : (
          <>
            {onPage === 'main' && (
              <Tooltip title="Projects">
                <Link className="navbar__link--project" to="projects">
                  <ProjectOutlined />
                </Link>
              </Tooltip>
            )}
            <Dropdown overlay={dropdown} placement="bottom">
              <span className="navbar__user">
                <UserOutlined style={{ fontSize: '1.1em' }} />
              </span>
            </Dropdown>
          </>
        )}
      </div>
    </nav>
  )
}
