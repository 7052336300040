import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Button } from 'antd'

import { HotTable } from '@handsontable/react'

import styles from './Table.module.css'

type Props = {
  data: string[][]
  rowHeaders: string[]
  colHeaders: string[]
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  setRightIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  clearDataSelection: () => void
  onSubmit?: ([row, col]: [number, number]) => void
  showTransform?: boolean
}

const OutputTable = ({
  setIsLoading,
  data,
  rowHeaders,
  colHeaders,
  setRightIsOpen,
  clearDataSelection,
  onSubmit = null,
  showTransform = true
}: Props) => {
  const history = useHistory()
  const hasData = data != null
  const [selection, setSelection] = useState<[number, number]>()
  const afterSelection = (row1, col1, row2, col2) => {
    setSelection([row1, col1])
  }
  const submit = async () => {
    if (onSubmit != null) {
      await onSubmit(selection)
    }
    history.goBack()
  }

  return (
    <div className="output-table-col">
      <div className="header-section">
        <h3>Output table</h3>
        <Button danger onClick={() => history.goBack()}>
          Back to slides
        </Button>
      </div>
      <div className="table-container">
        {hasData ? (
          <HotTable
            readOnly={true}
            readOnlyCellClassName={styles.readonly}
            data={data}
            autoRowSize={true}
            licenseKey="non-commercial-and-evaluation"
            width="100%"
            height="100%"
            rowHeaders={rowHeaders}
            colHeaders={colHeaders}
            afterSelection={afterSelection}
            selectionMode="single"
            rowHeaderWidth={100}
          />
        ) : (
          <div className="centered-btn">No Data Selected</div>
        )}
      </div>
      <div
        className="section"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '8px'
        }}
      >
        <div>
          <Button danger disabled={!hasData} onClick={clearDataSelection}>
            Clear
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            gap: '8px'
          }}
        >
          {showTransform && (
            <Button
              disabled={!hasData}
              type="default"
              onClick={() => {
                setRightIsOpen(true)
              }}
            >
              Transform Data
            </Button>
          )}
          <Button disabled={!hasData} type="primary" onClick={submit}>
            Submit selection
          </Button>
        </div>
      </div>
    </div>
  )
}

export default OutputTable
