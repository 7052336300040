import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '@store/rootReducer'
import { selectCanvasObjectById } from '@store/slices/shapes/selectors'

import { useCanvas } from '@components/canvas/hooks/useCanvas'
import { FabricObject } from '@components/canvas/types/object'

const alreadyHandledKeys = [
  'height',
  'width',
  'scaleY',
  'scaleX',
  'angle',
  'src',
  'file',
  'code',
  'filters',
  'shadow',
  'visible'
]

export function useCommonPropsHandler(object: FabricObject) {
  const selectObj = useMemo(() => selectCanvasObjectById, [])
  const selectedObject = useSelector((s: RootState) => selectObj(s, object?.id))
  const canvas = useCanvas()

  useEffect(() => {
    if (!object || !selectedObject) {
      return
    }
    Object.keys(selectedObject).forEach(key => {
      if (!alreadyHandledKeys.includes(key) && selectedObject[key] !== object[key]) {
        if (object.id === 'workarea' && key !== 'left' && key !== 'top') {
          object.set(key, selectedObject[key])
        } else if (
          object.id !== 'workarea' &&
          object.type !== 'textbox' &&
          key !== 'left' &&
          key !== 'top'
        ) {
          object.set(key, selectedObject[key])
        }
        canvas.requestRenderAll()
        const { id, superType, width, height, scaleX, scaleY, angle } = object
        const zoom = canvas.getZoom()
        const el = document.getElementById(`${id}_container`)
        if (superType === 'element' && el) {
          const { left, top } = object.getBoundingRect(false)
          const padLeft = (width * scaleX * zoom - width) / 2
          const padTop = (height * scaleY * zoom - height) / 2

          el.style.transform = `rotate(${angle}deg) scale(${scaleX * zoom}, ${scaleY * zoom})`
          el.style.width = `${width}px`
          el.style.height = `${height}px`
          el.style.left = `${left + padLeft}px`
          el.style.top = `${top + padTop}px`
        }
      }
    })
  }, [canvas, object, selectedObject])
}
