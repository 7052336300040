import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Tabs } from 'antd'

import axios from 'axios'
import i18n from 'i18next'

import { selectCurrentProjectId } from '@/store/slices/projects/selectors'
import { fetchAllSlides } from '@/store/slices/slides/actions'
import { selectAllSlideIds } from '@/store/slices/slides/selectors'
import { selectTemplates, selectTemplateUploadProgress } from '@/store/slices/template/selectors'
import { AppDispatch } from '@/store/store'

import { getTemplateSlides } from '@/services/slide-service'

import CommonModal from '@/components/common/CommonModal'

import SlideSelection from './SlideSelection'
import TemplateUploader from './TemplateUploader'

const { TabPane } = Tabs
interface Props {
  isVisible: boolean
  setIsVisible: (bool: boolean) => void
}

const SlideTemplating = ({ isVisible, setIsVisible }: Props) => {
  const cancelToken = axios.CancelToken.source()
  const dispatch: AppDispatch = useDispatch()
  const projectId = useSelector(selectCurrentProjectId)
  const currentSlideIds = useSelector(selectAllSlideIds)
  const templates = useSelector(selectTemplates)
  const templateUploadProgress = useSelector(selectTemplateUploadProgress)
  const [activeKey, setActiveKey] = useState('1')
  const [slides, setSlides] = useState({})
  const [isUploaderVisible, setIsUploaderVisible] = useState(false)
  const [updateModalHeight, setUpdateModalHeight] = useState(true)
  const onTabChange = (key: string) => {
    setActiveKey(key)
  }

  // const onTemplateUpload = (newSlides: ISlide<IShape[]>[]) => {
  //   if (newSlides.length > 0) {
  //     setSlides([...slides, ...newSlides])
  //   }
  // }

  const onTemplateUpload = (newSlides: any[], templateId: number) => {
    if (newSlides.length > 0) {
      const newObj = slides
      newObj[templateId] = newSlides
      setSlides({ ...newObj })
    }
  }

  const onTemplateRemove = (templateId: number) => {
    const newObj = slides
    delete newObj[templateId]
    setSlides({ ...newObj })
    if (Object.keys(newObj).length === 0) {
      setActiveKey('1')
    }
  }

  //used to clear slides when template replaced
  const onTemplateReplace = () => {
    setSlides({})
  }

  const fetchSlides = async () => {
    try {
      templates.forEach(async template => {
        const id = template?.id
        let newSlides = await getTemplateSlides({ templateId: id, projectId })
        const newObj = slides
        newObj[id] = Object.values(newSlides)
        setSlides({ ...newObj })
      })
    } catch (error) {
      console.error(error.message)
    }
  }

  const onSelectionComplete = () => {
    setIsVisible(false)
  }

  //sets active key to 1 if slide selection is disabled
  //active key from a previous project can sometimes be left over in state
  //which is why this is needed
  useEffect(() => {
    if (templates === undefined || Object.keys(slides).length === 0) {
      setActiveKey('1')
    } else {
      setActiveKey('2')
    }
  }, [Object.keys(slides).length])

  useEffect(() => {
    if (templateUploadProgress === 100) {
      setTimeout(() => {
        setActiveKey('2')
      }, 800)
    }
  }, [templateUploadProgress])

  useEffect(() => {
    if (templates && isVisible && Object.keys(slides).length === 0) {
      fetchSlides()
    }
  }, [currentSlideIds, isVisible, templates])

  useEffect(() => {
    if (!isVisible) {
      cancelToken.cancel()
    }
    setIsUploaderVisible(isVisible && activeKey === '1')
  }, [isVisible])

  useEffect(() => {
    setSlides({})
  }, [projectId])

  return (
    <CommonModal
      title={i18n.t('editor.left-panel.slides.templating')}
      visible={isVisible}
      onCancel={() => {
        dispatch(fetchAllSlides())
        setIsVisible(false)
      }}
      width={740}
      footer={null}
      afterClose={() => dispatch(fetchAllSlides())}
      update={updateModalHeight}
    >
      <Tabs activeKey={activeKey} onChange={onTabChange}>
        <TabPane
          tab={i18n.t('editor.left-panel.slides.upload')}
          key="1"
          disabled={templates !== undefined && Object.keys(slides).length !== 0}
        >
          <TemplateUploader
            onReplace={() => onTemplateReplace()}
            onComplete={(slides: any[], templateId: number) => onTemplateUpload(slides, templateId)}
            cancelToken={cancelToken}
            onCancel={() => setIsVisible(false)}
            isVisible={isUploaderVisible}
            setActiveKey={setActiveKey}
            updateModalHeight={updateModalHeight}
            setUpdateModalHeight={setUpdateModalHeight}
          />
        </TabPane>
        <TabPane
          tab={i18n.t('editor.left-panel.slides.slide-templates')}
          key="2"
          disabled={templates === undefined || Object.keys(slides).length === 0}
        >
          <SlideSelection
            slides={slides}
            projectId={projectId}
            onCancel={() => setIsVisible(false)}
            templates={templates || null}
            onComplete={() => onSelectionComplete()}
            onRemove={(templateId: number) => onTemplateRemove(templateId)}
            updateModalHeight={updateModalHeight}
            setUpdateModalHeight={setUpdateModalHeight}
          />
        </TabPane>
      </Tabs>
    </CommonModal>
  )
}

export default SlideTemplating
