import axios from 'axios'

import { IPercentageIndexingPayload, IPercentageIndexingResponse } from '@/interfaces/data-overlays'
import { IShapeData } from '@/interfaces/shape'

export type IndexingResponse = IPercentageIndexingResponse & { shapeData: IShapeData['indexing'] }

interface IBasePercentageIndexing<T = any> {
  indexingId: number
  payload: T
}

type CreatePecentageIndexing = Omit<
  IBasePercentageIndexing<IPercentageIndexingPayload>,
  'indexingId'
>

export async function createPercentageIndexing({ payload }: CreatePecentageIndexing) {
  const url = `/api/indexing/`
  const response = await axios.post<IndexingResponse>(url, payload)
  return response.data
}

type RetrieveDeleteIndexing = Omit<IBasePercentageIndexing, 'payload'>

export async function retrievePercentageIndexing({
  indexingId
}: RetrieveDeleteIndexing): Promise<IPercentageIndexingResponse> {
  const url = `/api/indexing/${indexingId}`
  const response = await axios.get<IPercentageIndexingResponse>(url)
  return response.data
}

type UpdatePercentageIndexing = IBasePercentageIndexing<IPercentageIndexingPayload>

export async function updatePercentageIndexing({ indexingId, payload }: UpdatePercentageIndexing) {
  const url = `/api/indexing/${indexingId}/`
  const response = await axios.put<IndexingResponse>(url, payload)
  return response.data
}

type DeletePercentageIndexing = Omit<IBasePercentageIndexing, 'payload'>

export async function deletePercentageIndexing({ indexingId }: DeletePercentageIndexing) {
  const url = `/api/indexing/${indexingId}/`
  const response = await axios.delete<void>(url)
  return response.data
}
