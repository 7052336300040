import { useState } from 'react'
import { Select, Table, Button } from 'antd'
import { Flex } from '@components/flex'
import CommonDetail from '@components/common/CommonDetail'
import { useDataTable, useRowsNColumns } from '@components/imagemap/right-panel/hooks'
import { uniq } from 'lodash'
import { DataSourceDto } from '@/interfaces/data'
import MultiSelectDropdown from '@components/common/MultiSelectDropdown'
import DropDownMenu from '@components/imagemap/right-panel/data-source/DropDownMenu'

interface DataEntry {
  layout: number
  dataTable: number
  columns: number[]
  rows: number[]
  rowTags?: string[]
}

type Props = {
  dataSource: DataSourceDto
  layouts: { id: number; kind: string }[]
  onFinish: (values: DataEntry[]) => void
  onCancel: () => void
  Layout: any
}

export const ProjectCustomizer = ({ dataSource, layouts, onFinish, onCancel, Layout }: Props) => {
  const selectColumnsMode = false ? '' : 'multiple'
  const [selectedTableId, setSelectedTableId] = useState<number>(null)
  const [selectedLayoutId, setSelectedLayoutId] = useState<number>(null)
  const dataTables = dataSource?.dataSections?.map(section => section.dataTables)?.flat() || []

  const [columnsDropdownVisible, setColumnsDropdownVisible] = useState(false)
  const [rowsDropdownVisible, setRowsDropdownVisible] = useState(false)
  const [dataTypesDropdownVisible, setDataTypesDropdownVisible] = useState(false)

  const { rows, columns } = useRowsNColumns(selectedTableId)
  const { data: table } = useDataTable(selectedTableId)
  const [antdTableData, setAntdTableData] = useState([{ index: 1 }])
  const [selectedDataTypes, setSelectedDataTypes] = useState<string[]>([])
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [selectedColumns, setSelectedColumns] = useState<number[]>([])
  const [entries, setEntries] = useState<DataEntry[]>([])

  const dataLayoutSelected =
    layouts.filter(layout => {
      const dataLayouts = [
        'Data Slide [col]',
        'Data Slide [col stacked %]',
        'Data Slide [bar]',
        'Data Slide [bar stacked %]',
        'Data Slide [line]'
      ]
      return layout.id === selectedLayoutId && dataLayouts.includes(layout.kind)
    }).length > 0

  const onLayoutChange = (value: number) => {
    setSelectedLayoutId(value)
  }

  const onTableChange = (value: number) => {
    setSelectedTableId(value)
  }

  const onDataTypesChange = (values: string[]) => {
    setSelectedDataTypes(values)
  }

  const onRowsChange = (values: number[]) => {
    setSelectedRows(values)
  }

  const onColumnsChange = (values: number[]) => {
    setSelectedColumns(values)
  }

  const handleAddSlide = () => {
    setAntdTableData([...antdTableData, { index: antdTableData.length + 1 }])
    const newEntry: DataEntry = {
      layout: selectedLayoutId,
      dataTable: selectedTableId,
      columns: selectedColumns,
      rows: selectedRows,
      rowTags: selectedDataTypes
    }
    setEntries([...entries, newEntry])
  }

  const handleDeleteSlide = () => {
    if (antdTableData.length > 0) {
      setAntdTableData(antdTableData.slice(0, antdTableData.length - 1))
      setEntries(entries.slice(0, entries.length - 1))
      setSelectedLayoutId(null)
      setSelectedTableId(null)
      setSelectedRows([])
      setSelectedColumns([])
      setSelectedDataTypes([])
    }
  }

  const onSendEntries = () => {
    if (antdTableData.length > entries.length) {
      const newEntry: DataEntry = {
        layout: selectedLayoutId,
        dataTable: selectedTableId,
        columns: selectedColumns,
        rows: selectedRows,
        rowTags: selectedDataTypes
      }
      onFinish([...entries, newEntry])
    } else {
      onFinish(entries)
    }
  }

  const antdTableColumns = [
    {
      title: 'Slide',
      dataIndex: 'index',
      key: '1'
    },
    {
      title: 'Slide layout',
      dataIndex: 'slideLayout',
      key: '2',
      render: (text, record) => {
        return (
          <Select
            placeholder={'Select layout'}
            style={{ width: 200 }}
            onChange={onLayoutChange}
            disabled={record.index !== antdTableData.length}
          >
            {layouts.map(layout => (
              <Select.Option key={layout.id} value={layout.id}>
                {layout.kind}
              </Select.Option>
            ))}
          </Select>
        )
      }
    },
    {
      title: 'Table',
      dataIndex: 'table',
      key: '3',
      render: (text, record) => (
        <Select
          placeholder={'Search tables'}
          style={{ width: 200 }}
          onChange={onTableChange}
          disabled={record.index !== antdTableData.length || !dataLayoutSelected}
        >
          {dataTables.map(table => (
            <Select.Option key={table.id} value={table.id}>
              {table.name}
            </Select.Option>
          ))}
        </Select>
      )
    },
    {
      title: 'Columns',
      dataIndex: 'columns',
      key: '4',
      render: (text, record) => {
        return (
          <MultiSelectDropdown
            onChange={onColumnsChange}
            mode={selectColumnsMode}
            optionFilterProp="children"
            value={selectedColumns}
            dropdownRender={menu =>
              selectColumnsMode ? (
                <DropDownMenu
                  menu={menu}
                  onSelect={() => {
                    onColumnsChange(columns.map(col => col.index))
                  }}
                  onClear={() => onColumnsChange([])}
                  onOk={() => setColumnsDropdownVisible(false)}
                  onCancel={() => {
                    setColumnsDropdownVisible(false)
                  }}
                />
              ) : (
                menu
              )
            }
            align="left"
            visible={record.index === antdTableData.length && columnsDropdownVisible}
            setVisible={setColumnsDropdownVisible}
            placeholder="Search columns"
            style={{ width: 200 }}
            disabled={record.index !== antdTableData.length || !dataLayoutSelected}
          >
            {columns.map(column => (
              <Select.Option
                style={{ whiteSpace: 'normal', height: 'auto' }}
                value={column.index}
                key={column.name}
              >
                {column.name}
              </Select.Option>
            ))}
          </MultiSelectDropdown>
        )
      }
    },
    {
      title: 'Rows',
      dataIndex: 'rows',
      key: '5',
      render: (text, record) => {
        return (
          <MultiSelectDropdown
            onChange={onRowsChange}
            mode={selectColumnsMode}
            optionFilterProp="children"
            value={selectedRows}
            dropdownRender={menu =>
              selectColumnsMode ? (
                <DropDownMenu
                  menu={menu}
                  onSelect={() => setSelectedRows(rows.map(row => row.index))}
                  onClear={() => setSelectedRows([])}
                  onOk={() => setRowsDropdownVisible(false)}
                  onCancel={() => {
                    setRowsDropdownVisible(false)
                  }}
                />
              ) : (
                menu
              )
            }
            align="left"
            visible={record.index === antdTableData.length && rowsDropdownVisible}
            setVisible={setRowsDropdownVisible}
            placeholder="Search rows"
            style={{ width: 200 }}
            disabled={record.index !== antdTableData.length || !dataLayoutSelected}
          >
            {rows.map(row => (
              <Select.Option
                style={{ whiteSpace: 'normal', height: 'auto' }}
                value={row.index}
                key={row.name}
              >
                {row.name}
              </Select.Option>
            ))}
          </MultiSelectDropdown>
        )
      }
    },
    {
      title: 'Data types',
      dataIndex: 'dataTypes',
      key: '6',
      render: (text, record) => {
        return (
          <MultiSelectDropdown
            onChange={onDataTypesChange}
            mode={selectColumnsMode}
            optionFilterProp="children"
            value={selectedDataTypes}
            dropdownRender={menu =>
              selectColumnsMode ? (
                <DropDownMenu
                  menu={menu}
                  onSelect={() => setSelectedDataTypes(uniq(table?.rowsMeta) || [])}
                  onClear={() => setSelectedDataTypes([])}
                  onOk={() => {
                    setDataTypesDropdownVisible(false)
                  }}
                  onCancel={() => {
                    setDataTypesDropdownVisible(false)
                  }}
                />
              ) : (
                menu
              )
            }
            align="left"
            visible={record.index === antdTableData.length && dataTypesDropdownVisible}
            setVisible={setDataTypesDropdownVisible}
            placeholder={'Filter row tags'}
            style={{ width: 200 }}
            disabled={record.index !== antdTableData.length || !dataLayoutSelected}
          >
            {(uniq(table?.rowsMeta) || []).map(row => (
              <Select.Option style={{ whiteSpace: 'normal', height: 'auto' }} value={row} key={row}>
                {row}
              </Select.Option>
            ))}
          </MultiSelectDropdown>
        )
      }
    }
  ]

  return (
    <Layout
      previous={<Button onClick={() => onCancel()}>Previous</Button>}
      next={
        <Button type="primary" onClick={() => onSendEntries()}>
          Next
        </Button>
      }
    >
      <CommonDetail
        className="data-uploader"
        style={{
          minWidth: 'auto'
        }}
      >
        <div className="slide__text">
          <h1 className="slide__title">Customise Presentation</h1>
        </div>
        <Flex style={{ padding: '6px 0' }} justifyContent="flex-start">
          <Button type="primary" onClick={handleAddSlide}>
            Add slide
          </Button>
          <Button disabled={antdTableData.length < 1} onClick={handleDeleteSlide}>
            Delete slide
          </Button>
        </Flex>
        <Flex justifyContent="center">
          <Table dataSource={antdTableData} columns={antdTableColumns} />
        </Flex>
      </CommonDetail>
    </Layout>
  )
}
