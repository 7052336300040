import { ITableViewData } from '@/services/data-service'

import { Selection } from '@components/imagemap/right-panel/properties/TableConditionProperty'

/** Covert selection value from array-like to string: [[0, 0], [4, 4]] -> A1:E5 */
export function tableSelectionToStringRepr(selection: [number, number][]) {
  const startsAt = 'A'.charCodeAt(0) + 1
  let [start, end] = selection
  let colStart = String.fromCharCode(start[0] + startsAt)
  let rowStart = (start[1] + 2).toString()
  let colEnd = String.fromCharCode(end[0] + startsAt)
  let rowEnd = (end[1] + 2).toString()
  const selectionAsString = `${colStart}${rowStart}:${colEnd}${rowEnd}`
  return selectionAsString
}

/** Convert selection value from string to array-like: B2:E5 -> [[1, 1], [4, 4]] */
export function tableSelectionToArrayRepr(selection: Selection<string>): Selection {
  let re1 = new RegExp(/[a-zA-Z]+/g)
  let re2 = new RegExp(/[0-9]+/g)
  let [start, end] = selection.split(':')
  let startColIdx = start.match(re1)[0].toLowerCase().charCodeAt(0) - 96 - 2 // get letter's alhpabetic index
  let startRowIdx = parseInt(start.match(re2)[0], 10) - 2
  let endColIdx = end.match(re1)[0].toLowerCase().charCodeAt(0) - 96 - 2
  let endRowIdx = parseInt(end.match(re2)[0]) - 2
  return [
    [startColIdx, startRowIdx],
    [endColIdx, endRowIdx]
  ]
}

/** Covert selection value from array-like to string: [[0, 0], [4, 4]] -> A1:E5 */
export function selectionToString(selection: Selection) {
  const startsAt = 'A'.charCodeAt(0)
  let [start, end] = selection
  let colStart = String.fromCharCode(start[0] + startsAt)
  let rowStart = start[1].toString()
  let colEnd = String.fromCharCode(end[0] + startsAt)
  let rowEnd = end[1].toString()
  const selectionAsString = `${colStart}${rowStart}:${colEnd}${rowEnd}`
  return selectionAsString
}

/** Convert selection value from string to array-like: B2:E5 -> [[1, 1], [4, 4]] */
export function selectionToArray(selection: Selection<''>) {
  return selection.split(':').map(piece => {
    let colIdx = piece[0].toLowerCase().charCodeAt(0) - 96 - 1 // get letter's alhpabetic index
    let rowIdx = parseInt(piece[1], 10)
    return [colIdx, rowIdx]
  })
}

export function getNumberFormat(decimalPlaces: number, showAsPercentage: boolean): string {
  let numberFormat = '0'
  if (showAsPercentage) {
    if (decimalPlaces) {
      numberFormat += '.' + '0'.repeat(decimalPlaces)
    }
    numberFormat += '%'
  } else {
    if (decimalPlaces) {
      numberFormat += '.' + '0'.repeat(decimalPlaces)
    }
  }
  return numberFormat
}

export function getValuesFromNumberFormat(
  numberFormat: string | null | undefined
): [number, boolean] {
  let formatCode = numberFormat || ''
  const showAsPercentage = formatCode?.endsWith('%') || false
  let match = formatCode.match(/(?<=0.)0+/)
  let decimalPlaces = match !== null ? match[0].length : 0
  return [decimalPlaces, showAsPercentage]
}

export const getFormatCodeFromDataTable = (
  rows: number[],
  columns: number[],
  tableData?: ITableViewData
): string | undefined => {
  // if (selectedObject.type !== 'table') {
  //   return
  // }
  const numberFormat2DArray: string[][] = tableData?.tableMeta?.numberFormat
  if (!numberFormat2DArray) {
    return
  }
  let formatCode = ''
  let isConsistent = true
  rows.forEach(row => {
    if (!isConsistent) {
      return
    }
    columns.forEach(col => {
      let currentCode = numberFormat2DArray?.[row]?.[col]
      // if formatCode changes across the data set we ignore it
      if (formatCode && currentCode !== formatCode) {
        isConsistent = false
        return
      }
      formatCode = currentCode
    })
  })
  return isConsistent ? formatCode : null
}
