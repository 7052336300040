import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { notification, Select, Space, Spin, Tooltip } from 'antd'

import i18n from 'i18next'

import { refreshSlide } from '@/store/slices/slides/actions'
import { AppDispatch } from '@/store/store'
import { selectCanvasSelectedObjectDataDefinition } from '@store/slices/shapes/selectors'
import { DefaultDataDefinition } from '@store/slices/shapes/types'

import {
  createDataAction,
  deleteDataAction,
  getDataActions,
  updateDataAction
} from '@services/data-actions-service'

import { useDataTransformation, useRowsNColumns } from '../hooks'
import { getResultingRowsnColumns } from '../utils'
import ActionList from './ActionList'
import CopyOrder from './CopyOrder'
import Filter from './Filter'
import Reorder from './Reorder'
import Reverse from './Reverse'
import ShiftLabels from './ShiftLabels'
import SortBy from './SortBy'
import StringFilter from './StringFilter'
import Transpose from './Transpose'
import Trim from './Trim'
import { Action, ActionTypes } from './types'

const DataTransformationsSelector = () => {
  const dispatch: AppDispatch = useDispatch()
  const { transformationId, tableId } = useSelector(
    selectCanvasSelectedObjectDataDefinition
  ) as DefaultDataDefinition
  const { rows, columns } = useRowsNColumns(tableId)

  const [selectedAction, setSelectedAction] = useState<string>(null)
  const [actionQueue, setActionQueue] = useState([])
  const { dataTransformation, mutate } = useDataTransformation(transformationId)
  const [loading, setLoading] = useState(false)
  const [initialValues, setInitialValues] = useState(null)
  const [mode, setMode] = useState(null)
  const [clearOptions, setClearOptions] = useState({
    filter: false,
    reorder: false,
    reverse: false,
    sort_by: false,
    transpose: false,
    top_and_bottom: false,
    copy_order: false,
    string_filter: false,
    shift_labels: false
  })

  const [resultingRows, resultingColumns] = getResultingRowsnColumns(
    rows,
    columns,
    dataTransformation
  )

  const [transformVisible, setTransformVisible] = useState(false)

  const tooltips = {
    [ActionTypes.FILTER]:
      'Remove columns or rows which contain values above, below or between the level set in the rule.',
    [ActionTypes.REORDER]: 'Set the order of rows and columns manually.',
    [ActionTypes.REVERSE]: 'Reverse the order of rows or columns.',
    [ActionTypes.SORTBY]: 'Set the order of rows or columns by ascending or descending order.',
    [ActionTypes.TRANSPOSE]: 'Switch the rows or columns in the mapped data object.',
    [ActionTypes.TOP_AND_BOTTOM]:
      'Limit the number of rows or columns that are mapped into the object by setting a numerical figure.',
    [ActionTypes.COPY_ORDER]:
      'Take the order of rows or columns from any mapped data object and apply to another mapped data object.',
    [ActionTypes.STRING_FILTER]:
      'Remove columns or rows which contain a set of characters in their names.',
    [ActionTypes.SHIFT_LABELS]: 'Shift rows/columns to start/end of table.'
  }
  const actionMapping = {
    [ActionTypes.FILTER]: i18n.t(
      'editor.right-panel.data-source.form.data-transformation.actions.filter'
    ),
    [ActionTypes.REORDER]: i18n.t(
      'editor.right-panel.data-source.form.data-transformation.actions.reorder'
    ),
    [ActionTypes.REVERSE]: i18n.t(
      'editor.right-panel.data-source.form.data-transformation.actions.reverse'
    ),
    [ActionTypes.SORTBY]: i18n.t(
      'editor.right-panel.data-source.form.data-transformation.actions.sort-by'
    ),
    [ActionTypes.TRANSPOSE]: i18n.t(
      'editor.right-panel.data-source.form.data-transformation.actions.transpose'
    ),
    [ActionTypes.TOP_AND_BOTTOM]: i18n.t(
      'editor.right-panel.data-source.form.data-transformation.actions.trim'
    ),
    [ActionTypes.COPY_ORDER]: i18n.t(
      'editor.right-panel.data-source.form.data-transformation.actions.rearrange'
    ),
    [ActionTypes.STRING_FILTER]: i18n.t(
      'editor.right-panel.data-source.form.data-transformation.actions.string_filter'
    ),
    [ActionTypes.SHIFT_LABELS]: i18n.t(
      'editor.right-panel.data-source.form.data-transformation.actions.shift_labels'
    )
  }

  const openNotification = error => {
    notification.warning({
      key: error,
      duration: 5,
      message: 'Sorry, an error occurred',
      description:
        'We encountered the following error when trying to apply your data transformation: ' + error
    })
  }

  const onSelect = (value: ActionTypes) => {
    const opts = clearOptions
    opts[value] = true
    setClearOptions({ ...opts })
    setInitialValues(null)
    setSelectedAction(value)
    setMode('create')
  }

  const onCancel = () => {
    setSelectedAction(null)
  }

  const onSubmit = async ({
    actionType,
    ...values
  }: Omit<Action<typeof actionType>, 'shapeDataSelection'>) => {
    try {
      setLoading(true)
      if (mode === 'create') {
        const response = await createDataAction({
          shapeDataSelection: transformationId,
          specs: {},
          by: [],
          actionType,
          ...values
        })
        dispatch(refreshSlide())

        setActionQueue(prev => [...prev, response])
      }
      if (mode === 'update') {
        const response = await updateDataAction(initialValues.id, {
          shapeDataSelection: transformationId,
          specs: {},
          by: [],
          actionType,
          ...values
        })
        dispatch(refreshSlide())
        setActionQueue(prev => [
          ...prev.map(action => (action.id !== response.id ? action : response))
        ])
      }
      mutate()
      const opts = clearOptions
      opts[actionType] = false
      setClearOptions({ ...opts })
      setLoading(false)
    } catch (error) {
      let errorMessage = error.message
      if (error?.response?.data?.nonFieldErrors?.[0]) {
        errorMessage = error.response.data.nonFieldErrors[0]
      } else if (error?.response?.request?.responseText) {
        errorMessage = error.response.request.responseText
      }
      openNotification(errorMessage)
      console.error(error.response)
      setLoading(false)
    }
    setSelectedAction(null)
    setInitialValues(null)
  }

  const onCopyOrderSubmit = () => {
    const opts = clearOptions
    opts['copy_order'] = false
    setClearOptions({ ...opts })
    dispatch(refreshSlide())
  }

  const onEditAction = (action: Action) => {
    const opts = clearOptions
    opts[action.actionType] = true
    setClearOptions({ ...opts })
    setMode('update')
    setInitialValues({ ...action, ...action.specs })
    setSelectedAction(action.actionType)
    fetchActions()
  }

  const onDeleteAction = async (id: number, actionType: string) => {
    try {
      const response = await deleteDataAction(id)
      const opts = clearOptions
      opts[actionType] = true
      setClearOptions({ ...opts })
      setInitialValues(null)
      //@ts-ignore
      dispatch(refreshSlide())
      mutate()
      fetchActions()
    } catch (error) {
      console.error(error.message)
    }
  }

  const fetchActions = useCallback(async () => {
    try {
      const actions = await getDataActions(transformationId)
      setActionQueue(actions)
      //if action list is 0 we have cleared data actions (probably by updating data transform)
      //therefore clear the states
      if (actions.length === 0) {
        setClearOptions({
          filter: true,
          reorder: true,
          reverse: true,
          sort_by: true,
          transpose: true,
          top_and_bottom: true,
          copy_order: true,
          string_filter: true,
          shift_labels: true
        })
        setInitialValues(null)
      }
    } catch (error) {
      console.error(error.message)
    }
  }, [transformationId])

  useEffect(() => {
    fetchActions()
  }, [fetchActions])

  return (
    <Spin spinning={loading}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Select<string | number, { value: string; children: string }>
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          dropdownMatchSelectWidth={false}
          dropdownAlign={{
            points: ['tr', 'tl'], //align dropdown's top-right to top-left of input element
            offset: [-5, 0], //align offset
            overflow: {
              adjustX: 0,
              adjustY: 0 //do not auto flip in y axis
            }
          }}
          dropdownStyle={{
            display: transformVisible ? 'inline-block' : 'none',
            width: 'fit-content',
            maxWidth: '33%',
            minWidth: '15%'
          }}
          onDropdownVisibleChange={open => setTransformVisible(open)}
          placeholder={i18n.t(
            'editor.right-panel.data-source.form.data-transformation.placeholder'
          )}
          onChange={onSelect}
          value={selectedAction}
          style={{ width: '100%' }}
        >
          {Object.values(ActionTypes).map(actionType => (
            <Select.Option value={actionType} key={actionType}>
              <Tooltip title={tooltips[actionType]}>{actionMapping[actionType]}</Tooltip>
            </Select.Option>
          ))}
        </Select>
      </Space>

      <Reorder
        isVisible={selectedAction === ActionTypes.REORDER}
        columns={resultingColumns}
        rows={resultingRows}
        onSubmit={onSubmit}
        onCancel={onCancel}
        initialValues={initialValues}
        clearOptions={clearOptions.reorder}
      />
      <Reverse
        isVisible={selectedAction === ActionTypes.REVERSE}
        onSubmit={onSubmit}
        onCancel={onCancel}
        initialValues={initialValues}
        clearOptions={clearOptions.reverse}
      />
      <Transpose
        clearOptions={clearOptions.transpose}
        isVisible={selectedAction === ActionTypes.TRANSPOSE}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
      <SortBy
        columns={columns}
        rows={rows}
        isVisible={selectedAction === ActionTypes.SORTBY}
        onSubmit={onSubmit}
        onCancel={onCancel}
        initialValues={initialValues}
        clearOptions={clearOptions.sort_by}
      />
      <Filter
        columns={columns}
        rows={rows}
        isVisible={selectedAction === ActionTypes.FILTER}
        onSubmit={onSubmit}
        onCancel={onCancel}
        initialValues={initialValues}
        clearOptions={clearOptions.filter}
      />
      <Trim
        columns={resultingColumns}
        rows={resultingRows}
        isVisible={selectedAction === ActionTypes.TOP_AND_BOTTOM}
        onSubmit={onSubmit}
        onCancel={onCancel}
        initialValues={initialValues}
        clearOptions={clearOptions.top_and_bottom}
      />
      <CopyOrder
        columns={resultingColumns}
        rows={resultingRows}
        onCancel={onCancel}
        onSubmit={onCopyOrderSubmit}
        isVisible={selectedAction === ActionTypes.COPY_ORDER}
        clearOptions={clearOptions.copy_order}
      />
      <StringFilter
        columns={resultingColumns}
        rows={resultingRows}
        onCancel={onCancel}
        onSubmit={onSubmit}
        initialValues={initialValues}
        isVisible={selectedAction === ActionTypes.STRING_FILTER}
        clearOptions={clearOptions.string_filter}
      />
      <ShiftLabels
        columns={resultingColumns}
        rows={resultingRows}
        isVisible={selectedAction === ActionTypes.SHIFT_LABELS}
        onSubmit={onSubmit}
        onCancel={onCancel}
        initialValues={initialValues}
        clearOptions={clearOptions.shift_labels}
      />
      <ActionList onEdit={onEditAction} onDelete={onDeleteAction} queue={actionQueue} />
    </Spin>
  )
}

export default DataTransformationsSelector
