import { range } from 'lodash'

import { ITableViewData } from '@/services/data-service'

import { applyNumberFormatting } from '@/components/canvas'

type HotData = (string | number)[][]

const _toSequence = (
  row1: number,
  col1: number,
  row2: number,
  col2: number
): [number[], number[]] => {
  let rowsRange = row2 > row1 ? range(row1, row2 + 1) : range(row2, row1 + 1)
  const colsRange = col2 > col1 ? range(col1, col2 + 1) : range(col2, col1 + 1)
  return [rowsRange, colsRange]
}

export const mergeSelections = (
  from: [number, number],
  to: [number, number],
  rows: number[] = [],
  columns: number[] = []
): [number[], number[]] => {
  const [selectedRows, selectedColumns] = _toSequence(...from, ...to)
  const rowSet = new Set([...selectedRows, ...rows])
  const columnSet = new Set([...selectedColumns, ...columns])
  const sortedRows = Array.from(rowSet).sort((a, b) => a - b)
  const sortedCols = Array.from(columnSet).sort((a, b) => a - b)
  return [sortedRows, sortedCols]
}

export function sequenceToRange(ids: number[]) {
  let ret = []
  let temp = []
  let i = 0
  do {
    temp.push(ids[i])
    if (ids[i] + 1 !== ids[i + 1]) {
      if (temp.length === 1) {
        ret.push([temp[0], temp[0]])
      } else {
        ret.push([temp[0], temp[temp.length - 1]])
      }
      temp = []
    }

    i++
  } while (i < ids.length)
  return ret
}

export function toRanges(rows: number[], columns: number[]) {
  const rowsRanges = sequenceToRange(rows)
  const colRanges = sequenceToRange(columns)
  let combinations = []
  for (const rowRange of rowsRanges) {
    for (const colRange of colRanges) {
      combinations.push([rowRange[0], colRange[0], rowRange[1], colRange[1]])
    }
  }
  return combinations
}

export function getColHeaders(tableDataFrame: ITableViewData) {
  if (tableDataFrame == null || !tableDataFrame?.headers) {
    return null
  }
  return tableDataFrame.headers.map(header => {
    return header.map(cell => {
      return { label: cell.name.substring(0, 40), colspan: cell.span }
    })
  })
}

export function convertData(tableDataFrame: ITableViewData): HotData {
  if (tableDataFrame == null) {
    return null
  }
  let rows = tableDataFrame.index
  let values = tableDataFrame.data.map((row, rowIndex) =>
    row.map((value, colIndex) => {
      const formatCode = tableDataFrame.tableMeta?.numberFormat?.[rowIndex]?.[colIndex]
      if (formatCode) {
        return applyNumberFormatting(value, formatCode)
      }
      return value
    })
  )
  let ret = []
  if (rows) {
    rows.forEach((_row, idx) => ret.push([...values[idx]]))
  }
  return ret
}

export function getSelectionRange(
  hotTableSelectedArea: number[][] | undefined,
  rowsCount: number,
  colsCount: number
) {
  const resultRangeCols = []
  const resultRangeRows = []

  const selectedColumns = hotTableSelectedArea
    ?.map(range => {
      return range.filter((_, idx) => {
        return idx === 1
      })
    })
    .flat()

  selectedColumns.forEach(colIndex => {
    const arr = []
    arr[0] = 0
    arr[1] = colIndex
    arr[2] = rowsCount
    arr[3] = colIndex
    resultRangeCols.push(arr)
  })

  const selectedRows = hotTableSelectedArea
    ?.map(range => {
      return range.filter((_, idx) => {
        return idx === 0
      })
    })
    .flat()

  selectedRows.forEach(rowIndex => {
    const arr = []
    arr[0] = rowIndex
    arr[1] = 0
    arr[2] = rowIndex
    arr[3] = colsCount
    resultRangeRows.push(arr)
  })

  return { resultRangeCols, resultRangeRows, selectedColumns, selectedRows }
}

export function getGlobalNumberFormat(
  numberFormat: string[][],
  rows: number[],
  columns: number[]
): string | undefined {
  if (!numberFormat) {
    return
  }
  let formatCode = ''
  let isConsistent = true
  rows.forEach(row => {
    if (!isConsistent) {
      return
    }
    columns.forEach(col => {
      let currentCode = numberFormat?.[row]?.[col]
      // @ symbol is used for cells that have no values, we ignore them
      if (currentCode !== '@') {
        // if formatCode changes across the data set we ignore it
        if (formatCode && currentCode !== formatCode) {
          isConsistent = false
          return
        }
        formatCode = currentCode
      }
    })
  })
  return isConsistent ? formatCode : null
}
