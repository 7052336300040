import { range } from 'lodash'

export class Parser {
  state = ''
  output = []
  isRange = false

  parse(str) {
    let i = 0
    while (i <= str.length) {
      this.feed(str[i])
      i++
    }
    return this.render()
  }

  feed(token) {
    if (this.isDigit(token)) {
      this.state += token
    }
    if (token === '-') {
      this.state += token
      this.isRange = true
    }
    if (token === ',') {
      if (this.state.length > 0) {
        if (this.isRange) {
          let [left, right] = this.state.split('-').map(Number)
          this.output = this.output.concat(range(left, right + 1))
        } else {
          this.output = this.output.concat(Number(this.state))
        }
      }
      this.isRange = false
      this.state = ''
    }
    if (token === undefined) {
      if (this.state.length > 0) {
        if (this.isRange) {
          let [left, right] = this.state.split('-').map(Number)
          this.output = this.output.concat(range(left, right + 1))
        } else {
          this.output = this.output.concat(Number(this.state))
        }
      }
    }
  }

  isDigit(token) {
    let digits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    return digits.includes(token)
  }

  render() {
    let output = [...this.output]
    this.output = []
    this.state = ''
    this.isRange = false
    return output
  }
}
