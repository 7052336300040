import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Button, Form, Select, Space } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import i18n from 'i18next'
import {
  IPercentageIndexingPayload,
  IPercentageIndexingResponse
} from 'src/interfaces/data-overlays'

import {
  selectCanvasSelectedObject,
  selectCanvasSelectedObjectDataDefinition
} from '@/store/slices/shapes/selectors'
import { DefaultDataDefinition } from '@store/slices/shapes/types'

import MultiSelectDropdown from '@/components/common/MultiSelectDropdown'
import { Switcher } from '@/components/form/UseFormattedDataSwitch'
import Icon from '@components/icon/Icon'
import { useDataTransformation, useRowsNColumns } from '@components/imagemap/right-panel/hooks'
import ThresholdModalForm, {
  ThresholdFormset
} from '@components/imagemap/right-panel/percentage-indexing/ThresholdModalForm'

import { Nullable } from '@/interfaces/utils'

import { getResultingRowsnColumns } from '../utils'
import IndexingRules from './IndexingRules'
import { IndexingForm, ThresholdSettings } from './types'

type Props = {
  initial: Nullable<IPercentageIndexingResponse>
  onFinish: (payload: IPercentageIndexingPayload, thresholds: ThresholdSettings[]) => Promise<void>
  onDelete: () => void
}

const PercentageIndexingForm = ({ initial, onFinish, onDelete }: Props) => {
  const selectedItem = useSelector(selectCanvasSelectedObject)
  const visualisationSettings = selectedItem?.visualSettings?.percentageIndexing || []
  const [axisType, setAxisType] = useState<'columns' | 'rows'>('columns')
  const [useFormattedValues, setUseFormattedValues] = useState(false)
  const dataDefinition = useSelector(
    selectCanvasSelectedObjectDataDefinition
  ) as DefaultDataDefinition
  const { dataTransformation } = useDataTransformation(dataDefinition.transformationId)
  const { tableId } = useSelector(selectCanvasSelectedObjectDataDefinition) as DefaultDataDefinition
  const { rows, columns, loading: loadingLabels } = useRowsNColumns(tableId)

  const [resultingRows = [], resultingColumns = []] = getResultingRowsnColumns(
    rows,
    columns,
    dataTransformation
  )

  const defaultValues: IndexingForm = {
    axis: 'columns',
    controlGroup: null,
    testGroup: [],
    thresholds: []
  }

  const [form] = useForm()
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [thresholdSettings, setThresholdSettings] = useState<ThresholdSettings[]>([])
  const [testGroupsVisible, setTestGroupsVisible] = useState(false)
  const [controlGroupVisible, setControlGroupVisible] = useState(false)
  const [testBetweenVisible, setTestBetweenVisible] = useState(false)

  const onFormSubmit = (values: IPercentageIndexingPayload) => {
    onFinish({ ...values, useFormattedData: useFormattedValues }, thresholdSettings)
  }

  const onAxisChange = value => {
    form.setFieldsValue({ ...defaultValues, axis: value })
    setAxisType(value)
  }

  const undoPercentageIndexing = () => {
    form.setFieldsValue(defaultValues)
    setThresholdSettings([])
    onDelete()
  }

  const onModalFormSubmit = ({ formList }: ThresholdFormset) => {
    let thresholds = formList.map(threshold => {
      if (!threshold.color) {
        let c = { ...threshold }
        return c
      } else {
        return threshold
      }
    })
    setThresholdSettings(thresholds)
    setModalVisible(false)
  }

  useEffect(() => {
    if (initial !== null) {
      setThresholdSettings(visualisationSettings)
      setAxisType(initial.axis)
      form.setFieldsValue(initial)
      setUseFormattedValues(initial.useFormattedData)
    }
  }, [initial, visualisationSettings])

  return (
    <div className="percentage-indexing">
      <Form
        layout="vertical"
        form={form}
        name="indexingForm"
        initialValues={defaultValues}
        onFinish={values => onFormSubmit(values)}
      >
        <Space
          direction="vertical"
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <Form.Item
            label={i18n.t('editor.right-panel.analytics.percentage-indexing.test-between')}
            name="axis"
            rules={[{ required: true }]}
            className="form-item"
          >
            <Select<string | number, { value: string; children: string }>
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              dropdownMatchSelectWidth={false}
              dropdownAlign={{
                points: ['tr', 'tl'], //align dropdown's top-right to top-left of input element
                offset: [-5, 0] //align offset
              }}
              dropdownStyle={{
                display: testBetweenVisible ? 'inline-block' : 'none',
                width: 'fit-content',
                maxWidth: '33%',
                minWidth: '15%'
              }}
              onDropdownVisibleChange={open => setTestBetweenVisible(open)}
              onChange={onAxisChange}
              placeholder={i18n.t('editor.right-panel.analytics.sig-testing.select-one')}
            >
              <Select.Option value={'columns'}>
                {i18n.t('editor.right-panel.data-source.form.columns')}
              </Select.Option>

              <Select.Option value={'rows'}>
                {i18n.t('editor.right-panel.data-source.form.rows')}
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={i18n.t('editor.right-panel.analytics.percentage-indexing.control-group')}
            name="controlGroup"
            rules={[{ required: true }]}
            className="form-item"
          >
            <Select<string | number, { value: string; children: string }>
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              dropdownMatchSelectWidth={false}
              dropdownAlign={{
                points: ['tr', 'tl'], //align dropdown's top-right to top-left of input element
                offset: [-5, 0] //align offset
              }}
              dropdownStyle={{
                display: controlGroupVisible ? 'inline-block' : 'none',
                width: 'fit-content',
                maxWidth: '33%',
                minWidth: '15%'
              }}
              onDropdownVisibleChange={open => setControlGroupVisible(open)}
              placeholder={i18n.t('editor.right-panel.analytics.percentage-indexing.select-one')}
              loading={loadingLabels}
            >
              {axisType === 'rows'
                ? rows.map(row => (
                    <Select.Option value={row?.index} key={row?.index}>
                      {row?.name}
                    </Select.Option>
                  ))
                : columns.map(column => (
                    <Select.Option value={column?.index} key={column?.index}>
                      {column?.name}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.controlGroup !== curValues.controlGroup
            }
          >
            {({ getFieldValue }) => {
              const controlGroupValue = getFieldValue('controlGroup')
              const options = dataTransformation.transposed
                ? axisType === 'rows'
                  ? resultingColumns.filter(column => column.index !== controlGroupValue)
                  : resultingRows.filter(row => row.index !== controlGroupValue)
                : axisType === 'rows'
                ? resultingRows.filter(row => row.index !== controlGroupValue)
                : resultingColumns.filter(column => column.index !== controlGroupValue)
              return (
                <Form.Item
                  label={i18n.t('editor.right-panel.analytics.percentage-indexing.test-groups')}
                  name="testGroup"
                  rules={[{ required: true }]}
                  className="form-item"
                >
                  <MultiSelectDropdown
                    align="left"
                    dropdownSelect={() =>
                      form.setFieldsValue({ testGroup: options.map(({ index }) => index) })
                    }
                    dropdownClear={() => form.setFieldsValue({ testGroup: [] })}
                    dropdownOk={() => setTestGroupsVisible(false)}
                    dropdownCancel={() => {
                      form.setFieldsValue({ testGroup: [] })
                      setTestGroupsVisible(false)
                    }}
                    visible={testGroupsVisible}
                    setVisible={setTestGroupsVisible}
                    placeholder={i18n.t(
                      'editor.right-panel.analytics.percentage-indexing.select-many'
                    )}
                    loading={loadingLabels}
                  >
                    {options.map(option => (
                      <Select.Option value={option?.index} key={option?.index}>
                        {option?.name}
                      </Select.Option>
                    ))}
                  </MultiSelectDropdown>
                </Form.Item>
              )
            }}
          </Form.Item>

          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.thresholds !== curValues.thresholds ||
              curValues.thresholds !== thresholdSettings
            }
          >
            {() => <IndexingRules settings={thresholdSettings} />}
          </Form.Item>

          <Switcher
            style={{ padding: '10px 0 18px 0' }}
            onToggle={value => setUseFormattedValues(value)}
            defaultChecked={useFormattedValues}
          />

          <Form.Item>
            <Button type="dashed" onClick={() => setModalVisible(true)} block>
              {i18n.t('editor.right-panel.analytics.percentage-indexing.add-threshold')}
            </Button>
          </Form.Item>

          <Form.Item>
            <Button
              disabled={thresholdSettings.length ? false : true}
              type="primary"
              htmlType="submit"
              ghost={thresholdSettings.length ? false : true}
              block
            >
              {i18n.t('editor.right-panel.analytics.percentage-indexing.apply')}
            </Button>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              style={{ whiteSpace: 'normal', height: 'auto' }}
              onClick={undoPercentageIndexing}
              disabled={!initial}
              block
              danger
            >
              <Icon name="sync-alt" style={{ marginRight: '2%' }} />
              {i18n.t('editor.right-panel.analytics.percentage-indexing.undo')}
            </Button>
          </Form.Item>
        </Space>
      </Form>

      <ThresholdModalForm
        initial={thresholdSettings}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onFinish={onModalFormSubmit}
      />
    </div>
  )
}

export default PercentageIndexingForm
