import React, { SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { Dropdown, Menu } from 'antd'

import { PlusOutlined } from '@ant-design/icons'
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import i18n from 'i18next'
import { isEqual } from 'lodash'

import { setCanvasLoading } from '@/store/slices/canvas/canvas/actions'
import { selectCurrentProjectId, selectProjectSlideOrder } from '@/store/slices/projects/selectors'
import { updateCurrentProject } from '@/store/slices/projects/thunks'
import { deleteMultipleSlides, deleteSlide, duplicateSlide } from '@/store/slices/slides/thunks'
import { selectSlide } from '@store/slices/slides/actions'
import { selectCurrentSlideId } from '@store/slices/slides/selectors'
import { AppDispatch } from '@store/store'

import { useThumbnails } from '@/hooks/useThumbnail'

import SlideTemplating from './SlideTemplating'

import './styles.less'

import CommonModal from '@/components/common/CommonModal'

type ContextMenuProps = {
  slideId: number
  handleConfirmPrompt: (
    mode: 'delete' | 'duplicate',
    slideId: number,
    callback?: () => void
  ) => void
  setVisible: React.Dispatch<SetStateAction<boolean>>
  selectedSlideIds: number[]
}

const ContextMenu = ({
  slideId,
  handleConfirmPrompt,
  setVisible,
  selectedSlideIds
}: ContextMenuProps) => {
  const isMultipleDelete = selectedSlideIds.length > 1 && selectedSlideIds.includes(slideId)
  return (
    <Menu id="contextMenu">
      {!isMultipleDelete && (
        <Menu.Item
          onClick={() => {
            handleConfirmPrompt('duplicate', slideId, () => {
              setVisible(false)
            })
          }}
          key="0"
          id="contextMenu-duplicate"
        >
          {i18n.t('editor.left-panel.slides.duplicate')}
        </Menu.Item>
      )}
      <Menu.Item
        onClick={() => {
          handleConfirmPrompt('delete', slideId, () => {
            setVisible(false)
          })
        }}
        key="1"
        id="contextMenu-delete"
      >
        {isMultipleDelete
          ? i18n.t('editor.left-panel.slides.delete-multiple')
          : i18n.t('editor.left-panel.slides.delete')}
      </Menu.Item>
    </Menu>
  )
}

type SortableSlideProps = {
  id: string
  currentId: number
  index: number
  thumbnail: string
  handleConfirmPrompt: (
    mode: 'delete' | 'duplicate',
    slideId: number,
    callback?: () => void
  ) => void
  onSelectClick: (id: number | string, isShiftKey?: boolean) => void
  selectedSlideIds: number[]
}

let currentUrlParams = new URLSearchParams(window.location.search)

const SortableSlide = ({
  id,
  currentId,
  index,
  thumbnail,
  handleConfirmPrompt,
  onSelectClick,
  selectedSlideIds
}: SortableSlideProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, node } = useSortable({ id })
  let slideId = Number(id)
  let history = useHistory()

  const onClick = (slideId: number) => {
    currentUrlParams.set('slide', slideId.toString())
    history.push(window.location.pathname + '?' + currentUrlParams.toString())
  }

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }
  const [visible, setVisible] = useState(false)

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag)
  }

  useEffect(() => {
    if (id === currentId?.toString() && index >= 3) {
      node.current.scrollIntoView()
    }
  }, [])

  return (
    <div id="sortableSlide" ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Dropdown
        overlay={
          <ContextMenu
            slideId={slideId}
            handleConfirmPrompt={handleConfirmPrompt}
            setVisible={setVisible}
            selectedSlideIds={selectedSlideIds}
          />
        }
        trigger={['contextMenu']}
        key={index}
        onVisibleChange={handleVisibleChange}
        visible={visible}
      >
        <div
          className="slide-container"
          id="sortableSlide-slide-container"
          onClick={e => {
            if (!e.ctrlKey && !e.shiftKey) {
              onClick(slideId)
            } else if (e.ctrlKey) {
              onSelectClick(slideId)
            } else if (e.shiftKey) {
              onSelectClick(slideId, true)
            }
          }}
        >
          <span
            id="sortableSlide-slide-container-index"
            className={`slide-index ${currentId === slideId ? 'slide-index-selected' : ''}`}
          >
            {index + 1}
          </span>
          <div
            id="sortableSlide-slide-container-selected"
            className={`slide ${
              selectedSlideIds.indexOf(slideId) !== -1 ? 'slide-container-selected' : ''
            }`}
          >
            <img
              id="sortableSlide-slide-container-selected-thumbnail"
              src={thumbnail}
              style={{ objectFit: 'contain', width: 150, height: 84 }}
            />
          </div>
        </div>
      </Dropdown>
    </div>
  )
}

const Slides = () => {
  const dispatch: AppDispatch = useDispatch()
  const projectId = useSelector(selectCurrentProjectId)
  const allSlideIds = useSelector(selectProjectSlideOrder)

  //when the slides component first renders the project selections aren't accurate
  //they only become accurate when the editor loads
  //therefore we store the first projectId as a ref
  //we can then compare against it to see if the project has loaded
  //only then do we update the searchparams to the current slide
  const idRef = useRef(projectId)

  //the above doesn't always work however
  //if a user enters a project, exits then re-enters, the projectid is the same
  //so to protect against this case - we monitor the currentslideid
  //this loads in as the previous slide selected
  //then it switches to 0 while slide is being loaded
  //then becomes the current slide again
  //so we ensure it's done the switch to 0 before updating search params
  const currentSlideId = useSelector(selectCurrentSlideId)

  const [updateHappened, setUpdateHappened] = useState(false)

  useEffect(() => {
    if (allSlideIds) {
      if (currentSlideId === allSlideIds[0]) {
        setUpdateHappened(true)
      }
    }
    if (!!currentSlideId) {
      setSelectedSlideIds([currentSlideId])
    }
  }, [currentSlideId])

  const [modalVisible, setModalVisible] = useState(false)
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
  const [slideId, setSlideId] = useState(null)
  const [mode, setMode] = useState<'delete' | 'duplicate'>(null)
  // dnd-kit requires id to be of string type
  const [slideIds, setSlideIds] = useState<string[]>([])

  const [selectedSlideIds, setSelectedSlideIds] = useState([])

  const { thumbnails } = useThumbnails(allSlideIds, projectId)
  const { search } = useLocation()
  let history = useHistory()

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8
      }
    })
  )

  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  useEffect(() => {
    if (
      (!searchParams.get('slide') && idRef.current != projectId) ||
      (!searchParams.get('slide') && updateHappened)
    ) {
      //the selectSlide has already been dispatched here in fetchAllSlides in Editor.jsx
      //so don't call it again
      //check if slides are added to the project at all
      if (allSlideIds?.length > 0) {
        searchParams.set('slide', allSlideIds[0].toString())
        history.replace({ search: searchParams.toString() })
      }
    } else {
      if (
        Number(searchParams.get('slide')) != currentSlideId &&
        Number(searchParams.get('slide')) != 0 &&
        allSlideIds?.length > 0
      ) {
        history.replace({ search: searchParams.toString() })
        if (allSlideIds.includes(Number(searchParams.get('slide')))) {
          dispatch(selectSlide(Number(searchParams.get('slide'))))
        }
      }
      //check if the slide is no longer in slide order
      //e.g. template has been deleted from project
      if (!allSlideIds?.includes(Number(searchParams.get('slide')))) {
        searchParams.delete('slide')
        history.replace({ search: searchParams.toString() })
      }
    }
  }, [searchParams, projectId, currentSlideId, updateHappened])

  const onOrderChange = async (reorderedSlideIds: string[]) => {
    setSlideIds(reorderedSlideIds)
    const payload = { slideOrder: reorderedSlideIds.map(id => Number(id)) }
    if (!isEqual(slideIds, reorderedSlideIds)) {
      await dispatch(updateCurrentProject({ projectId, payload }))
    }
  }

  const handleDragEnd = event => {
    const { active, over } = event
    try {
      const newValues = slideIds.slice(0)
      const oldIndex = newValues.findIndex(id => id === active.id)
      const newIndex = newValues.findIndex(id => id === over.id)
      onOrderChange(arrayMove(newValues, oldIndex, newIndex))
    } catch (error) {
      console.error(error.message)
    }
  }

  useEffect(() => {
    if (Array.isArray(allSlideIds)) {
      //remove any null values (as it can be null when you first enter the site)
      setSlideIds(allSlideIds.filter(() => true).map(id => id.toString()))
    }
  }, [allSlideIds])

  const handleSlideAction = async () => {
    dispatch(setCanvasLoading(true))
    if (mode === 'delete') {
      if (selectedSlideIds.length > 1 && selectedSlideIds.includes(slideId)) {
        await dispatch(deleteMultipleSlides(selectedSlideIds))
      } else {
        await dispatch(deleteSlide(slideId))
      }
    } else {
      await dispatch(duplicateSlide(slideId))
    }
    dispatch(setCanvasLoading(false))

    setIsConfirmModalVisible(false)
  }

  const handleConfirmPrompt = (
    mode: 'delete' | 'duplicate',
    slideId: number,
    callback?: () => void
  ) => {
    setSlideId(slideId)
    setMode(mode)
    setIsConfirmModalVisible(true)
    callback()
  }

  const onSelectClick = (id, isShiftKey?: boolean) => {
    if (isShiftKey) {
      const selectedSlideIndex = allSlideIds.findIndex(el => el === id)
      const startIndex = allSlideIds.findIndex(el => el === selectedSlideIds[0])

      if (selectedSlideIndex > startIndex) {
        const slice = allSlideIds.slice(startIndex, selectedSlideIndex + 1)
        setSelectedSlideIds([...slice])
      } else {
        const startIndexReversed = allSlideIds.findIndex(
          el => el === selectedSlideIds[selectedSlideIds.length - 1]
        )
        const slice = allSlideIds.slice(selectedSlideIndex, startIndexReversed + 1)
        setSelectedSlideIds([...slice])
      }
    } else {
      if (selectedSlideIds.includes(id)) {
        setSelectedSlideIds(prev => prev.filter(el => el !== id))
      } else {
        setSelectedSlideIds(prev => [...prev, id])
      }
    }
  }

  return (
    <>
      <CommonModal
        title={mode === 'delete' ? 'Delete slide' : 'Duplicate slide'}
        visible={isConfirmModalVisible}
        okText="Delete"
        onOk={handleSlideAction}
        onCancel={() => setIsConfirmModalVisible(false)}
      >
        Are you sure you want to continue?
      </CommonModal>
      <div id="slides-title" className="ant-tabs-tabpane-header">
        {i18n.t('editor.left-panel.slides.title')}
      </div>
      <Scrollbars autoHeight autoHeightMax={'calc(100vh - 240px)'} id="slides-scrollbar">
        <div id="slides-slides" className="slides">
          <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
            <SortableContext items={slideIds}>
              {slideIds.map((slideId, index) => (
                <SortableSlide
                  id={slideId}
                  index={index}
                  currentId={currentSlideId}
                  key={slideId}
                  thumbnail={thumbnails[slideId]}
                  handleConfirmPrompt={handleConfirmPrompt}
                  onSelectClick={onSelectClick}
                  selectedSlideIds={selectedSlideIds}
                />
              ))}
            </SortableContext>
          </DndContext>
        </div>
      </Scrollbars>
      <div
        id="slides-add-button"
        className="slide-button-add"
        onClick={() => setModalVisible(true)}
      >
        <PlusOutlined /> {i18n.t('editor.left-panel.slides.add')}
      </div>
      <SlideTemplating isVisible={modalVisible} setIsVisible={setModalVisible} />
    </>
  )
}
export default Slides
