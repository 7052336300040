import axios from 'axios'

import { SigTestingRequest, SigTestTypes, TSigTesting } from '@/interfaces/analytics'
import { IShapeData } from '@/interfaces/shape'

interface CreateSignificanceTestingProps {
  testing: SigTestingRequest
}

type SignificanceTestDTO<T extends SigTestTypes> = TSigTesting<T> & {
  shapeData: IShapeData['sigTesting']
}

export async function createSignificanceTesting<T extends SigTestTypes>({
  testing
}: CreateSignificanceTestingProps) {
  try {
    const response = await axios.post<SignificanceTestDTO<T>>(`/api/significance/`, testing)
    return response.data
  } catch (error) {
    throw error
  }
}

interface UpdateSignificanceTestingProps {
  testing: Partial<SigTestingRequest>
  significanceId: number
}

export async function updateSignificanceTesting<T extends SigTestTypes>({
  significanceId,
  testing
}: UpdateSignificanceTestingProps) {
  const response = await axios
    .put<SignificanceTestDTO<T>>(`/api/significance/${significanceId}/`, testing)
    .catch(error => {
      throw error
    })
  return response.data
}

interface GetSignificanceTestingProps {
  significanceId: number
}

export async function getSignificanceTesting<T extends SigTestTypes>({
  significanceId
}: GetSignificanceTestingProps): Promise<TSigTesting<T>> {
  const response = await axios.get<TSigTesting<T>>(`/api/significance/${significanceId}/`)
  return response.data
}

interface DeleteSignificanceTestingProps {
  significanceId: number
}

export async function deleteSignificanceTesting<T extends SigTestTypes>({
  significanceId
}: DeleteSignificanceTestingProps) {
  const response = await axios.delete<SignificanceTestDTO<T>>(
    `/api/significance/${significanceId}/`
  )
  return response.data
}
