import React from 'react'

import { Flex } from '@components/flex'

import './EmptyElement.less'

import Icon from '@components/icon/Icon'

interface NoElementSelectedProps {
  title: string
}

const EmptyElement = ({ title }: NoElementSelectedProps) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      style={{
        width: '100%',
        minHeight: '20%',
        color: 'rgba(0, 0, 0, 0.45)',
        fontSize: '16px',
        padding: 16
      }}
    >
      <Icon className="icon" name="times-circle" prefix="far" size={2} />
      <div className="title">{title}</div>
    </Flex>
  )
}

export default EmptyElement
