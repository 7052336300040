import { AxisValues } from '@/interfaces/analytics'
import { DataFrameDefinition } from '@/interfaces/data'

export enum DataActions {
  SORT_BY = 'sort_by',
  REVERSE = 'reverse',
  REORDER = 'reorder',
  FILTER = 'filter',
  TRANSPOSE = 'transpose'
}

export enum FilterTypes {
  ABOVE = 'above',
  BELOW = 'below',
  HEAD = 'head',
  TAIL = 'tail',
  BETWEEN = 'between'
}

export interface BaseDataActionDto<T> {
  id: number
  action: T
  columns: string[]
  rows: string[]
  data_frame: DataFrameDefinition
  broken: boolean
}

export interface SortByDataActionDto {
  id: number
  action: DataActions.SORT_BY
  axis: AxisValues
  by: number[]
  ascending: boolean
}

export interface ReversDataActionDto {
  id: number
  action: DataActions.REVERSE
  columns: boolean
  rows: boolean
  data_frame: DataFrameDefinition
  broken: boolean
}

export interface ReorderDataActionDto extends BaseDataActionDto<DataActions.REORDER> {}

export interface FilterDataActionDto
  extends Omit<BaseDataActionDto<DataActions.FILTER>, 'columns' | 'rows'> {
  axis_type: AxisValues
  actionType: FilterTypes
  by: string[]
  actionValue: {
    above?: number
    below?: number
    head?: number
    tail?: number
    from?: number
    to?: number
  }
}

export interface TransposeDataActionDto {
  id: number
  action: DataActions.TRANSPOSE
  data_frame: DataFrameDefinition
  broken: boolean
}

export type DataActionDto =
  | SortByDataActionDto
  | ReversDataActionDto
  | ReorderDataActionDto
  | FilterDataActionDto
  | TransposeDataActionDto

export interface CreateBaseDataActionParams<T> {
  action: T
  columns: string[]
  rows: string[]
}

export enum SortTypes {
  QUICKSORT = 'quicksort',
  MERGESORT = 'mergesort',
  HEAPSORT = 'heapsort'
}

export enum NanPosition {
  FIRST = 'first',
  LAST = 'last'
}

export interface CreateSortByDataActionParams {
  action: DataActions.SORT_BY
  axis: AxisValues
  by: string[]
  ascending: boolean
}

export interface CreateReversDataActionParams {
  action: DataActions.REVERSE
  columns: boolean
  rows: boolean
}

export interface CreateReorderDataActionParams
  extends CreateBaseDataActionParams<DataActions.REORDER> {}

export interface CreateFilterDataActionDto extends CreateBaseDataActionParams<DataActions.FILTER> {
  actionType: FilterTypes
  actionValue: {
    above?: number
    below?: number
    head?: number
    tail?: number
    from?: number
    to?: number
  }
}

export interface CreateTransposeDataActionDto {
  action: DataActions.TRANSPOSE
}

export enum TextTransformationTypes {
  SORTBY = 'sort_by'
}

export interface TextTransformation {
  id: number
  type: 'sort_by'
  axis: 0 | 1
  by: number[]
  interpolation: number
  specs?: { ascending: boolean }
  dataFrame?: DataFrameDefinition
}

export type CreateDataActionParams =
  | CreateSortByDataActionParams
  | CreateReversDataActionParams
  | CreateReorderDataActionParams
  | CreateFilterDataActionDto
  | CreateTransposeDataActionDto
