import { useState } from 'react'

import { Button, Form, Input } from 'antd'

import i18n from 'i18next'

import CommonModal from '@/components/common/CommonModal'
import Icon from '@components/icon/Icon'

interface UrlModalProps {
  url?: string
  onChange?: (tempUrl: string) => {}
}

const UrlModal = ({ url, onChange }: UrlModalProps) => {
  const [actUrl, setActUrl] = useState(url)
  const [tempUrl, setTempUrl] = useState('')
  const [visible, setVisibility] = useState(false)

  const onOk = () => {
    onChange(tempUrl)
    setVisibility(false)
    setActUrl(tempUrl)
  }
  const onCancel = () => {
    setVisibility(false)
  }

  const onClick = () => {
    setVisibility(true)
  }

  const label = (
    <>
      <span style={{ marginRight: 8 }}>{i18n.t('common.url')}</span>
      <Button onClick={onClick} shape="circle" className="rde-action-btn">
        <Icon name="edit" />
      </Button>
    </>
  )
  return (
    <>
      <Form.Item
        label={label}
        colon={false}
        name="url"
        rules={[
          {
            required: true,
            message: i18n.t('validation.enter-property', { arg: i18n.t('common.url') })
          }
        ]}
        initialValue={actUrl || ''}
      >
        <span style={{ wordBreak: 'break-all' }}>{actUrl}</span>
      </Form.Item>
      <CommonModal title={i18n.t('common.url')} onCancel={onCancel} onOk={onOk} visible={visible}>
        <Form.Item label={i18n.t('common.url')} colon={false}>
          <Input defaultValue={url} onChange={e => setTempUrl(e.target.value)} />
        </Form.Item>
      </CommonModal>
    </>
  )
}

export default UrlModal
