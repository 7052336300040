import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Button, Checkbox, Divider, List } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

import { LeftOutlined, RightOutlined } from '@ant-design/icons'

import { selectRawData } from '@/store/slices/data/selectors'

export default function RawDataInterface(props) {
  const rawData: string[] = useSelector(selectRawData)
  const [checkedVariables, setCheckedVariables] = useState<number[]>([])
  const [columns, setColumns] = useState<number[]>([])
  const [checkedColumns, setCheckedColumns] = useState<number[]>([])
  const [rows, setRows] = useState<number[]>([])
  const [checkedRows, setCheckedRows] = useState<number[]>([])

  const move = (direction: 'left' | 'right', source: 'columns' | 'rows') => {
    if (direction === 'right' && source === 'columns') {
      const uniqueElements = new Set([...columns, ...checkedVariables])
      setColumns(Array.from(uniqueElements))
      setCheckedVariables([])
    }
    if (direction === 'left' && source === 'columns') {
      setColumns(columns.filter(index => !checkedColumns.includes(index)))
      setCheckedColumns([])
    }
    if (direction === 'right' && source === 'rows') {
      const uniqueElements = new Set([...rows, ...checkedVariables])
      setRows(Array.from(uniqueElements))
      setCheckedVariables([])
    }
    if (direction === 'left' && source === 'rows') {
      setRows(rows.filter(index => !checkedRows.includes(index)))
      setCheckedRows([])
    }
  }

  const onVariableChecked = (e: CheckboxChangeEvent, index: number) => {
    if (e.target.checked) {
      setCheckedVariables([...checkedVariables, index])
    } else {
      setCheckedVariables(checkedVariables.filter(varIndex => varIndex !== index))
    }
  }

  const onColumnChecked = (e: CheckboxChangeEvent, index: number) => {
    if (e.target.checked) {
      setCheckedColumns([...checkedColumns, index])
    } else {
      setCheckedColumns(checkedColumns.filter(varIndex => varIndex !== index))
    }
  }

  const onRowChecked = (e: CheckboxChangeEvent, index: number) => {
    if (e.target.checked) {
      setCheckedRows([...checkedRows, index])
    } else {
      setCheckedRows(checkedRows.filter(varIndex => varIndex !== index))
    }
  }

  return (
    <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '392px 168px 392px'
        }}
      >
        <div style={{ overflowY: 'scroll', overflowX: 'hidden', maxHeight: '600px' }}>
          <List
            header="Variables"
            dataSource={rawData}
            bordered
            renderItem={(item: string, index: number) => (
              <List.Item>
                <span>
                  <Checkbox
                    checked={checkedVariables.includes(index)}
                    onChange={(e: CheckboxChangeEvent) => onVariableChecked(e, index)}
                  >
                    <span style={{ overflowWrap: 'anywhere' }}>{item}</span>
                  </Checkbox>
                </span>
              </List.Item>
            )}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center'
          }}
        >
          <div style={{ width: '142px', display: 'flex', flexDirection: 'column' }}>
            <Button size="small" onClick={() => move('right', 'columns')}>
              Add Banner
              <RightOutlined />
            </Button>
            <Button size="small" onClick={() => move('left', 'columns')}>
              <LeftOutlined />
              Remove Banner
            </Button>
          </div>
          <div style={{ width: '142px', display: 'flex', flexDirection: 'column' }}>
            <Button size="small" onClick={() => move('right', 'rows')}>
              Add Stub
              <RightOutlined />
            </Button>
            <Button size="small" onClick={() => move('left', 'rows')}>
              <LeftOutlined />
              Remove Stub
            </Button>
          </div>
        </div>
        <div>
          <div style={{ height: '300px', overflowY: 'scroll', overflowX: 'hidden' }}>
            <List
              header="Columns [Banner]"
              dataSource={columns}
              bordered
              renderItem={(index: number) => (
                <List.Item>
                  <Checkbox
                    checked={checkedColumns.includes(index)}
                    onChange={(e: CheckboxChangeEvent) => onColumnChecked(e, index)}
                  >
                    <span style={{ overflowWrap: 'anywhere' }}>{rawData[index]}</span>
                  </Checkbox>
                </List.Item>
              )}
            />
          </div>
          <div style={{ height: '300px', overflowY: 'scroll', overflowX: 'hidden' }}>
            <List
              header="Rows [Stub]"
              dataSource={rows}
              bordered
              renderItem={(index: number) => (
                <List.Item>
                  <Checkbox
                    checked={checkedRows.includes(index)}
                    onChange={(e: CheckboxChangeEvent) => onRowChecked(e, index)}
                  >
                    <span style={{ overflowWrap: 'anywhere' }}>{rawData[index]}</span>
                  </Checkbox>
                </List.Item>
              )}
            />
          </div>
        </div>
      </div>
      <Divider />
      <div style={{ height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
        <Button
          onClick={() =>
            props.onDataSourceOK(
              rawData.filter((_, i) => columns.includes(i)),
              rawData.filter((_, i) => rows.includes(i))
            )
          }
          type="primary"
          disabled={rows.length === 0 || columns.length === 0}
        >
          Build Crosstab
        </Button>
      </div>
    </>
  )
}
