import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { fabric } from 'fabric'
import { IEvent } from 'fabric/fabric-impl'

import { updateOneCanvasObject } from '@store/slices/shapes/actions'

import { useCanvas } from '@components/canvas/hooks/useCanvas'
import { FabricObjectOptions } from '@components/canvas/types/object'

export function useFabricObject<T extends fabric.Object, O extends FabricObjectOptions>(
  objectFactory: (options: O) => T,
  id: string,
  options: O
): T | undefined {
  const [object, setObject] = useState<T | undefined>()
  const canvas = useCanvas()
  const dispatch = useDispatch()

  useEffect(() => {
    let awaitedElement

    const setupObject = () => {
      awaitedElement = objectFactory(options)
      canvas.add(awaitedElement)
      setObject(awaitedElement)
    }

    setupObject()

    return () => {
      if (awaitedElement) {
        canvas.remove(awaitedElement)
        setObject(undefined)
      }
    }
  }, [canvas])

  useEffect(() => {
    const onMoved = ({ target }: IEvent) => {
      if (target) {
        dispatch(updateOneCanvasObject({ id: id, changes: { top: target.top, left: target.left } }))
      }
    }
    const onScaled = ({ target }: IEvent) => {
      if (target) {
        dispatch(
          updateOneCanvasObject({
            id: id,
            changes: {
              top: target.top,
              left: target.left,
              scaleX: target.scaleX,
              scaleY: target.scaleY,
              angle: target.angle,
              width: target.width,
              height: target.height
            }
          })
        )
      }
    }
    const onRotated = ({ target }: IEvent) => {
      if (target) {
        dispatch(
          updateOneCanvasObject({
            id: id,
            changes: {
              scaleX: target.scaleX,
              scaleY: target.scaleY,
              angle: target.angle
            }
          })
        )
      }
    }

    if (object) {
      object.on('moved', onMoved)
      object.on('scaled', onScaled)
      object.on('rotated', onRotated)
    }

    return () => {
      if (object) {
        object.off('moved', onMoved)
        object.off('scaled', onScaled)
        object.off('rotated', onRotated)
      }
    }
  }, [object, id, dispatch])

  return object
}
