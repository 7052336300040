import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { Button, Layout } from 'antd'

import { selectCurrentUser } from '@/store/slices/auth/selectors'
import { selectCurrentProjectId } from '@/store/slices/projects/selectors'
import { selectCanvasSelectedObjectId } from '@/store/slices/shapes/selectors'
import { selectCurrentSlideId } from '@/store/slices/slides/selectors'

import { sendErrorMessage } from '@/services/logs-service'

const { Content } = Layout

const ErrorScreen = ({ error, resetErrorBoundary }) => {
  const currentUser = useSelector(selectCurrentUser)
  const projectId = useSelector(selectCurrentProjectId)
  const slideId = useSelector(selectCurrentSlideId)
  const shapeId = useSelector(selectCanvasSelectedObjectId)
  //to-do - call an api with the error log in a useEffect hook
  useEffect(() => {
    const logMessage = async () => {
      const stack = error.stack
      const message = error.message
      await sendErrorMessage({ message, currentUser, stack, projectId, slideId, shapeId })
    }
    logMessage()
  }, [error])

  return (
    <Layout className="layout" style={{ height: '100vh' }}>
      <Content style={{ padding: '0 50px', height: '100%', backgroundColor: 'white' }}>
        <div
          className="home-section-intro"
          style={{
            minHeight: '100vh',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <section
            className="top-section"
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '5%'
            }}
          >
            <div className="flex-align-center" style={{ backgroundColor: 'white' }}>
              <h1
                className="title-line1"
                style={{ fontSize: '48px', fontWeight: 700, lineHeight: '56px', marginBottom: 0 }}
              >
                SORRY
              </h1>
              <h1
                className="title-line2"
                style={{ fontSize: '48px', fontWeight: 700, lineHeight: '56px', color: '#135FF6' }}
              >
                WE ENCOUNTERED A PROBLEM
              </h1>
              <p
                className="paragraph"
                style={{ fontFamily: 'Inter', fontSize: '18px', lineHeight: 1.6, margin: '1rem 0' }}
              >
                <strong>Details of the problem have been sent to our engineers</strong> <br />
                {error.message}
              </p>
              <Link
                to={{
                  pathname: '/projects'
                }}
              >
                <Button onClick={resetErrorBoundary}>Click here to return to your projects</Button>
              </Link>
            </div>
            <div className="flex-align-center" style={{ backgroundColor: 'white' }}>
              <div className="brand-image-wrapper">
                <img
                  src="/images/report-analysis-flatline.svg"
                  alt="Happy researcher"
                  style={{ height: '560', width: 560 }}
                />
              </div>
            </div>
          </section>
        </div>
      </Content>
    </Layout>
  )
}

export default ErrorScreen
