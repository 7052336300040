import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectAccessToken } from '@store/slices/auth/selectors'
import { Card, Modal, Button, Steps } from 'antd'
import { ProjectDataUploader } from '@pages/projects/ProjectDataUploader'
import CreateFile from '@components/common/CreateFile'
import { ProjectCustomizer } from '@pages/projects/ProjectCustomizer'
import { IShape } from '@/interfaces/shape'
import { DataSourceDto } from '@/interfaces/data'

import { getDataSourceTables } from '@services/data-service'
import * as api from '@services/project-service'

import './styles.less'

const { Step } = Steps

interface TemplateSlide {
  id: number
  shapes: IShape[]
  layout: { kind: string; id: number }
}

interface ProjectTemplate {
  id: number
  slides: TemplateSlide[]
}

const TemplateProjectList = ({ projectTemplates, onSelect }) => {
  const [selected, setSelected] = useState(null)

  const onClick = id => {
    setSelected(id)
    onSelect(id)
  }
  return (
    <>
      <div className="slide__text">
        <h1 className="slide__title">Choose a theme</h1>
        <p className="slide__description">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        </p>
      </div>
      <div className="card-list-item">
        <>
          {projectTemplates.map(template => (
            <Card
              key={template.id}
              title={template?.name}
              className="card-item"
              style={{
                borderColor: template?.id === selected ? '#3e84ff' : '',
                backgroundColor: template?.name
              }}
              hoverable={true}
              headStyle={{ color: 'gray' }}
              onClick={() => onClick(template.id)}
            ></Card>
          ))}
        </>
      </div>
    </>
  )
}

const Content = ({ children, previous, next }) => {
  return (
    <>
      <div className="steps-content">{children}</div>
      <div className="steps-action">
        {previous}
        {next}
      </div>
    </>
  )
}

const useDataSource = (id: number, projectId: number): DataSourceDto => {
  const [dataSource, setDataSource] = useState<DataSourceDto>(null)

  const getTables = () => {
    if (id) {
      getDataSourceTables(id, projectId).then((data: DataSourceDto) => {
        setDataSource(data)
      })
    }
  }

  useEffect(() => {
    getTables()
    return () => {
      setDataSource(null)
    }
  }, [id])

  return dataSource
}

const defaults = {
  selected: { id: null, slides: [] },
  all: []
}

export const Autoreport = ({ visible, hide }) => {
  const history = useHistory()
  const accessToken = useSelector(selectAccessToken)
  const [current, setCurrent] = useState<number>(0)
  const [projectTemplates, setTemplateProjects] = useState(defaults.all)
  const [selectedProjectTemplate, setSelectedProjectTemplate] = useState<ProjectTemplate>(
    defaults.selected
  )
  const [dataSourceId, setDataSourceId] = useState<number>(null)
  const [newProjectId, setNewProjectId] = useState<number>(null)
  const layouts =
    selectedProjectTemplate.slides.length > 0
      ? selectedProjectTemplate.slides.map(slide => slide.layout)
      : []
  const dataSource = useDataSource(dataSourceId, newProjectId)

  const onSelectTemplate = (projectId: number) => {
    api.getProjectLayoutApi({ projectId, token: accessToken.key }).then(project => {
      setSelectedProjectTemplate(project)
    })
  }

  const onDataUploadComplete = id => {
    setDataSourceId(id)
    next()
  }

  const onThemeChosen = () => {
    if (selectedProjectTemplate?.id) {
      api
        .createProjectFromTemplate(selectedProjectTemplate.id, accessToken.key)
        .then(newProj => setNewProjectId(newProj.id))
        .then(() => next())
    }
  }

  const onFinish = values => {
    api
      .updateAutoReport(newProjectId, values)
      .then(() => next())
      .catch(err => console.error(err))
  }

  const enterNewProject = () => {
    history.push(`/app/${newProjectId}`)
  }

  const next = () =>
    current > steps.length - 1 ? setCurrent(steps.length - 1) : setCurrent(current + 1)
  const prev = () => (current > 0 ? setCurrent(current - 1) : setCurrent(0))

  const steps = [
    {
      title: 'Choose Theme',
      content: (
        <Content
          previous={
            <Button disabled={true} onClick={() => prev()}>
              Previous
            </Button>
          }
          next={
            <Button
              type="primary"
              disabled={selectedProjectTemplate.id == null}
              onClick={() => onThemeChosen()}
            >
              Next
            </Button>
          }
        >
          <TemplateProjectList projectTemplates={projectTemplates} onSelect={onSelectTemplate} />
        </Content>
      )
    },
    {
      title: 'Upload Data',
      content: (
        <ProjectDataUploader
          projectId={newProjectId}
          onComplete={onDataUploadComplete}
          onCancel={() => prev()}
          Layout={Content}
        />
      )
    },
    {
      title: 'Customise Presentation',
      content: (
        <>
          {dataSource != null && (
            <ProjectCustomizer
              dataSource={dataSource}
              layouts={layouts}
              onFinish={onFinish}
              onCancel={() => prev()}
              Layout={Content}
            />
          )}
        </>
      )
    },
    {
      title: 'Create Presentation',
      content: (
        <Content
          previous={null}
          next={
            <Button type="primary" onClick={() => enterNewProject()}>
              Done
            </Button>
          }
        >
          <CreateFile />
        </Content>
      )
    }
  ]

  const getTemplates = () => {
    api.getProjectTemplateList(accessToken.key).then(projects => {
      setTemplateProjects(projects)
    })
  }

  useEffect(() => {
    getTemplates()
    return () => {
      setTemplateProjects(defaults.all)
    }
  }, [])

  return (
    <>
      <Modal
        title="Build with AI"
        visible={visible}
        onCancel={hide}
        afterClose={() => {
          setCurrent(0)
          setSelectedProjectTemplate(defaults.selected)
        }}
        width={current === 2 ? '100%' : '60%'}
        footer={null}
        bodyStyle={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <Steps current={current}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content">{steps[current].content}</div>
      </Modal>
    </>
  )
}
