import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Form, message, Progress, Space, Typography, Upload } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import { FileAddOutlined } from '@ant-design/icons'
import { CancelTokenSource } from 'axios'
import i18n from 'i18next'

import { selectCurrentProject, selectCurrentProjectId } from '@/store/slices/projects/selectors'
import { getCurrentProject } from '@/store/slices/projects/thunks'
import {
  removeTemplate,
  setTemplate,
  setTemplateUploadProgress
} from '@/store/slices/template/actions'
import { appendTemplate, uploadTemplate } from '@/store/slices/template/thunks'
import { AppDispatch } from '@/store/store'
import { selectTemplateIds, selectTemplateUploadProgress } from '@store/slices/template/selectors'

import { deleteProjectTemplate } from '@/services/template-service'
import * as api from '@services/project-service'

import { Flex } from '@/components/flex'

const { Text } = Typography
const { Dragger } = Upload

interface Props {
  onComplete: (slides: any[], templateId: number) => void
  onReplace: () => void
  onCancel: () => void
  cancelToken: CancelTokenSource
  isVisible: boolean
  setActiveKey: (key: string) => void
  updateModalHeight: boolean
  setUpdateModalHeight: (updateModalHeight: boolean) => void
}

const TemplateUploader = ({
  onComplete,
  onCancel,
  cancelToken,
  isVisible,
  setActiveKey,
  updateModalHeight,
  setUpdateModalHeight
}: Props) => {
  const dispatch: AppDispatch = useDispatch()
  const templateIds = useSelector(selectTemplateIds)
  const projectId = useSelector(selectCurrentProjectId)
  const templateUploadProgress = useSelector(selectTemplateUploadProgress)
  const [form] = useForm()
  const [fileList, setFileList] = useState([])
  const [errorMessage, setErrorMessage] = useState<string>(null)
  const [loading, setLoading] = useState(false)
  const uploadStatus =
    templateUploadProgress === null
      ? 'normal'
      : errorMessage
      ? 'exception'
      : templateUploadProgress === 100
      ? 'success'
      : 'active'

  const normalizeFileName = (fileName: string) => {
    const parts = fileName.split('.')
    parts[parts.length - 1] = parts[parts.length - 1].toLowerCase()
    return parts.join('.')
  }

  const setNormalizedFileList = files => {
    const normalizedFiles = files.map(file => {
      const normalizedFileName = normalizeFileName(file.name)
      Object.defineProperty(file, 'name', {
        writable: true,
        value: normalizedFileName
      })
      return file
    })
    setFileList(normalizedFiles)
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const checkFile = () => {
    if (Array.isArray(fileList) && fileList.length > 0) {
      return Promise.resolve()
    }
    return Promise.reject(new Error('Please provide a template file'))
  }

  const onSubmit = ({ fileList }) => {
    const errMsg = `Sorry, an error occured while parsing the template.`
    dispatch(setTemplateUploadProgress(0))
    setErrorMessage(null)
    setLoading(true)

    if (templateIds.length > 0) {
      fileList.forEach(file => {
        dispatch(
          appendTemplate({ file: file, projectId: projectId, cancelToken: cancelToken.token })
        )
          .unwrap()
          .then(res => {
            let slides = res?.templateJson?.slides || []
            if (slides.length > 0) {
              dispatch(setTemplate([{ id: res.id, filename: res.filename }]))
              setErrorMessage(null)
              setNormalizedFileList([])
              onComplete(slides, res.id)
              dispatch(setTemplateUploadProgress(null))
              return slides
            } else {
              setErrorMessage(errMsg)
              setActiveKey('1')
            }
          })
          .catch(rejected => {
            console.error(rejected)
            dispatch(getCurrentProject({ projectId }))
            setErrorMessage(errMsg + `\n${rejected}`)
            setActiveKey('1')
          })
      })
    } else {
      const firstFile = fileList.slice(0, 1)[0]
      const normalizedFileName = normalizeFileName(firstFile.name)
      Object.defineProperty(firstFile, 'name', {
        writable: true,
        value: normalizedFileName
      })
      const furtherFiles = fileList.slice(1)
      dispatch(
        uploadTemplate({ file: firstFile, projectId: projectId, cancelToken: cancelToken.token })
      )
        .unwrap()
        .then(res => {
          let slides = res?.templateJson?.slides || []
          if (slides.length > 0) {
            dispatch(setTemplate([{ id: res.id, filename: res.filename }]))
            setErrorMessage(null)
            setNormalizedFileList([])
            onComplete(slides, res.id)
            dispatch(setTemplateUploadProgress(null))
            return slides
          } else {
            setErrorMessage(errMsg)
            setActiveKey('1')
          }
        })
        .then(() => {
          furtherFiles.forEach(file => {
            dispatch(
              appendTemplate({ file: file, projectId: projectId, cancelToken: cancelToken.token })
            )
              .unwrap()
              .then(res => {
                let slides = res?.templateJson?.slides || []
                if (slides.length > 0) {
                  dispatch(setTemplate([{ id: res.id, filename: res.filename }]))
                  setErrorMessage(null)
                  setNormalizedFileList([])
                  onComplete(slides, res.id)
                  dispatch(setTemplateUploadProgress(null))
                  return slides
                } else {
                  setErrorMessage(errMsg)
                  setActiveKey('1')
                }
              })
              .catch(rejected => {
                console.error(rejected)
                dispatch(getCurrentProject({ projectId }))
                setErrorMessage(errMsg + `\n${rejected}`)
                setActiveKey('1')
              })
          })
        })
        .catch(async rejected => {
          console.error(rejected)

          const response = await api.getProjectByID({ projectId })

          const templateId = response?.templates[0]?.id

          setErrorMessage(errMsg + `\n${rejected}`)
          setActiveKey('1')
          dispatch(setTemplateUploadProgress(null))

          if (templateId) {
            deleteProjectTemplate({ projectId: projectId, templateId: templateId })
            dispatch(removeTemplate(templateId))
          }

          dispatch(getCurrentProject({ projectId }))
        })
    }

    setNormalizedFileList([])
    onComplete([], -1)
    setLoading(false)
  }

  useEffect(() => {
    setErrorMessage(null)
    dispatch(setTemplateUploadProgress(null))
  }, [isVisible])

  const draggerProps = {
    name: 'file',
    beforeUpload(file, fileList) {
      const correctType =
        file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
      if (!correctType) {
        message.error('Sorry, you can only upload PPTX files.')
        return Upload.LIST_IGNORE
      }
      if (file.size / 1024 / 1024 > 25) {
        message.error('Sorry, file size must be under 25 MB')
        return false
      }
      setNormalizedFileList(fileList)
      return false
    },
    onRemove(file) {
      setUpdateModalHeight(!updateModalHeight)
      fileList.forEach(function (val, index) {
        if (val.uid === file.uid) {
          fileList.splice(index, 1)
          setNormalizedFileList(fileList)
        }
      })
    },
    accept: '.pptx',
    multiple: true,
    showUploadList: true,
    fileList: fileList
  }

  useEffect(() => {
    setUpdateModalHeight(!updateModalHeight)
  }, [fileList])

  return (
    <>
      <Flex justifyContent="center">
        <Form form={form} layout="vertical" onFinish={() => onSubmit({ fileList: fileList })}>
          <Space size="small" direction="vertical">
            <Form.Item
              className="upload-template-file-item"
              //label={templateIds.length > 0 ? 'Append template file' : 'Upload template file'}
              name="template"
              getValueFromEvent={normFile}
              valuePropName="fileList"
              rules={[{ validator: checkFile }]}
              required
            >
              <Space size="small" direction="vertical" style={{ height: '100%' }}>
                <Dragger {...draggerProps}>
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    style={{ width: '100%', height: '80px' }}
                  >
                    <FileAddOutlined style={{ fontSize: '24px' }} />
                  </Flex>
                  <p style={{ width: '70%', marginLeft: '15%', fontSize: '14px' }}>
                    Click or drag file to this area to upload
                  </p>
                </Dragger>
                {/* <Flex alignItems="center" justifyContent="center" flexDirection="column">
                  <p>or</p>

                  <Upload showUploadList={false} {...draggerProps}>
                    <Button loading={loading}>Choose file</Button>
                  </Upload>
                </Flex> */}
              </Space>
            </Form.Item>

            <Form.Item>
              {templateUploadProgress !== null ? (
                <Progress
                  style={{ marginLeft: '5px' }}
                  percent={templateUploadProgress || 0}
                  status={uploadStatus}
                />
              ) : null}
              <Flex alignItems="center" flexDirection="column">
                {errorMessage && (
                  <Text
                    type="danger"
                    style={{ maxWidth: 400, textAlign: 'center', padding: '10px 0' }}
                  >
                    {errorMessage}
                  </Text>
                )}
              </Flex>
            </Form.Item>
          </Space>
        </Form>
      </Flex>
      <Flex flexDirection="row" justifyContent="flex-end">
        <Space size="small" direction="horizontal">
          <Button
            disabled={fileList.length === 0}
            type="primary"
            onClick={() => form.submit()}
            key="submit"
            htmlType="submit"
            loading={loading}
          >
            {templateIds.length > 0
              ? i18n.t('editor.left-panel.slides.append-btn')
              : i18n.t('editor.left-panel.slides.upload-btn')}
          </Button>

          <Button onClick={onCancel} key="cancel">
            Cancel
          </Button>
        </Space>
      </Flex>
    </>
  )
}

export default TemplateUploader
