import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { Button, Form, Input, Layout, notification, Space } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import { LockOutlined } from '@ant-design/icons'

import { resetPassword } from '@/services/auth-service'

import { Navbar } from '@components/common/Navbar'

import '../styles.less'

import { useState } from 'react'

const { Header, Content } = Layout

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const notify = () => {
  notification.success({
    message: 'Password successfully reset',
    description: 'Sign in with your new password',
    className: 'notification',
    onClick: () => {},
    style: {
      backgroundColor: '#f6ffed',
      borderColor: '#c6efa7'
    }
  })
}

const ResetPassword = () => {
  const query = useQuery()
  const history = useHistory()
  const [form] = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string>(null)

  const resetPasswordHandler = async () => {
    const uid = query.get('uid')
    const token = query.get('token')
    const password = form.getFieldValue('password')
    const repeatPassword = form.getFieldValue('repeatPassword')
    setIsLoading(true)
    const response = await resetPassword({
      token,
      uid,
      newPassword: password,
      repeatPassword: repeatPassword
    })
    if (response.status === 200) {
      notify()
      history.push('/sign-in')
      form.setFieldsValue({
        password: '',
        repeatPassword: ''
      })
      setError(response.headers.error.message)
    } else {
    }
    setIsLoading(false)
  }

  return (
    <Layout className="layout" style={{ background: 'white' }}>
      <Header style={{ background: 'white' }}>
        <Navbar
          onPage="main"
          right={<Button onClick={() => history.push('sign-up')}>Sign up</Button>}
        />
      </Header>
      <Content>
        <div className="auth__container">
          <div className="auth__card">
            <div className="card__header">
              <h1>Reset password</h1>
              <p>Enter and confirm a new password</p>
            </div>

            <div className="card__body">
              <Form
                form={form}
                onFinish={resetPasswordHandler}
                layout="vertical"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                autoComplete="off"
              >
                <Space direction="vertical" size="middle">
                  <Form.Item
                    name="password"
                    label="Type your NEW password"
                    labelCol={{ span: 20, offset: 0 }}
                    rules={[{ required: true, message: 'Please enter your new password!' }]}
                  >
                    <Input.Password prefix={<LockOutlined />} />
                  </Form.Item>

                  <Form.Item
                    name="repeatPassword"
                    label="Retype your NEW password"
                    labelCol={{ span: 20, offset: 0 }}
                    dependencies={['password']}
                    rules={[
                      { required: true, message: 'Please confirm your password!' },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve()
                          }
                          return Promise.reject(new Error('Passwords do not match!'))
                        }
                      })
                    ]}
                  >
                    <Input.Password prefix={<LockOutlined />} />
                  </Form.Item>

                  <Form.Item wrapperCol={{ offset: 4, span: 16 }} style={{ marginTop: '18px' }}>
                    <Button type="primary" htmlType="submit" loading={isLoading} block>
                      Reset password
                    </Button>
                  </Form.Item>

                  <Form.Item>
                    <p style={{ textAlign: 'center' }}>{error ? error : null}</p>
                  </Form.Item>
                </Space>
              </Form>
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default ResetPassword
