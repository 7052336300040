import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Modal, notification, Radio, RadioChangeEvent, Space, Typography } from 'antd'

import { CopyOutlined } from '@ant-design/icons'
import i18n from 'i18next'

import { clearCanvasSelectedObject } from '@/store/slices/shapes/actions'
import { selectCanvasSelectedObject } from '@/store/slices/shapes/selectors'
import { AppDispatch } from '@/store/store'

import { createDataAction } from '@/services/data-actions-service'

import Info from '@/components/common/Info'

import { useLocalStorage } from '@/hooks'

import { useDataTransformation } from '../hooks'
import { ActionProps, ActionTypes, CopyOrder as TCopyOrder } from './types'

const { Link } = Typography

/** Set columns or rows in the given order */
const CopyOrder = ({ onCancel, isVisible, onSubmit }: ActionProps<TCopyOrder>) => {
  const dispatch: AppDispatch = useDispatch()
  const [axisValue, setAxisValue] = useState<0 | 1>(0)
  const selectedShape = useSelector(selectCanvasSelectedObject)
  const [modalVisible, setModalVisible] = useState(false)
  const [isSelecting, setIsSelecting] = useLocalStorage('isSelecting', false)
  const [target, setTarget] = useLocalStorage('targetId', null)

  const { dataTransformation } = useDataTransformation(
    selectedShape?.data?.definition?.transformationId
  )

  const onChange = (e: RadioChangeEvent) => {
    setAxisValue(e.target.value)
  }

  const onOk = async () => {
    setIsSelecting(false)
    const payload: TCopyOrder = {
      actionType: ActionTypes.COPY_ORDER,
      id: Number(selectedShape.id),
      axis: axisValue,
      shapeDataSelection: target,
      linkedDataSelection: dataTransformation.id
    }
    try {
      //@ts-ignore
      await createDataAction(payload)
      onSubmit(null)
    } catch (e) {
      notification.error({
        message: 'Copy order transformation failed.',
        description: e?.response?.data?.detail,
        top: 60,
        duration: 7
      })
    }
    setTarget(null)
  }

  const handleOnCancel = () => {
    setIsSelecting(false)
    setTarget(null)
    onCancel()
  }

  const handleSelectShape = () => {
    try {
      setTarget(selectedShape.data.definition.transformationId)
    } catch {
      setTarget(null)
    }
    setIsSelecting(true)
    dispatch(clearCanvasSelectedObject())
  }

  useEffect(() => {
    console.count('useeffect')
    if (isSelecting && selectedShape) {
      setModalVisible(true)
    }
    if (!isSelecting) {
      setModalVisible(false)
    }
  }, [isSelecting, selectedShape, isVisible])

  const popoverContent = (
    <div>
      <strong>Function</strong>
      <p>
        This function will take the order of rows/columns from any mapped object and apply to
        another mapped object
      </p>
      <strong>How to use</strong>
      <ol style={{ paddingLeft: '14px' }}>
        <li>Click "Select shape to copy from"</li>
        <li>Click on the chart or table object you wish to copy the order of</li>
        <li>Select to copy the order of rows or columns</li>
        <li>Click OK</li>
      </ol>
      <strong>NOTES</strong>
      <p>
        <em>
          This function uses index values and can therefore be used across objects that use
          different data tables
        </em>
      </p>
    </div>
  )

  return (
    <Modal
      title={
        <span>
          Copy order <Info>{popoverContent}</Info>
        </span>
      }
      visible={isVisible || modalVisible}
      onCancel={handleOnCancel}
      footer={[
        target && (
          <Button key={0} type="primary" onClick={onOk}>
            {i18n.t('editor.right-panel.data-source.form.okButton')}
          </Button>
        ),
        <Button key={1} onClick={handleOnCancel}>
          {i18n.t('editor.right-panel.data-source.form.cancelButton')}
        </Button>
      ]}
    >
      <Space direction="vertical" size="large">
        {!target && (
          <Link onClick={handleSelectShape}>
            <CopyOutlined></CopyOutlined> Select shape to copy order from
          </Link>
        )}
        {target && (
          <Radio.Group onChange={onChange} value={axisValue}>
            <Radio value={0}>Sort rows</Radio>
            <Radio value={1}>Sort columns</Radio>
          </Radio.Group>
        )}
      </Space>
    </Modal>
  )
}

export default CopyOrder
