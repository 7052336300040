import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Scrollbar } from 'react-scrollbars-custom'

import { Button, Input, Switch } from 'antd'

import { CloseSquareOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import i18next from 'i18next'

import { selectCurrentProjectId } from '@store/slices/projects/selectors'
import { selectCanvasObject, updateOneCanvasObject } from '@store/slices/shapes/actions'
import { selectCanvasObjects, selectCanvasSelectedObjectId } from '@store/slices/shapes/selectors'
import { setSlideObjectState } from '@store/slices/slides/actions'
import { selectCurrentSlideId, selectSlidesObjectState } from '@store/slices/slides/selectors'

import { updateShape } from '@/services/shape-service'

import { fabricCanvas } from '@/components/canvas/CanvasObject'

import { Flex } from '../../flex'
import Icon from '../../icon/Icon'

import './ShapesList.less'

const icons = {
  textbox: 'font',
  circle: 'circle',
  polygon: 'polygon',
  table: 'table',
  chart: 'chart-bar'
}

const ShapesList = ({ openShapeListDrawer, showShapelistDrawer }) => {
  const objects = useSelector(selectCanvasObjects)
  const selectedObjectId = useSelector(selectCanvasSelectedObjectId)
  const dispatch = useDispatch()
  const [filterObject, setFilterObject] = useState(null)
  const [filterValue, setFilterValue] = useState(null)
  const selectedSlideId = useSelector(selectCurrentSlideId)
  const selectSlidesObject = useSelector(selectSlidesObjectState)
  const selectedProjectId = useSelector(selectCurrentProjectId)
  const [arrOfObjects, setaArrOfObjects] = useState([])

  useEffect(() => {
    if (filterObject) {
      setFilterValue(null)
      setFilterObject(null)
    }
  }, [selectedSlideId])

  useEffect(() => {
    setaArrOfObjects(fabricCanvas.getObjects())
  }, [openShapeListDrawer])

  function arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex]
    arr.splice(fromIndex, 1)
    arr.splice(toIndex, 0, element)
    setaArrOfObjects([...arr])
  }

  function sendBackwards() {
    const getActiveObject = fabricCanvas.getActiveObject()
    if (getActiveObject) {
      let index = arrOfObjects.findIndex(object => {
        return object.id === getActiveObject['id']
      })
      if (index !== 1) {
        arraymove(arrOfObjects, index, index - 1)
        fabricCanvas.sendBackwards(getActiveObject)
      }
    }
  }

  function bringForward() {
    const getActiveObject = fabricCanvas.getActiveObject()
    if (getActiveObject) {
      let index = arrOfObjects.findIndex(object => {
        return object.id === getActiveObject['id']
      })
      arraymove(arrOfObjects, index, index + 1)
      fabricCanvas.bringForward(getActiveObject)
    }
  }

  function searchElements(e) {
    if (e.target.value) {
      let item = arrOfObjects.filter(o =>
        o.name.toLowerCase().includes(e.target.value.toLowerCase())
      )
      setFilterValue(e.target.value)
      setFilterObject(item)
    } else {
      setFilterValue(null)
      setFilterObject(null)
    }
  }

  const shapeExists = (arr, id) => {
    return arr.some(function (el) {
      return el.id === id
    })
  }

  const toggleVisibility = (visible: boolean, e, obj) => {
    let tempObject = JSON.parse(JSON.stringify(selectSlidesObject))
    let tempArr = []
    if (tempObject[selectedSlideId]) {
      tempArr = tempObject[selectedSlideId]
    }

    if (tempArr.length === 0) {
      tempArr.push({ id: obj.id, visibility: visible })
    }

    if (shapeExists(tempArr, obj.id)) {
      tempArr.forEach(function (o) {
        if (o['id'] === obj.id) {
          o['visibility'] = visible
        }
      })
    } else {
      tempArr.push({ id: obj.id, visibility: visible })
    }

    tempObject[selectedSlideId] = tempArr
    dispatch(setSlideObjectState(tempObject))
    dispatch(updateOneCanvasObject({ id: obj.id, changes: { visible } }))
    updateShape({ projectId: selectedProjectId, shapeId: obj.pk, payload: { visible: visible } })
  }

  return (
    <div className="canvas-shapes-list">
      <div className="ant-tabs-tabpane-header">
        Selection
        <span className="close-drawer">
          <CloseSquareOutlined onClick={openShapeListDrawer} />
        </span>
      </div>
      <Flex.Item className="rde-canvas-list-actions" flex="0 1 auto">
        <Flex>
          <Input.Search
            className="search-object"
            value={filterValue}
            placeholder={i18next.t('editor.main.shapes-list.search-node')}
            onChange={searchElements}
          />
        </Flex>
      </Flex.Item>
      {filterObject ? (
        <Scrollbar style={{ height: '85%' }}>
          {filterObject
            .slice(0)
            .reverse()
            .map(obj => (
              <div
                key={obj.id}
                className="shape-object"
                onClick={() => dispatch(selectCanvasObject(obj))}
              >
                <Flex
                  className={classNames({
                    'shape-object-container': true,
                    'shape-object-container-selected': selectedObjectId === obj.id
                  })}
                  justifyContent="space-between"
                >
                  <Flex>
                    <Icon name={icons[obj.type] || 'image'} size={1.5} style={{ width: 32 }} />
                    <div>{obj.name}</div>
                  </Flex>
                  <span style={{ flexShrink: '-moz-initial' }}>
                    <Switch
                      onChange={(c, e) => toggleVisibility(c, e, obj)}
                      size="small"
                      defaultChecked={obj.visible}
                    />
                  </span>
                </Flex>
              </div>
            ))}
        </Scrollbar>
      ) : (
        <>
          <Flex.Item>
            {/**
            <Flex justifyContent="space-between" alignItems="center" className="arraow-container">
              <Flex flex="1" justifyContent="center">
                <Button
                  className="rde-action-btn"
                  style={{ width: '100%', height: 30 }}
                  onClick={bringForward}
                >
                  <Icon name="arrow-up" />
                </Button>
              </Flex>
              <Flex flex="1" justifyContent="center">
                <Button
                  className="rde-action-btn"
                  style={{ width: '100%', height: 30 }}
                  onClick={sendBackwards}
                >
                  <Icon name="arrow-down" />
                </Button>
              </Flex>
            </Flex>
            */}
          </Flex.Item>
          <Scrollbar style={{ height: '85%', paddingBottom: '5%' }}>
            {arrOfObjects &&
              arrOfObjects
                .filter(o => o.id !== 'workarea')
                .slice(0)
                .reverse()
                .map(obj => (
                  <div
                    data-id={obj.id}
                    key={obj.id}
                    className="shape-object"
                    onClick={() => dispatch(selectCanvasObject(obj))}
                  >
                    <Flex
                      className={classNames({
                        'shape-object-container': true,
                        'shape-object-container-selected': selectedObjectId === obj.id
                      })}
                      justifyContent="space-between"
                    >
                      <Flex>
                        <Icon style={{ width: 32 }} name={icons[obj.type] || 'image'} size={1.5} />
                        <div>{obj.name}</div>
                      </Flex>
                      <span style={{ flexShrink: '-moz-initial' }}>
                        <Switch
                          onChange={(c, e) => {
                            toggleVisibility(c, e, obj)
                          }}
                          size="small"
                          defaultChecked={obj.visible}
                        />
                      </span>
                    </Flex>
                  </div>
                ))}
          </Scrollbar>
        </>
      )}
    </div>
  )
}

export default React.memo(ShapesList)
