import { createAsyncThunk } from '@reduxjs/toolkit'

import { setHealthStatus } from '@store/slices/health/actions'

import * as api from '@services/health-service'

import { HealthStatusResponse } from '@/interfaces/health'

export const getHealthStatus = createAsyncThunk<void, never, { rejectValue: string }>(
  'health/getStatus',
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const response: HealthStatusResponse = await api.getHealthStatus()
      dispatch(setHealthStatus(response))
    } catch (err) {
      return rejectWithValue(err.response?.data?.error?.message || null)
    }
  }
)
