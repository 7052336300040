import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import { Button, Form, Input, Space } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import { LockOutlined, MailOutlined } from '@ant-design/icons'

import {
  selectIsAuthenticated,
  selectSignInError,
  selectSignInLoading
} from '@/store/slices/auth/selectors'
import { AppDispatch } from '@/store/store'
import { clearSignInError } from '@store/slices/auth/actions'
import { signIn, signOut } from '@store/slices/auth/thunks'

import { reSendActivationEmail } from '@/services/auth-service'

import '../styles.less'

const EmailForm = ({ onSuccess }) => {
  const history = useHistory()
  const dispatch: AppDispatch = useDispatch()
  const error = useSelector(selectSignInError)
  const isLoading = useSelector(selectSignInLoading)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const [form] = useForm()
  const [notActivatedError, setNotActivatedError] = useState<string>(null)
  const errors = [error, notActivatedError]
  const signInUser = () => {
    const email = form.getFieldValue('email').toLowerCase()
    const password = form.getFieldValue('password')
    dispatch(
      signIn({
        email,
        password
      })
    ).then(response => {
      if (response?.meta?.requestStatus === 'fulfilled') {
        if (response.payload.active === true) {
          onSuccess({ email, password })
          form.setFieldsValue({
            email: '',
            password: ''
          })
          setNotActivatedError(null)
        } else {
          setNotActivatedError('Your account has not been activated, please, check your email.')
        }
      }
    })
  }

  const resendActivation = () => {
    const email = form.getFieldValue('email').toLowerCase()
    const password = form.getFieldValue('password')
    reSendActivationEmail({ email, password })
    setNotActivatedError(null)
  }

  const onValueChange = () => {
    if (error) {
      dispatch(clearSignInError())
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/projects')
    }
  }, [history, isAuthenticated])

  useEffect(() => {
    dispatch(clearSignInError())
  }, [dispatch])

  useEffect(() => {
    dispatch(signOut())
  }, [])

  return (
    <div className="auth__card">
      <div className="card__header">
        <h1>Sign in</h1>
        <p>
          Don't have an account? <Link to="sign-up">Sign up</Link>
        </p>
      </div>

      <div className="card__body">
        <Form
          form={form}
          onValuesChange={onValueChange}
          onFinish={signInUser}
          layout="vertical"
          style={{ width: '85%' }}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, type: 'email', message: 'Please enter a valid email' }]}
            >
              <Input placeholder="Email" prefix={<MailOutlined />} />
            </Form.Item>
            <Form.Item name="password" label="Password" rules={[{ required: true }]}>
              <Input.Password prefix={<LockOutlined />} />
            </Form.Item>

            <p>
              <Link to="forgot-password">Forgot password?</Link>
            </p>

            <Form.Item wrapperCol={{ offset: 4, span: 16 }} style={{ marginTop: '8px' }}>
              <Button type="primary" htmlType="submit" loading={isLoading} block>
                Sign in
              </Button>
            </Form.Item>

            {notActivatedError !== null && (
              <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                <Button onClick={resendActivation} htmlType="button" block>
                  Resend activation email
                </Button>
              </Form.Item>
            )}

            <Form.Item>
              {errors.map((error, index) => (
                <p key={index} style={{ textAlign: 'center' }}>
                  {error}
                </p>
              ))}
            </Form.Item>
          </Space>
        </Form>
      </div>
    </div>
  )
}

export default EmailForm
