import { useEffect, useRef, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useDispatch, useSelector } from 'react-redux'

import { notification } from 'antd'

import { fabric } from 'fabric'
import ResizeObserver from 'resize-observer-polyfill'

import { selectCanvasFilter } from '@/store/slices/canvas/canvas/selectors'
import { zoomToFit, zoomToRatio } from '@/store/slices/canvas/zoom/actions'
import { selectAllSlideIds, selectCurrentSlideId } from '@/store/slices/slides/selectors'
import { setCanvasCenterPoint, setCanvasDimensions } from '@store/slices/canvas/canvas/actions'
import { selectCanvasObjects } from '@store/slices/shapes/selectors'

import { fabricCanvas } from '@components/canvas/CanvasObject'
import { CANVAS_COMPONENTS } from '@components/canvas/components'
import { defaults } from '@components/canvas/constants'
import { useSelectionHandler } from '@components/canvas/handlers/canvas/useSelectionHandler'
import { useZoomHandler } from '@components/canvas/handlers/zoom/useZoomHandler'

import useEventsHandler from './handlers/canvas/useEventsHandler'
import { mapChartTypes } from './utils'

import './objects/Textarea'

const errorList = []

const openNotification = (error, object) => {
  if (!errorList.includes(object.id)) {
    notification.warning({
      key: object.id,
      duration: 4.5,
      message: object.name + " isn't being shown",
      description: (
        <>
          <p>Unfortunately, we encountered an error: {error}.</p>
          <p>Don't worry it will still be there when you download the file!</p>
        </>
      )
    })
    errorList.push(object.id)
  }
}

const CanvasWrapper = () => {
  const canvasId = 'unique_id_x1a'
  let canvasObjects = useSelector(selectCanvasObjects) || []

  const canvasFilter = useSelector(selectCanvasFilter)
  const currentSlide = useSelector(selectCurrentSlideId)
  const slideOrder = useSelector(selectAllSlideIds) || []

  const dispatch = useDispatch()
  const containerRef = useRef(null)
  const resizeObserver = useRef(null)
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    // @ts-ignore
    fabricCanvas.initialize(`canvas_${canvasId}`, defaults.canvasOption)
    fabricCanvas.setBackgroundColor('#f3f3f3', () => fabricCanvas.renderAll())
    fabricCanvas.renderAll()
    dispatch(
      setCanvasDimensions({ width: fabricCanvas.getWidth(), height: fabricCanvas.getHeight() })
    )
    dispatch(
      setCanvasCenterPoint({
        y: fabricCanvas.getCenter().top,
        x: fabricCanvas.getCenter().left
      })
    )
  }, [dispatch])

  const recenterSlide = () => {
    //before centering slide we make sure we're only selecting workarea
    //then we calculate the transform viewportcenter does
    //and do it to the other objects
    let objArr = fabricCanvas.getObjects() || []
    //@ts-ignore
    const selection = objArr.filter(obj => obj.id === 'workarea')
    //@ts-ignore
    const selection2 = objArr.filter(obj => obj.id !== 'workarea')

    let selectObjects = new fabric.ActiveSelection(selection, {
      canvas: fabricCanvas
    })
    let selectObjects2 = new fabric.ActiveSelection(selection2, {
      canvas: fabricCanvas
    })
    const activeObject = fabricCanvas.getActiveObject() || null // `undefined` seems to cause errors while `null` doesn't
    const leftBefore = selectObjects.left
    const topBefore = selectObjects.top

    fabricCanvas.setActiveObject(selectObjects) //store currently selected object
    fabricCanvas.viewportCenterObject(selectObjects)
    selectObjects.left = selectObjects.left
    selectObjects.setCoords()
    fabricCanvas.discardActiveObject()

    const leftAfter = selectObjects.left
    const leftDiff = leftBefore - leftAfter

    const topAfter = selectObjects.top
    const topDiff = topBefore - topAfter

    fabricCanvas.setActiveObject(selectObjects2)
    selectObjects2.left = selectObjects2.left - leftDiff
    selectObjects2.top = selectObjects2.top - topDiff
    selectObjects2.setCoords()
    fabricCanvas.discardActiveObject()
    fabricCanvas.renderAll()
    if (activeObject) fabricCanvas.setActiveObject(activeObject) //set active object back to original
  }

  useEffect(() => {
    let scaleMultiplier, heightScaleMultiplier
    let oldWidth = containerRef.current.clientWidth
    let oldHeight = containerRef.current.clientHeight
    fabricCanvas
      .setWidth(containerRef.current.clientWidth)
      .setHeight(containerRef.current.clientHeight)

    resizeObserver.current = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      const { width = 0, height = 0 } = (entries[0] && entries[0].contentRect) || {}

      scaleMultiplier = width / oldWidth
      oldWidth = width

      heightScaleMultiplier = height / oldHeight
      oldHeight = height

      fabricCanvas
        .setWidth(fabricCanvas.width * scaleMultiplier)
        .setHeight(fabricCanvas.height * heightScaleMultiplier)
      dispatch(
        setCanvasDimensions({ height: fabricCanvas.getHeight(), width: fabricCanvas.getWidth() })
      )

      dispatch(
        setCanvasCenterPoint({
          y: fabricCanvas.getCenter().top,
          x: fabricCanvas.getCenter().left
        })
      )
      dispatch(zoomToRatio(scaleMultiplier))
      setLoaded(true)
      recenterSlide()
      fabricCanvas.fire('window:resized')
    })

    if (containerRef) {
      resizeObserver.current.observe(containerRef.current)
    }
    return () => {
      if (resizeObserver && containerRef.current) {
        resizeObserver.current.unobserve(containerRef.current)
        //resizeObserver.disconnect()
      }
    }
  }, [containerRef, dispatch])

  //we generally want to zoomtofit on each slide
  //if we do zoomtofit in selectslide then the canvaselements won't render in time
  //and the slide will be off centre
  //so instead we do it here
  useEffect(() => {
    const allObjects = fabricCanvas.getObjects()
    if (allObjects.length > 0) {
      dispatch(zoomToFit())
    }
  }, [`${canvasObjects}`]) //use string literals as klass doesn't like JSON.stringify

  useZoomHandler()
  useEventsHandler()
  useSelectionHandler()

  return (
    <div
      ref={containerRef}
      id={canvasId}
      className="rde-canvas"
      style={{ width: '100%', height: '100%' }}
    >
      <canvas id={`canvas_${canvasId}`} />

      {loaded &&
        (slideOrder.indexOf(currentSlide) >= 0 || canvasObjects.length === 1) &&
        canvasObjects
          .filter(obj => !canvasFilter.includes(obj.type) || obj.id === 'workarea')
          .map(object => {
            if (object.type === 'NO_SHAPE_TYPE') {
              const Component = CANVAS_COMPONENTS['placeholder']
              // @ts-ignore
              return Component ? (
                <ErrorBoundary
                  key={object.id}
                  fallbackRender={({ error, resetErrorBoundary }) => {
                    openNotification(error.message, object)
                    return <></>
                  }}
                >
                  {/*
                  //@ts-ignore */}
                  <Component id={object.id} options={object} />
                </ErrorBoundary>
              ) : (
                <></>
              )
            }

            if (object.type === 'chart') {
              const type = object.chart?.type
              const [mappedType] = mapChartTypes(type)
              if (mappedType === null) {
                const Component = CANVAS_COMPONENTS['placeholder']
                // @ts-ignore
                return Component ? (
                  <ErrorBoundary
                    key={object.id}
                    fallbackRender={({ error, resetErrorBoundary }) => {
                      return <></>
                    }}
                    onError={error => openNotification(error.message, object)}
                  >
                    {/*
                    //@ts-ignore */}
                    <Component id={object.id} options={object} />
                  </ErrorBoundary>
                ) : (
                  <></>
                )
              }
              const Component = CANVAS_COMPONENTS[object.type]
              // @ts-ignore
              return Component ? (
                <ErrorBoundary
                  key={object.id}
                  fallbackRender={({ error, resetErrorBoundary }) => {
                    return <></>
                  }}
                  onError={error => openNotification(error.message, object)}
                >
                  {/*
                  //@ts-ignore */}
                  <Component id={object.id} options={object} />
                </ErrorBoundary>
              ) : (
                <></>
              )
            }

            if (object.type === 'table') {
              const Component = CANVAS_COMPONENTS[object.type]

              return Component ? (
                <ErrorBoundary
                  key={object.id}
                  fallbackRender={({ error, resetErrorBoundary }) => {
                    return <></>
                  }}
                  onError={error => openNotification(error.message, object)}
                >
                  {/*
                // @ts-ignore */}
                  <Component id={object.id} options={object} />
                </ErrorBoundary>
              ) : (
                <></>
              )
            }

            const Component = CANVAS_COMPONENTS[object.type]
            return Component ? (
              <ErrorBoundary
                key={object.id}
                fallbackRender={({ error, resetErrorBoundary }) => {
                  return <></>
                }}
                onError={error => openNotification(error.message, object)}
              >
                <Component id={object.id} options={object} /> : <></>
              </ErrorBoundary>
            ) : (
              <></>
            )
          })}
    </div>
  )
}

export default CanvasWrapper
