function _is(keyword: string): boolean {
  const pattern = new RegExp(`${keyword}\/[0-9]+`, 'i')
  return navigator.userAgent.search(pattern) !== -1
}

export function verifyUserAgent(): [boolean, string] {
  const isFirefox = _is('Firefox')
  const isSamsung = _is('SamsungBrowser')
  const isOpera = _is('Opera') || _is('OPR')
  const isIE = _is('Trident')
  const isEdge = _is('Edg') && !_is('Edge')
  const isEdgeLegacy = _is('Edge')
  const isChrome = _is('Chrome')
  const isSafari = _is('Safari')
  let browser: string
  if (isIE) {
    browser = 'Microsoft Internet Explorer'
  } else if (isEdge) {
    browser = 'Microsoft Edge (Chromium)'
  } else if (isEdgeLegacy) {
    browser = 'Microsoft Edge (Legacy)'
  } else if (isFirefox) {
    browser = 'Mozilla Firefox'
  } else if (isSamsung) {
    browser = 'Samsung Internet'
  } else if (isOpera) {
    browser = 'Opera'
  } else if (isChrome) {
    browser = 'Google Chrome or Chromium'
  } else if (isSafari) {
    browser = 'Apple Safari'
  } else {
    browser = 'unknown'
  }
  const isSupported = isFirefox || isSamsung || isOpera || isEdge || isChrome
  return [isSupported, browser]
}
