import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Parser } from '@utils/parser'

import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Input,
  notification,
  Progress,
  Radio,
  Result,
  Space,
  Tabs
} from 'antd'
import type { RadioChangeEvent } from 'antd'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { TransferItem } from 'antd/lib/transfer'

import {
  selectSlideDataSelections,
  selectSlideDataSources,
  selectTextInterpolationIds
} from '@/store/slices/data/selectors'
import { selectCurrentProjectId } from '@/store/slices/projects/selectors'
import { selectCurrentSlideId } from '@/store/slices/slides/selectors'
import { addManySlides } from '@store/slices/slides/actions'
import { setProjectSlidesOrder } from '@store/slices/projects/actions'

import { repeatSlide } from '@services/slide-service'

import CommonModal from '@/components/common/CommonModal'
import Info from '@/components/common/Info'
import Report from '@/components/imagemap/main/CompatibilityReport'
import type { ReportEntry } from '@/components/imagemap/main/CompatibilityReport'

import { useTables } from '../../imagemap/right-panel/hooks/'
import { useDataTransformation } from '../right-panel/hooks'

const { Panel } = Collapse

const notify = (reason: string) => {
  switch (reason) {
    case 'inconsistentData':
      notification.warning({
        message: 'Inconsistent data',
        description: 'Two or more shapes use different data sources. Operation canceled.',
        top: 60
      })
      break
    case 'noData':
      notification.warning({
        message: 'No data',
        description: 'No shapes have data associated with them. Operation canceled.',
        top: 60
      })
      break
    case 'multipleDataShapes':
      notification.warning({
        message: 'Multiple shapes have associated data.',
        description: 'This is currently not supported but will change in the future.',
        top: 60
      })
      break
  }
}

const Feedback = ({ visible, feedback, score, onOk }) => {
  const subtitle = feedback
    ? ''
    : 'There is no extra feedback, you may process to the compatibility report.'
  return (
    <CommonModal
      visible={visible}
      width={700}
      maxHeight={1000}
      footer={[
        <Button type="primary" onClick={onOk}>
          Next
        </Button>
      ]}
    >
      <Space size="middle" direction="vertical" align="center" style={{ width: '100%' }}>
        <Result
          icon={<Progress type="circle" percent={score * 100} />}
          title="Repeat function result"
          subTitle={subtitle}
          extra={(feedback || []).map(entry => (
            <Alert type="info" description={entry} showIcon />
          ))}
        />
      </Space>
    </CommonModal>
  )
}

interface DataTableRecord {
  id: number
  name: string
}

function transformDataTables(dataTables: DataTableRecord[]): TransferItem[] {
  return dataTables.map(({ id, name }) => {
    return { key: id.toString(), title: name }
  })
}

type Props = {
  show: boolean
  setVisibility: (value: boolean) => void
}

const BulkRepeatSlide = (props: Props) => {
  const dataSources = useSelector(selectSlideDataSources)
  const dataSelections = useSelector(selectSlideDataSelections)
  const dataSectionIds = useMemo(
    () => dataSources?.[0]?.dataSections.map(item => item.id) || null,
    [dataSources]
  )

  const interpolationIds = useSelector(selectTextInterpolationIds)
  const slideId = useSelector(selectCurrentSlideId)
  const projectId = useSelector(selectCurrentProjectId)
  const [allOptions, setAllOptions] = useState<{ label: string; value: string }[]>([])
  const [specifiedIndex, setSpecifiedIndex] = useState([])
  const [selectedKeys, setSelectedKeys] = useState<CheckboxValueType[]>([])
  const [report, setReport] = useState(null)
  const [copyDataTransformations, setCopyDataTransformations] = useState(true)
  const [copySignificanceTests, setCopySignificanceTests] = useState(true)
  const [copyIndexing, setCopyIndexing] = useState(true)
  const [indexMatching, setIndexMatching] = useState(true)
  const [globalStrategy, setGlobalStrategy] = useState(false)
  const [reindexingStrategy, setReindexingStrategy] = useState<
    'by_name' | 'by_parent_group' | 'by_group_inclusion' | null
  >(null)
  const [indeterminate, setIndeterminate] = useState(true)
  const [checkAll, setCheckAll] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const filteredOptions = allOptions.filter(el =>
    el.label.toLowerCase().includes(searchTerm.toLowerCase())
  )
  const [activeTab, setActiveTab] = useState<string>('1')
  const [step, setStep] = useState<number>(0)

  const { tables } = useTables(dataSectionIds)
  const { dataTransformation } = useDataTransformation(dataSelections?.[0] || null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (tables.length > 0) {
      const items = transformDataTables(tables)

      if (items && items.length) {
        setAllOptions(
          items.map(el => {
            return { value: el.key, label: el.title }
          })
        )
      }
    }
  }, [tables])

  const cleanUp = () => {
    setAllOptions([])
    setSelectedKeys([])
    setReport(null)
    setStep(0)
    props.setVisibility(false)
  }

  const onOk = async () => {
    if (dataSources.length !== 1 || dataSelections.length !== 1) {
      if (dataSelections.length === 0) {
        notify('noData')
      } else if (dataSources.length > 1) {
        notify('inconsistentData')
      }
    }
    const dataTableIds = selectedKeys.map(id => +id)
    const { objects, report, order } = await repeatSlide({
      projectId,
      slideId,
      dataTablePks: dataTableIds,
      dataSelectionPks: dataSelections,
      interpolationPks: interpolationIds,
      copyTransformations: copyDataTransformations,
      copySignificanceTest: copySignificanceTests,
      copyIndexing: copyIndexing,
      reindexingStrategy: reindexingStrategy,
      globalStrategy: globalStrategy,
      indexMatching: indexMatching,
      specifiedIndex: specifiedIndex,
      repeatType: activeTab === '1' ? 'data_tables' : 'columns'
    })
    setReport(report)
    setStep(1)
    setCopyDataTransformations(true)
    setCopySignificanceTests(true)
    dispatch(addManySlides({ objects, order }))
    dispatch(setProjectSlidesOrder(order))
  }

  const onCancel = () => {
    props.setVisibility(false)
  }

  const onChange = (checkedValues: CheckboxValueType[]) => {
    // keys that were checked previously and not in the current filteredOptions
    // so they aren't listed in checkedValues
    const preservedKeys = selectedKeys.filter(
      key => !filteredOptions.map(opt => opt.value).includes(key as string)
    )
    setSelectedKeys([...preservedKeys, ...checkedValues])
    setIndeterminate(!!checkedValues.length && checkedValues.length < allOptions.length)
    setCheckAll(checkedValues.length === allOptions.length)
  }

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setSelectedKeys(e.target.checked ? filteredOptions.map(el => el.value) : [])
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }

  const onDelete = (id: number, _tableId: number) => {
    setReport(prevReport => {
      const nextReport = {
        ...prevReport,
        records: {
          ...prevReport.records,
          [slideId]: prevReport.records[slideId].filter((entry: ReportEntry) => entry.id !== id)
        }
      }
      return nextReport
    })
  }

  const onEdit = (id: number, _tableId: number) => {
    setReport(prevReport => {
      const nextReport = {
        ...prevReport,
        records: {
          ...prevReport.records,
          [slideId]: prevReport.records[slideId].map((entry: ReportEntry) =>
            entry.id === id ? { ...entry, compatible: 1 } : entry
          )
        }
      }
      return nextReport
    })
  }

  const options = [
    { label: 'Copy data transformations?', value: 'transformations' },
    { label: 'Copy significance tests?', value: 'sigTest' },
    { label: 'Copy percentage indexing?', value: 'indexing' },
    { label: 'Match transformation indexes', value: 'indexMatching' }
  ]

  const onToggleOptions = (checkedValues: CheckboxValueType[]) => {
    if (checkedValues.includes('transformations')) {
      setCopyDataTransformations(true)
    } else {
      setCopyDataTransformations(false)
    }
    if (checkedValues.includes('sigTest')) {
      setCopySignificanceTests(true)
    } else {
      setCopySignificanceTests(false)
    }
    if (checkedValues.includes('indexing')) {
      setCopyIndexing(true)
    } else {
      setCopyIndexing(false)
    }
    if (checkedValues.includes('indexMatching')) {
      setIndexMatching(true)
    } else {
      setIndexMatching(false)
    }
  }

  const toggleCopyDataTransformations = (e: CheckboxChangeEvent) => {
    setCopyDataTransformations(e.target.checked)
  }

  const toggleCopySignificanceTests = (e: CheckboxChangeEvent) => {
    setCopySignificanceTests(e.target.checked)
  }

  const toggleCopyIndexing = (e: CheckboxChangeEvent) => {
    setCopyIndexing(e.target.checked)
  }

  const toggleGlobalStrategy = (e: CheckboxChangeEvent) => {
    setGlobalStrategy(e.target.checked)
  }

  const onStrategyChange = (e: RadioChangeEvent) => {
    setReindexingStrategy(e.target.value)
  }

  const onChangeIndex = e => {
    const parser = new Parser()
    let value = parser.parse(e.target.value).map(val => val - 1)
    setSpecifiedIndex(value)
  }

  const popoverContent = (
    <div>
      <div>
        <p>
          This feature allows you to generate duplicate slides, either moving down your data source,
          replacing the <b>data tables</b> on the original slide, or across your data source,
          replacing the <b>columns</b> <b>inserted into objects</b> on the original slide.
        </p>
      </div>

      <h4>
        <strong>Repeat Data Tables</strong>
      </h4>
      <div>
        <p>
          Duplicate the current slide and replace the data table(s) used on the original slide with
          those selected by the user in the function. By default, data will be inserted on generated
          slides as follows:
        </p>

        <p>
          Charts/Tables: Columns will be inserted using the column index(es) used on the original
          slide. For example, if columns 1-3 are used on the original slide, then whichever columns
          appear in positions 1-3 in the data tables selected in the function will be used,
          regardless of whether these columns use the same name.
        </p>

        <p>
          Tags: Data will be inserted into tags using the exact position from the original table.
          For example, if a cell from column 3, row 5 is selected on the original slide, the same
          tag on duplicate slides will use the exact same cell from all the other tables selected in
          the function.
        </p>
      </div>
      <h4>
        <strong>Repeat Columns</strong>
      </h4>
      <div>
        <p>
          Duplicate the current slide and replace the column(s) selected for mapping to chart/table
          objects. The function will insert only one column by default, with data selected on
          generated slides as follows:
        </p>

        <p>
          Charts/Tables: Each generated slide will use a different column from the same data table
          inserted on the original slide.
        </p>

        <p>
          Tags: Data will be inserted into tags using the exact position from the original table.
          For example, if a cell from column 3, row 5 is selected on the original slide, the exact
          same cell will be inserted on all generated slides.
        </p>
      </div>
      <h4>
        <strong>Advanced Options</strong>
      </h4>
      <div>
        <p>
          Copy data transformations: Enable this feature to ensure any data transformations from the
          original slide are copied over to generated slides. This is enabled by default
        </p>

        <p>
          Copy significance tests: Enable this feature to ensure any significance tests from the
          original slide are copied over to generated slides. This is enabled by default
        </p>

        <p>Reordering strategies (Repeat Data Tables):</p>
        <ul style={{ marginLeft: '40px' }}>
          <li>
            By Column Position: The platform will use the positional indexes of columns inserted on
            the original slide and match to the same position on generated slides
          </li>
          <li>
            By Column Name: The platform will search for the exact named columns used on the
            original slide
          </li>
          <li>
            By Column Position in Group: The platform will insert using positional indexes with
            reference to column groups. For example, if the second column from the second column
            group is used on the original slide, any slides generated from other tables will also
            take the second column, from the second column group, regardless of the number of
            columns in selected tables
          </li>
          <li>
            All Columns by Group Position: The platform will insert all columns from whichever{' '}
            <b>numbered</b> column group was used on the original slide. For example, if column 2
            from column group 3 was used on the original slide, ALL columns from column group 3 will
            be inserted into generated slides.
          </li>
          <li>
            All Columns by Group Name: The platform will insert all columns from whichever{' '}
            <b>named</b> column group was used on the original slide. For example, if column 2 from
            the “Region” column group was used on the original slide, ALL columns from the “Region”
            column group will be inserted into generated slides. This is particularly useful if the
            structure of data tables is inconsistent across your data source
          </li>
        </ul>

        <p>Reordering strategies (Repeat Columns):</p>
        <ul style={{ marginLeft: '40px' }}>
          <li>
            Slide per Column: The platform will generate a slide for each column in the data table.
            Only one column will be inserted on each new slide
          </li>
          <li>
            Slide per Column in Group: The platform will generate slides but only for the columns in
            the column group used on the original slide.
          </li>
          <li>
            Slide per Group: The platform will generate a new slide for each column group in the
            data table, inserting all columns from each group.
          </li>
        </ul>
      </div>
      <h4>
        <strong>Notes</strong>
      </h4>
      <div>
        <li>- The function requires data to be mapped to at least one object</li>
        <li>
          - Rows inserted into generated slides will include all rows with the selected row tag type
        </li>
        <li>
          - Repeat Data Tables cannot be used with slides that include objects mapped from different
          data tables
        </li>
      </div>
    </div>
  )

  const repeatDataTables = (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
          Check all
        </Checkbox>

        <Input.Search
          style={{ width: '420px', paddingTop: '15px' }}
          className="search-object"
          placeholder="Search table name"
          onChange={e => setSearchTerm(e?.target?.value)}
        />

        <Divider />

        <div style={{ overflowX: 'scroll', maxHeight: '420px' }}>
          <Checkbox.Group options={filteredOptions} onChange={onChange} value={selectedKeys} />
        </div>

        <Divider />

        <Collapse ghost>
          <Panel header="Advanced options" key="1">
            <Checkbox onChange={toggleCopyDataTransformations} checked={copyDataTransformations}>
              Copy data transformations?
            </Checkbox>
            <Checkbox onChange={toggleCopySignificanceTests} checked={copySignificanceTests}>
              Copy significance tests?
            </Checkbox>
            <Checkbox onChange={toggleCopyIndexing} checked={copyIndexing}>
              Copy percentage indexing?
            </Checkbox>
            <p style={{ margin: '14px 0px 8px 0px' }}>How should we select columns?</p>
            <Checkbox
              style={{ margin: '0px 0px 14px 0px' }}
              onChange={toggleGlobalStrategy}
              checked={globalStrategy}
            >
              Override stored data selection strategy?
            </Checkbox>
            <Radio.Group
              onChange={onStrategyChange}
              value={reindexingStrategy}
              disabled={!globalStrategy}
            >
              <Space wrap>
                <Radio value={null}>By Column Position</Radio>
                <Radio value="by_absolute_position">By Absolute Position</Radio>
                <Radio value="by_name">By Column Name</Radio>
                <Radio value="by_parent_group">By Column Position in Group</Radio>
                <Radio value="by_group_inclusion">All Columns by Group Position</Radio>
                <Radio value="by_group_name">All Columns by Group Name</Radio>
              </Space>
            </Radio.Group>
          </Panel>
        </Collapse>
      </Space>
    </>
  )

  const repeatColumns = (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Divider />

        <Collapse ghost>
          <Panel header="Advanced options" key="1">
            <Checkbox.Group
              options={options}
              onChange={onToggleOptions}
              defaultValue={['transformations', 'sigTest', 'indexing', 'indexMatching']}
            />
            <p style={{ margin: '14px 0px 8px 0px' }}>How should we build slides?</p>
            <Radio.Group onChange={onStrategyChange} value={reindexingStrategy}>
              <Space wrap>
                <Radio value={null}>Slide per Column</Radio>
                <Radio value="by_group_inclusion">Slide per Column in Group</Radio>
                <Radio value="by_parent_group">Slide per Group</Radio>
              </Space>
            </Radio.Group>
            <div style={{ margin: '14px 0px 8px 0px' }}>
              <Input
                placeholder="Optionally, provide specific column indexes (e.g.: 1,3-5)"
                onChange={onChangeIndex}
                disabled={reindexingStrategy !== null}
              />
            </div>
          </Panel>
        </Collapse>
      </Space>
    </>
  )

  const onFeedbackOk = () => {
    setStep(2)
  }

  const onTabChange = (key: string) => {
    setActiveTab(key)
  }

  return (
    <>
      <Feedback
        visible={step === 1}
        feedback={report?.feedback}
        score={report?.compatibilityRatio}
        onOk={onFeedbackOk}
      />
      <Report
        visible={step === 2}
        report={report?.records}
        onDeleteEntry={onDelete}
        onEditEntry={onEdit}
        rowTags={dataTransformation?.rowTags || []}
        onFinish={cleanUp}
      />
      <CommonModal
        title={
          <>
            Repeat Slide <Info>{popoverContent}</Info>
          </>
        }
        visible={props.show}
        onCancel={onCancel}
        width={700}
        maxHeight={1000}
        footer={[
          <Button
            onClick={onOk}
            type="primary"
            disabled={selectedKeys.length === 0 && activeTab === '1'}
            key={0}
          >
            Ok
          </Button>,
          <Button key={1} onClick={onCancel}>
            Cancel
          </Button>
        ]}
      >
        <Tabs onChange={onTabChange}>
          <Tabs.TabPane tab="Repeat Data Tables" key="1">
            {repeatDataTables}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Repeat Columns" key="2">
            {repeatColumns}
          </Tabs.TabPane>
        </Tabs>
      </CommonModal>
    </>
  )
}

export default BulkRepeatSlide
