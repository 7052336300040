import React from 'react'

import { fabric } from 'fabric'

import { FabricObject } from '@components/canvas/types/object'

import {
  Arrow,
  Cube,
  CurvedLink,
  Element,
  Iframe,
  Line,
  Link,
  Node,
  OrthogonalLink
} from './objects'
import { Code } from './objects/Element'
import Svg, { SvgOption } from './objects/Svg'
import { FabricCanvas } from './utils'

import './objects/Chart'

export interface ComponentProps {
  onChange: (id: string, object: any) => void
  id: string
}

export interface ObjectSchema {
  create: (...option: any) => fabric.Object
  component?: React.FC<ComponentProps>
}

export interface CanvasObjectSchema {
  [key: string]: ObjectSchema
}

export const createCanvasObject = (objectSchema: CanvasObjectSchema) => objectSchema

const CanvasObject: CanvasObjectSchema = {
  group: {
    create: ({ objects, ...option }: { objects: FabricObject[] }) =>
      new fabric.Group(objects, option)
  },
  'i-text': {
    create: ({ text, ...option }: { text: string }) => new fabric.IText(text, option)
  },
  textbox: {
    create: ({ text, ...option }: { text: string }) => new fabric.Textbox(text, option)
  },
  triangle: {
    create: (option: any) => new fabric.Triangle(option)
  },
  circle: {
    create: (option: any) => new fabric.Circle(option)
  },
  rect: {
    create: (option: any) => new fabric.Rect(option)
  },
  cube: {
    create: (option: any) => new Cube(option)
  },
  image: {
    create: ({ element = new Image(), ...option }) =>
      new fabric.Image(element, {
        ...option,
        crossOrigin: 'anonymous'
      })
  },
  polygon: {
    create: ({ points, ...option }: { points: any }) =>
      new fabric.Polygon(points, {
        ...option,
        perPixelTargetFind: true
      })
  },
  line: {
    create: ({ points, ...option }: { points: any }) => new Line(points, option)
  },
  arrow: {
    create: ({ points, ...option }: { points: any }) => new Arrow(points, option)
  },
  table: {
    create: option => new fabric.Table(option)
  },
  element: {
    create: ({ code, ...option }: { code: Code }) => new Element(code, option)
  },
  iframe: {
    create: ({ src, ...option }: { src: string }) => new Iframe(src, option)
  },
  node: {
    create: (option: any) => new Node(option)
  },
  link: {
    create: (fromNode, fromPort, toNode, toPort, option) =>
      new Link(fromNode, fromPort, toNode, toPort, option)
  },
  curvedLink: {
    create: (fromNode, fromPort, toNode, toPort, option) =>
      new CurvedLink(fromNode, fromPort, toNode, toPort, option)
  },
  orthogonalLink: {
    create: (fromNode, fromPort, toNode, toPort, option) =>
      new OrthogonalLink(fromNode, fromPort, toNode, toPort, option)
  },
  svg: {
    create: (option: SvgOption) => new Svg(option)
  }
}
// @ts-ignore
export const fabricCanvas: FabricCanvas = new fabric.Canvas()
