import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import { updateOneCanvasObject } from '@store/slices/shapes/actions'
import { selectCanvasSelectedObject } from '@store/slices/shapes/selectors'

import IconChooser from '@components/icon/IconChooser'

const MarkerProperty = () => {
  const selectedItem = useSelector(selectCanvasSelectedObject)
  const dispatch = useDispatch()
  const [form] = useForm()

  useEffect(() => {
    form.resetFields()
  }, [form, selectedItem])

  const changeProperties = changedValues => {
    const changedKey = Object.keys(changedValues)[0]
    const changedValue = changedValues[changedKey]
    const { unicode, styles } = changedValue[Object.keys(changedValue)[0]]
    const uni = parseInt(unicode, 16)
    let font = 'Font Awesome 5 Free'
    if (styles[0] === 'brands') {
      font = 'Font Awesome 5 Brands'
    } else if (styles[0] === 'regular') {
      font = 'Font Awesome 5 Regular'
    }

    // dispatch(
    //   updateOneCanvasObject({
    //     id: selectedItem.id,
    //     changes: {
    //       text: String.fromCodePoint(uni),
    //       icon: changedValue,
    //       fontFamily: font
    //     }
    //   })
    // )
  }

  return (
    <Form form={form} onValuesChange={changeProperties}>
      {/* <Form.Item name="icon" initialValue={selectedItem.icon}>
        <IconChooser icon={selectedItem.icon} />
      </Form.Item> */}
    </Form>
  )
}

export default MarkerProperty
