import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Spin, Tabs, Tooltip } from 'antd'

import i18n from 'i18next'

import { RootState } from '@/store/rootReducer'
import {
  selectCanvasSelectedObject,
  selectCanvasSelectedObjectDataDefinition,
  selectCanvasSelectedObjectId,
  selectCanvasSelectedObjectProperty,
  selectCanvasSelectionLoading
} from '@store/slices/shapes/selectors'
import { DefaultDataDefinition } from '@store/slices/shapes/types'

import DataSource from '@components/imagemap/right-panel/DataSource'
import DataTransformation from '@components/imagemap/right-panel/DataTransformation'

import Icon from '../icon/Icon'
import DataLayouts from './right-panel/DataLayouts'
import NodeProperties from './right-panel/NodeProperties'

const EditorRightPanel = () => {
  const loading = useSelector(selectCanvasSelectionLoading)
  const selectedObjectId = useSelector(selectCanvasSelectedObjectId)
  const selectedShape = useSelector(selectCanvasSelectedObject)
  //@ts-ignore
  const type = useSelector((s: RootState) => selectCanvasSelectedObjectProperty(s, 'type'))

  const dataDefinition = useSelector(
    selectCanvasSelectedObjectDataDefinition
  ) as DefaultDataDefinition
  const [activeKey, setActiveKey] = useState()

  const wrapperSetActiveKey = useCallback(
    val => {
      setActiveKey(val)
    },
    [setActiveKey]
  )

  useEffect(() => {
    //if there's no data mapped it should default to styles
    //also make sure we have an object selected to prevent it changing on resize
    if (!dataDefinition && selectedObjectId) {
      //@ts-ignore
      setActiveKey('styles')
    }
  }, [selectedObjectId])

  return (
    <div className={`rde-editor-configurations`} id="editor-right-panel">
      <Tabs
        activeKey={activeKey}
        onTabClick={wrapperSetActiveKey}
        id="editor-right-panel-tabs"
        tabPosition="right"
        style={{ height: '100%' }}
        tabBarStyle={{ marginTop: 12 }}
      >
        <Tabs.TabPane
          tab={
            <Tooltip
              id="editor-right-panel-data-source"
              placement="left"
              title={i18n.t('editor.right-panel.data-source.title')}
            >
              <span id="editor-right-panel-tabs-tabpane-tooltip-span">
                <Icon
                  name="table"
                  id="editor-right-panel-tabs-tabpane-tooltip-span-table"
                  prefix="fas"
                />
              </span>
            </Tooltip>
          }
          key="styles"
          id="editor-right-panel-tabs-tabpane"
        >
          <Spin spinning={loading}>
            <DataSource />
            {/* type !== 'textbox' && (
              <div style={{ padding: '0 16px' }}>
                <Button
                  block
                  onClick={() => {
                    setInsertIsOpen(true)
                  }}
                  disabled={selectedShape == null}
                >
                  Insert data
                </Button>
              </div>
            ) */}
          </Spin>
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={
            <Tooltip
              id="editor-right-panel-data-transformation"
              placement="left"
              title={i18n.t('editor.right-panel.data-transformation.title')}
            >
              <span>
                <Icon name="server" id="editor-right-panel-server" prefix="fas" />
              </span>
            </Tooltip>
          }
          id="editor-right-panel-tabs-tabpane-2"
          key="data-transformation"
        >
          <Spin spinning={loading}>
            <DataTransformation />
          </Spin>
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={
            <Tooltip
              id="editor-right-panel-shape-settings"
              placement="left"
              title={i18n.t('editor.right-panel.shape-settings.title')}
            >
              <span>
                <Icon name="cogs" id="editor-right-panel-cogs" prefix="fa" />
              </span>
            </Tooltip>
          }
          key="object"
        >
          <Spin spinning={loading}>
            <NodeProperties />
          </Spin>
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={
            <Tooltip
              id="editor-right-panel-analytics"
              placement="left"
              title={i18n.t('editor.right-panel.analytics.title')}
            >
              <span>
                <Icon name="brush" id="editor-right-panel-flask" prefix="fa" />
              </span>
            </Tooltip>
          }
          key="overlays"
          id="editor-right-panel-tabs-tabpane-3"
        >
          <Spin spinning={loading}>
            <DataLayouts setActiveKey={wrapperSetActiveKey} />
          </Spin>
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

export default React.memo(EditorRightPanel)
