import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Form, Select } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import i18n from 'i18next'

import { updateOneCanvasObject } from '@store/slices/shapes/actions'
import { selectCanvasSelectedObject } from '@store/slices/shapes/selectors'

import { FabricChartOptions } from '@components/canvas/types/chart'

const ChartProperty = () => {
  const selectedItem = useSelector(selectCanvasSelectedObject) as FabricChartOptions
  const dispatch = useDispatch()
  const CHART_TYPES = {
    Line: 'LINE',
    'Line Stacked': 'LINE_STACKED',
    Bar: 'COLUMN_CLUSTERED',
    'Bar Stacked': 'COLUMN_STACKED',
    'Horizontal Bar': 'BAR_CLUSTERED',
    'Horizontal Bar Stacked': 'BAR_STACKED',
    Doughnut: 'DOUGHNUT',
    Radar: 'RADAR',
    Pie: 'PIE'
  }
  const [form] = useForm()
  useEffect(() => {
    form.resetFields()
  }, [form, selectedItem])
  const changeProperty = (changedValues, allValues) => {
    dispatch(
      updateOneCanvasObject({
        id: selectedItem.id,
        changes: { chart: { ...selectedItem.chart, ...allValues } }
      })
    )
  }

  return (
    <Form onValuesChange={changeProperty} form={form}>
      <Form.Item
        label={i18n.t('editor.right-panel.chart.type')}
        name="type"
        rules={[{ required: true }]}
        initialValue={selectedItem.chart?.type}
      >
        <Select<string | number, { value: string; children: string }>
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {Object.keys(CHART_TYPES).map(type => (
            <Select.Option value={CHART_TYPES[type]} key={CHART_TYPES[type]}>
              {type}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  )
}

export default ChartProperty
