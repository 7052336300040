import { statement } from '@babel/template'
import { createReducer } from '@reduxjs/toolkit'

import {
  addTemplateStatus,
  clearTemplateStatus,
  removeTemplate,
  resetTemplate,
  setTemplate,
  setTemplateUploadProgress
} from '@store/slices/template/actions'

import { CeleryStatus } from '@/interfaces/celery'
import { TemplateDto } from '@/interfaces/template'

export interface TemplateState {
  template: TemplateDto[] | null
  templateAddStatus: TemplateAddStatus
  templateUploadProgress: number | null
}

export interface TemplateAddStatus {
  status: CeleryStatus | null
  error: string
}

const initialState: TemplateState = {
  template: null,
  templateAddStatus: {
    error: '',
    status: null
  },
  templateUploadProgress: null
}

const containsObject = (obj, list) => {
  var i
  for (i = 0; i < list.length; i++) {
    if (list[i] === obj) {
      return true
    }
  }

  return false
}

export const templateReducer = createReducer(initialState, builder => {
  builder
    .addCase(resetTemplate, state => {
      state.template = null
    })
    .addCase(addTemplateStatus, (state, action) => {
      state.templateAddStatus = action.payload
    })
    .addCase(clearTemplateStatus, state => {
      state.templateAddStatus = {
        error: '',
        status: null
      }
    })
    .addCase(setTemplate, (state, action) => {
      if (action.payload) {
        if (state.template) {
          action.payload.forEach(temp => {
            const template = [...state.template]
            const inState = template.find(element => {
              if (element.id === temp.id) {
                return true
              } else {
                return false
              }
            })
            if (!inState) {
              state.template = [...state.template, temp]
            }
          })
        } else {
          state.template = action.payload
        }
      }
    })
    .addCase(removeTemplate, (state, action) => {
      if (action.payload) {
        let templates = [...state.template]
        templates = templates.filter(temp => temp.id !== action.payload)
        state.template = [...templates]
      }
    })
    .addCase(setTemplateUploadProgress, (state, action) => {
      state.templateUploadProgress = action.payload
    })
})
