import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Form, Input, Select, Switch } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import i18n from 'i18next'

import { updateOneCanvasObject } from '@store/slices/shapes/actions'
import { selectCanvasSelectedObject } from '@store/slices/shapes/selectors'

import { defaultOption } from '@components/canvas/constants/defaults'

const LinkProperty = () => {
  const selectedItem = useSelector(selectCanvasSelectedObject)
  const [form] = useForm()
  const dispatch = useDispatch()

  const changeProperty = (changedValues, allValues) => {
    const link = Object.assign({}, selectedItem.link, defaultOption.link, allValues)

    dispatch(updateOneCanvasObject({ id: selectedItem.id, changes: { link: link } }))
  }

  useEffect(() => {
    form.resetFields()
  }, [form, selectedItem])

  return (
    <Form form={form} onValuesChange={changeProperty}>
      <Form.Item
        name="enabled"
        valuePropName="checked"
        label="link-enabled"
        colon={false}
        initialValue={selectedItem.link.enabled}
        rules={[
          {
            required: true,
            message: i18n.t('validation.enter-property', {
              arg: i18n.t('imagemap.marker.link-enabled')
            })
          }
        ]}
      >
        <Switch size="small" />
      </Form.Item>
      {selectedItem.link.enabled ? (
        <React.Fragment>
          <Form.Item
            initialValue={selectedItem.link.state || 'current'}
            name="state"
            label={'state'}
            colon={false}
          >
            <Select<string | number, { value: string; children: string }>
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Select.Option value="current">CURRENT</Select.Option>
              <Select.Option value="new">NEW</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            initialValue={(selectedItem.link as any).url || ''}
            name="url"
            rules={[
              {
                required: true,
                message: i18n.t('validation.enter-property', { arg: i18n.t('common.url') })
              }
            ]}
            label={i18n.t('common.url')}
            colon={false}
          >
            <Input />
          </Form.Item>
        </React.Fragment>
      ) : null}
    </Form>
  )
}

export default LinkProperty
