import { createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit'
import jwtDecode from 'jwt-decode'

import {
  clearAccessToken,
  clearLoading,
  clearRefreshToken,
  setAccessToken,
  setAuthenticated,
  setCurrentUser
} from '@store/slices/auth/actions'
import { Token, TokenTypes } from '@store/slices/auth/types'

import * as api from '@services/auth-service'

import {
  ForgotPasswordParams,
  GetCurrentUserResponse,
  MFASignInParams,
  MFASignInResponse,
  RefreshTokenParams,
  RefreshTokenResponse,
  ReSendActivationEmailParams,
  ResetPasswordParams,
  SignInParams,
  SignUpParams
} from '@/interfaces/auth'

export const signIn = createAsyncThunk<MFASignInResponse, SignInParams, { rejectValue }>(
  'auth/signIn',
  async (args, { rejectWithValue }) => {
    try {
      const response: MFASignInResponse = await api.signIn(args)
      return response
    } catch (error) {
      return rejectWithValue(error.response?.data?.detail || null)
    }
  }
)

export const getCurrentUser = createAsyncThunk<void, never, { rejectValue: string }>(
  'auth/getCurrentUser',
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const response: GetCurrentUserResponse = await api.getCurrentUser()
      dispatch(setCurrentUser(response))
    } catch (err) {
      return rejectWithValue(err.response?.data?.error?.message || null)
    }
  }
)

export const reSendActivationEmail = createAsyncThunk<
  void,
  ReSendActivationEmailParams,
  { rejectValue: string }
>('auth/reSendActivationEmail', async (args, { rejectWithValue, dispatch }) => {
  try {
    await api.reSendActivationEmail(args)
  } catch (err) {
    return rejectWithValue(err.response?.data?.error?.message || null)
  }
})

export const mfaSignIn = createAsyncThunk<
  MFASignInResponse,
  MFASignInParams,
  { rejectValue: string }
>('auth/mfaSignIn', async (args, { rejectWithValue }) => {
  try {
    const response: MFASignInResponse = await api.mfaSignIn(args)
    return response
  } catch (err) {
    return rejectWithValue(err.response?.data?.error?.message || null)
  }
})

export const signOut = createAsyncThunk<void, never>('auth/signOut', (args, { dispatch }) => {
  dispatch(setAuthenticated(false))
  dispatch(clearAccessToken())
  dispatch(clearRefreshToken())
  dispatch(setCurrentUser(null))
  dispatch(clearLoading())
})

export const signUp = createAsyncThunk<void, SignUpParams, { rejectValue: string }>(
  'auth/signUp',
  async (args, { rejectWithValue }) => {
    try {
      await api.signUp(args)
    } catch (err) {
      return rejectWithValue(err.response?.data || null)
    }
  }
)

export const refreshToken = createAsyncThunk<void, RefreshTokenParams, { rejectValue: string }>(
  'auth/signUp',
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const response: RefreshTokenResponse = await api.refreshToken(args)
      dispatch(setAuthenticated(true))
      const accessToken: Token = {
        type: TokenTypes.ACCESS,
        key: response.access,
        payload: jwtDecode(response.access)
      }
      dispatch(setAccessToken(accessToken))
    } catch (err) {
      return rejectWithValue(err.response?.data?.error?.message || null)
    }
  }
)
