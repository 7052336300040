import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Col, Form, Input, InputNumber, Row, Slider, Switch } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import i18n from 'i18next'
import { debounce } from 'lodash'

import { updateOneCanvasObject } from '@store/slices/shapes/actions'
import { selectCanvasSelectedObject } from '@store/slices/shapes/selectors'

const GeneralProperty = () => {
  const selectedItem = useSelector(selectCanvasSelectedObject)
  const [form] = useForm()
  const dispatch = useDispatch()

  useEffect(() => {
    form.resetFields()
  }, [form, selectedItem])

  const changeProperties = debounce((changedValues, allValues) => {
    const changedKey = Object.keys(changedValues)[0]
    const changedValue = changedValues[changedKey]

    if (changedKey === 'width' || changedKey === 'height') {
      const { id } = selectedItem
      const obj = {
        id,
        scaleX: allValues.width / selectedItem.width,
        scaleY: allValues.height / selectedItem.height
      }
      dispatch(updateOneCanvasObject({ id: selectedItem.id, changes: obj }))
      return
    }

    if (changedKey === 'locked') {
      dispatch(
        updateOneCanvasObject({
          id: selectedItem.id,
          changes: {
            lockMovementX: changedValue,
            lockMovementY: changedValue,
            hasControls: !changedValue,
            hoverCursor: changedValue ? 'pointer' : 'move',
            editable: !changedValue
            // locked: changedValue
          }
        })
      )
      return
    }

    dispatch(
      updateOneCanvasObject({ id: selectedItem.id, changes: { [changedKey]: changedValue } })
    )
  }, 200)

  return (
    <Form onValuesChange={changeProperties} form={form}>
      <Row>
        <Col span={12}>
          <Form.Item
            initialValue={!selectedItem.hasControls}
            valuePropName={'checked'}
            name="locked"
            rules={[
              {
                type: 'boolean'
              }
            ]}
            label={'Locked'}
            colon={false}
          >
            <Switch size="small" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="visible"
            initialValue={selectedItem.visible}
            valuePropName={'checked'}
            label={'Visible'}
            colon={false}
            rules={[
              {
                type: 'boolean'
              }
            ]}
          >
            <Switch size="small" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item initialValue={selectedItem.name} name="name" label={'Name'} colon={false}>
        <Input />
      </Form.Item>
      <Row>
        <Col span={12}>
          <Form.Item
            name="width"
            label={'Width'}
            colon={false}
            initialValue={parseInt((selectedItem.width * selectedItem.scaleX).toString(), 10)}
            rules={[
              {
                type: 'number',
                required: true,
                message: 'Please input width',
                min: 1
              }
            ]}
          >
            <InputNumber min={1} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="height"
            label={'Height'}
            initialValue={parseInt((selectedItem.height * selectedItem.scaleY).toString(), 10)}
            colon={false}
            rules={[
              {
                type: 'number',
                required: true,
                message: 'Please input height',
                min: 1
              }
            ]}
          >
            <InputNumber min={1} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            name="left"
            label={'Left'}
            initialValue={selectedItem.left}
            colon={false}
            rules={[
              {
                required: true,
                message: 'Please input x position'
              }
            ]}
          >
            <InputNumber />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="top"
            initialValue={selectedItem.top}
            label={i18n.t('common.top')}
            colon={false}
            rules={[
              {
                required: true,
                message: 'Please input y position'
              }
            ]}
          >
            <InputNumber />
          </Form.Item>
        </Col>
      </Row>
      {selectedItem.superType === 'element' ? null : (
        <Form.Item
          name="angle"
          initialValue={selectedItem.angle}
          label={'Angle'}
          colon={false}
          rules={[
            {
              type: 'number',
              required: true,
              message: 'Please input rotation'
            }
          ]}
        >
          <Slider min={0} max={360} />
        </Form.Item>
      )}
    </Form>
  )
}

export default GeneralProperty
