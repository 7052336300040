import { range } from 'lodash'

import { SigTestTypes } from '@/interfaces/analytics'
import { DataFrameDefinition } from '@/interfaces/data'
import { IShapeData } from '@/interfaces/shape'

import { applyNumberFormatting } from '../../utils'
import {
  ConditionType,
  IndexingVisualisation,
  SigTestVisualisation,
  SymbolPosition,
  TableDefinition,
  TableVisualSettings,
  VisualisationSymbols
} from './types'

export const DEFAULT_CELL_BG_COLOR = '#FFFFFF'
export const DEFAULT_CELL_VALUE_COLOR = '#000000'

export type SymbolOverlayValue = {
  symbol: string
  color: string
  position: SymbolPosition
}
export type FillValue<T extends string | SymbolOverlayValue = string> = T
export type StringOverlay = string[][]
export type SymbolsOverlay = SymbolOverlayValue[][][]
export type TestResult<T> = {
  [key: string]: {
    [key: string]: T
  }
}
export type SigTestHeaders = {
  rows?: {
    [key: string]: string
  }
  columns?: {
    [key: string]: string
  }
}

/** Check if the cell is within the selection range. */
export function cellInRange(
  boundaries: [[number, number], [number, number]],
  columnIndex: number,
  rowIndex: number
) {
  const [start, end] = boundaries
  return (
    columnIndex >= start[0] && columnIndex <= end[0] && rowIndex >= start[1] && rowIndex <= end[1]
  )
}

/** Check if the cell value meets the condition. */
export function meetsCondition(
  condition: ConditionType,
  testValue: number | string,
  value: number | string,
  formatCode: string = null
) {
  testValue = formatCode
    ? parseInt(applyNumberFormatting(testValue, formatCode))
    : Number(testValue)
  value = formatCode ? parseInt(applyNumberFormatting(value, formatCode)) : Number(value)

  switch (condition) {
    case 'eq':
      return value === testValue
    case 'gt':
      return value > testValue
    case 'gte':
      return value >= testValue
    case 'lt':
      return value < testValue
    case 'lte':
      return value <= testValue
    default:
      return false
  }
}

/**
 * Takes two overlays and overwrites the values of the `target`
 * with non-null values from the `source`.
 * @param target container that accumulates values
 * @param source container from which new values are added
 * @returns target whose values are overriden with the new ones.
 */
export function squashOverlays(target: StringOverlay, source: StringOverlay): StringOverlay {
  return target.map((row, rowIndex) => {
    return row.map((value, columnIndex) => {
      const newValue = source[rowIndex][columnIndex]
      return newValue ? newValue : value
    })
  })
}

/**
 * Takes two overlays and joins their values into one array for each "cell".
 * Values of target and source 2D arrays can also be accumulators themselves. Examples:
 * ```
 * 1) null U [{ [key]: value }] => [{ [key]: value }]
 * 2) [null, { [key]: value }] U { [key]: value } => [{ [key]: value }, { [key]: value }]
 * ```
 * @param target container that accumulates values
 * @param source container from which new values are added
 * @returns target with new values added to it
 */
export function mergeOverlays(target, source) {
  return target.map((row, rowIndex) => {
    return row.map((value, columnIndex) => {
      let newValue = source[rowIndex][columnIndex]
      value = value ? [].concat(value) : []
      newValue = newValue ? [].concat(newValue) : []
      return [...value, ...newValue]
    })
  })
}

export abstract class TableLayout {
  table: TableDefinition

  constructor(table: TableDefinition) {
    this.table = table
  }

  /**
   * Get cell colors from the PPT and format into a 2D array.
   * @param {boolean} span - if `true` the first color value in each row will be
   * spanned for all columns. It's supposed to make results more predictable at the expense
   * of accuracy.`span = false` may work better with a better PPT parser.
   */
  protected abstract createBackground(span: boolean): string[][]

  abstract getLayouts(): [StringOverlay, StringOverlay?, SymbolsOverlay?]
}

export class TableWithoutDataLayout extends TableLayout {
  constructor(table: TableDefinition) {
    super(table)
  }

  getLayouts(): [StringOverlay] {
    return [this.createBackground(true)]
  }

  protected createBackground(span): string[][] {
    const { cells, columnsWidths, rowsHeights } = this.table
    const columnsLength = columnsWidths.length + 1
    const rowsLength = rowsHeights.length

    const headerBg = range(columnsLength).map(index => {
      let cellIndex = span ? 0 : index
      return cells[cellIndex]?.fill
    })
    const oddRowBg = range(columnsLength).map(index => {
      if (rowsLength <= 2) {
        return headerBg[index]
      }
      let cellIndex = span ? 0 : index
      return cells[cellIndex + columnsLength]?.fill
    })
    const evenRowBg = range(columnsLength).map(index => {
      if (rowsLength <= 3) {
        return oddRowBg[index]
      }
      let cellIndex = span ? 0 : index
      return cells[cellIndex + 2 * columnsLength]?.fill
    })
    let cellColors = [headerBg]
    let i = 0
    while (i <= rowsLength) {
      if (i % 2 === 1) {
        cellColors.push(oddRowBg)
      } else {
        cellColors.push(evenRowBg)
      }
      i++
    }
    return cellColors
  }
}

/**
 * A class that unifies all overlay instances.
 */
export class TableWithDataLayout extends TableLayout {
  data: number[][]
  rows: string[]
  columns: string[]
  defaultLayout: null[][]
  formatting?: FormattingOverlay
  indexing?: IndexingOverlay
  sigtesting?: AbstractSignificanceTestOverlay
  showRowHeaders?: boolean
  showColumnHeaders?: boolean

  constructor(
    dataOptions: IShapeData,
    table: TableDefinition,
    visualSettings: TableVisualSettings
  ) {
    super(table)
    const { dataFrame, indexing, sigTesting } = dataOptions
    this.data = dataFrame.data
    this.rows = dataFrame.index
    this.columns = dataFrame.columns
    this.showRowHeaders = visualSettings?.table?.showRowHeaders === false ? false : true
    this.showColumnHeaders = visualSettings?.table?.showColHeaders === false ? false : true
    this.defaultLayout = this.generateDefaultLayout()
    if (visualSettings?.table?.formatting) {
      const numberFormat = visualSettings?.table?.formatting?.useFormattedValues
        ? visualSettings.numberFormat
        : null
      this.formatting = new FormattingOverlay(
        dataFrame,
        visualSettings.table.formatting,
        numberFormat
      )
    }
    if (indexing?.response && visualSettings?.percentageIndexing) {
      this.indexing = new IndexingOverlay(
        dataFrame,
        indexing.dataFrame,
        visualSettings.percentageIndexing
      )
    }
    if (sigTesting?.response) {
      this.sigtesting = significanceTestManager(
        sigTesting.analysisType,
        dataFrame,
        sigTesting.response.values as TestResult<'+' | '-' | ''>,
        visualSettings?.significanceTest,
        sigTesting.response.params
      )
    }
  }

  /**
   * Get 2D arrays that contain information of how to paint the grid's
   * background and values.
   * @returns tuple where the 1st value is background colors, 2nd is
   * value colors and 3rd is symbols.
   */
  public getLayouts(): [StringOverlay, StringOverlay, SymbolsOverlay] {
    let backgroundColors = this.createBackground()
    let valueColors: string[][] = this.defaultLayout
    let symbols: SymbolsOverlay = this.defaultLayout
    if (this.formatting) {
      const [o1, o2] = this.formatting.getOverlays()
      backgroundColors = squashOverlays(backgroundColors, o1)
      valueColors = squashOverlays(valueColors, o2)
    }
    if (this.sigtesting) {
      try {
        const [o1, o2, o3] = this.sigtesting.getOverlays()
        backgroundColors = squashOverlays(backgroundColors, o1)
        valueColors = squashOverlays(valueColors, o2)
        symbols = mergeOverlays(symbols, o3)
      } catch (e) {
        console.error(e.message)
      }
    }
    if (this.indexing) {
      try {
        const [o1, o2, o3] = this.indexing.getOverlays()
        backgroundColors = squashOverlays(backgroundColors, o1)
        valueColors = squashOverlays(valueColors, o2)
        symbols = mergeOverlays(symbols, o3)
      } catch (e) {
        console.error(e.message)
      }
    }
    return [backgroundColors, valueColors, symbols]
  }

  protected createBackground(span = true): string[][] {
    let columnsLength = this.table.columnsWidths.length
    let rowsLength = this.table.rowsHeights.length
    let mappedRowsLength = this.rows.length - 1
    let mappedColumnsLength = this.columns.length + 1
    const cells = this.table.cells
    let headerBg = range(mappedColumnsLength).map(index => {
      return cells[index % columnsLength]?.fill
    })
    let oddRowBg = range(mappedColumnsLength).map(index => {
      if (rowsLength < 2) {
        return null
      }
      const cellIndex = span ? 0 : index
      return cells[(cellIndex % columnsLength) + columnsLength]?.fill
    })
    let evenRowBg = range(mappedColumnsLength).map(index => {
      if (rowsLength < 3) {
        return null
      }
      const cellIndex = span ? 0 : index
      return cells[(cellIndex % columnsLength) + 2 * columnsLength]?.fill
    })
    let cellColors = [headerBg]
    let i = 0
    while (i <= mappedRowsLength) {
      if (i % 2 === 1) {
        cellColors.push(oddRowBg)
      } else {
        cellColors.push(evenRowBg)
      }
      i++
    }
    return cellColors
  }

  private generateDefaultLayout<T>(fillValue: T = null): T[][] {
    return range(this.rows.length + 1).map(() => {
      return range(this.columns.length + 1).map(() => {
        return fillValue
      })
    })
  }
}

abstract class AbstractOverlay {
  data: number[][]
  rows: string[]
  columns: string[]
  defaultOverlay: null[][]

  constructor(dataFrame: DataFrameDefinition) {
    const { data, index, columns } = dataFrame
    this.data = data
    this.rows = index
    this.columns = columns
    this.defaultOverlay = this.generateOverlay()
  }

  abstract getOverlays(): [StringOverlay, StringOverlay?, SymbolsOverlay?]

  /**
   * Generic method for overlay creation.
   * @param {FillValue<T>} [fillValue = null] - value with which cells should be conditionally filled.
   * Defaults to `null`.
   * @param {(row: number, col: number) => boolean} [fn] function that determines whether
   * a cell should be filled with `fillValue` or `null`. Defaults to a function that
   * always returns `true`.
   * @returns {FillValue<T>[][]}
   */
  protected generateOverlay<T extends string | SymbolOverlayValue>(
    fillValue: FillValue<T> = null,
    fn: (row: number, col: number) => boolean = () => true,
    ignoreHeaders = true
  ): FillValue<T>[][] {
    const rowsLength = this.rows.length + 1
    const columnsLength = this.columns.length + 1
    const overlay = range(rowsLength).map(rowIndex => {
      const column = range(columnsLength).map(columnIndex => {
        if ((ignoreHeaders && rowIndex === 0) || columnIndex === 0) {
          return null
        }
        const meetsConditions = fn(rowIndex - 1, columnIndex - 1)
        if (meetsConditions) {
          return fillValue
        } else {
          return null
        }
      })
      return column
    })
    return overlay
  }
}

export class FormattingOverlay extends AbstractOverlay {
  formatting: TableVisualSettings['table']['formatting']
  numberFormat: string | null

  constructor(
    dataFrame: DataFrameDefinition,
    formatting: TableVisualSettings['table']['formatting'],
    numberFormat: string = '0'
  ) {
    super(dataFrame)
    this.formatting = formatting
    this.numberFormat = numberFormat
  }

  /**
   * Creates an overlay of cell bg colors and an overlay of cell value colors
   * accordingly to the formatting conditions.
   * @returns {[StringOverlay, StringOverlay]} `[bgColors[][], valueColors[][]]`
   */
  public getOverlays(): [StringOverlay, StringOverlay] {
    let backgroundColors: StringOverlay = this.defaultOverlay
    let valueColors: StringOverlay = this.defaultOverlay
    if (this.formatting?.conditions) {
      this.formatting.conditions.forEach(condition => {
        const {
          color,
          selection,
          minLimitType,
          minLimitValue,
          maxLimitType,
          maxLimitValue,
          applyTo
        } = condition
        const fn = (row: number, column: number) => {
          const value = this.data[row][column]
          const inRange = cellInRange(selection, column, row)
          const formatCode = this.formatting?.useFormattedValues ? this.numberFormat : null
          const isMinMet = meetsCondition(minLimitType, minLimitValue, value, formatCode)
          let isMaxMet = true
          if (maxLimitType && maxLimitType) {
            isMaxMet = meetsCondition(maxLimitType, maxLimitValue, value, formatCode)
          }
          return inRange && isMinMet && isMaxMet
        }
        const overlay = this.generateOverlay<string>(color, fn)
        if (applyTo === 'cell-background') {
          if (backgroundColors) {
            backgroundColors = squashOverlays(backgroundColors, overlay)
          } else {
            backgroundColors = overlay
          }
        } else if (applyTo === 'value-color') {
          if (valueColors) {
            valueColors = squashOverlays(valueColors, overlay)
          } else {
            valueColors = overlay
          }
        }
      })
    }
    return [backgroundColors, valueColors]
  }
}

export class IndexingOverlay extends AbstractOverlay {
  result: TestResult<number>
  options: IndexingVisualisation[]

  constructor(
    dataFrame: DataFrameDefinition,
    result: TestResult<number>,
    options: IndexingVisualisation[]
  ) {
    super(dataFrame)
    this.result = result
    this.options = options
  }

  /**
   * Creates overlays of cells that are engaged in visualisation of percentage indexing results.
   * @returns {[StringOverlay, StringOverlay, SymbolsOverlay]} background colors, value colors, symbol tuples
   * (containing information of how to draw symbols in cells [utf-8 char, color, position]).
   */
  public getOverlays(): [StringOverlay, StringOverlay, SymbolsOverlay] {
    let backgroundColors: StringOverlay = this.defaultOverlay
    let valueColors: StringOverlay = this.defaultOverlay
    let symbols: SymbolsOverlay = this.defaultOverlay
    this.options.forEach((indexing, index) => {
      const { color, visualise, symbol, position } = indexing
      const fn = (row: number, column: number) => {
        const colName = this.columns[column]
        const rowName = this.rows[row]
        if (rowName in this.result && colName in this.result[rowName]) {
          const res = this.result[rowName][colName]
          if (!res) {
            return false
          }
          return true
        }
        return false
      }
      const colorsOverlay = this.generateOverlay(color, fn)
      let fillValue = {
        symbol: VisualisationSymbols[symbol],
        color: color,
        position: position
      }
      const symbolsOverlay = this.generateOverlay<SymbolOverlayValue>(fillValue, fn)
      switch (visualise) {
        case 'cell-background':
          backgroundColors = squashOverlays(backgroundColors, colorsOverlay)
          break
        case 'colored-labels':
          valueColors = squashOverlays(valueColors, colorsOverlay)
          break
        case 'symbols':
          symbols = mergeOverlays(symbols, symbolsOverlay)
          break
        case 'symbols-colored-labels':
          symbols = mergeOverlays(symbols, symbolsOverlay)
          valueColors = squashOverlays(valueColors, colorsOverlay)
          break
        case 'values-colored-labels':
          // Replace the symbol ('undefined' in this case) in the resulting array with the indexing result for that cell.
          // This code should be deleted at some point and override 'generateOverlay' instead.
          const newSymbolsOverlay = symbolsOverlay.map((row, rowIndex) => {
            return row.map((column, columnIndex) => {
              if (rowIndex === 0 || column === null) {
                return column
              }
              return {
                ...column,
                symbol: `(${Math.round(
                  this.result[this.rows[rowIndex - 1]][this.columns[columnIndex - 1]]
                )})`
              }
            })
          })
          symbols = mergeOverlays(symbols, newSymbolsOverlay)
          break
      }
    })
    return [backgroundColors, valueColors, symbols]
  }
}

export abstract class AbstractSignificanceTestOverlay extends AbstractOverlay {
  result: TestResult<string>
  options: SigTestVisualisation | SigTestVisualisation<'all_vs_all'>
}

export class SignificanceTestOverlay extends AbstractSignificanceTestOverlay {
  result: TestResult<string>
  options: SigTestVisualisation

  constructor(
    dataFrame: DataFrameDefinition,
    result: TestResult<string>,
    options: SigTestVisualisation
  ) {
    super(dataFrame)
    this.result = result
    this.options = options
  }

  /** See {@link IndexingOverlay.getOverlays}. */
  getOverlays(): [StringOverlay, StringOverlay, SymbolsOverlay] {
    let backgroundColors: StringOverlay = this.defaultOverlay
    let valueColors: StringOverlay = this.defaultOverlay
    let symbols: SymbolsOverlay = this.defaultOverlay
    const {
      visualise,
      symbol,
      insignificantColor,
      significantColor,
      position = 'right'
    } = this.options
    const fn1 = (row: number, column: number) => {
      const colName = this.columns[column]
      const rowName = this.rows[row]
      const value = this.result?.[rowName]?.[colName]
      if (value === '+') {
        return true
      }
      return false
    }
    const fn2 = (row: number, column: number) => {
      const colName = this.columns[column]
      const rowName = this.rows[row]
      const value = this.result?.[rowName]?.[colName]
      if (value === '-') {
        return true
      }
      return false
    }
    const positiveColors = this.generateOverlay(significantColor, fn1)
    const negativeColors = this.generateOverlay(insignificantColor, fn2)
    const positiveSymbol = symbol === 'arrow' ? 'arrowUp' : 'pyramidUp'
    const negativeSymbol = symbol === 'arrow' ? 'arrowDown' : 'pyramidDown'
    const positiveFillValue = {
      symbol: VisualisationSymbols[positiveSymbol],
      color: significantColor,
      position: position
    }
    const negativeFillValue = {
      symbol: VisualisationSymbols[negativeSymbol],
      color: insignificantColor,
      position: position
    }
    const positiveSymbols = this.generateOverlay<SymbolOverlayValue>(positiveFillValue, fn1)
    const negativeSymbols = this.generateOverlay<SymbolOverlayValue>(negativeFillValue, fn2)
    const colorsOverlay = squashOverlays(positiveColors, negativeColors)
    const symbolsOverlay = mergeOverlays(positiveSymbols, negativeSymbols)
    switch (visualise) {
      case 'cell-background':
        backgroundColors = squashOverlays(backgroundColors, colorsOverlay)
        break
      case 'colored-labels':
        valueColors = squashOverlays(valueColors, colorsOverlay)
        break
      case 'symbols':
        symbols = mergeOverlays(symbols, symbolsOverlay)
        break
      case 'symbols-colored-labels':
        symbols = mergeOverlays(symbols, symbolsOverlay)
        valueColors = squashOverlays(valueColors, colorsOverlay)
        break
      case 'values-colored-labels':
        symbols = mergeOverlays(symbols, symbolsOverlay)
        break
    }
    return [backgroundColors, valueColors, symbols]
  }
}

export class AllvsAllOverlay extends AbstractSignificanceTestOverlay {
  result: TestResult<string>
  options: SigTestVisualisation<'all_vs_all'>
  headers: SigTestHeaders

  constructor(
    dataFrame: DataFrameDefinition,
    result: TestResult<string>,
    options: SigTestVisualisation<'all_vs_all'>,
    headers: SigTestHeaders
  ) {
    super(dataFrame)
    this.result = result
    this.options = options
    this.headers = headers
  }

  getOverlays(): [StringOverlay, StringOverlay, SymbolsOverlay] {
    const setFillValue = (
      value: string,
      brackets: boolean = true,
      uppercase: boolean = false
    ): SymbolOverlayValue => {
      if (uppercase) {
        value = value.toUpperCase()
      }
      if (brackets) {
        value = `(${value})`
      }
      return {
        symbol: value,
        color: '#000000',
        position: 'right'
      }
    }
    const symbolsOverlay = range(this.rows.length + 1).map(rowIndex => {
      return range(this.columns.length + 1).map(columnIndex => {
        const rowName = rowIndex > 0 ? this.rows[rowIndex - 1] : ''
        const columnName = columnIndex > 0 ? this.columns[columnIndex - 1] : ''
        if (columnIndex === 0 && this.headers['rows'] && this.headers['rows'][rowName]) {
          const fillValue = setFillValue(this.headers['rows'][rowName])
          return [fillValue]
        }
        if (rowIndex === 0 && this.headers['columns'] && this.headers['columns'][columnName]) {
          const fillValue = setFillValue(this.headers['columns'][columnName])
          return [fillValue]
        }
        if (this.result[rowName] && this.result[rowName][columnName]) {
          const fillValue = setFillValue(
            this.result[rowName][columnName],
            this.options['useBrackets'],
            this.options['useUppercase']
          )
          return [fillValue]
        }
        return null
      })
    })
    return [this.defaultOverlay, this.defaultOverlay, symbolsOverlay]
  }
}

export function significanceTestManager<T>(
  type: SigTestTypes,
  dataFrame: DataFrameDefinition,
  result: TestResult<string>,
  options: SigTestVisualisation<T>,
  headers?: SigTestHeaders
) {
  if (type === 'all_vs_all') {
    return new AllvsAllOverlay(
      dataFrame,
      result,
      options as SigTestVisualisation<'all_vs_all'>,
      headers
    )
  } else {
    return new SignificanceTestOverlay(dataFrame, result, options as SigTestVisualisation<string>)
  }
}
