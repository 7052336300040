import { Collapse } from 'antd'

import i18n from 'i18next'

import EmptyElement from '@components/imagemap/right-panel/EmptyElement'
import { PropertyDefinition } from '@components/imagemap/right-panel/properties/PropertyDefinition'

import './NodeProperties.less'

import { useSelector } from 'react-redux'

import { InfoCircleFilled, InfoCircleOutlined } from '@ant-design/icons'

import { RootState } from '@store/rootReducer'
import {
  selectCanvasSelectedObject,
  selectCanvasSelectedObjectDataDefinition,
  selectCanvasSelectedObjectProperty
} from '@store/slices/shapes/selectors'
import { DefaultDataDefinition } from '@store/slices/shapes/types'

import Info from '@/components/common/Info'
import SigTesting from '@/components/imagemap/right-panel/sig-testing'
import PercentageIndexing from '@components/imagemap/right-panel/percentage-indexing'

import { useDataTransformation, useRowsNColumns } from './hooks'
import TableConditionProperty from './properties/TableConditionProperty'
import { getResultingRowsnColumns } from './utils'

import './styles.less'

const { Panel } = Collapse

const popoverContentSigTesting = (
  <div>
    <h4>
      <strong>Function</strong>
    </h4>
    <div>
      <p>
        This performs a Z Test on data inserted to a chart or table object. Z Tests, or Significance
        Tests, help the user identify if the difference between two values are statistically
        significant, taking into account difference in values with reference to the sample size used
        to generate data values.
      </p>
    </div>
    <h4>
      <strong>Test Types</strong>
    </h4>
    <div>
      <p>
        Tests can be performed testing values across Columns or Rows. The following tests are
        available:
      </p>
      <ul style={{ marginLeft: '40px' }}>
        <li>
          All vs. All: This will test all values against all other values. For example, if testing
          All vs. All across Rows, then all row values in a single column are tested against each
          other. Where statistically significant differences are found, these are indicated through
          the presence of letters in data labels
        </li>
        <li>
          Control Group: This allows the user to benchmark values used in the test against a single
          value. Where statistically significant differences are found, these are indicated through
          up/down arrows
        </li>
        <li>
          Next Data Point: This will test any value against the next value in the chart or table
          object. Where statistically significant differences are found, these are indicated through
          up/down arrows
        </li>
        <li>
          Previous Data Point: As above, except the test is completed against the value directly
          before.
        </li>
      </ul>
    </div>
    <h4>
      <strong>Significance Confidence</strong>
    </h4>
    <div>
      <p>
        This denotes the chance of correctly identifying a statistically significant difference
        using these tests. Confidence intervals can be set at 90%, 95% and 99%.
      </p>
    </div>
  </div>
)

const popoverContentPercentageIndexing = (
  <div>
    <h4>
      <strong>Function</strong>
    </h4>
    <div>
      <p>
        This feature allows you to compare a base and test value, expressing the difference between
        these in percentage terms. You can test values across Columns or Rows.
      </p>
      <p>
        Multiple thresholds can be set, with each being formatted separately to allow for high
        bespoking.
      </p>
    </div>
  </div>
)

const DataLayouts = props => {
  //@ts-ignore
  const type = useSelector((s: RootState) => selectCanvasSelectedObjectProperty(s, 'type'))
  const selectedObject = useSelector(selectCanvasSelectedObject)
  const dataDefinition = useSelector(
    selectCanvasSelectedObjectDataDefinition
  ) as DefaultDataDefinition
  const label = type === 'chart' && dataDefinition
  let show = dataDefinition && (type === 'table' || type === 'chart')
  const { dataTransformation } = useDataTransformation(dataDefinition?.transformationId)
  const { tableId } =
    (useSelector(selectCanvasSelectedObjectDataDefinition) as DefaultDataDefinition) || {}
  const { rows, columns } = useRowsNColumns(tableId)
  let [resultingRows, resultingColumns] = getResultingRowsnColumns(
    rows,
    columns,
    dataTransformation
  ) || [[], []]
  if (resultingRows === undefined) {
    resultingRows = []
  }
  if (resultingColumns === undefined) {
    resultingColumns = []
  }
  if (type === 'chart') {
    const series = Array.from(Object.values(selectedObject.chart.plots[0].series))
    const dataLabelsShown = series.map((s: any) => s.dataLabels?.showValue).some(showVal => showVal)
    show = dataDefinition && dataLabelsShown
  }
  if (!type) {
    return <EmptyElement title={i18n.t('editor.right-panel.node.nothing-selected')} />
  }
  return show ? (
    <div className="node-properties">
      <div className="ant-tabs-tabpane-header" style={{ marginBottom: '8px' }}>
        {i18n.t('editor.right-panel.analytics.title')}
      </div>
      <div
        className="collapse__header--informational"
        style={{ display: type === 'chart' ? 'none' : 'block' }}
      >
        <InfoCircleOutlined style={{ fontSize: '18px' }} />
        <span style={{ paddingLeft: '8px' }}>
          {i18n.t('editor.right-panel.analytics.order-info')}
        </span>
      </div>
      {PropertyDefinition[type] ? (
        <Collapse bordered={false} accordion style={{ backgroundColor: '#ffffff' }}>
          {type === 'table' && (
            <Panel
              header={i18n.t('editor.right-panel.analytics.conditional-formatting.title')}
              key="conditional-formatting"
              showArrow={true}
            >
              <TableConditionProperty />
            </Panel>
          )}
          {resultingColumns.length > 1 || resultingRows.length > 1 ? (
            <Panel
              header={
                <div>
                  <span>{i18n.t('editor.right-panel.analytics.sig-testing.title')} </span>
                  <Info placement="left">{popoverContentSigTesting}</Info>
                </div>
              }
              key="sig-testing"
              showArrow={true}
            >
              <SigTesting />
            </Panel>
          ) : (
            <div className="collapse__header--informational">
              <InfoCircleOutlined style={{ fontSize: '18px' }} />
              <span style={{ paddingLeft: '8px' }}>
                {i18n.t('editor.right-panel.analytics.not-enough-columns')}
              </span>
            </div>
          )}
          <Panel
            header={
              <div>
                <span>{i18n.t('editor.right-panel.analytics.percentage-indexing.title')} </span>
                <Info placement="left">{popoverContentPercentageIndexing}</Info>
              </div>
            }
            key="indexing"
            showArrow={true}
          >
            <PercentageIndexing />
          </Panel>
        </Collapse>
      ) : (
        <EmptyElement title={i18n.t('editor.right-panel.node.no-property')} />
      )}
    </div>
  ) : label ? (
    <div className="node-properties">
      <div className="ant-tabs-tabpane-header" style={{ marginBottom: '8px' }}>
        {i18n.t('editor.right-panel.analytics.title')}
      </div>
      <div className="collapse__header--informational">
        <InfoCircleOutlined style={{ fontSize: '18px' }} />
        <span style={{ paddingLeft: '8px' }}>
          {i18n.t('editor.right-panel.analytics.order-info')}
        </span>
      </div>
      <div
        className="collapse__header--informational"
        style={{ cursor: 'pointer' }}
        onClick={() => props.setActiveKey('object')}
      >
        <InfoCircleFilled type="link" style={{ fontSize: '18px', color: 'orange' }} />
        <span style={{ paddingLeft: '8px' }}>
          {i18n.t('editor.right-panel.analytics.data-labels-off')}
        </span>
      </div>
    </div>
  ) : (
    <EmptyElement title={i18n.t('editor.right-panel.analytics.no-data-or-unsupported')} />
  )
}

export default DataLayouts
