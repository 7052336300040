import { useDispatch, useSelector } from 'react-redux'

import { Form, message, Space, Typography } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import { RootState } from '@/store/rootReducer'
import { refreshSlide } from '@/store/slices/slides/actions'
import { AppDispatch } from '@/store/store'

import { copyShapeConf } from '@/services/shape-service'

import CommonModal from '@/components/common/CommonModal'

const { Text } = Typography

interface Props {
  baseShapeId: string | number
  targetShapeId: string | number
  visible: boolean
  onConfirm: () => void
  onCancel: () => void
}

interface IForm {
  selections: boolean
  transformations: boolean
  settings: boolean
  overlays: boolean
}

const CopyShapeConfModal = ({
  baseShapeId,
  targetShapeId,
  visible,
  onConfirm,
  onCancel
}: Props) => {
  const dispatch: AppDispatch = useDispatch()
  const projectId = useSelector((state: RootState) => state?.projects?.currentProject?.id)
  const [form] = useForm<IForm>()

  const onSubmitForm = async ({
    selections = false,
    transformations = false,
    overlays = false,
    settings = false
  }: IForm) => {
    const payload = {
      shapeId: targetShapeId,
      isDataSelection: selections,
      isDataActions: transformations,
      isOverlays: overlays,
      isShapeSettings: settings
    }
    try {
      const result = await copyShapeConf({ projectId, shapeId: baseShapeId, payload: payload })
      if (result) {
        message.success('Shape configuration has been successfully applied')
      } else {
        message.warning('Shape configuration has been applied with issues')
      }
      dispatch(refreshSlide())
    } catch (error) {
      message.error('Failed to copy shape configuration')
      console.error(error.message)
    }
    onConfirm()
  }

  return (
    <CommonModal
      visible={visible}
      title="Confirm action"
      onOk={() => form.submit()}
      onCancel={onCancel}
    >
      <Text>All transformations from the source shape will be applied to the target shape.</Text>
      <Form form={form} onFinish={onSubmitForm} style={{ paddingTop: '1rem' }}>
        <Space direction="vertical"></Space>
      </Form>
    </CommonModal>
  )
}

export default CopyShapeConfModal
