import { useEffect, useState } from 'react'

import { Button, Form, Radio, Select, Space } from 'antd'
import { useForm } from 'antd/es/form/Form'

import i18n from 'i18next'

import CommonModal from '@/components/common/CommonModal'
import Info from '@/components/common/Info'
import MultiSelectDropdown from '@/components/common/MultiSelectDropdown'

import { ActionProps, ActionTypes, ReorderAction } from './types'

export type FormValues = {
  axis: 0 | 1
  terms: string[]
  position: string
}

const ShiftLabels = ({
  columns,
  rows,
  onSubmit,
  onCancel,
  clearOptions,
  isVisible,
  initialValues
}: ActionProps<ReorderAction>) => {
  const initial = {
    axis: 0,
    terms: [],
    position: 'start',
    ...initialValues
  }

  const [form] = useForm<FormValues>()
  const [termsLength, setTermsLength] = useState(initial.terms.length)
  const [positionValue, setPositionValue] = useState(initial.position)
  const [labelsVisible, setLabelsVisible] = useState(false)

  useEffect(() => {
    if (clearOptions === true) {
      setPositionValue(initial.position)
      setTermsLength(initial.terms.length)
      form.resetFields()
      //@ts-ignore
      form.setFieldsValue(initial)
    }
  }, [clearOptions, initialValues])
  useEffect(() => {
    form.resetFields()
  }, [])

  const submitForm = () => {
    form.submit()
    const { axis, position, terms } = form.getFieldsValue(true)
    const payload = {
      actionType: ActionTypes.SHIFT_LABELS,
      axis,
      specs: {
        position,
        terms
      }
    }
    onSubmit(payload)
  }

  const onAxisChange = (value: 0 | 1) => {
    //form.resetFields()
    //@ts-ignore
    //form.setFieldsValue(initial)
    form.setFieldsValue({ axis: value, terms: [] })
    setTermsLength(0)
  }

  const onPositionChange = e => {
    setPositionValue(e.target.value)
    form.setFieldsValue({ position: e.target.value })
  }

  const onTermsChange = (value: string[]) => {
    form.setFieldsValue({ terms: value })
    setTermsLength(form.getFieldsValue(true).terms.length)
  }

  useEffect(() => {
    setTermsLength(initial.terms.length)
  }, [initialValues])

  const popoverContent = (
    <div>
      <strong>Function</strong>
      <p>Anchor named labels to the start or end of a chart/table</p>
      <strong>How to use</strong>
      <p>
        Orientation: Select if the label to anchor is a column or row header
        <br />
        Labels: Select the label you would like to anchor
        <br />
        Start/End: Select if the labels should be anchored to the start or end of a chart/table
      </p>
    </div>
  )

  return (
    <CommonModal
      visible={isVisible}
      onCancel={onCancel}
      onOk={submitForm}
      okDisabled={termsLength <= 0}
      title={
        <span>
          Anchor labels <Info>{popoverContent}</Info>
        </span>
      }
    >
      <Form form={form} initialValues={initialValues} layout="vertical">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form.Item
            name="axis"
            rules={[{ required: true }]}
            label={i18n.t(
              'editor.right-panel.data-source.form.data-transformation.sortBy.orientation'
            )}
          >
            <Select<string | number, { value: string; children: string }>
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={onAxisChange}
            >
              <Select.Option value={1}>
                {i18n.t('editor.right-panel.data-source.form.columns')}
              </Select.Option>

              <Select.Option value={0}>
                {i18n.t('editor.right-panel.data-source.form.rows')}
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            rules={[{ required: true }]}
            label={i18n.t('editor.right-panel.data-source.form.data-transformation.labels')}
            shouldUpdate={(prevValues, curValues) => prevValues.axis !== curValues.axis}
          >
            {({ getFieldValue }) => {
              const axis = getFieldValue('axis')
              const options = axis === 0 ? rows : columns
              return (
                <Form.Item name="terms" initialValue={initial.terms}>
                  <MultiSelectDropdown
                    onChange={onTermsChange}
                    visible={labelsVisible}
                    setVisible={setLabelsVisible}
                    dropdownSelect={() => {
                      form.setFieldsValue({ terms: options.map(({ name }) => name) })
                      setTermsLength(form.getFieldsValue(true).terms.length)
                    }}
                    dropdownClear={() => {
                      form.setFieldsValue({ terms: [] })
                      setTermsLength(form.getFieldsValue(true).terms.length)
                    }}
                    dropdownOk={() => setLabelsVisible(false)}
                    dropdownCancel={() => {
                      form.setFieldsValue({ terms: [] })
                      setLabelsVisible(false)
                    }}
                  >
                    {options.map(({ index, name }) => (
                      <Select.Option value={name} key={index}>
                        {name}
                      </Select.Option>
                    ))}
                  </MultiSelectDropdown>
                </Form.Item>
              )
            }}
          </Form.Item>

          <Form.Item name="position" rules={[{ required: true }]}>
            <Radio.Group onChange={onPositionChange} value={positionValue}>
              <Space direction="horizontal">
                <Radio value={'start'}>Start</Radio>
                <Radio value={'end'}>End</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Space>
      </Form>
    </CommonModal>
  )
}

export default ShiftLabels
