import { useSelector } from 'react-redux'

import i18n from 'i18next'

import { RootState } from '@store/rootReducer'
import {
  selectCanvasSelectedObjectDataDefinition,
  selectCanvasSelectedObjectId,
  selectCanvasSelectedObjectProperty
} from '@store/slices/shapes/selectors'

import { OBJECTS_WITH_DATA_TRANSFORMATION } from '@components/canvas'
import DataActions from '@components/imagemap/right-panel/data-actions'
import EmptyElement from '@components/imagemap/right-panel/EmptyElement'

import './DataTransformation.less'

const DataTransformation = () => {
  const dataDefinition = useSelector(selectCanvasSelectedObjectDataDefinition)
  const selectedItemId = useSelector(selectCanvasSelectedObjectId)
  //@ts-ignore
  const type = useSelector((s: RootState) => selectCanvasSelectedObjectProperty(s, 'type'))

  if (!selectedItemId) {
    return <EmptyElement title={i18n.t('editor.right-panel.node.nothing-selected')} />
  }

  if (!OBJECTS_WITH_DATA_TRANSFORMATION.includes(type)) {
    return <EmptyElement title={i18n.t('editor.right-panel.data-transformation.no-element')} />
  }

  if (!dataDefinition?.transformationId) {
    return (
      <EmptyElement title={i18n.t('editor.right-panel.data-transformation.no-data-selection')} />
    )
  }

  return (
    <div className="data-transformation">
      <div className="ant-tabs-tabpane-header">
        {i18n.t('editor.right-panel.data-transformation.title')}
      </div>
      <div className="data-transformation-body">
        <DataActions />
      </div>
    </div>
  )
}

export default DataTransformation
