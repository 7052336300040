import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Layout, Steps } from 'antd'

import { CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons'

import { AppDispatch } from '@/store/store'
import {
  selectHealthStatus,
  selectHealthStatusError,
  selectHealthStatusLoading
} from '@store/slices/health/selectors'
import { getHealthStatus } from '@store/slices/health/thunks'

const { Header, Content } = Layout
const { Step } = Steps

const HealthStatus = () => {
  const history = useHistory()
  const dispatch: AppDispatch = useDispatch()
  const healthStatus = useSelector(selectHealthStatus)
  const error = useSelector(selectHealthStatusError)
  const isLoading = useSelector(selectHealthStatusLoading)

  const getIcon = (status: string | undefined) => {
    if (status === 'running') return <CheckCircleOutlined style={{ color: '#3cd42a' }} />
    if (status === 'not running') return <CloseCircleOutlined style={{ color: '#ff2501' }} />
    if (status === undefined) return <LoadingOutlined style={{ color: '#969696' }} />
  }

  useEffect(() => {
    dispatch(getHealthStatus())
  }, [])

  return (
    <Layout className="layout" style={{ background: 'white' }}>
      <Content>
        <div className="auth__container">
          <div className="auth__card">
            <div className="card__header">
              <h1>Health Status</h1>
            </div>
            <div className="card__body">
              <Steps direction="vertical">
                <Step
                  status="finish"
                  title="Server"
                  description={healthStatus?.server}
                  icon={getIcon(healthStatus?.server)}
                />
                <Step
                  status="finish"
                  title="Redis"
                  description={healthStatus?.redis}
                  icon={getIcon(healthStatus?.redis)}
                />
                <Step
                  status="finish"
                  title="Celery"
                  description={healthStatus?.celery}
                  icon={getIcon(healthStatus?.celery)}
                />
                <Step
                  status="finish"
                  title="Database"
                  description={healthStatus?.database}
                  icon={getIcon(healthStatus?.database)}
                />
              </Steps>
              <p style={{ textAlign: 'center' }}>{error ? error : null}</p>
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default HealthStatus
