import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Button, Layout } from 'antd'

import { selectIsAuthenticated } from '@/store/slices/auth/selectors'

import { Flex } from '@/components/flex'
import { Navbar } from '@components/common/Navbar'

import CodeSignIn from './CodeSignIn'
import EmailSignIn from './EmailSignIn'

import '../styles.less'

type Credentials = {
  email: string
  password: string
}

const { Header, Content } = Layout

const SignIn = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const history = useHistory()
  const [credentials, setCredentials] = useState<Credentials>(null)
  const [step, setStep] = useState(1)

  const onSuccess = (credentials: Credentials) => {
    if (!isAuthenticated) {
      setStep(2)
      setCredentials(credentials)
    }
  }

  return (
    <Layout className="layout" style={{ background: 'white' }}>
      <Header style={{ background: 'white' }}>
        <Navbar
          onPage="main"
          right={<Button onClick={() => history.push('sign-up')}>Sign up</Button>}
        />
      </Header>
      <Content>
        <div className="auth__container">
          {step === 1 ? (
            <EmailSignIn onSuccess={onSuccess} />
          ) : (
            <div className="auth__divider">
              <CodeSignIn credentials={credentials} />
              <Flex justifyContent="center" alignItems="center" flexDirection="column">
                <div className="auth__text-block--large">
                  A verification code <br />
                  has been sent to <br />
                  your email <br />
                </div>
                <div className="auth__text-block--normal">
                  Your two-factor verification code will expire in 5 minutes
                </div>
                <div className="auth__text-block--normal" style={{ maxWidth: '400px' }}>
                  Protecting your data is our top priority. Please confirm your account by enterting
                  the verification code sent to your email.
                </div>
              </Flex>
            </div>
          )}
        </div>
      </Content>
    </Layout>
  )
}

export default SignIn
