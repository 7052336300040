import React, { useEffect, useRef } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useDispatch, useSelector } from 'react-redux'
import {
  Redirect,
  Route,
  RouteComponentProps,
  BrowserRouter as Router,
  Switch
} from 'react-router-dom'

import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

import {
  selectAccessToken,
  selectCurrentUser,
  selectIsAuthenticated
} from '@/store/slices/auth/selectors'
import { AppDispatch } from '@/store/store'
import { getCurrentUser } from '@store/slices/auth/thunks'

import AccountSettings from '@/pages/account-settings'
import ResetPassword from '@/pages/auth/sign-in/ResetPassword'
import App from '@/pages/editor/Editor'
import ErrorScreen from '@/pages/error-screen/ErrorScreen'
import UnauthErrorScreen from '@/pages/error-screen/UnauthErrorScreen'
import HealthStatus from '@/pages/HealthStatus'
import Home from '@/pages/home'
import SignIn from '@pages/auth/sign-in'
import ForgotPassword from '@pages/auth/sign-in/ForgotPassword'
import SignUp from '@pages/auth/sign-up'
import InsertData from '@pages/editor/InsertData/InsertData'
import { Projects } from '@pages/projects/'

import { useTokenInterceptor } from '@/hooks/useTokenInterceptor'
import { useTokenRefresh } from '@/hooks/useTokenRefresh'

interface IProps {
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
  exact?: boolean
  path?: string | string[]
  render?: (props: RouteComponentProps<any>) => React.ReactNode
  isAuthenticated: boolean
}

const Container = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const isLoaded = useTokenInterceptor()
  const tawkMessengerRef = useRef<TawkMessengerReact>()
  useEffect(() => {
    var hostNames = process.env?.REACT_APP_INSPECTLET_HOSTNAMES?.split(',') || []
    var script = document.createElement('script')
    script.type = 'text/javascript'
    script.innerHTML = `(function() {
      window.__insp = window.__insp || [];
      __insp.push(['wid', 177064379]);
      var ldinsp = function(){
      if(typeof window.__inspld != "undefined") return; window.__inspld = 1; var insp = document.createElement('script'); insp.type = 'text/javascript'; insp.async = true; insp.id = "inspsync"; insp.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js?wid=177064379&r=' + Math.floor(new Date().getTime()/3600000); var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(insp, x); };
      setTimeout(ldinsp, 0);
      })();`
    hostNames.includes(window.location.hostname) &&
      document.getElementsByTagName('head')[0].appendChild(script)
  }, [])

  useTokenRefresh()

  if (!isLoaded) {
    return null
  }

  return (
    <>
      <TawkMessengerReact
        propertyId="620bb38ea34c24564126621c"
        widgetId="1frurap97"
        useRef={tawkMessengerRef}
      />
      <Router>
        <Switch>
          <PublicRoute isAuthenticated={isAuthenticated} path="/sign-in" component={SignIn} />
          <PublicRoute isAuthenticated={isAuthenticated} path="/sign-up" component={SignUp} />
          <PublicRoute
            isAuthenticated={isAuthenticated}
            path="/forgot-password"
            component={ForgotPassword}
          />
          <PublicRoute
            isAuthenticated={isAuthenticated}
            path="/reset-password"
            component={ResetPassword}
          />
          <PublicRoute isAuthenticated={isAuthenticated} path="/health" component={HealthStatus} />
          <PrivateRoute isAuthenticated={isAuthenticated} path="/projects" component={Projects} />
          <PrivateRoute isAuthenticated={isAuthenticated} path="/app/:id" component={App} />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            path="/account-settings"
            component={AccountSettings}
          />
          <PrivateRoute isAuthenticated={isAuthenticated} exact path="/" component={Home} />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            path="/data-view"
            component={InsertData}
          />
        </Switch>
      </Router>
    </>
  )
}

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }: IProps) => {
  const dispatch: AppDispatch = useDispatch()
  const gotToken = useSelector(selectAccessToken) !== null
  const currentUser = useSelector(selectCurrentUser)
  useEffect(() => {
    const getUser = async () => {
      if (isAuthenticated && gotToken) {
        await dispatch(getCurrentUser())
        if (currentUser) {
          var script = document.createElement('script')
          script.type = 'text/javascript'
          script.innerHTML = `(function(apiKey){
              (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
              v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
                  o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
                  y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
                  z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
                  pendo.initialize({
                    visitor: {
                        id:              ${currentUser.id}   // Required if user is logged in
                        // email:        // Recommended if using Pendo Feedback, or NPS Email
                        // full_name:    // Recommended if using Pendo Feedback
                        // role:         // Optional
                
                        // You can add any additional visitor level key-values here,
                        // as long as it's not one of the above reserved names.
                    },
                });
            })('399b95ec-c8e1-4ed1-7050-fa0348dc3269');`
          document.getElementsByTagName('head')[0].appendChild(script)
        }
      }
    }
    getUser()
  }, [currentUser?.id])

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <ErrorBoundary FallbackComponent={ErrorScreen}>
            <Component {...props} />
          </ErrorBoundary>
        ) : (
          <Redirect
            to={{
              pathname: '/sign-in'
            }}
          />
        )
      }
    />
  )
}

const PublicRoute = ({ component: Component, isAuthenticated, ...rest }: IProps) => {
  if (!Component) return null
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Redirect
            to={{
              pathname: '/projects'
            }}
          />
        ) : (
          <ErrorBoundary FallbackComponent={UnauthErrorScreen}>
            <Component {...props} />
          </ErrorBoundary>
        )
      }
    />
  )
}

export default Container
