import { useSelector } from 'react-redux'

import { Checkbox, Collapse, Form, Radio, Select, Space, Typography } from 'antd'

import { selectDataSources } from '@/store/slices/data/selectors'

const { Text } = Typography
const { Panel } = Collapse

const SelectDataTarget = ({
  dataSource,
  onTargetChange,
  reindexingStrategy,
  onStrategyChange,
  globalStrategy,
  onGlobalStrategyChange
}) => {
  const sources = useSelector(selectDataSources)
  const dataSourceIndex = sources.map((source, index) => {
    if (source?.id === dataSource.id) {
      return index + 1
    }
    return null
  })

  return (
    <Form layout="vertical" name="switchDataForm">
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <div className="container-original-source">
          <div>Original data source:</div>
          <div className="card-original-source">
            <div>
              <Text strong>Data source:</Text> {dataSourceIndex}
            </div>
            <div>
              <Text strong>Filename:</Text> {dataSource.filename}
            </div>
          </div>
        </div>
        <Form.Item label="Data source" name="source" rules={[{ required: true }]}>
          <Select<string | number, { value: string; children: string }>
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={onTargetChange}
          >
            {sources.map(source => {
              if (source.id !== dataSource.id)
                return (
                  <Select.Option value={source.id} key={source.id}>
                    {source.filename}
                  </Select.Option>
                )
              return null
            })}
          </Select>
        </Form.Item>

        <Collapse ghost>
          <Panel header="Advanced options" key="1">
            <p>How should we select columns?</p>
            <Checkbox
              style={{ margin: '0px 0px 14px 0px' }}
              onChange={onGlobalStrategyChange}
              checked={globalStrategy}
            >
              Override stored data selection strategy?
            </Checkbox>
            <Radio.Group
              onChange={onStrategyChange}
              value={reindexingStrategy}
              disabled={!globalStrategy}
            >
              <Space wrap>
                <Radio value={null}>By Column Position</Radio>
                <Radio value="by_absolute_position">By Absolute Position</Radio>
                <Radio value="by_name">By Column Name</Radio>
                <Radio value="by_parent_group">By Column Position in Group</Radio>
                <Radio value="by_group_inclusion">All Columns by Group Position</Radio>
                <Radio value="by_group_name">All Columns by Group Name</Radio>
              </Space>
            </Radio.Group>
          </Panel>
        </Collapse>
      </Space>
    </Form>
  )
}

export default SelectDataTarget
