import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Button, Col, Form, Input, InputNumber, Row, Select } from 'antd'

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import i18n from 'i18next'
import { isEqual } from 'lodash'

import { selectCanvasSelectedObject } from '@/store/slices/shapes/selectors'

import { useDefaultFieldValues, useResetModalForm } from '@components/imagemap/right-panel/hooks'

import { ConditionTypes } from '@/interfaces/data-overlays'
import { Nullable } from '@/interfaces/utils'

import { PositionOptions, VisualisationSymbols } from '../sig-testing/VisualisationSettings'
import { ThresholdSettings } from './types'

import './styles.less'

import CommonModal from '@/components/common/CommonModal'
import Info from '@/components/common/Info'

export type ThresholdFormset = {
  formList: ThresholdSettings[]
}

interface Props {
  initial: Nullable<ThresholdSettings[]>
  visible: boolean
  onCancel: () => void
  onFinish: ({ formList }: ThresholdFormset) => void
}

const ThresholdModalForm = ({ initial, visible, onCancel, onFinish }: Props) => {
  const [form] = Form.useForm<ThresholdFormset>()
  const selectedItem = useSelector(selectCanvasSelectedObject)
  const [setIndex, setConditionValue] = useDefaultFieldValues({
    form,
    formListName: 'formList',
    applyToFieldName: 'visualise',
    colorFieldName: 'color'
  })

  const onConditionChange = (index, value) => {
    setIndex(index)
    setConditionValue(value)
  }

  useResetModalForm({
    form,
    visible
  })

  useEffect(() => {
    let initialValues: ThresholdFormset = { formList: initial }
    form.setFieldsValue(initialValues)
  }, [initial])

  const popoverContent = (
    <div>
      <div>
        <p></p>
      </div>
      <h4>
        <strong></strong>
      </h4>
      <div>
        <p>
          The Percentage Indexing formula works as follows: (Test Group Values / Control Group
          Values) * 100 = Percentage Index Value
        </p>
        <p>
          Set rules here by entering the percentage index value(s) and decide how to visualise,
          based on whether the value is greater than or less than these values.
        </p>
        <p>
          For example, if you would like to identify test values, or test groups, that over-index by
          10% or more against your base value, or control group, you would select set your rule to
          “Greater than or equal to” and input a value of 110 – 100 + 10%.
        </p>
        <p>
          You can select how to visualise each rule individually, giving you complete flexibility to
          bespoke your percentage indexing rules
        </p>
      </div>
    </div>
  )

  return (
    <CommonModal
      title={
        <>
          <p style={{ marginBottom: '0em' }}>
            {i18n.t('editor.right-panel.analytics.percentage-indexing.thresholds-title')}{' '}
            <Info>{popoverContent}</Info>
          </p>
        </>
      }
      visible={visible}
      onCancel={onCancel}
      width={1100}
      footer={[
        <Button type="primary" form="modalForm" key="submit" htmlType="submit">
          OK
        </Button>,
        <Button onClick={onCancel} key="cancel">
          Cancel
        </Button>
      ]}
    >
      <Form
        form={form}
        name="modalForm"
        id="modalForm"
        onFinish={onFinish}
        initialValues={{ formList: initial }}
      >
        <Form.List name="formList">
          {(fields, { add, remove }) => (
            <>
              {/* Formset */}
              {fields.map((field, index) => (
                <React.Fragment key={index}>
                  <Row gutter={16} style={{ paddingBottom: '12px' }}>
                    <Col span={0.5}>
                      <Form.Item>If</Form.Item>
                    </Col>

                    {/* Threshold type */}
                    <Col span={4}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'thresholdType']}
                        rules={[{ required: true }]}
                      >
                        <Select<string | number, { value: string; children: string }>
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={value => onConditionChange(index, value)}
                          placeholder="Select condition"
                        >
                          {Object.entries(ConditionTypes).map(([key, value], conIdx) => {
                            return (
                              <Select.Option key={conIdx} value={key}>
                                {value}
                              </Select.Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                    </Col>

                    {/* Threshold value */}
                    <Col span={2.5}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'thresholdValue']}
                        rules={[{ required: true }]}
                      >
                        <InputNumber placeholder="Value" />
                      </Form.Item>
                    </Col>

                    <Col key={field.key} span={1.5}>
                      <Form.Item style={{ textAlign: 'center' }}>then</Form.Item>
                    </Col>

                    {/* Visualise with */}
                    <Col span={5}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'visualise']}
                        rules={[{ required: true }]}
                      >
                        <Select<string | number, { value: string; children: string }>
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder={i18n.t(
                            'editor.right-panel.analytics.visualisation.visualise'
                          )}
                        >
                          {selectedItem.type === 'table' && (
                            <Select.Option value="cell-background">
                              {i18n.t('editor.right-panel.analytics.visualisation.cell-background')}
                            </Select.Option>
                          )}
                          <Select.Option value="symbols">
                            {i18n.t('editor.right-panel.analytics.visualisation.only-symbols')}
                          </Select.Option>
                          <Select.Option value="colored-labels">
                            {i18n.t(
                              'editor.right-panel.analytics.visualisation.color-coded-labels'
                            )}
                          </Select.Option>
                          <Select.Option value="symbols-colored-labels">
                            {i18n.t(
                              'editor.right-panel.analytics.visualisation.symbols-and-labels'
                            )}
                          </Select.Option>
                          <Select.Option value="values-colored-labels">
                            Show resulting value
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    {/* Symbol */}
                    <Form.Item
                      shouldUpdate={(prevValue, curValue) =>
                        !isEqual(prevValue.formList, curValue.formList)
                      }
                    >
                      {({ getFieldValue }) => {
                        let visualise = getFieldValue(['formList', field.name, 'visualise'])
                        let show = visualise === 'symbols' || visualise === 'symbols-colored-labels'
                        return show ? (
                          <Col span={1.5}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'symbol']}
                              rules={[{ required: true }]}
                            >
                              <Select<string | number, { value: string; children: string }>
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder="Symbol"
                              >
                                {Object.entries(VisualisationSymbols).map(([key, value], index) => (
                                  <Select.Option key={index} value={key}>
                                    {value}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        ) : null
                      }}
                    </Form.Item>

                    {/* Color */}
                    <Col span={1.5}>
                      <Form.Item {...field} name={[field.name, 'color']}>
                        <Input style={{ width: '50px' }} type="color" />
                      </Form.Item>
                    </Col>

                    {/* Position */}
                    <Col span={3}>
                      <Form.Item
                        shouldUpdate={(prevValue, curValue) =>
                          !isEqual(prevValue.formList, curValue.formList)
                        }
                      >
                        {({ getFieldValue }) => {
                          let visualise = getFieldValue(['formList', field.name, 'visualise'])
                          let show = [
                            'symbols',
                            'symbols-colored-labels',
                            'values-colored-labels'
                          ].includes(visualise)
                          return show ? (
                            <Form.Item
                              {...field}
                              name={[field.name, 'position']}
                              rules={[{ required: true }]}
                            >
                              <Select placeholder="Position">
                                {Object.keys(PositionOptions).map(
                                  (position: keyof typeof PositionOptions, posIdx) => (
                                    <Select.Option key={posIdx} value={position}>
                                      {position}
                                    </Select.Option>
                                  )
                                )}
                              </Select>
                            </Form.Item>
                          ) : null
                        }}
                      </Form.Item>
                    </Col>

                    <Col span={1}>
                      <Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined onClick={() => remove(field.name)} />
                        ) : null}
                      </Form.Item>
                    </Col>
                  </Row>
                </React.Fragment>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: '100%' }}
                  icon={<PlusOutlined />}
                >
                  Add field
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </CommonModal>
  )
}

export default ThresholdModalForm
