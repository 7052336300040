import axios, { AxiosResponse } from 'axios'

import { IShape } from '@/interfaces/shape'

const API_URL = '/api/projects'

export async function getShapesBySlideIds(
  projectId: number,
  slideIds: number[]
): Promise<IShape[]> {
  const url = `${API_URL}/${projectId}/shapes/?slide=${slideIds}`
  const response = (await axios.get(url)) as AxiosResponse<IShape[]>
  return response.data
}

interface IUpdateShape {
  projectId: number
  shapeId: number | string
  payload: Partial<IShape>
}

export function updateShape({ projectId, shapeId, payload }: IUpdateShape): Promise<IShape> {
  return new Promise((resolve, reject) => {
    const url = `${API_URL}/${projectId}/shapes/${shapeId}/`
    axios
      .put(url, payload)
      .then(res => {
        const item = res.data
        resolve(item)
      })
      .catch(err => {
        reject(err?.response?.data)
      })
  })
}

export async function resetShape({ projectId, shapeId }) {
  const url = `${API_URL}/${projectId}/shapes/${shapeId}/reset`
  const response = await axios.delete(url)
  return response.data
}

export async function getShapeData(projectId, shapeId) {
  const url = `${API_URL}/${projectId}/shapes/${shapeId}/data`
  const response = await axios.get(url)
  return response.data
}

interface ICopyShapeProps {
  projectId: number
  shapeId: number | string
  payload: {
    shapeId: string | number
    isDataSelection: boolean
    isDataActions: boolean
    isOverlays: boolean
    isShapeSettings: boolean
  }
}

export async function copyShapeConf({
  projectId,
  shapeId,
  payload
}: ICopyShapeProps): Promise<IShape> {
  const url = `${API_URL}/${projectId}/shapes/${shapeId}/copy_configuration/`
  const response = await axios.post(url, payload)
  return response.data
}
