import { Button, Form, Modal, Radio, RadioChangeEvent, Space } from 'antd'
import { useForm } from 'antd/es/form/Form'

import i18n from 'i18next'

import CommonModal from '@/components/common/CommonModal'
import Info from '@/components/common/Info'

import { ActionProps, ActionTypes, ReverseAction } from './types'

export type FormValues = {
  axis: 0 | 1
}

const Reverse = ({ onSubmit, onCancel, isVisible, initialValues }: ActionProps<ReverseAction>) => {
  const [form] = useForm<FormValues>()

  const initial = {
    axis: 1,
    ...initialValues
  }

  const submitForm = () => {
    form.submit()
    const formValues = form.getFieldsValue(true)
    const cleanValues = {
      actionType: ActionTypes.REVERSE,
      axis: formValues.axis
    }
    onSubmit(cleanValues)
  }

  const onChange = (e: RadioChangeEvent) => {
    form.setFieldsValue({ axis: e.target.value })
  }

  const popoverContent = (
    <div>
      <strong>Function</strong>
      <p>The order of the rows/columns will be reversed in comparison to the original data table</p>
      <strong>How to use</strong>
      <p>Select to reverse the order of either the columns or rows mapped into the object</p>
      <strong>Notes</strong>
      <p>
        <em>
          Using the Sort by and Reorder Transformations will overwrite any reverse performed using
          this function
        </em>
      </p>
    </div>
  )

  return (
    <CommonModal
      title={
        <span>
          Reverse <Info>{popoverContent}</Info>
        </span>
      }
      visible={isVisible}
      onCancel={onCancel}
      onOk={submitForm}
    >
      <Form form={form} initialValues={initial} layout="vertical">
        <Form.Item
          name="axis"
          label={i18n.t('editor.right-panel.data-source.form.data-transformation.reverse.axis')}
        >
          <Radio.Group onChange={onChange}>
            <Space direction="horizontal">
              <Radio value={1}>{i18n.t('editor.right-panel.data-source.form.columns')}</Radio>
              <Radio value={0}>{i18n.t('editor.right-panel.data-source.form.rows')}</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      </Form>
    </CommonModal>
  )
}

export default Reverse
