import { useEffect, useState } from 'react'

import { Form, Input, Radio, Select, Space } from 'antd'
import { useForm } from 'antd/es/form/Form'

import CommonModal from '@/components/common/CommonModal'
import Info from '@/components/common/Info'

import { ActionProps, ActionTypes, FilterAction } from './types'

export enum FilterTypes {
  Above = 'above',
  Below = 'below',
  Between = 'between'
}

export interface FilterProps {
  isVisible: boolean
  onSubmit: (formValues: Omit<FilterAction, 'shapeDataSelection'>) => void
  onCancel: () => void
  initialValues?: Partial<FilterAction>
}

export type FormValues = {
  axis: 0 | 1
  term: string
  position: string
  action: 'keep' | 'remove'
}

const popoverContent = (
  <div>
    <strong>Function</strong>
    <p>
      {' '}
      Remove columns or rows from the selected object which contain a specified set of characters.
    </p>
    <strong>How to use</strong>
    <p>[Choose axis]: Select to filter out Columns or Rows from the object</p>
    <p>
      [Filter by term]: Specify a valid regular expression that determine which Columns or Rows to
      filter.
    </p>
    <strong>RegExp reference</strong>
    <ul style={{ paddingLeft: '12px' }}>
      <li>
        <code>\s</code> - matches white space characters
        <br />
        Example: expression "Chrome\sUsers" matches "Safari & Chrome Users"
      </li>
      <li>
        <code>^</code> - matches the start of the string
        <br />
        Example: expression "^Chrome" matches "Chrome Users" but doesn't match "Safari & Chrome
        Users"
      </li>
      <li>
        <code>$</code> - matches the end of the string
        <br />
        Example: expression "Chrome\sUsers" matches "Safari & Chrome Users" but doesn't match
        "Chrome Users & Something"
      </li>
      <li>
        <code>.</code> - matches any character except the newline
        <br />
        Example: expression "Chr.me" matches "Safari & Chrome Users"
      </li>
    </ul>
    <p>
      For a complete RegExp cheatsheet and tester visit:{' '}
      <a href="https://pythex.org/">https://pythex.org/</a>
    </p>
  </div>
)

const StringFilter = ({
  onSubmit,
  onCancel,
  isVisible,
  initialValues
}: ActionProps<FilterAction>) => {
  const initial = {
    axis: 0,
    term: '',
    position: 'start',
    action: 'keep',
    ...initialValues
  }

  const [form] = useForm<FormValues>()
  const [positionValue, setPositionValue] = useState(initial.position)
  const [actionValue, setActionValue] = useState(initial.action)

  const submitForm = () => {
    const formValues = form.getFieldsValue(true)
    const { axis, term, position, action } = formValues
    const payload = {
      actionType: ActionTypes.STRING_FILTER,
      axis,
      specs: { term, position, action }
    }
    onSubmit(payload)
  }

  useEffect(() => {
    //@ts-ignore
    form.setFieldsValue(initial)
  }, [form, initialValues])

  const onPositionChange = e => {
    setPositionValue(e.target.value)
    form.setFieldsValue({ position: e.target.value })
  }

  const onActionChange = e => {
    setActionValue(e.target.value)
    form.setFieldsValue({ action: e.target.value })
  }

  return (
    <CommonModal
      title={
        <span>
          Filter by text search <Info>{popoverContent}</Info>
        </span>
      }
      visible={isVisible}
      onCancel={onCancel}
      onOk={submitForm}
      okDisabled={false}
    >
      <Form form={form} onFinish={submitForm} initialValues={initialValues} layout="vertical">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form.Item name="axis" rules={[{ required: true }]} label="Choose axis">
            <Select<
              string | number,
              { value: string; children: string }
            > optionFilterProp="children">
              <Select.Option value={1}>Columns</Select.Option>

              <Select.Option value={0}>Rows</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item name="term" rules={[{ required: true }]} label="Filter by term">
            <Input />
          </Form.Item>

          <Form.Item
            name="position"
            rules={[{ required: true }]}
            label="Where is the text in the label?"
          >
            <Radio.Group onChange={onPositionChange} value={positionValue}>
              <Space direction="horizontal">
                <Radio value={'start'}>Start</Radio>
                <Radio value={'end'}>End</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="action" rules={[{ required: true }]} label="Keep or remove?">
            <Radio.Group onChange={onActionChange} value={actionValue}>
              <Space direction="horizontal">
                <Radio value={'keep'}>Keep</Radio>
                <Radio value={'remove'}>Remove</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Space>
      </Form>
    </CommonModal>
  )
}

export default StringFilter
