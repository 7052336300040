import React, { useCallback } from 'react'

import { fabric } from 'fabric'

import {
  useCommonPropsHandler,
  useTextboxPropsHandler,
  useVisibilityHandler
} from '@components/canvas/handlers/objects'
import { CanvasComponentProps } from '@components/canvas/types/component'
import { FabricTextObject, FabricTextOptions } from '@components/canvas/types/text'

import { useFabricObject } from '../utils/useFabricObject'

const textboxFactory = (options: FabricTextOptions): FabricTextObject => {
  return new fabric.Textarea(options as any)
}

const Text = ({ id, options }: CanvasComponentProps<FabricTextOptions>) => {
  const factory = useCallback(() => textboxFactory(options), [])
  const object = useFabricObject(factory, id, options)

  useVisibilityHandler(object)
  useTextboxPropsHandler(object)
  useCommonPropsHandler(object)
  return <></>
}

export default React.memo(Text)
