import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectCanvasSelectedObjectId } from '@store/slices/shapes/selectors'

import { useCanvas } from '@components/canvas/hooks/useCanvas'
import { FabricObject } from '@components/canvas/types/object'

export function useSelectionHandler() {
  const selectedObjectId = useSelector(selectCanvasSelectedObjectId)
  const canvas = useCanvas()

  useEffect(() => {
    const findObject = canvas.getObjects().find((obj: FabricObject) => obj.id === selectedObjectId)
    if (findObject && findObject !== canvas.getActiveObject()) {
      canvas.discardActiveObject()
      canvas.setActiveObject(findObject)
      canvas.requestRenderAll()
    }
  }, [canvas, selectedObjectId])
}
