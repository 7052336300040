import { createAction, Update } from '@reduxjs/toolkit'

import { RootState } from '@/store/rootReducer'
import { selectCanvasHeight, selectCanvasWidth } from '@store/slices/canvas/canvas/selectors'
import {
  selectCanvasAllObjects,
  selectCanvasSelectedObjectId
} from '@store/slices/shapes/selectors'
import { ObjectDataDefinition } from '@store/slices/shapes/types'

import { updateProjectUpdateTime } from '@/services/project-service'
import { updateShape } from '@/services/shape-service'

import { VisualSettings } from '@/components/canvas/types/visualSettings'
import { CanvasObjectOptions } from '@components/canvas/types/object'
import { FabricWorkAreaOptions } from '@components/canvas/types/workArea'

import { IShapeData } from '@/interfaces/shape'

export const setStoredConfig = createAction<{ [key: string]: unknown }>(
  'canvas/object.setStoredConfig'
)
export const setCanvasSelectedObjectId = createAction<string | number>(
  'canvas/object/setCanvasSelectedObjectId'
)
export const setCanvasSelectedObjectPk = createAction<number>(
  'canvas/object/setCanvasSelectedObjectPk'
)
export const setCanvasSelectedObjectDataDefinition = createAction<ObjectDataDefinition>(
  'canvas/object/setCanvasSelectedObjectDataDefinition'
)
export const setCanvasSelectionLoading = createAction<boolean>(
  'canvas/object/setCanvasDataDefinitionLoading'
)
export const clearCanvasSelectedObjectDataDefinition = createAction(
  'canvas/object/clearCanvasSelectedObjectDataDefinition'
)
export const clearCanvasSelectedObject = createAction('canvas/object/clearCanvasSelectedObject')
export const setAllCanvasObjets = createAction<CanvasObjectOptions[]>(
  'canvas/object/setAllCanvasObjets'
)
export const updateOneCanvasObject = createAction<Update<CanvasObjectOptions>>(
  'canvas/object/updateOneCanvasObject'
)
export const updateManyCanvasObject = createAction<Update<CanvasObjectOptions>[]>(
  'canvas/object/updateManyCanvasObject'
)
export const addOneCanvasObject = createAction<CanvasObjectOptions>(
  'canvas/object/addOneCanvasObject'
)
export const removeOneCanvasObject = createAction<string | number>(
  'canvas/object/removeOneCanvasObject'
)
export const removeManyCanvasObjects = createAction<(string | number)[]>(
  'canvas/object/removeManyCanvasObject'
)
export const removeAllCanvasObjects = createAction('canvas/object/removeAllCanvasObjects')

export function removeCanvasSelectedObject() {
  return (dispatch, getState) => {
    const state = getState()
    const selectedObjectId = selectCanvasSelectedObjectId(state)
    dispatch(clearCanvasSelection())
    dispatch(removeOneCanvasObject(selectedObjectId))
  }
}

export function selectCanvasObject(obj: CanvasObjectOptions) {
  return async (dispatch, getState) => {
    const state = getState()
    const previousSelectedObjectId = selectCanvasSelectedObjectId(state)
    if (previousSelectedObjectId !== obj.id) {
      dispatch(setCanvasSelectionLoading(true))
      dispatch(setCanvasSelectedObjectId(obj.id))
      dispatch(setCanvasSelectedObjectPk(obj.pk))
      dispatch(setCanvasSelectionLoading(false))
    }
  }
}

export function updateShapeData(id, payload: Partial<IShapeData>, partial = true) {
  return async (dispatch, getState) => {
    const state: RootState = getState()
    const obj = state.shapes.objects.entities[id]
    const projectId = state.projects.currentProject.id
    await dispatch(setCanvasSelectionLoading(true))
    await updateProjectUpdateTime(projectId)
    let data = null
    if (payload) {
      data = partial ? { ...obj.data, ...payload } : payload
    }
    await dispatch(updateOneCanvasObject({ id: obj.id, changes: { data } }))
    await dispatch(setCanvasSelectionLoading(false))
  }
}

export function updateShapeVisualSettings(id, payload: Partial<VisualSettings>, partial = true) {
  return async (dispatch, getState) => {
    const state: RootState = getState()
    const projectId = state.projects.currentProject.id
    const obj = state.shapes.objects.entities[id]
    await dispatch(setCanvasSelectionLoading(true))
    let visualSettings = null
    if (payload) {
      visualSettings = partial ? { ...obj.visualSettings, ...payload } : payload
    }
    await updateShape({ projectId, shapeId: obj.pk, payload: { visualSettings } })
    await dispatch(updateOneCanvasObject({ id: obj.id, changes: { visualSettings } }))
    await dispatch(setCanvasSelectionLoading(false))
  }
}

export function clearCanvasSelection() {
  return async dispatch => {
    dispatch(clearCanvasSelectedObjectDataDefinition())
    dispatch(clearCanvasSelectedObject())
  }
}

export function updateCanvasObjectsPosition() {
  return (dispatch, getState) => {
    const state = getState()
    const allObjects = selectCanvasAllObjects(state)
    const canvasWidth = selectCanvasWidth(state)
    const canvasHeight = selectCanvasHeight(state)
    const workArea = (allObjects.find(obj => obj.id === 'workarea') ||
      allObjects[0]) as FabricWorkAreaOptions
    const previousWorkAreaTop = workArea.top || 0
    const previousWorkAreaLeft = workArea.left || 0
    const workAreaLeft = canvasWidth / 2 - (workArea.width * workArea.scaleX) / 2
    const workAreaTop = canvasHeight / 2 - (workArea.height * workArea.scaleY) / 2

    const newObjects = allObjects.map(obj => {
      if (obj.id === 'workarea') {
        return {
          ...obj,
          top: workAreaTop,
          left: workAreaLeft
        }
      }

      return {
        ...obj,
        left: obj.left + (workAreaLeft - previousWorkAreaLeft),
        top: obj.top + (workAreaTop - previousWorkAreaTop)
      }
    })
    dispatch(setAllCanvasObjets(newObjects))
  }
}
