import { createReducer } from '@reduxjs/toolkit'
import jwtDecode from 'jwt-decode'

import {
  clearAccessToken,
  clearLoading,
  clearMfaSignInError,
  clearRefreshToken,
  clearSignInError,
  clearSignUpError,
  setAccessToken,
  setAuthenticated,
  setCurrentUser,
  setMfaEnabled,
  setRefreshToken
} from '@store/slices/auth/actions'
import { mfaSignIn, signIn, signUp } from '@store/slices/auth/thunks'
import { IAuthState, Token, TokenTypes } from '@store/slices/auth/types'

const initialState: IAuthState = {
  isAuthenticated: false,
  accessToken: null,
  refreshToken: null,
  currentUser: null,
  signInReq: {
    loading: false,
    error: null
  },
  mfaSignInReq: {
    loading: false,
    error: null
  },
  signUpReq: {
    loading: false,
    error: null
  },
  getCurrentUserReq: {
    loading: false,
    error: null
  }
}

export const authReducer = createReducer(initialState, builder => {
  builder.addCase(setAuthenticated, (state, { payload }) => {
    state.isAuthenticated = payload
  })
  builder.addCase(setAccessToken, (state, { payload }) => {
    state.accessToken = payload
  })
  builder.addCase(setRefreshToken, (state, { payload }) => {
    state.refreshToken = payload
  })
  builder.addCase(setCurrentUser, (state, { payload }) => {
    state.currentUser = payload
  })
  builder.addCase(setMfaEnabled, (state, { payload }) => {
    state.currentUser.is_mfa_activated = payload
  })
  builder.addCase(clearAccessToken, (state, { payload }) => {
    state.accessToken = null
  })
  builder.addCase(clearRefreshToken, (state, { payload }) => {
    state.refreshToken = null
  })
  builder.addCase(clearSignInError, (state, { payload }) => {
    state.signInReq.error = null
  })
  builder.addCase(clearMfaSignInError, (state, { payload }) => {
    state.mfaSignInReq.error = null
  })
  builder.addCase(clearSignUpError, (state, { payload }) => {
    state.signUpReq.error = null
  })
  builder.addCase(clearLoading, (state, { payload }) => {
    state.signInReq.loading = false
    state.mfaSignInReq.loading = false
    state.signUpReq.loading = false
    state.getCurrentUserReq.loading = false
  })
  builder.addCase(signIn.pending, state => {
    if (!state.signInReq.loading) {
      state.signInReq.loading = true
      state.signInReq.error = null
    }
  })
  builder.addCase(signIn.fulfilled, (state, { payload }) => {
    if (payload.access && payload.refresh) {
      const accessToken: Token = {
        type: TokenTypes.ACCESS,
        key: payload.access,
        payload: jwtDecode(payload.access)
      }
      const refreshToken: Token = {
        type: TokenTypes.REFRESH,
        key: payload.refresh,
        payload: jwtDecode(payload.refresh)
      }
      state.accessToken = accessToken
      state.refreshToken = refreshToken
      state.isAuthenticated = true
    }
    if (state.signInReq.loading) {
      state.signInReq.loading = false
      state.signInReq.error = null
    }
  })
  builder.addCase(signIn.rejected, (state, { payload }) => {
    state.signInReq.loading = false
    state.signInReq.error = payload
  })
  builder.addCase(mfaSignIn.pending, state => {
    if (!state.mfaSignInReq.loading) {
      state.mfaSignInReq.loading = true
      state.mfaSignInReq.error = null
    }
  })
  builder.addCase(mfaSignIn.fulfilled, (state, { payload }) => {
    if (payload.access && payload.refresh) {
      const accessToken: Token = {
        type: TokenTypes.ACCESS,
        key: payload.access,
        payload: jwtDecode(payload.access)
      }
      const refreshToken: Token = {
        type: TokenTypes.REFRESH,
        key: payload.refresh,
        payload: jwtDecode(payload.refresh)
      }
      state.accessToken = accessToken
      state.refreshToken = refreshToken
      state.isAuthenticated = true
    }
    if (state.mfaSignInReq.loading) {
      state.mfaSignInReq.loading = false
      state.mfaSignInReq.error = null
    }
  })
  builder.addCase(mfaSignIn.rejected, (state, { payload }) => {
    if (state.mfaSignInReq.loading) {
      state.mfaSignInReq.loading = false
      state.mfaSignInReq.error = payload
    }
  })
  builder.addCase(signUp.pending, state => {
    if (!state.signUpReq.loading) {
      state.signUpReq.loading = true
      state.signUpReq.error = null
    }
  })
  builder.addCase(signUp.fulfilled, state => {
    if (state.signUpReq.loading) {
      state.signUpReq.loading = false
      state.signUpReq.error = null
    }
  })
  builder.addCase(signUp.rejected, (state, { payload }) => {
    if (state.signUpReq.loading) {
      state.signUpReq.loading = false
      state.signUpReq.error = payload
    }
  })
})
