import { createEntityAdapter, createReducer, EntityState } from '@reduxjs/toolkit'

import {
  addOneCanvasObject,
  clearCanvasSelectedObject,
  removeAllCanvasObjects,
  removeManyCanvasObjects,
  removeOneCanvasObject,
  setAllCanvasObjets,
  setCanvasSelectedObjectId,
  setCanvasSelectedObjectPk,
  setCanvasSelectionLoading,
  setStoredConfig,
  updateManyCanvasObject,
  updateOneCanvasObject
} from '@store/slices/shapes/actions'
import { CanvasSelectedObject } from '@store/slices/shapes/types'

import { CanvasObjectOptions } from '@components/canvas/types/object'

export const objectAdapter = createEntityAdapter<CanvasObjectOptions>({
  selectId: ({ id }) => id
})

export interface ObjectState {
  objects: EntityState<CanvasObjectOptions>
  selectedObject: CanvasSelectedObject
  storedConfig: Record<string, unknown>
}

const initialState: ObjectState = {
  objects: objectAdapter.getInitialState(),
  selectedObject: {
    id: null,
    pk: null,
    loading: false
  },
  storedConfig: null
}

export const shapesReducer = createReducer(initialState, builder => {
  builder.addCase(setCanvasSelectedObjectId, (state, { payload }) => {
    state.selectedObject.id = payload
  })
  builder.addCase(setCanvasSelectedObjectPk, (state, { payload }) => {
    state.selectedObject.pk = payload
  })
  builder.addCase(clearCanvasSelectedObject, state => {
    state.selectedObject.id = null
    state.selectedObject.pk = null
  })
  builder.addCase(setCanvasSelectionLoading, (state, { payload }) => {
    state.selectedObject.loading = payload
  })
  builder.addCase(setAllCanvasObjets, (state, { payload }) => {
    objectAdapter.setAll(state.objects, payload)
  })
  builder.addCase(updateOneCanvasObject, (state, { payload }) => {
    objectAdapter.updateOne(state.objects, payload)
  })
  builder.addCase(updateManyCanvasObject, (state, { payload }) => {
    objectAdapter.updateMany(state.objects, payload)
  })
  builder.addCase(addOneCanvasObject, (state, { payload }) => {
    objectAdapter.addOne(state.objects, payload)
  })
  builder.addCase(removeOneCanvasObject, (state, { payload }) => {
    objectAdapter.removeOne(state.objects, payload)
  })
  builder.addCase(removeManyCanvasObjects, (state, { payload }) => {
    objectAdapter.removeMany(state.objects, payload)
  })
  builder.addCase(removeAllCanvasObjects, state => {
    state.objects = objectAdapter.getInitialState() as any
    state.selectedObject.id = null
  })
  builder.addCase(setStoredConfig, (state, { payload }) => {
    state.storedConfig = payload
  })
})
