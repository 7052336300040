import Chart from './Chart'
import Circle from './Circle'
import Image from './Image'
import Placeholder from './Placeholder'
import Rect from './Rect'
import Table from './Table'
import Text from './Text'

export const CANVAS_COMPONENTS = {
  textbox: Text,
  circle: Circle,
  rect: Rect,
  image: Image,
  chart: Chart,
  table: Table,
  placeholder: Placeholder
}
