import { Collapse } from 'antd'

import i18n from 'i18next'

import EmptyElement from '@components/imagemap/right-panel/EmptyElement'
import { PropertyDefinition } from '@components/imagemap/right-panel/properties/PropertyDefinition'

import './NodeProperties.less'

import { useSelector } from 'react-redux'

import { RootState } from '@store/rootReducer'
import { selectCanvasSelectedObjectProperty } from '@store/slices/shapes/selectors'

import './styles.less'

const { Panel } = Collapse

const NodeProperties = () => {
  //@ts-ignore
  const type = useSelector((s: RootState) => selectCanvasSelectedObjectProperty(s, 'type'))
  if (!type) {
    return <EmptyElement title={i18n.t('editor.right-panel.node.nothing-selected')} />
  }
  return (
    <div className="node-properties">
      <div className="ant-tabs-tabpane-header" style={{ marginBottom: '8px' }}>
        {i18n.t('editor.right-panel.shape-settings.title')}
      </div>
      {PropertyDefinition[type] ? (
        <Collapse bordered={false} accordion style={{ backgroundColor: '#ffffff' }}>
          {Object.keys(PropertyDefinition[type]).map(key => {
            if (type === 'table' && PropertyDefinition[type][key].title === 'General') {
              return null
            } else if (
              (type === 'chart' && PropertyDefinition[type][key].title === 'General') ||
              PropertyDefinition[type][key].title === 'Chart Option'
            ) {
              return null
            } else {
              return (
                <Panel key={key} header={PropertyDefinition[type][key].title} showArrow={true}>
                  {PropertyDefinition[type][key].component}
                </Panel>
              )
            }
          })}
        </Collapse>
      ) : (
        <EmptyElement title={i18n.t('editor.right-panel.node.no-property')} />
      )}
    </div>
  )
}

export default NodeProperties
