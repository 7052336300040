import { Switch, Tooltip } from 'antd'

import { InfoCircleOutlined } from '@ant-design/icons'

const defaultHelpText = `
  When turned on, comparison between test and control data
  is performed on formatted values accordingly to the specified
  number format. 
  When turned off, comparison is performed on raw values. 
`

const defaultStyle = {
  margin: '14px 0'
}

type Props = {
  onToggle: (value: boolean) => void
  defaultChecked?: boolean
  checked?: boolean
  helpText?: string
  style?: Partial<React.CSSProperties>
}

export const Switcher: React.FC<Props> = ({
  onToggle,
  style = defaultStyle,
  checked = false,
  defaultChecked = false,
  helpText = defaultHelpText
}) => {
  return (
    <div style={style}>
      <Switch size="small" onChange={onToggle} checked={checked} defaultChecked={defaultChecked} />
      <span style={{ padding: '0 8px' }}>Use formatted data</span>
      <Tooltip title={helpText}>
        <InfoCircleOutlined style={{ cursor: 'pointer' }} />
      </Tooltip>
    </div>
  )
}
