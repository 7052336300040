import { CeleryStatus } from '@/interfaces/celery'
import {
  CheckProjectDataSourceCompatibilityResponse,
  DataSectionDto,
  DataSourceDto
} from '@/interfaces/data'

export enum DataSourceAddStatuses {
  SECTIONS_SELECTION = 'SECTIONS_SELECTION',
  COMPATIBILITY_WARNINGS = 'COMPATIBILITY_WARNINGS',
  RAW_DATA_SELECTION = 'RAW_DATA_SELECTION'
}

export type DataSourceAddStatus = CeleryStatus | DataSourceAddStatuses | null

export interface DataSourceUpload {
  status: DataSourceAddStatus | null
  dataSource: DataSourceDto | null
  compatibility: CheckProjectDataSourceCompatibilityResponse | null
  celeryTaskId: string | null
  dataSections: DataSectionDto[]
  rawData: string[]
}
