import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Col, Form, Row, Slider, Tag } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import { fabric } from 'fabric'
import i18n from 'i18next'
import { debounce } from 'lodash'

import { RootState } from '@store/rootReducer'
import { updateOneCanvasObject } from '@store/slices/shapes/actions'
import { selectCanvasSelectedObject } from '@store/slices/shapes/selectors'

import { FILTER_TYPES } from '@components/canvas'
import { FabricImageOptions } from '@components/canvas/types/image'

const ImageFilterProperty = () => {
  const selectedItem = useSelector(selectCanvasSelectedObject) as FabricImageOptions
  const { filters = [] } = selectedItem
  const [form] = useForm()
  const dispatch = useDispatch()

  const applyFilterByType = (
    type: string,
    apply = true,
    value?: any,
    imageObj?: fabric.Image
  ): void => {
    const newFilters = filters.slice(0)
    const findIndex = FILTER_TYPES.findIndex(ft => ft === type)
    if (findIndex > -1) {
      if (apply) {
        newFilters[findIndex] = {
          type,
          ...value
        }
      } else {
        newFilters.splice(findIndex, 1)
      }

      dispatch(updateOneCanvasObject({ id: selectedItem.id, changes: { filters: newFilters } }))
    }
  }

  const changeProperty = debounce((changedValues, allValues) => {
    const filterKey = Object.keys(changedValues)[0]

    if (filterKey.includes('gamma')) {
      const rgb = [allValues['gamma.r'], allValues['gamma.g'], allValues['gamma.b']]
      applyFilterByType('gamma', allValues['gamma.enabled'], {
        gamma: rgb
      })
      return
    }
    if (filterKey.includes('brightness')) {
      applyFilterByType('brightness', allValues['brightness.enabled'], {
        brightness: allValues['brightness.brightness']
      })
      return
    }
    if (filterKey.includes('contrast')) {
      applyFilterByType('contrast', allValues['contrast.enabled'], {
        contrast: allValues['contrast.contrast']
      })
      return
    }
    if (filterKey.includes('saturation')) {
      applyFilterByType('saturation', allValues['saturation.enabled'], {
        saturation: allValues['saturation.saturation']
      })
      return
    }
    if (filterKey.includes('hue')) {
      applyFilterByType('hue', allValues['hue.enabled'], {
        rotation: allValues['hue.rotation']
      })
      return
    }
    if (filterKey.includes('noise')) {
      applyFilterByType('noise', allValues['noise.enabled'], {
        noise: allValues['noise.noise']
      })
      return
    }
    if (filterKey.includes('pixelate')) {
      applyFilterByType('pixelate', allValues['pixelate.enabled'], {
        blocksize: allValues['pixelate.blocksize']
      })
      return
    }
    if (filterKey.includes('blur')) {
      applyFilterByType('blur', allValues['blur.enabled'], {
        value: allValues['blur.value']
      })
      return
    }
    applyFilterByType(filterKey, allValues[filterKey])
  }, 200)

  useEffect(() => {
    form.resetFields()
  }, [form, selectedItem])

  return (
    <Row>
      <Form onValuesChange={changeProperty} form={form}>
        <Row>
          <Col md={24} lg={6}>
            <Form.Item
              label={i18n.t('editor.right-panel.properties.filter.grayscale')}
              name="grayscale"
              valuePropName="checked"
              initialValue={!!filters[0]}
            >
              <Tag.CheckableTag checked={!!filters[0]} className="rde-action-tag">
                {'G'}
              </Tag.CheckableTag>
            </Form.Item>
          </Col>
          <Col md={24} lg={6}>
            <Form.Item
              label={i18n.t('editor.right-panel.properties.filter.invert')}
              name="invert"
              valuePropName="checked"
              initialValue={!!filters[1]}
            >
              <Tag.CheckableTag checked={!!filters[1]} className="rde-action-tag">
                {'I'}
              </Tag.CheckableTag>
            </Form.Item>
          </Col>
          <Col md={24} lg={6}>
            <Form.Item
              label={i18n.t('editor.right-panel.properties.filter.sepia')}
              name="sepia"
              valuePropName="checked"
              initialValue={!!filters[3]}
            >
              <Tag.CheckableTag checked={!!filters[3]} className="rde-action-tag">
                {'S'}
              </Tag.CheckableTag>
            </Form.Item>
          </Col>
          <Col md={24} lg={6}>
            <Form.Item
              label={i18n.t('editor.right-panel.properties.filter.brownie')}
              name="brownie"
              valuePropName="checked"
              initialValue={!!filters[4]}
            >
              <Tag.CheckableTag checked={!!filters[4]} className="rde-action-tag">
                {'B'}
              </Tag.CheckableTag>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={24} lg={6}>
            <Form.Item
              label={i18n.t('editor.right-panel.properties.filter.vintage')}
              name="vintage"
              valuePropName="checked"
              initialValue={!!filters[9]}
            >
              <Tag.CheckableTag checked={!!filters[9]} className="rde-action-tag">
                {'V'}
              </Tag.CheckableTag>
            </Form.Item>
          </Col>
          <Col md={24} lg={6}>
            <Form.Item
              label={i18n.t('editor.right-panel.properties.filter.blackwhite')}
              name="blackwhite"
              valuePropName="checked"
              initialValue={!!filters[19]}
            >
              <Tag.CheckableTag checked={!!filters[19]} className="rde-action-tag">
                {'B'}
              </Tag.CheckableTag>
            </Form.Item>
          </Col>
          <Col md={24} lg={6}>
            <Form.Item
              label={i18n.t('editor.right-panel.properties.filter.technicolor')}
              name="technicolor"
              valuePropName="checked"
              initialValue={!!filters[14]}
            >
              <Tag.CheckableTag checked={!!filters[14]} className="rde-action-tag">
                {'T'}
              </Tag.CheckableTag>
            </Form.Item>
          </Col>
          <Col md={24} lg={6}>
            <Form.Item
              label={i18n.t('editor.right-panel.properties.filter.polaroid')}
              name="polaroid"
              valuePropName="checked"
              initialValue={!!filters[15]}
            >
              <Tag.CheckableTag checked={!!filters[15]} className="rde-action-tag">
                {'P'}
              </Tag.CheckableTag>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={24} lg={6}>
            <Form.Item
              label={i18n.t('editor.right-panel.properties.filter.sharpen')}
              name="sharpen"
              valuePropName="checked"
              initialValue={!!filters[12]}
            >
              <Tag.CheckableTag checked={!!filters[12]} className="rde-action-tag">
                {'S'}
              </Tag.CheckableTag>
            </Form.Item>
          </Col>
          <Col md={24} lg={6}>
            <Form.Item
              label={i18n.t('editor.right-panel.properties.filter.emboss')}
              name="emboss"
              valuePropName="checked"
              initialValue={!!filters[13]}
            >
              <Tag.CheckableTag checked={!!filters[13]} className="rde-action-tag">
                {'E'}
              </Tag.CheckableTag>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={24} lg={6}>
            <Form.Item
              label={i18n.t('editor.right-panel.properties.filter.gamma')}
              name="gamma.enabled"
              valuePropName="checked"
              initialValue={!!filters[17]}
            >
              <Tag.CheckableTag checked={!!filters[17]} className="rde-action-tag">
                {'G'}
              </Tag.CheckableTag>
            </Form.Item>
          </Col>
          <Col md={24} lg={6}>
            <Form.Item
              label={i18n.t('color.red')}
              name="gamma.r"
              initialValue={filters[17] ? filters[17].gamma[0] : 1}
            >
              <Slider disabled={!filters[17]} step={0.01} min={0.01} max={2.2} />
            </Form.Item>
          </Col>
          <Col md={24} lg={6}>
            <Form.Item
              label={i18n.t('color.green')}
              name="gamma.g"
              initialValue={filters[17] ? filters[17].gamma[1] : 1}
            >
              <Slider disabled={!filters[17]} step={0.01} min={0.01} max={2.2} />
            </Form.Item>
          </Col>
          <Col md={24} lg={6}>
            <Form.Item
              label={i18n.t('color.blue')}
              name="gamma.b"
              initialValue={filters[17] ? filters[17].gamma[2] : 1}
            >
              <Slider disabled={!filters[17]} step={0.01} min={0.01} max={2.2} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={24} lg={6}>
            <Form.Item
              label={i18n.t('editor.right-panel.properties.filter.brightness')}
              name="brightness.enabled"
              valuePropName="checked"
              initialValue={!!filters[5]}
            >
              <Tag.CheckableTag checked={!!filters[5]} className="rde-action-tag">
                {'B'}
              </Tag.CheckableTag>
            </Form.Item>
          </Col>
          <Col md={24} lg={18}>
            <Form.Item
              label={i18n.t('editor.right-panel.properties.filter.brightness')}
              name="brightness.brightness"
              initialValue={filters[5] ? filters[5].brightness : 0.1}
            >
              <Slider disabled={!filters[5]} step={0.01} min={-1} max={1} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={24} lg={6}>
            <Form.Item
              label={i18n.t('editor.right-panel.properties.filter.contrast')}
              name="contrast.enabled"
              valuePropName="checked"
              initialValue={!!filters[6]}
            >
              <Tag.CheckableTag checked={!!filters[6]} className="rde-action-tag">
                {'C'}
              </Tag.CheckableTag>
            </Form.Item>
          </Col>
          <Col md={24} lg={18}>
            <Form.Item
              label={i18n.t('editor.right-panel.properties.filter.contrast')}
              name="contrast.contrast"
              initialValue={filters[6] ? filters[6].contrast : 0}
            >
              <Slider disabled={!filters[6]} step={0.01} min={-1} max={1} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={24} lg={6}>
            <Form.Item
              label={i18n.t('editor.right-panel.properties.filter.saturation')}
              name="saturation.enabled"
              valuePropName="checked"
              initialValue={!!filters[7]}
            >
              <Tag.CheckableTag checked={!!filters[7]} className="rde-action-tag">
                {'S'}
              </Tag.CheckableTag>
            </Form.Item>
          </Col>
          <Col md={24} lg={18}>
            <Form.Item
              label={i18n.t('editor.right-panel.properties.filter.saturation')}
              name="saturation.saturation"
              initialValue={filters[7] ? filters[7].saturation : 0}
            >
              <Slider disabled={!filters[7]} step={0.01} min={-1} max={1} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={24} lg={6}>
            <Form.Item
              label={i18n.t('editor.right-panel.properties.filter.hue')}
              name="hue.enabled"
              valuePropName="checked"
              initialValue={!!filters[21]}
            >
              <Tag.CheckableTag checked={!!filters[21]} className="rde-action-tag">
                {'H'}
              </Tag.CheckableTag>
            </Form.Item>
          </Col>
          <Col md={24} lg={18}>
            <Form.Item
              label={i18n.t('editor.right-panel.properties.filter.hue')}
              name="hue.rotation"
              initialValue={filters[21] ? filters[21].rotation : 0}
            >
              <Slider disabled={!filters[21]} step={0.002} min={-2} max={2} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={24} lg={6}>
            <Form.Item
              label={i18n.t('editor.right-panel.properties.filter.noise')}
              name="noise.enabled"
              valuePropName="checked"
              initialValue={!!filters[8]}
            >
              <Tag.CheckableTag checked={!!filters[8]} className="rde-action-tag">
                {'N'}
              </Tag.CheckableTag>
            </Form.Item>
          </Col>
          <Col md={24} lg={18}>
            <Form.Item
              label={i18n.t('editor.right-panel.properties.filter.noise')}
              name="noise.noise"
              initialValue={filters[8] ? filters[8].noise : 100}
            >
              <Slider disabled={!filters[8]} step={1} min={0} max={1000} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={24} lg={6}>
            <Form.Item
              label={i18n.t('editor.right-panel.properties.filter.pixelate')}
              name="pixelate.enabled"
              valuePropName="checked"
              initialValue={!!filters[10]}
            >
              <Tag.CheckableTag checked={!!filters[10]} className="rde-action-tag">
                {'P'}
              </Tag.CheckableTag>
            </Form.Item>
          </Col>
          <Col md={24} lg={18}>
            <Form.Item
              label={i18n.t('editor.right-panel.properties.filter.pixelate')}
              name="pixelate.blocksize"
              initialValue={filters[10] ? filters[10].blocksize : 4}
            >
              <Slider disabled={!filters[10]} step={1} min={2} max={20} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={24} lg={6}>
            <Form.Item
              label={i18n.t('editor.right-panel.properties.filter.blur')}
              name="blur.enabled"
              valuePropName="checked"
              initialValue={!!filters[11]}
            >
              <Tag.CheckableTag checked={!!filters[11]} className="rde-action-tag">
                {'B'}
              </Tag.CheckableTag>
            </Form.Item>
          </Col>
          <Col md={24} lg={18}>
            <Form.Item
              label={i18n.t('editor.right-panel.properties.filter.blur')}
              name="blur.value"
              initialValue={filters[11] ? filters[11].value : 0.1}
            >
              <Slider disabled={!filters[11]} step={0.01} min={0} max={1} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Row>
  )
}

export default ImageFilterProperty
