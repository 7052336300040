import { RootState } from '@store/rootReducer'

export function selectCanvasZoomPoint(state: RootState) {
  return state.canvas.zoom.zoomPoint
}

export function selectCanvasZoomRatio(state: RootState) {
  return state.canvas.zoom.zoomRatio
}

export function selectCanvasMinZoom(state: RootState) {
  return state.canvas.zoom.minZoom
}

export function selectCanvasMaxZoom(state: RootState) {
  return state.canvas.zoom.maxZoom
}

export function selectCanvasViewportTransform(state: RootState) {
  return state.canvas.zoom.viewportTransform
}
