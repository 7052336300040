import { useCallback, useRef, useState } from 'react'

import 'antd/dist/antd.css'

import Draggable from 'react-draggable'
import type { DraggableData, DraggableEvent } from 'react-draggable'
import { ResizableBox } from 'react-resizable'
import type { ResizeCallbackData } from 'react-resizable'

import { Button, Modal } from 'antd'

import i18n from 'i18next'

import './CommonModal.css'

import useWindowDimensions from '../canvas/hooks/useWindowDimensions'

const CommonModal = props => {
  const { height: windowHeight, width: windowWidth } = useWindowDimensions()
  const [disabled, setDisabled] = useState(true)
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const draggleRef = useRef<HTMLDivElement>(null)
  const [minHeight, setMinHeight] = useState(0)
  const [height, setHeight] = useState(props?.height || 300)
  const [width, setWidth] = useState(
    props?.useWindowWidth ? windowWidth / 1.5 : props?.width || 500
  )
  const [resizeCounter, setResizeCounter] = useState(0)
  const [needRerender, setNeedRerender] = useState(false)
  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement
    const targetRect = draggleRef.current?.getBoundingClientRect()
    if (!targetRect) {
      return
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y)
    })
  }

  const onResize = (event, { size }: ResizeCallbackData) => {
    setHeight(size.height)
    setWidth(size.width)
  }

  const onResizeStop = () => {
    if (props?.onResize) {
      props.onResize(resizeCounter)
      setResizeCounter(resizeCounter + 1)
    }
  }

  const renderContent = () => {
    return <>{props.children}</>
  }

  const divRef = useCallback(
    node => {
      setTimeout(() => {
        if (node != null) {
          if (node.clientHeight !== 0) {
            setHeight(node.clientHeight)
            setMinHeight(node.clientHeight)
          }
          //sometimes the tableview won't have rendered in time so we call this to let it rerun until it has
          else {
            setNeedRerender(!needRerender)
          }
        }
      }, 400)
    },
    [props.children, needRerender, props?.update]
  )

  if (props?.resizable) {
    return (
      <Modal
        destroyOnClose={true}
        footer={[
          <Button key={0} type="primary" disabled={props.okDisabled} onClick={props.onOk}>
            {i18n.t('editor.right-panel.data-source.form.okButton')}
          </Button>,
          <Button key={1} disabled={props.cancelDisabled} onClick={props.onCancel}>
            {i18n.t('editor.right-panel.data-source.form.cancelButton')}
          </Button>
        ]}
        {...props}
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move'
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false)
              }
            }}
            onMouseOut={() => {
              setDisabled(true)
            }}
            // fix eslintjsx-a11y/mouse-events-have-key-events
            // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
            onFocus={() => {}}
            onBlur={() => {}}
            // end
          >
            {props.title}
          </div>
        }
        modalRender={modal => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
        width={width + 30}
      >
        <ResizableBox
          width={width}
          height={height}
          draggableOpts={{ offsetParent: document.body }}
          resizeHandles={['se']}
          minConstraints={[500, minHeight]}
          maxConstraints={[windowWidth - 100, windowHeight - 300]}
          onResize={onResize}
          onResizeStop={onResizeStop}
          style={{ minHeight: 'fit-content', overflow: 'hidden' }}
        >
          <div
            ref={divRef}
            className="dialog-wrapper"
            style={{ width: width, height: 'fit-content', overflow: 'hidden' }}
            onMouseOver={() => {
              setDisabled(true)
            }}
          >
            {renderContent()}
          </div>
        </ResizableBox>
      </Modal>
    )
  } else {
    return (
      <Modal
        destroyOnClose={true}
        footer={[
          <Button key={0} type="primary" disabled={props.okDisabled} onClick={props.onOk}>
            {i18n.t('editor.right-panel.data-source.form.okButton')}
          </Button>,
          <Button key={1} disabled={props.cancelDisabled} onClick={props.onCancel}>
            {i18n.t('editor.right-panel.data-source.form.cancelButton')}
          </Button>
        ]}
        {...props}
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move'
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false)
              }
            }}
            onMouseOut={() => {
              setDisabled(true)
            }}
            // fix eslintjsx-a11y/mouse-events-have-key-events
            // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
            onFocus={() => {}}
            onBlur={() => {}}
            // end
          >
            {props.title}
          </div>
        }
        modalRender={modal => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
        width={width + 30}
      >
        {renderContent()}
      </Modal>
    )
  }
}

export default CommonModal
