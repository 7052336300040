import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Form, Slider } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import { debounce } from 'lodash'

import { updateOneCanvasObject } from '@store/slices/shapes/actions'
import { selectCanvasSelectedObject } from '@store/slices/shapes/selectors'

import { ColorPicker } from '@components/common'

const StyleProperty = () => {
  const selectedItem = useSelector(selectCanvasSelectedObject)
  const dispatch = useDispatch()
  const [form] = useForm()

  useEffect(() => {
    form.resetFields()
  }, [form, selectedItem])

  const changeProperty = debounce(changedValues => {
    const changedKey = Object.keys(changedValues)[0]
    const changedValue = changedValues[changedKey]
    dispatch(
      updateOneCanvasObject({ id: selectedItem.id, changes: { [changedKey]: changedValue } })
    )
  }, 200)

  return (
    <Form form={form} onValuesChange={changeProperty}>
      <Form.Item
        name="opacity"
        label={'opacity'}
        colon={false}
        initialValue={selectedItem.opacity || 1}
        rules={[
          {
            type: 'number',
            min: 0,
            max: 1
          }
        ]}
      >
        <Slider min={0} max={1} step={0.1} />
      </Form.Item>
      <Form.Item
        name="fill"
        initialValue={selectedItem.fill || 'rgba(0, 0, 0, 1)'}
        label={'fill'}
        colon={false}
      >
        <ColorPicker />
      </Form.Item>
      <Form.Item
        initialValue={selectedItem.stroke || 'rgba(255, 255, 255, 0)'}
        name="stroke"
        label="Stroke color"
        colon={false}
      >
        <ColorPicker />
      </Form.Item>
    </Form>
  )
}

export default StyleProperty
