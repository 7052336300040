import { useEffect, useState } from 'react'

import { Form, Radio, Select, Space } from 'antd'
import { useForm } from 'antd/es/form/Form'

import i18n from 'i18next'

import CommonModal from '@/components/common/CommonModal'
import Info from '@/components/common/Info'
import MultiSelectDropdown from '@/components/common/MultiSelectDropdown'

import { ActionProps, ActionTypes, SortByAction } from './types'

export type FormValues = {
  axis: 0 | 1
  by: number[]
  ascending: boolean
}

const SortBy = ({
  columns,
  rows,
  onSubmit,
  onCancel,
  isVisible,
  initialValues,
  clearOptions = false
}: ActionProps<SortByAction>) => {
  const initial = {
    axis: 1,
    by: [],
    ascending: false,
    ...(initialValues || {})
  }
  const [form] = useForm<FormValues>()
  const [byLength, setByLength] = useState(initial.by.length)
  const [ascValue, setAscValue] = useState(initial.ascending)
  const [labelsVisible, setLabelsVisible] = useState(false)

  useEffect(() => {
    if (clearOptions === true) {
      setAscValue(initial.ascending)
      setByLength(initial.by.length)
      form.resetFields()
      //@ts-ignore
      form.setFieldsValue(initial)
      onByChange(initial.by)
    }
  }, [clearOptions, initialValues])

  useEffect(() => {
    form.resetFields()
  }, [])

  const submitForm = () => {
    form.submit()
    const { axis, by, ascending } = form.getFieldsValue(true)

    // actionType and type have the same meaning but they are used for different objects in the backend
    // under different field names
    const payload = {
      actionType: ActionTypes.SORTBY,
      type: ActionTypes.SORTBY,
      axis,
      by,
      specs: {
        ascending: ascValue
      }
    }
    onSubmit(payload)
  }

  const onAxisChange = (value: 0 | 1) => {
    form.setFieldsValue({ axis: value, by: [] })
    setByLength(0)
  }

  const onAscendingChange = e => {
    setAscValue(e.target.value)
    form.setFieldsValue({ ascending: e.target.value })
  }

  const onByChange = (value: number[]) => {
    form.setFieldsValue({ by: value })
    setByLength(form.getFieldsValue(true).by.length)
  }

  //the component mounts when data actions tab is selected
  //for this reason, initial will be 0 originally
  //to get state to update we have to watch for initialvalues
  useEffect(() => {
    setByLength(initial.by.length)
  }, [initialValues])

  const popoverContent = (
    <div>
      <strong>Function</strong>
      <p>
        Set the order of rows and columns by ascending or descending order.
        <br /> Any row or column in the selected data table can be used
      </p>
      <strong>How to use</strong>
      <p>[Orientation]: Select if the label (headers) to use in the function is a column or row</p>
      <p>[Labels]: Select the label you would like to sort the chart by</p>
      <p>
        [Sorting]: Select if the order should by in ascending (lowest to highest value) or
        descending <br />
        (highest to lowest) order
      </p>
      <strong>NOTES</strong>
      <p>
        <em>
          You may select more than label (column/row) to Sort by. Where more than one label is
          selected,
          <br />
          we will order the data table by the first label. If two cells in the first label contain
          the exact <br />
          same value, the app will use subsequent labels to discern the order of these tied
          rows/columns
        </em>
      </p>
    </div>
  )

  //as form is in a modal, blocking submit if required things isn't done by default
  //to get round it I've put a different footer with disabled button or not depending on if requirements are satisfied
  return (
    <CommonModal
      visible={isVisible}
      onCancel={onCancel}
      onOk={submitForm}
      okDisabled={byLength <= 0}
      title={
        <span>
          Sort by <Info>{popoverContent}</Info>
        </span>
      }
    >
      <Form form={form} initialValues={initialValues} layout="vertical">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form.Item
            name="axis"
            rules={[{ required: true }]}
            label={i18n.t(
              'editor.right-panel.data-source.form.data-transformation.sortBy.orientation'
            )}
          >
            <Select<string | number, { value: string; children: string }>
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={onAxisChange}
            >
              <Select.Option value={1}>
                {i18n.t('editor.right-panel.data-source.form.columns')}
              </Select.Option>

              <Select.Option value={0}>
                {i18n.t('editor.right-panel.data-source.form.rows')}
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            rules={[{ required: true }]}
            label={i18n.t('editor.right-panel.data-source.form.data-transformation.labels')}
            shouldUpdate={(prevValues, curValues) => prevValues.axis !== curValues.axis}
          >
            {({ getFieldValue }) => {
              const axis = getFieldValue('axis')
              const options = axis === 0 ? rows : columns
              return (
                <Form.Item name="by" initialValue={initial.by}>
                  <MultiSelectDropdown
                    onChange={onByChange}
                    visible={labelsVisible}
                    setVisible={setLabelsVisible}
                    dropdownSelect={() => {
                      form.setFieldsValue({ by: options.map(({ index }) => index) })
                      setByLength(form.getFieldsValue(true).by.length)
                    }}
                    dropdownClear={() => {
                      form.setFieldsValue({ by: [] })
                      setByLength(form.getFieldsValue(true).by.length)
                    }}
                    dropdownOk={() => setLabelsVisible(false)}
                    dropdownCancel={() => {
                      form.setFieldsValue({ by: [] })
                      setLabelsVisible(false)
                    }}
                  >
                    {options.map(({ index, name }) => (
                      <Select.Option value={index} key={index}>
                        {name}
                      </Select.Option>
                    ))}
                  </MultiSelectDropdown>
                </Form.Item>
              )
            }}
          </Form.Item>

          <Form.Item name="ascending" valuePropName="checked">
            <Radio.Group onChange={onAscendingChange} value={ascValue}>
              <Radio value={false}>Descending</Radio>
              <Radio value={true}>Ascending</Radio>
            </Radio.Group>
          </Form.Item>
        </Space>
      </Form>
    </CommonModal>
  )
}

export default SortBy
