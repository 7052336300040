import { useEffect, useState } from 'react'

import { Form, InputNumber, Select, Space } from 'antd'
import { useForm } from 'antd/es/form/Form'

import { init } from '@sentry/browser'
import i18n from 'i18next'

import CommonModal from '@/components/common/CommonModal'
import Info from '@/components/common/Info'

import { ActionProps, ActionTypes, FilterAction } from './types'

export enum FilterTypes {
  Head = 'head',
  Tail = 'tail'
}

export interface FilterProps {
  isVisible: boolean
  onSubmit: (formValues: Omit<FilterAction, 'shapeDataSelection'>) => void
  onCancel: () => void
  initialValues?: Partial<FilterAction>
}

export type FormValues = {
  axis: 0 | 1
  by: string[]
  ascending: boolean
}

const Trim = ({
  columns,
  rows,
  clearOptions,
  onSubmit,
  onCancel,
  isVisible,
  initialValues
}: ActionProps<FilterAction>) => {
  columns = columns || []
  rows = rows || []

  const initial = {
    axis: 1,
    filterType: FilterTypes.Head,
    threshold: 0,
    term: 'keep',
    ...initialValues
  }

  const [form] = useForm<FormValues>()
  // const [byLength, setByLength] = useState(initial.by.length)
  const filterNames = {
    [FilterTypes.Head]: i18n.t(
      'editor.right-panel.data-source.form.data-transformation.filter.first'
    ),
    [FilterTypes.Tail]: i18n.t(
      'editor.right-panel.data-source.form.data-transformation.filter.last'
    )
  }

  useEffect(() => {
    if (clearOptions === true) {
      form.resetFields()
      //@ts-ignore
      form.setFieldsValue(initial)
      //@ts-ignore
      onAxisChange(initial.axis)
    }
  }, [clearOptions, initialValues])

  useEffect(() => {
    form.resetFields()
    //@ts-ignore
    form.setFieldsValue(initial)
  }, [])

  const submitForm = () => {
    form.submit()
    const formValues = form.getFieldsValue(true)
    const { filterType, axis, term, threshold, ...values } = formValues
    const payload = {
      actionType: ActionTypes.TOP_AND_BOTTOM,
      axis,
      specs: { filter_type: filterType, term: term, threshold: threshold, ...values }
    }
    onSubmit(payload)
  }

  const onAxisChange = (value: 0 | 1) => {
    setAxis(value)
    form.validateFields()
  }

  const [axis, setAxis] = useState(initial.axis)
  const maxNumber = axis === 1 ? columns.length - 1 : rows.length - 1
  const message = `Value equals or exceeds the number of ${
    axis === 1 ? 'columns' : 'rows'
  } in the mapped data`

  const popoverContent = (
    <div>
      <strong>Function</strong>
      <p>
        Limit the number of rows or columns that are mapped into the object
        <br /> by setting a numerical figure
      </p>
      <strong>How to use</strong>
      <p>[Filter out]: Select to remove columns or rows from the mapped object</p>
      <p>
        [Keep first or last]: Select if the function will keep columns or rows <br />
        from the start (first) or end (last) of the data table. This is particularly
        <br />
        important when the mapped object utilises the Sort by transformation
        <br />
      </p>
      <p>
        [Number of rows/columns]: Select the number of columns/rows to <br />
        visualise on the mapped object
      </p>
    </div>
  )

  return (
    <CommonModal
      visible={isVisible}
      onCancel={onCancel}
      onOk={submitForm}
      title={
        <span>
          Trim <Info>{popoverContent}</Info>
        </span>
      }
    >
      <Form form={form} initialValues={initial} layout="vertical">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form.Item
            name="term"
            rules={[{ required: true }]}
            label=""
            style={{ marginTop: '16px' }}
          >
            <Select<string | number, { value: string; children: string }>
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Select.Option value={'keep'}>
                {i18n.t(
                  'editor.right-panel.data-source.form.data-transformation.top-and-bottom.keep'
                )}
              </Select.Option>

              <Select.Option value={'remove'}>
                {i18n.t(
                  'editor.right-panel.data-source.form.data-transformation.top-and-bottom.remove'
                )}
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            rules={[{ required: true }]}
            name="filterType"
            label=""
            style={{ marginTop: '16px' }}
          >
            <Select<string | number, { value: string; children: string }>
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {Object.keys(FilterTypes).map((key, index) => (
                <Select.Option value={FilterTypes[key]} key={index}>
                  {filterNames[FilterTypes[key]]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.axis !== curValues.axis}>
            {({ getFieldValue }) => {
              return (
                <Form.Item
                  name="threshold"
                  rules={[
                    { required: true },
                    {
                      type: 'number',
                      max: maxNumber,
                      message: message,
                      warningOnly: true
                    }
                  ]}
                  label={i18n.t(
                    'editor.right-panel.data-source.form.data-transformation.top-and-bottom.n-rows-cols'
                  )}
                >
                  <InputNumber />
                </Form.Item>
              )
            }}
          </Form.Item>

          <Form.Item
            name="axis"
            rules={[{ required: true }]}
            label=""
            style={{ marginTop: '16px' }}
          >
            <Select<string | number, { value: string; children: string }>
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={onAxisChange}
            >
              <Select.Option value={1}>
                {i18n.t('editor.right-panel.data-source.form.columns')}
              </Select.Option>

              <Select.Option value={0}>
                {i18n.t('editor.right-panel.data-source.form.rows')}
              </Select.Option>
            </Select>
          </Form.Item>
        </Space>
      </Form>
    </CommonModal>
  )
}

export default Trim
