import { fabric } from 'fabric'

import { getTemplateAsset } from '@/services/template-service'

import { IShape } from '@/interfaces/shape'

export function dataURItoBlob(dataURI) {
  var binary = atob(dataURI.split(',')[1])
  var array = []
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }
  return new Blob([new Uint8Array(array)], { type: 'image/png' })
}

export interface ThumbnailConfig {
  width: number
  height: number
  scale: number
}

/**
 *
 * @param {IShape[]} shapes pptx shapes
 * @param {number} projectId
 * @param {number} templateId
 * @param {number} [scale] scale image
 * @returns {string} dataUrl
 */
export const createThumbnail = async (
  shapes: IShape[],
  projectId: number = null,
  templateId: number = null,
  scale: number = 1
): Promise<string> => {
  // calc canvas dimensions based on shapes dimensional properties
  let canvasWidth = 0
  let canvasHeight = 0
  //get workarea so we can make canvas height/width from it
  shapes.forEach(shape => {
    let y = shape.height + shape.top
    let x = shape.width + shape.left
    if (shape.id === 'workarea' || shape.shapeObjectId === 'workarea') {
      canvasHeight = y
      canvasWidth = x
    }
  })

  // create a thumbnail for a slide
  let canvas = new fabric.Canvas('canvas', {
    width: canvasWidth,
    height: canvasHeight
  })

  /** Uploads an image, encode into base64, add to canvas. */
  const imageHandler = async (shape: IShape<'image'>) => {
    if (projectId && templateId && shape.src) {
      let image = await getTemplateAsset(projectId, templateId, shape.src)
      const o = new fabric.Image(null)
      const reader = new FileReader()
      reader.readAsDataURL(image)
      return new Promise((resolve, reject) => {
        reader.onloadend = function () {
          var base64data = reader.result
          fabric.util.loadImage(base64data as string, image => {
            o.setElement(image, shape)
            o.set('width', shape.width / shape.scaleX)
            o.set('height', shape.height / shape.scaleY)
            o.set('top', shape.top)
            o.set('left', shape.left)
            canvas.renderAll()
            resolve(o)
          })
        }
      })
    } else {
      return new fabric.Rect({
        left: shape.left,
        top: shape.top,
        width: shape.width / shape.scaleX,
        height: shape.height / shape.scaleY,
        fill: shape.fill || shape.backgroundColor || 'white'
      })
    }
  }

  //can't use await in higher order functions so we use simple for loop now
  const objArray = []
  for (const shape of shapes) {
    let obj = null
    switch (shape.type) {
      case 'chart':
        obj = new fabric.Chart(shape as IShape<'chart'>)
        break
      case 'textbox':
        obj = new fabric.Textarea(shape as IShape<'textbox'>)
        obj.backgroundColor = obj.fill || 'transparent'
        obj.fill = obj.fontColor
        break
      case 'rect':
        obj = new fabric.Rect(shape as IShape<'rect'>)
        break
      case 'circle':
        shape['radius'] = shape['width'] / 2
        obj = new fabric.Circle(shape as IShape<'circle'>)
        break
      case 'image':
        obj = await imageHandler(shape as IShape<'image'>)
        break
      case 'table':
        obj = new fabric.Table(shape as IShape<'table'>)
        break
    }
    if (obj) {
      objArray.push(obj)
    }
  }
  objArray.forEach(obj => {
    canvas.add(obj)
  })
  let dataUrl = canvas.toDataURL({
    multiplier: scale
  })

  return dataUrl
}
