import { RootState } from '@store/rootReducer'

import { CeleryStatus } from '@/interfaces/celery'
import { TemplateDto } from '@/interfaces/template'

export function selectTemplates(state: RootState): TemplateDto[] {
  return state.template.template
}
export function selectTemplateIds(state: RootState): number[] | null {
  const ids = []
  if (state.template.template) {
    state.template.template.forEach(template => {
      if (template?.id) {
        ids.push(template?.id)
      }
    })
  }
  return ids
}

export function selectTemplateAddStatus(state: RootState): CeleryStatus {
  return state.template.templateAddStatus.status
}

export function selectTemplateAddStatusError(state: RootState): string {
  return state.template.templateAddStatus.error
}

export function selectTemplateUploadProgress(state: RootState): number {
  return state.template.templateUploadProgress
}
