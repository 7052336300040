import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectCurrentProjectId } from '@/store/slices/projects/selectors'
import { updateOneCanvasObject } from '@/store/slices/shapes/actions'
import { RootState } from '@store/rootReducer'
import { selectCanvasObjectById } from '@store/slices/shapes/selectors'

import { updateShape } from '@/services/shape-service'

import { useCanvas } from '@components/canvas/hooks/useCanvas'
import { FabricObject } from '@components/canvas/types/object'

const textProperties = ['data', 'visualSettings', 'text']

export function useTextboxPropsHandler(object: FabricObject) {
  const projectId = useSelector(selectCurrentProjectId)
  const canvas = useCanvas()
  const dispatch = useDispatch()
  const selectObj = useMemo(() => selectCanvasObjectById, [])
  const selectedObject = useSelector((s: RootState) => selectObj(s, object?.id))
  const { data, visualSettings } = selectedObject || {}

  const updateTextProperty = async () => {
    try {
      let payload = {
        shapeContents: {
          text: object.text
        }
      }
      await updateShape({ projectId, shapeId: object.pk, payload: payload })
      dispatch(updateOneCanvasObject({ id: object.id, changes: { text: object.text } }))
      canvas.renderAll()
    } catch (error) {
      console.error(error.message)
    }
  }

  useEffect(() => {
    if (!object || !selectedObject) {
      return
    }
    if (data || visualSettings) {
      object.fire('beforeUpdate')
      textProperties.forEach(key => {
        object.set(key, selectedObject[key])
      })
      object.fire('afterUpdate')
      canvas.renderAll()
    }

    if (selectedObject.id === object.id) {
      object.on('selected', () => {
        dispatch(updateOneCanvasObject({ id: object.id, changes: { text: object.text } }))
      })
      object.on('text:updated', () => {
        updateTextProperty()
      })
    }
  }, [object, data, visualSettings])
}
