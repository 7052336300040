import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Card, Tooltip } from 'antd'

import { DeleteOutlined, UploadOutlined } from '@ant-design/icons'
import i18n from 'i18next'

import { addDataSourceUploadStatus, deleteDataSource } from '@store/slices/data/action'
import { selectDataSources } from '@store/slices/data/selectors'

import CommonModal from '@/components/common/CommonModal'
import { Flex } from '@/components/flex'
import { DataUploader } from '@components/imagemap/left-panel/data-source/DataUploader'

import SwitchData from './switch-data/SwitchData'

import './styles.less'

const { Meta } = Card

const DataSources = () => {
  const [isDataUploaderVisible, changeDataUploaderVisibility] = useState(false)
  const dataSources = useSelector(selectDataSources)
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const [dataSourceId, setDataSourceId] = useState<number | undefined>(null)

  const closeModal = () => {
    changeDataUploaderVisibility(false)
  }

  const addNewData = () => {
    dispatch(addDataSourceUploadStatus(null))
    changeDataUploaderVisibility(true)
  }

  const openModal = (id: number) => {
    setDataSourceId(id)
    setVisible(true)
  }

  const deleteDataSourceFile = (val: boolean) => {
    if (val) {
      dispatch(deleteDataSource(dataSourceId))
      setVisible(false)
    } else {
      setVisible(false)
    }
  }

  return (
    <div className="data-sources" id="data-sources">
      <CommonModal
        title={i18n.t('editor.left-panel.data-sources.delete-modal.title')}
        visible={visible}
        onOk={() => deleteDataSourceFile(true)}
        onCancel={() => deleteDataSourceFile(false)}
        okText={i18n.t('editor.left-panel.data-sources.delete-modal.okText')}
        cancelText={i18n.t('editor.left-panel.data-sources.delete-modal.cancelText')}
      >
        <p>{i18n.t('editor.left-panel.data-sources.delete-modal.p1')}</p>
        <p>{i18n.t('editor.left-panel.data-sources.delete-modal.p2')}</p>
      </CommonModal>
      <div id="datasources-tab-pane-header" className="ant-tabs-tabpane-header">
        {i18n.t('editor.left-panel.data-sources.title')}
      </div>
      <Flex
        flex="1"
        justifyContent="center"
        id="datasources-upload"
        className="data-sources-upload"
      >
        <Button id="datasources-addbutton" type="primary" onClick={() => addNewData()}>
          <UploadOutlined id="datasources-uploadoutlined" />
          {i18n.t('editor.left-panel.data-sources.upload-data-source')}
        </Button>
      </Flex>

      <div className="data-sources-list" id="data-sources-list">
        {dataSources?.map((dataSource, index) => (
          <Card
            key={dataSource.id}
            size="small"
            style={{ width: '90%', marginTop: 16, marginLeft: '5%' }}
            actions={[
              <Tooltip
                placement="bottom"
                id="data-sources-tooltip-switch"
                title={'Switch with another file'}
              >
                <SwitchData dataSource={dataSource} key="switch" />
              </Tooltip>,
              <Tooltip
                id="data-sources-tooltip-remove"
                placement="bottom"
                title={'Remove data source'}
              >
                <DeleteOutlined
                  id="data-sources-deleteoutlined"
                  key="delete"
                  onClick={() => openModal(dataSource.id)}
                />
              </Tooltip>
            ]}
            id="data-sources-card"
          >
            <Meta
              title={
                <div style={{ fontSize: '0.8em' }}>{`${i18n.t(
                  'editor.left-panel.data-sources.file'
                )} ${index + 1}`}</div>
              }
              description={<div style={{ fontSize: '0.8em' }}>{` ${dataSource.filename} `}</div>}
            />
          </Card>
        ))}
      </div>
      <DataUploader
        onCancel={() => closeModal()}
        isModalVisible={isDataUploaderVisible}
        onClose={closeModal}
        onComplete={closeModal}
      />
    </div>
  )
}

export default DataSources
