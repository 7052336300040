import { useDispatch } from 'react-redux'

import { Action, configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import { PersistConfig } from 'redux-persist/es/types'
import storage from 'redux-persist/lib/storage'
import { ThunkAction } from 'redux-thunk'

import rootReducer, { RootState } from './rootReducer'

const persistConfig: PersistConfig<any> = {
  key: 'store',
  storage,
  whitelist: ['auth', 'slides']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST']
      }
    })
})

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

export default store
