import { FabricWorkAreaOptions } from '@components/canvas/types/workArea'

import { FabricObjectOption, WorkareaObject } from '../utils'

export const canvasOption = {
  preserveObjectStacking: true,
  width: 300,
  height: 150,
  selection: true,
  defaultCursor: 'default',
  backgroundColor: '#f3f3f3'
}

export const keyEvent = {
  move: true,
  all: true,
  copy: true,
  paste: true,
  esc: true,
  del: true,
  clipboard: false,
  transaction: true,
  zoom: true,
  cut: true
}

export const gridOption = {
  enabled: false,
  grid: 10,
  snapToGrid: false,
  lineColor: '#ebebeb',
  borderColor: '#cccccc'
}

export const workareaOption: Partial<FabricWorkAreaOptions> = {
  width: 600,
  height: 400,
  workareaWidth: 600,
  workareaHeight: 400,
  lockScalingX: true,
  lockScalingY: true,
  scaleX: 1,
  scaleY: 1,
  backgroundColor: '#fff',
  hasBorders: false,
  hasControls: false,
  selectable: false,
  lockMovementX: true,
  lockMovementY: true,
  hoverCursor: 'default',
  name: '',
  id: 'workarea',
  type: 'image',
  layout: 'fixed', // fixed, responsive, fullscreen
  link: {},
  tooltip: {
    enabled: false
  },
  isElement: false
}

export const objectOption: Partial<FabricObjectOption> = {
  rotation: 0,
  centeredRotation: true,
  strokeUniform: true
}

export const guidelineOption = {
  enabled: true
}

export const activeSelectionOption = {
  hasControls: true
}

export const propertiesToInclude = ['id', 'name', 'locked', 'editable']

export const defaultOption: FabricObjectOption = {
  fill: 'rgba(0, 0, 0, 1)',
  stroke: 'rgba(255, 255, 255, 0)',
  strokeUniform: true,
  resource: {},
  link: {
    enabled: false,
    type: 'resource',
    state: 'new',
    dashboard: {}
  },
  tooltip: {
    enabled: true,
    type: 'resource',
    template: '<div>{{message.name}}</div>'
  },
  animation: {
    type: 'none',
    loop: true,
    autoplay: true,
    duration: 1000
  }
}
