import Chart, { ChartConfiguration, ChartData, ChartSize, ChartType } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { fabric } from 'fabric'
import { debounce, merge, range } from 'lodash'

import {
  getDefaultChartDatasetOptions,
  getDefaultChartOptions
} from '@components/canvas/constants/chart'
import {
  ChartContext,
  ChartDefinition,
  DataLabelsDefinition,
  DataSet,
  FabricChartOptions,
  InternalAxis,
  PlotDefinition
} from '@components/canvas/types/chart'
import {
  applyNumberFormatting,
  calcOffsetAndAlign,
  findValueAxisMinMax,
  getIndexingVisualisationSettings,
  mapLegendPosition,
  splitNames
} from '@components/canvas/utils/'

import { IDataFrame, IShapeData } from '@/interfaces/shape'

import 'chartjs-plugin-stacked100'

import {
  TAllvsAllSettings,
  VisualisationOptions
} from '@/components/imagemap/right-panel/sig-testing/VisualisationSettings'

import { SigTestTypes } from '@/interfaces/analytics'

import { transposeDataFrame } from '@/utils/transpose-frame'

import { BACKGROUND_COLORS, CHART_EVENTS } from '../constants/chart'
import { VisualSettings } from '../types/visualSettings'
import { getSigTestVisualisationSettings, mapChartTypes } from '../utils/'

export class ChartObject extends fabric.Object {
  public static type: string = 'chart'
  public chart: ChartDefinition
  public data: IShapeData
  public visualSettings: VisualSettings
  private chartInstance: Chart

  // set object properties
  public _set(key: string, value: any) {
    if (key === 'chart') {
      return this.setChartOptions(value)
    }

    if (key === 'data') {
      console.debug("data", value)
      return this.setChartData(value)
    }
    return super._set(key, value)
  }

  // set chart data
  private setChartOptions(options: ChartDefinition): ChartObject {
    this.chart = options
    if (this.chartInstance) {
      this.chartInstance.destroy()
    }
    this.createChart()

    return this
  }

  private setChartData(data: IShapeData): ChartObject {
    this.data = data
    if (this.chartInstance && data) {
      this.updateChart()
    } else {
      // on chart reset data is null but chartInstance may still exist
      if (this.chartInstance) {
        this.chartInstance.destroy()
      }
      this.createChart()
    }

    return this
  }

  // return json representation for chart
  public toObject(propertiesToInclude: string[] = []) {
    return fabric.util.object.extend(
      super.toObject(propertiesToInclude),
      propertiesToInclude.reduce(
        (prev, property) =>
          Object.assign(prev, {
            [property]: this.get(property as any)
          }),
        {
          chart: this.get('chart'),
          data: this.get('data')
        }
      )
    )
  }

  get chartModifier() {
    return mapChartTypes(this.chart.type)[1]
  }

  get isStackedChart() {
    return ['stacked', 'stacked100'].includes(this.chartModifier)
  }

  get chartType() {
    return mapChartTypes(this.chart.type)[0]
  }

  get isReversedChart(): boolean {
    const hasPlots = this.chart.plots && this.chart.plots.length > 0
    return hasPlots ? this.chart.plots[0].reversed : false
  }

  /** Returns a tuple of three boolean values that define chart config.
   * The 1st value defines if the legend should be reversed, the 2nd - rows,
   * the 3rd - columns.
   */
  get reversedParams(): [boolean, boolean, boolean] {
    let reverseLegend = -1
    let reverseRows = -1
    let reverseColumns = -1
    if (this.isStackedChart) {
      if (this.chartType === 'horizontalBar') {
        reverseRows *= -1
      }
      if (this.isReversedChart) {
        reverseRows *= -1
      }
    } else {
      if (this.chartType === 'horizontalBar') {
        reverseRows *= -1
        reverseColumns *= -1
        if (this.isStackedChart) {
          reverseColumns *= -1
        }
      }
      if (this.isReversedChart) {
        reverseLegend *= -1
        reverseRows *= -1
        reverseColumns *= -1
      }
    }
    return [reverseLegend, reverseRows, reverseColumns].map(x => (x === -1 ? false : true)) as [
      boolean,
      boolean,
      boolean
    ]
  }

  // set chart instance size
  private setChartSize() {
    const canvas = this.chartInstance.canvas!
    canvas.width = this.getScaledWidth() * (window?.devicePixelRatio || 1)
    canvas.height = this.getScaledHeight() * (window?.devicePixelRatio || 1)
    this.chartInstance.resize()
    this.chartInstance.update()
  }

  // default mandatory options for chartjs
  private defaultChartConfiguration(chartType: ChartType) {
    const display = chartType === 'doughnut' || chartType === 'pie' ? false : true
    return {
      type: chartType,
      plugins: [
        {
          ChartDataLabels,
          beforeDraw: function(c) {
            // var chartHeight = c.chart.height;
            // if(c.scales && c.scales['y-axis-0'] && c.scales['y-axis-0'].options && c.scales['y-axis-0'].options.ticks && c.scales['y-axis-0'].options.ticks.minor){
            //   c.scales['y-axis-0'].options.ticks.minor['fontSize'] = chartHeight * 6 / 100; //fontSize: 6% of canvas height
            // }
          }
        }
      ],
      options: {
        responsive: true,
        layout: {
          padding: {
            left: 50,
            right: 50
          }
        },
        maintainAspectRatio: true,
        scales: {
          yAxes: [
            {
              display: display,
              ticks: {
                callback: function(value) {
                  return value.length > 10 ? String(value).substring(0, 10) + '.' : value
                }
              }
            }
          ]
        },
        onResize: (newSize: ChartSize) => {
          this.dirty = true
          this.canvas?.requestRenderAll()
        },
        // animation: {
        //   onProgress: () => {
        //     this.dirty = true
        //     this.canvas?.requestRenderAll()
        //   }
        // },
        animation: false,
        title: {
          display: !!this.chart?.title?.text,
          text: this.chart?.title?.text
        },
        legend: {
          reverse: this.reversedParams[0],
          display: this.chart.legend ? true : false,
          position: this.chart.legend ? mapLegendPosition(this.chart.legend.position) : null,
          labels: {
            boxWidth: 16
          },
          onClick: function(_e, legendItem, _legend) {
            //don't allow legend items to be hidden
            legendItem.hidden = false
          }
        },
        ...getDefaultChartOptions(chartType)
      }
    }
  }

  // getBoundingClientRect for fabric object
  private getChartBoundingClientRect() {
    return {
      bottom: this.top! + this.getScaledHeight(),
      height: this.getScaledHeight(),
      left: this.left,
      right: this.left! + this.getScaledWidth(),
      top: this.top,
      width: this.getScaledWidth(),
      x: this.left! + this.getScaledWidth() / 2,
      y: this.top! + this.getScaledHeight() / 2
    } as DOMRect
  }

  // remove object paddings
  private getChartCurrentStyle() {
    return {
      'padding-left': 0,
      'padding-right': 0,
      'padding-top': 0,
      'padding-bottom': 0
    } as Partial<CSSStyleDeclaration>
  }

  // create canvas base element for chartjs object
  private createChartCanvas() {
    const canvas = document.createElement('canvas')
    canvas.width = this.getScaledWidth()
    canvas.height = this.getScaledHeight()

    Object.defineProperty(canvas, 'clientWidth', {
      get: () => canvas.width / window.devicePixelRatio
    })

    Object.defineProperty(canvas, 'clientHeight', {
      get: () => canvas.height / window.devicePixelRatio
    })

    Object.defineProperty(canvas, 'getBoundingClientRect', {
      value: this.getChartBoundingClientRect.bind(this)
    })

    Object.defineProperty(canvas, 'currentStyle', {
      value: this.getChartCurrentStyle()
    })

    return canvas
  }

  // bind fabric and chart js events
  private bindChartEvents() {
    for (const name in CHART_EVENTS) {
      const event = name as keyof typeof CHART_EVENTS
      this.on(event, e => {
        if (this.canvas && this.chartInstance.canvas) {
          let { x, y } = this.toLocalPoint(
            this.canvas.getPointer(e.e) as fabric.Point,
            'left',
            'top'
          )

          if (this.flipX) {
            x = this.getScaledWidth() - x
          }
          if (this.flipY) {
            y = this.getScaledHeight() - y
          }
          this.chartInstance.canvas!.dispatchEvent(
            new MouseEvent(CHART_EVENTS[event], {
              clientX: this.left! + x,
              clientY: this.top! + y
            })
          )
        }
      })
    }
  }

  private formatPlot(plot: PlotDefinition): { datasets: DataSet[]; labels: string[] } {
    let labels: string[] = []
    let datasets: DataSet[] = []
    if (!plot) {
      return {
        labels,
        datasets
      }
    }
    const [chartType, _] = mapChartTypes(this.chart.type)
    const columnIndices = Object.keys(plot.series)
    const rowNames = Object.keys(plot.categories)
    const rowsParsed = {}

    // populate dataSets with numeric values from parsed data
    columnIndices.forEach(i => {
      const columnData = plot.series[i]
      const columnName = plot.series[i].name
      if (columnData) {
        const values = columnData.values
        if (!!Object.keys(values).length) {
          rowNames.forEach(rn => {
            let value = values[rn]
            rowsParsed[columnName] = rowsParsed[columnName]
              ? rowsParsed[columnName].concat(value)
              : [value]
          })
        }
      }
    })

    const partialDatasets: DataSet[] = Object.keys(rowsParsed).map((rowName, rowIndex) => {
      const [chartType, chartModifier] = mapChartTypes(this.chart.type)
      //some horizontal bar charts won't have dataLabels so use ?.
      const dataLabels = this?.chart?.plots[0]?.series[rowIndex]?.dataLabels
      const showValue = dataLabels?.showValue
      const { formatCode, sourceLinked } = this.getNumberFormatValues(dataLabels)
      return {
        ...getDefaultChartDatasetOptions(chartType, chartModifier),
        label: rowName,
        data: rowsParsed[rowName],
        customLabels: rowsParsed[rowName],
        formatCode: formatCode,
        sourceLinked: sourceLinked,
        showValues: showValue,
        ...(chartModifier === 'area'
          ? {
            fill: true,
            pointRadius: 0,
            borderWidth: 0
          }
          : {})
      }
    })

    // add colors
    let customColors = Object.keys(plot.series).map((key, idx) => {
      if (plot.series[key]['color']) {
        return plot.series[key]['color']
      } else {
        return BACKGROUND_COLORS[idx]
      }
    })
    partialDatasets.forEach((pd, pdIndex) => {
      if (partialDatasets.length > 1) {
        let colors: string | string[] = customColors[pdIndex]
        if (chartType === 'pie' || chartType === 'doughnut') {
          colors = customColors.slice(0, Object.values(rowsParsed).flat().length)
        }
        datasets.push({ ...pd, backgroundColor: colors, borderColor: colors })
      } else {
        if (chartType === 'doughnut' || chartType === 'pie') {
          let colors = customColors.slice(0, Object.values(rowsParsed).flat().length)
          datasets.push({ ...pd, backgroundColor: colors, borderColor: colors })
        } else {
          let color = customColors[0]
          datasets.push({ ...pd, backgroundColor: color, borderColor: color })
        }
      }
    })

    // pie charts should render only first column
    if (chartType === 'pie') {
      datasets = [datasets[0]]
    }

    labels = rowNames.map(rn => plot.categories[rn])
    // remove undefined values from datasets array
    datasets = datasets.filter(dataset => dataset !== undefined)
    return { datasets, labels }
  }

  private get dataFrame(): IDataFrame {
    if (!this.data?.dataFrame) {
      return null
    }
    if (this.chart?.isSwitchRowAndColumn) {
      return transposeDataFrame(this.data.dataFrame)
    } else {
      return this.data.dataFrame
    }
  }

  private getInternalRowAxis(): InternalAxis[] {
    if (!this.dataFrame) {
      return []
    }
    const rowMapping = this.data?.sigTesting?.response?.params?.rows
    const allVsAllSettings = this.visualSettings?.significanceTest as TAllvsAllSettings
    let rows = this.dataFrame.index.map((rowName, idx) => {
      if (rowMapping && rowMapping[rowName]) {
        let sigToken = rowMapping[rowName]

        if (allVsAllSettings?.useUppercase) {
          sigToken = sigToken.toUpperCase()
        }

        if (allVsAllSettings?.useBrackets) {
          sigToken = `(${sigToken})`
        }

        return {
          name: String(rowName),
          index: idx,
          sigName: `${rowName} ${sigToken}`,
          letter: rowMapping[rowName]
        }
      } else {
        return {
          name: String(rowName),
          index: idx
        }
      }
    })
    if (this.reversedParams[1]) {
      rows.reverse()
    }
    return rows
  }

  private getInternalColumnAxis(): InternalAxis[] {
    if (!this.dataFrame) {
      return []
    }
    const columnMapping = this.data?.sigTesting?.response?.params?.columns || []
    const allVsAllSettings = this.visualSettings?.significanceTest as TAllvsAllSettings
    let columns = this.dataFrame.columns.map((columnName, idx) => {
      if (columnMapping && columnMapping[columnName]) {
        let sigToken = columnMapping[columnName]

        if (allVsAllSettings?.useUppercase) {
          sigToken = sigToken.toUpperCase()
        }

        if (allVsAllSettings?.useBrackets) {
          sigToken = `(${sigToken})`
        }

        return {
          name: String(columnName),
          index: idx,
          sigName: `${columnName} ${sigToken}`,
          letter: columnMapping[columnName]
        }
      } else {
        return {
          name: String(columnName),
          index: idx
        }
      }
    })
    if (this.reversedParams[2]) {
      columns.reverse()
    }
    return columns
  }

  private formatChartData(chartType: ChartType, chartModifier): ChartData {
    const chartData = this.chart
    // If data is not applied and data is present from parser,
    // then use it for rendering in editor
    if (chartData && !this.dataFrame && chartData.plots) {
      let datasets = []
      let labels = []
      chartData.plots.forEach(plot => {
        let { datasets: ds, labels: lbls } = this.formatPlot(plot)
        datasets = [...datasets, ...ds]
        labels = [...labels, ...lbls]
      })

      return {
        labels,
        datasets
      }
    }

    if (!this.dataFrame) {
      return {
        labels: [],
        datasets: []
      }
    }

    const rows = this.getInternalRowAxis()
    const columns = this.getInternalColumnAxis()
    const generateDataLabels = this.generateDataLabels.bind(this)
    const generateCustomLabelsCtx = this.generateCustomLabelsCtx.bind(this)
    return {
      labels: rows.map(({ name, sigName }) => splitNames(!sigName ? name : sigName)),
      datasets: columns.map((column, index) => {
        const color =
          chartType === 'doughnut' || chartType === 'pie' || chartType === 'polarArea'
            ? rows.map((_: never, rowIndex) => {
              return BACKGROUND_COLORS[rowIndex % BACKGROUND_COLORS.length]
            })
            : this.isReversedChart
              ? BACKGROUND_COLORS[(columns.length - index - 1) % BACKGROUND_COLORS.length]
              : BACKGROUND_COLORS[index % BACKGROUND_COLORS.length]
        const keys = Object.keys(this.chart.plots[0].series)
        const dataLabels = this.chart.plots[0].series[keys[0]].dataLabels
        const { formatCode, sourceLinked } = this.getNumberFormatValues(dataLabels)
        const showValue = this.chart?.plots?.[0].series?.[index]?.dataLabels?.showValue || true

        return {
          ...getDefaultChartDatasetOptions(chartType, chartModifier),
          label: !column.sigName ? column.name : column.sigName,
          customLabels: rows.map(row => generateCustomLabelsCtx(column, row)),
          data: rows.map(row => {
            return this.dataFrame.data[row.index][column.index]
          }),
          formatCode: formatCode,
          sourceLinked: sourceLinked,
          backgroundColor: color,
          borderColor: color,
          showValues: showValue,
          datalabels: {
            labels: generateDataLabels()
          }
        }
      })
    }
  }

  /**
   * Get number format related values
   * @param {DataLabelsDefinition} dataLabels
   * @returns {{ formatCode: string, sourceLinked: boolean }}
   */
  private getNumberFormatValues(dataLabels: DataLabelsDefinition = null): {
    formatCode: string | null
    sourceLinked: boolean | null
  } {
    let formatCode = null
    let sourceLinked = null
    let isDataMapped = !!this.data?.dataFrame
    const valueAxis = this.chart.valueAxis

    // TODO this is just for demonstration, do something about it later
    if (this.visualSettings?.numberFormat) {
      return { formatCode: this.visualSettings.numberFormat, sourceLinked: false }
    }

    if (valueAxis) {
      // use this value only when data is coming from template
      if (!isDataMapped) {
        sourceLinked = valueAxis?.numberFormat?.sourceLinked
      }
      formatCode = valueAxis?.numberFormat?.code
    }

    if (dataLabels?.numberFormat?.code) {
      formatCode = dataLabels?.numberFormat?.code
    }

    //if chart is a pie or doughnut chart we get the format from within datalabels from PGA
    if (this.chart.type === 'PIE' || this.chart.type === 'DOUGHNUT') {
      formatCode = this.chart.plots[0]?.series[0]?.dataLabels?.numberFormat?.sourceFormatCode
      sourceLinked = this.chart.plots[0]?.series[0]?.dataLabels?.numberFormat?.sourceLinked
    }

    return { formatCode, sourceLinked }
  }

  private generateCustomLabelsCtx(column: InternalAxis, row: InternalAxis) {
    let { analysisType, response } = this.data?.sigTesting || {}
    let values = response?.values
    const sigTestingValue =
      values && values[row.name] ? values[row.name][column.name] || null : null
    const sigVisualSettings = this.visualSettings?.significanceTest
    const indexingVisualSettings = this.visualSettings?.percentageIndexing
    const percentageIndexing = this.data?.indexing?.response
    const indexingResultDataFrame = this.data?.indexing?.dataFrame
    let indexingSettingsList = []
    if (this.chart.isSwitchRowAndColumn) {
      indexingSettingsList =
        percentageIndexing && percentageIndexing[column.name]
          ? percentageIndexing[column.name][row.name] || []
          : []
    } else {
      indexingSettingsList =
        percentageIndexing && percentageIndexing[row.name]
          ? percentageIndexing[row.name][column.name] || []
          : []
    }
    const indexingResult =
      indexingResultDataFrame && indexingResultDataFrame[row.name]
        ? indexingResultDataFrame[row.name][column.name] || null
        : null

    // preset to calc offset and angle
    const [chartType, chartModifier] = mapChartTypes(this.chart.type)
    let baseAngle: 0 | -90 = chartType === 'bar' ? -90 : 0
    // const baseOffset = chartModifier === 'stacked100' ? -15 : 0
    const baseOffset = 0
    const multiplier = chartType === 'bar' ? 1 : 2
    // variables that will be returned in the end
    let labels = []
    let colors = []
    let aligns = []
    let offsets = []
    let dataLabelColor = '#666'
    // padding variables
    const dataValue = this.dataFrame.data[row.index][column.index]
    const { formatCode } = this.getNumberFormatValues()
    let dataLabelLength = applyNumberFormatting(dataValue, formatCode).length
    let padding = {
      top: 0,
      right: dataLabelLength * multiplier,
      bottom: 0,
      left: dataLabelLength * multiplier
    }

    // significance test
    if (analysisType === 'all_vs_all') {
      let visual = sigVisualSettings as TAllvsAllSettings
      let token = sigTestingValue

      if (token) {
        if (visual?.useUppercase) {
          token = token.toUpperCase()
        }

        if (visual?.useBrackets) {
          token = `(${token})`
        }
      }

      if (token !== null) {
        padding.right += token.length
        let paddedLabel = token
        padding.right += 2
        const [offset, angle] = calcOffsetAndAlign('right', baseAngle, baseOffset)
        labels.push(paddedLabel)
        aligns.push(angle)
        offsets.push(offset)
      }
    }

    if (
      [
        SigTestTypes.controlGroup,
        SigTestTypes.nextDataPoint,
        SigTestTypes.previousDataPoint
      ].includes(analysisType)
    ) {
      let {
        tokens: label,
        color,
        coloredValue,
        position
      } = getSigTestVisualisationSettings(
        sigVisualSettings as VisualisationOptions,
        sigTestingValue
      )

      if (coloredValue === true) {
        dataLabelColor = color
      }

      if (label !== null) {
        let paddedLabel = label
        let margin = 4
        if (position === 'left') {
          if (chartType === 'horizontalBar') {
            padding.left += label.length * multiplier
            paddedLabel = label
            margin = padding[position]
          } else {
            padding.left += label.length * multiplier
            paddedLabel = label
            padding.left += 2 * multiplier
            margin = padding[position]
          }
        } else if (position === 'right') {
          padding[position] += label.length
          paddedLabel = label
          margin = padding[position]
        } else {
          padding[position] += label.length
          padding[position] += 2
          paddedLabel = label
          margin = padding[position]
        }

        const [offset, angle] = calcOffsetAndAlign(position, baseAngle, baseOffset, margin)
        labels.push(paddedLabel)
        colors.push(color)
        aligns.push(angle)
        offsets.push(offset)
      }
    }

    // percentage indexing
    indexingSettingsList.forEach(settingsIdx => {
      if (indexingVisualSettings && indexingVisualSettings[settingsIdx]) {
        let settings = indexingVisualSettings[settingsIdx]
        let {
          tokens: label,
          color,
          coloredValue
        } = getIndexingVisualisationSettings(settings, indexingResult)
        if (coloredValue) {
          dataLabelColor = color
        }
        if (label !== null) {
          let paddedLabel = label
          let margin = 4
          // This doesn't make a lot of sense, you can't get far using whitespaces for padding here.
          // So whatever makes it look somewhat decent works.
          if (settings.position === 'left') {
            if (chartType === 'horizontalBar') {
              padding.left += label.length * 4
              paddedLabel = label
              padding.left += 4
              margin = padding[settings.position]
            } else {
              padding.left += label.length * multiplier
              paddedLabel = label
              margin = padding[settings.position]
            }
          } else {
            padding[settings.position] += label.length
            paddedLabel = label
            padding[settings.position] += 2
            margin = padding[settings.position]
          }
          const [offset, angle] = calcOffsetAndAlign(
            settings.position,
            baseAngle,
            baseOffset,
            margin
          )

          labels.push(paddedLabel)
          colors.push(color)
          aligns.push(angle)
          offsets.push(offset)
        }
      }
    })
    return {
      labels: labels,
      colors: colors,
      aligns: aligns,
      offsets: offsets,
      dataLabelColor
    }
  }

  private generateDataLabels() {
    let totalCustomLabels = 0
    if (this.data?.sigTesting) {
      totalCustomLabels += 1
    }
    if (this.visualSettings?.percentageIndexing) {
      totalCustomLabels += this.visualSettings?.percentageIndexing?.length
    }
    const [chartType, chartModifier] = mapChartTypes(this.chart.type)
    let labels = {}
    let defaultOpts = getDefaultChartDatasetOptions(chartType, chartModifier)
    labels['value'] = {
      ...defaultOpts.datalabels,
      formatter(value: number, context: ChartContext) {
        let formatCode = context.dataset?.formatCode || 'General'
        const formatted = applyNumberFormatting(value, formatCode)
        return formatted
      },
      color: function(context: ChartContext) {
        const index = context.dataIndex
        return context.dataset.customLabels[index]?.dataLabelColor
      },
      display: function(context: ChartContext) {
        return context.dataset?.showValues
      }
    }

    range(totalCustomLabels).forEach(idx => {
      labels[idx.toString()] = {
        formatter(_: never, context: ChartContext) {
          const index = context.dataIndex
          const label = context.dataset.customLabels[index]?.labels[idx] || null
          return label
        },
        color: function(context: ChartContext) {
          const index = context.dataIndex
          const color = context.dataset.customLabels[index]?.colors[idx]
          return color
        },
        align: function(context: ChartContext) {
          const index = context.dataIndex
          const align = context.dataset.customLabels[index]?.aligns[idx]
          return align
        },
        offset: function(context: ChartContext) {
          const index = context.dataIndex
          const offset = context.dataset.customLabels[index]?.offsets[idx]
          return offset
        }
      }
    })
    return labels
  }

  // create chart instance
  private createChart() {
    const { formatCode, sourceLinked } = this.getNumberFormatValues()
    const [chartType, chartModifier] = mapChartTypes(this.chart.type)
    const chartData = this.formatChartData(chartType, chartModifier)
    const [min, max] = findValueAxisMinMax(this.chart?.valueAxis, chartData)
    const chartOptions: ChartConfiguration = merge(this.defaultChartConfiguration(chartType), {
      data: chartData,
      ...(chartType === 'bar' && {
        options: {
          scales: {
            yAxes: [
              {
                ticks: {
                  callback: function(value: string): string {
                    return applyNumberFormatting(value, formatCode, sourceLinked)
                  },
                  suggestedMax: max,
                  suggestedMin: min
                }
              }
            ]
          }
        }
      }),
      ...(chartType === 'horizontalBar' && {
        options: {
          scales: {
            xAxes: [
              {
                ticks: {
                  callback: function(value: string): string {
                    return applyNumberFormatting(value, formatCode, sourceLinked)
                  },
                  suggestedMax: max,
                  suggestedMin: min
                }
              }
            ]
          }
        }
      }),
      ...(chartModifier === 'stacked' && {
        options: {
          scales: {
            xAxes: [{ stacked: true }],
            yAxes: [{ stacked: true }]
          },
          plugins: {
            datalabels: {
              anchor: 'left',
              align: 'left'
            }
          }
        }
      }),
      ...(chartModifier === 'stacked100' && {
        options: {
          plugins: {
            stacked100: { enable: true }
          }
        }
      }),
      ...(chartModifier === 'area' && {
        options: {
          plugins: {
            filler: {
              propagate: true
            }
          }
        }
      })
    })
    this.chartInstance = new Chart(this.createChartCanvas(), chartOptions)

    return this.chartInstance
  }

  private updateChart() {
    if (this.chartInstance) {
      // @ts-ignore
      this._removeCacheCanvas()
      const [chartType, chartModifier] = mapChartTypes(this.chart.type)
      this.chartInstance.data = this.formatChartData(chartType, chartModifier)
      this.chartInstance.update()
      this.canvas?.requestRenderAll()
    }
  }

  // Initialize the object, create the chart and bind events.
  public initialize(options?: any) {
    const { data, ...rest } = options
    super.initialize(rest)
    this.chart = options.chart || null
    this.data = options.data || null
    this.createChart()
    this.bindChartEvents()
    this.on('scaling', debounce(this.setChartSize.bind(this), 5))
    return this
  }

  // Execute the drawing operation for an object on a specified context
  public drawObject(ctx: CanvasRenderingContext2D) {
    this._render(ctx)
  }

  // function that actually render something on the context.
  public _render(ctx: CanvasRenderingContext2D) {
    if (this.chartInstance) {
      ctx.drawImage(
        this.chartInstance.canvas!,
        -this.width! / 2,
        -this.height! / 2,
        this.width!,
        this.height!
      )
    }
  }

  // from object
  public static fromObject(object: FabricChartOptions, callback: Function) {
    return callback && callback(new fabric.Chart(object))
  }
}

declare module 'fabric' {
  namespace fabric {
    class Chart extends ChartObject {
      constructor(options?: FabricChartOptions)
    }
  }
}

// Install the plugin on a given fabric instance.
export function install(fabricInstance: typeof fabric) {
  fabricInstance.Chart = fabricInstance.util.createClass(ChartObject)
  fabricInstance.Chart.type = ChartObject.type
  fabricInstance.Chart.fromObject = ChartObject.fromObject
}

install(fabric)
