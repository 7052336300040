import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  Alert,
  Card,
  Col,
  Input,
  Layout,
  notification,
  Pagination,
  Row,
  Select,
  Spin,
  Typography,
  Button
} from 'antd'
import { selectCurrentUser } from '@/store/slices/auth/selectors'
import { DiffOutlined, PlusSquareOutlined } from '@ant-design/icons'
import { arrayMove, rectSortingStrategy, SortableContext } from '@dnd-kit/sortable'
import { unwrapResult } from '@reduxjs/toolkit'
import { chunk } from 'lodash'

import { setCurrentProject } from '@/store/slices/projects/actions'
import { selectProjects } from '@/store/slices/projects/selectors'
import {
  createProject,
  duplicateProject,
  getProjects,
  removeProjectById
} from '@/store/slices/projects/thunks'
import { clearAllSlides, clearCurrentSlideWithoutWorkarea } from '@/store/slices/slides/actions'
import { resetTemplate } from '@/store/slices/template/actions'
import { AppDispatch } from '@/store/store'

import CommonModal from '@/components/common/CommonModal'
import { Navbar } from '@/components/common/Navbar'

import ProjectItem from './ProjectItem'
import { Autoreport } from './Autoreport'

const { Text } = Typography
const { Option } = Select

const Projects = ({ history }) => {
  const projects = useSelector(selectProjects)
  const currentUser = useSelector(selectCurrentUser)
  const dispatch: AppDispatch = useDispatch()
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [projectToDelete, setProjectToDelete] = useState(-1)
  const [projectToDuplicate, setProjectToDuplicate] = useState(null)
  const [loading, setLoading] = useState(false)
  const [addBlock, setAddBlock] = useState(false)
  const [storedValue, setStoredValue] = useState([])
  const [searchValue, setSearchValue] = useState<string>('')
  const duplicateProjectName = projects.find(project => project?.id === projectToDuplicate)?.name
  const [sortedProjects, setSortedProjects] = useState(projects)
  const [sortVal, setSortVal] = useState('Most recently updated')
  const [autoreportVisible, setAutoreportVisible] = useState(false)
  const [onPage, setOnPage] = useState(1)

  const layoutRef = useRef(null)

  const onCreateProject = () => {
    setLoading(true)
    dispatch(
      createProject({
        projectType: 'shell'
      })
    )
      .then(unwrapResult)
      .then(project => {
        setLoading(false)
        setStoredValue([project.id.toString(), ...storedValue])
        history.push(`/app/${project.id}`)
      })
  }

  const onDeleteProject = (id: number) => {
    setProjectToDelete(id)
    setIsDeleteModalVisible(true)
  }

  const onDeleteConfirm = (projectId: number) => {
    let project = projects.find(project => project.id === projectId)
    let message = `${project.name} removed successfully`
    setStoredValue(storedValue.filter(id => id != projectId.toString()))
    dispatch(removeProjectById({ projectId }))
      .then(() => {
        openNotification(message)
      })
      .catch(error => {
        setStoredValue(storedValue)
        openNotification(error.message)
      })
    setIsDeleteModalVisible(false)
  }

  const onDuplicateProject = async (id: number) => {
    setProjectToDuplicate(null)
    setLoading(true)
    await dispatch(duplicateProject({ projectId: id }))
    setLoading(false)
  }

  const openNotification = message => {
    notification.open({
      message: 'Project deleted',
      description: message
    })
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchValue(e.target.value)
    setOnPage(1)
  }

  const handleChange = (value: string) => {
    switch (value) {
      case 'Most recently updated':
        setSortedProjects(projects)
        setSortVal('Most recently updated')
        break
      case 'Least recently updated':
        let newProjects = [...projects]
        newProjects.reverse()
        setSortedProjects(newProjects)
        setSortVal('Least recently updated')
        break
      case 'Alphabetical (A-Z)':
        let alphaProjects = [...projects]
        alphaProjects.sort((a, b) => (a.name > b.name ? 1 : -1))
        setSortedProjects(alphaProjects)
        setSortVal('Alphabetical (A-Z)')
        break
      case 'Alphabetical (Z-A)':
        let ahplaProjects = [...projects]
        ahplaProjects.sort((a, b) => (a.name < b.name ? 1 : -1))
        setSortedProjects(ahplaProjects)
        setSortVal('Alphabetical (Z-A)')
        break
      case 'Most recently created':
        let createdProjects = [...projects]
        createdProjects.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
        setSortedProjects(createdProjects)
        setSortVal('Most recently created')
        break
      case 'Least recently created':
        let leastCreatedProjects = [...projects]
        leastCreatedProjects.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
        setSortedProjects(leastCreatedProjects)
        setSortVal('Least recently created')
        break
      case 'Total number of slides (Largest first)':
        let numProjects = [...projects]
        numProjects.sort((a, b) => (a.slideOrder.length < b.slideOrder.length ? 1 : -1))
        setSortedProjects(numProjects)
        setSortVal('Total number of slides (Largest first)')
        break
      case 'Total number of slides (Smallest first)':
        let numSmallProjects = [...projects]
        numSmallProjects.sort((a, b) => (a.slideOrder.length > b.slideOrder.length ? 1 : -1))
        setSortedProjects(numSmallProjects)
        setSortVal('Total number of slides (Smallest first)')
        break
    }
  }

  const loadProjects = async () => {
    setLoading(true)
    await dispatch(getProjects())
    setLoading(false)
  }

  useEffect(() => {
    loadProjects()
  }, [dispatch])

  useEffect(() => {
    setSortedProjects(projects)
    handleChange(sortVal)
  }, [projects])

  useEffect(() => {
    const projectIds = sortedProjects.map(project => project.id.toString())
    setStoredValue(projectIds)
  }, [sortedProjects])

  useEffect(() => {
    dispatch(clearCurrentSlideWithoutWorkarea())
    dispatch(resetTemplate())
    dispatch(setCurrentProject(null))
    dispatch(clearAllSlides())
    // if (currentProject != null){
    //   dispatch(setProjectSlidesOrder([]))
    // }
    setTimeout(() => {
      if (
        typeof window['pendo'] !== 'undefined' &&
        window['pendo'].hasOwnProperty('validateEnvironment')
      ) {
      } else {
        setAddBlock(true)
      }
    }, 2000)
    return () => { }
  }, [])

  const onSelectPage = (page: number) => {
    layoutRef.current.querySelector('.ant-card-body').scrollTo({ top: 0 })
    setOnPage(page)
  }

  const openAutoreport = () => {
    setAutoreportVisible(true)
  }

  return (
    <Spin spinning={loading}>
      <CommonModal
        title="Delete project"
        visible={isDeleteModalVisible}
        okText="Delete"
        onOk={() => onDeleteConfirm(projectToDelete)}
        onCancel={() => setIsDeleteModalVisible(false)}
      >
        Are you sure you want to continue?
      </CommonModal>
      <CommonModal
        title="Confirm action"
        visible={projectToDuplicate !== null}
        okText="Confirm"
        onOk={() => onDuplicateProject(projectToDuplicate)}
        onCancel={() => setProjectToDuplicate(null)}
      >
        Duplicate {duplicateProjectName}?
      </CommonModal>
      {addBlock && (
        <Alert
          style={{ textAlign: 'center' }}
          message={
            <span>
              This website contains elements that may be rendered unusable if using any adblocking
              plugins. Please disable these, or whitelist this website, to ensure you get the best
              possible user experience. You may need to refresh the page after.
            </span>
          }
          type="error"
        />
      )}
      <Navbar onPage="projects" />
      <Layout
        ref={layoutRef}
        className="layout"
        style={{ height: '100%', background: 'white', minHeight: 'calc(100vh - 64px)' }}
      >
        <Card
          title={
            <Row gutter={[16, 16]} align="middle">
              <Col flex="1">
                <Text style={{ fontSize: '1.3rem' }}>Projects</Text>
                <div style={{ float: 'right' }}>
                  <Select defaultValue="Most recently updated" onChange={handleChange}>
                    <Option value="Most recently updated">Most recently updated</Option>
                    <Option value="Least recently updated">Least recently updated</Option>
                    <Option value="Alphabetical (A-Z)">Alphabetical (A-Z)</Option>
                    <Option value="Alphabetical (Z-A)">Alphabetical (Z-A)</Option>
                    <Option value="Most recently created">Most recently created</Option>
                    <Option value="Least recently created">Least recently created</Option>
                    <Option value="Total number of slides (Largest first)">
                      Total number of slides (Largest First)
                    </Option>
                    <Option value="Total number of slides (Smallest first)">
                      Total number of slides (Smallest First)
                    </Option>
                  </Select>
                  <Input
                    onChange={onChange}
                    style={{ width: '10rem' }}
                    placeholder="Search projects"
                  />
                </div>
              </Col>
            </Row>
          }
          style={{ margin: '24px 50px', maxHeight: '100%' }}
          bodyStyle={{
            overflowY: 'scroll',
            height: 'calc(100vh - 11rem)'
          }}
        >
          <div className="project-container">
            <div className="card__add">
              <div className="card-container" onClick={() => onCreateProject()}>
                <PlusSquareOutlined style={{ fontSize: 36 }} />
                <div>
                  <h1 style={{ fontWeight: 600 }}>Blank Project</h1>
                  <h3>Start from scratch</h3>
                </div>
              </div>
              {currentUser?.isStaff && (
                <>
                  <hr style={{ width: '80%', height: '1px' }} />
                  <div className="card-container" onClick={() => openAutoreport()}>
                    <DiffOutlined style={{ fontSize: 36 }} />
                    <div style={{ width: '50%', position: 'relative' }}>
                      <h1 style={{ fontWeight: 600 }}>Build with AI</h1>
                      <h3>Save time and create faster - let AI handle it.</h3>
                      <Button ghost={true} size="small" type="primary" className="card-button">
                        Beta
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
            {/* wrap this with DnDContext to make draggable again*/}
            <SortableContext items={storedValue} strategy={rectSortingStrategy}>
              {(
                chunk(
                  storedValue.filter(id =>
                    sortedProjects
                      .find(project => project?.id === Number(id))
                      ?.name?.includes(searchValue)
                  ),
                  10
                )[onPage - 1] || []
              ).map(id => {
                const project = sortedProjects.find(project => project?.id === Number(id))
                return project?.name?.includes(searchValue) ? (
                  <ProjectItem
                    key={project?.id}
                    item={project}
                    onDuplicate={id => setProjectToDuplicate(id)}
                    onDelete={onDeleteProject}
                  />
                ) : null
              })}
            </SortableContext>
          </div>
          <div className="pagination">
            <Pagination
              current={onPage}
              total={
                storedValue.filter(id =>
                  sortedProjects
                    .find(project => project?.id === Number(id))
                    ?.name?.includes(searchValue)
                ).length
              }
              pageSize={10}
              showSizeChanger={false}
              onChange={onSelectPage}
            />
          </div>
        </Card>
        <Autoreport visible={autoreportVisible} hide={() => setAutoreportVisible(false)} />
      </Layout>
    </Spin>
  )
}

export default withRouter(Projects)
