import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Pagination, Skeleton, Space, Spin, Tabs, Typography } from 'antd'

import { ExportOutlined, SelectOutlined } from '@ant-design/icons'
import i18n from 'i18next'
import { chunk } from 'lodash'

import { addSlidesToProject } from '@/store/slices/slides/thunks'
import { removeTemplate } from '@/store/slices/template/actions'
import { selectTemplateUploadProgress } from '@/store/slices/template/selectors'
import { AppDispatch } from '@/store/store'

import { uploadThumbnailFile } from '@/services/slide-service'
import { deleteProjectTemplate } from '@/services/template-service'

import { createThumbnail, dataURItoBlob } from '@/components/canvas/utils/createThumbnail'
import CommonModal from '@/components/common/CommonModal'
import { Flex } from '@/components/flex'

import { IShape } from '@/interfaces/shape'
import { TemplateDto } from '@/interfaces/template'

import './styles.less'

const { Title } = Typography
const { TabPane } = Tabs

interface Props {
  slides: object
  templates: TemplateDto[]
  projectId: number
  onComplete: () => void
  onCancel: () => void
  onRemove: (templateId: number) => void
  updateModalHeight: boolean
  setUpdateModalHeight: (updateModalHeight: boolean) => void
}

const SlideSelection = ({
  templates,
  projectId,
  onComplete,
  onCancel,
  slides,
  onRemove,
  updateModalHeight,
  setUpdateModalHeight
}: Props) => {
  const [selectedSlides, setSelectedSlides] = useState<{ id: number; thumbnail?: string }[]>([])
  const [addingSlides, setAddingSlides] = useState(false)
  const [thumbnails, setThumbnails] = useState<string[]>([])
  const [targetKey, setTargetKey] = useState<string>('')
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(true)
  const [templateId, setTemplateId] = useState<number>()
  const [onPage, setOnPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [loadingSelectAll, setLoadingSelectAll] = useState(false)
  const slideIds = useMemo(() => Object.keys(slides[templateId] || {}), [slides, templateId])
  const batchedSlides = useMemo(() => {
    return chunk(slides[templateId], 9)[onPage - 1] || []
  }, [slides, onPage, templateId])
  const dispatch: AppDispatch = useDispatch()
  const placeholders = Array(3).fill(null)
  const templateUploadProgress = useSelector(selectTemplateUploadProgress)

  const onSlideSelection = (slideIndex: number) => {
    let idx = selectedSlides.findIndex(item => item.id === slideIndex)

    if (idx === -1) {
      setSelectedSlides([
        ...selectedSlides,
        { id: slideIndex, thumbnail: thumbnails[slideIndex % 9] }
      ])
    } else {
      setSelectedSlides([...selectedSlides.filter(item => item.id !== slideIndex)])
    }
  }

  const onAddSlides = async () => {
    try {
      setAddingSlides(true)
      if (selectedSlides.length > 0) {
        let bucketObjectKey = {}
        for (let slide of selectedSlides) {
          let data = await uploadThumbnailFile({ projectId, file: dataURItoBlob(slide.thumbnail) })
          bucketObjectKey[slide.id] = data.key
        }

        await dispatch(
          addSlidesToProject({
            projectId,
            templateId,
            slideIndex: selectedSlides.map(x => x.id),
            thumbnails: bucketObjectKey
          })
        )
      }
      setDisabled(true)
      setAddingSlides(false)
      onComplete()
    } catch (error) {
      console.error(error.message)
    }
  }

  const addThumbs = useCallback(async () => {
    let thumbs = await Promise.all(
      Object.values(batchedSlides).map(async (slide: any) => {
        const shapes = Object.values(slide.shapes) as IShape[]
        try {
          return await createThumbnail(shapes, projectId, templateId)
        } catch (e) {
          console.error(e.message)
          return null
        }
      })
    )
    setThumbnails(thumbs)
  }, [projectId, batchedSlides, templateId])

  const onSelectAll = async () => {
    setLoadingSelectAll(true)
    const entries = []
    let i = 0
    for (const slide of Object.values(slides[templateId])) {
      const thumb = await createThumbnail((slide as any).shapes, projectId, templateId)
      entries.push({ id: i, thumbnail: thumb })
      i++
    }
    setSelectedSlides(entries)
    setLoadingSelectAll(false)
  }

  // const onSelectAll = () => {
  //   let entries = []
  //   let index = 0
  //   for (const _ of batchedSlides) {
  //     const slideIdx = index + 9 * (onPage - 1)
  //     entries.push({ id: slideIdx, thumbnail: thumbnails[index] })
  //     index += 1
  //   }
  //   setSelectedSlides([ ...selectedSlides, ...entries ])
  // }
  //
  const onRemoveAll = () => {
    setSelectedSlides([])
  }

  useEffect(() => {
    if (Object.values(batchedSlides || {}).length) {
      setLoading(true)
      addThumbs()
      setLoading(false)
    }
  }, [batchedSlides])

  useEffect(() => {
    if (templates) {
      //if there's an error in upload - templates will be null
      if (templates.length > 0) {
        setTemplateId(templates[0].id)
        setSelectedSlides([])
      }
    }
  }, [templates])

  useEffect(() => {
    let totalLength = 0
    templates?.forEach(template => {
      totalLength += selectedSlides.length
    })

    setDisabled(totalLength === 0)
  }, [selectedSlides, templates])

  const tip =
    slides[templateId]?.length > 0 && Object.keys(thumbnails).length === 0
      ? 'Preparing slides...'
      : 'Parsing the template...'

  const onChange = (key: string) => {
    setTemplateId(templates[key].id)
  }

  const onEdit = (targetKey: string, action: 'add' | 'remove') => {
    //TODO: Add modal before to warn users?
    if (action === 'remove') {
      setTargetKey(targetKey)
      setModalVisible(true)
    }
  }

  const onOk = () => {
    deleteProjectTemplate({ projectId: projectId, templateId: templates[targetKey].id })
    dispatch(removeTemplate(templates[targetKey].id))
    onRemove(templates[targetKey].id)
    setTargetKey('')
    setModalVisible(false)
  }
  return !templateUploadProgress ? (
    <>
      <CommonModal
        title="Delete this template?"
        onOk={() => onOk()}
        onCancel={() => setModalVisible(false)}
        okDisabled={false}
        visible={modalVisible}
      >
        This will remove the template - as well as all slides in the project that use the template.
        Are you sure you want to delete the template?
      </CommonModal>
      <div className="slide-selection-container">
        <Tabs
          hideAdd
          type="editable-card"
          defaultActiveKey={'0'}
          onEdit={onEdit}
          onChange={onChange}
        >
          {Object.keys(slides).map((key, index) => {
            return (
              <TabPane
                closable={true}
                tab={index + 1 + '. ' + templates[index]?.filename}
                key={index.toString()}
              >
                <div className="slide-selection-tools">
                  <Title style={{ fontWeight: 400 }} level={5}>
                    Select slides to add to your presentation
                  </Title>
                  <div>
                    <Button
                      onClick={() => onSelectAll()}
                      disabled={slides[templateId]?.length === selectedSlides.length}
                      className="slide-select-button"
                    >
                      {'Select all'} <SelectOutlined />
                    </Button>
                    <Button
                      onClick={() => onRemoveAll()}
                      disabled={selectedSlides.length === 0}
                      className="slide-select-button"
                    >
                      {'Remove all'} <ExportOutlined />
                    </Button>
                  </div>
                </div>

                {thumbnails.length > 0 ? (
                  <></>
                ) : (
                  <p style={{ color: 'red', fontSize: 'small' }}>
                    Heads up: we're creating your thumbnails in the background...
                    <span>
                      <Spin size="small" />
                    </span>
                  </p>
                )}
                <Spin size="large" spinning={loadingSelectAll}>
                  <div className="slide-thumbnail-container">
                    {slides[templateId]?.length > 0 ? (
                      Object.entries(batchedSlides).map((_, index) => {
                        const slideIdx = index + 9 * (onPage - 1)
                        const dataUrl = thumbnails[index]
                        return loading ? (
                          <Skeleton.Image />
                        ) : (
                          <>
                            <div
                              className={`slide-thumbnail ${
                                selectedSlides.find(slide => slide.id === slideIdx)
                                  ? 'selected'
                                  : ''
                              }`}
                              onClick={() => onSlideSelection(slideIdx)}
                              key={slideIdx}
                              style={{ position: 'relative' }}
                            >
                              {selectedSlides.find(slide => slide.id === slideIdx) ? (
                                <span style={{ position: 'absolute' }}>
                                  {selectedSlides.findIndex(slide => slide.id === slideIdx) + 1}
                                </span>
                              ) : (
                                <></>
                              )}
                              <img src={dataUrl} width={200} alt="slide thumbnail" />
                            </div>
                          </>
                        )
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                </Spin>
                <div className="pagination">
                  <Pagination
                    defaultCurrent={1}
                    total={slideIds.length}
                    pageSize={9}
                    showSizeChanger={false}
                    onChange={val => setOnPage(val)}
                  />
                </div>
              </TabPane>
            )
          })}
        </Tabs>

        <Flex
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
          style={{ marginTop: '2rem' }}
        >
          <Space size="small" direction="horizontal">
            <Button type="primary" onClick={onAddSlides} disabled={disabled} loading={addingSlides}>
              {i18n.t('editor.left-panel.slides.add-slides')}
            </Button>
            <Button onClick={onCancel} key="cancel">
              Cancel
            </Button>
          </Space>
        </Flex>
      </div>
    </>
  ) : (
    <div className="slide-selection-container">
      <Spin spinning={true} size="large" tip={tip}>
        <div className="slide-thumbnail-container">
          {placeholders.map((_, index) => (
            <Skeleton.Image key={index} style={{ width: '200px', height: '100px' }} />
          ))}
        </div>
      </Spin>
    </div>
  )
}

export default SlideSelection
