import React from 'react'

import { Flex } from '@components/flex'
import { Button } from 'antd'

import { updateOneCanvasObject } from '@/store/slices/shapes/actions'
import { resetShape } from '@/services/shape-service'
import { selectCurrentProjectId } from '@store/slices/projects/selectors'
import {
  selectCanvasSelectedObjectPk,
  selectCanvasSelectedObjectId
} from '@/store/slices/shapes/selectors'
import { useDispatch, useSelector } from 'react-redux'
import './EmptyElement.less'

import { WarningOutlined } from '@ant-design/icons'

interface NoElementSelectedProps {
  title: string
}

const ElementWithErorrs = ({ title }: NoElementSelectedProps) => {
  const dispatch = useDispatch()
  const projectId = useSelector(selectCurrentProjectId)
  const shapeId = Number(useSelector(selectCanvasSelectedObjectPk))
  const selectedObjectId = useSelector(selectCanvasSelectedObjectId)
  const onReset = () => {
    resetShape({ projectId, shapeId }).then(() => {
      dispatch(
        updateOneCanvasObject({
          id: selectedObjectId,
          changes: { data: null, visualSettings: null, errors: [] }
        })
      )
    })
  }
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      style={{
        width: '100%',
        minHeight: '20%',
        color: 'rgba(0, 0, 0, 0.45)',
        fontSize: '16px',
        padding: 16,
        rowGap: '1rem'
      }}
    >
      <WarningOutlined />
      <div className="title">{title}</div>
      <Button danger onClick={onReset}>
        Reset
      </Button>
    </Flex>
  )
}

export default ElementWithErorrs
