import { VisualSettings } from '@components/canvas/types/visualSettings'

import { IShapeData } from '@/interfaces/shape'

import { TextFrameDefinition } from '../../types/text'

export type FabricTableOptions = {
  id: number | string
  pk: number
  /**
   * Chart object definition
   * @type {TableDefinition}
   */
  table: TableDefinition
  /**
   * Data object
   * @type {IShapeData}
   */
  data?: IShapeData
  visualSettings?: VisualSettings
}

export interface TableDefinition {
  cells: TableCell[]
  rowsHeights: number[]
  columnsWidths: number[]
}

export interface CellVisualisation {
  background?: string
  color?: string
  tokens?: string
  coloredValue?: boolean
  position?: 'top' | 'bottom' | 'right' | 'left'
}

export interface TableCell {
  index: number
  text: TextFrameDefinition
  fill: string
  marginTop: number
  marginBottom: number
  marginLeft: number
  marginRight: number
}

export interface FabricTableOption {
  options?: any
  conditions?: any
  rowsHeights: number[]
  columnsWidths: number[]
  cells: any[]
}

export type TableProperties = {
  data: (number | string)[][]
  colWidths: number[]
  rowHeights: number[]
}

export type DataFormat = (number | string)[][]

export type DataGridData = { [key: string]: string | number }[] | DataFormat

type StyleProperty =
  | 'cellBackgroundColor'
  | 'cellBorderColor'
  | 'activeCellBorderColor'
  | 'activeCellOverlayBorderColor'
  | 'activeCellBackgroundColor'
  | 'cellHoverBackgroundColor'
  | 'cellHeight'
  | 'cellWidth'
  | 'columnHeaderCellHeight'
  | 'activeColumnHeaderCellBackgroundColor'
  | 'activeRowHeaderCellBackgroundColor'
  | 'columnHeaderCellBorderColor'
  | 'columnHeaderCellCapBorderColor'
  | 'columnHeaderCellBackgroundColor'
  | 'rowHeaderCellBackgroundColor'
  | 'columnHeaderCellHoverBackgroundColor'
  | 'rowHeaderCellHoverBackgroundColor'
  | 'cellFont'
  | 'activeCellFont'
  | 'columnHeaderCellFont'
  | 'rowHeaderCellFont'
  | 'gridBorderColor'

export type StyleOptions = Partial<{ [key in StyleProperty]: string }>

export type DataGridSchema = {
  name: string
  type?: string
  title?: string
  width?: number
  hidden?: boolean
  defaultValue?: string | number
  formatter?: Function
}[]

export type DataGridOptions = {
  /** 2D array or array of objects where keys are column names. */
  data: DataGridData

  /** Array of header objects. */
  schema?: DataGridSchema

  /** Node to append to */
  parentNode?: HTMLElement

  /** Color and dimensional options. */
  style: StyleOptions

  /** Cell values are read-only if set to `true`. */
  editable?: boolean

  allowSorting?: boolean

  allowColumnReordering?: boolean

  allowRowReordering?: boolean

  autoResizeRows?: boolean

  autoResizeColumns?: boolean

  autoGenerateSchema?: boolean

  selectionMode?: 'cell' | 'row'

  showRowHeaders?: boolean

  showColumnHeaders?: boolean

  /** Sizes of columns and rows. Defaults to style options if not provided */
  sizes?: { columns: number[]; rows: number[] }
}

/** Partial definition of the `canvasDataGrid` Event object. */
export type DataGridEvent = {
  cell: {
    value: string
    formattedValue: string
    height: number
    width: number
    rowIndex: number
    columnIndex: number
    x: number
    y: number
    [key: string]: any
  }
  ctx: CanvasRenderingContext2D
  header: {
    columnIndex: number
    index: number
    rowIndex: number
    name: string
    title: string
    type: string
  }
  /** Objects where keys are column names and values  */
  row: { [key: string]: string | number }
  value: string | number
  preventDefault: () => void
}

export type ConditionType = 'gt' | 'gte' | 'eq' | 'lt' | 'lte'
export type SymbolPosition = 'top' | 'left' | 'bottom' | 'right'
export type Symbol<T extends 'key' | 'value' = 'key'> = T extends 'key'
  ? keyof typeof VisualisationSymbols
  : VisualisationSymbols
export type VisualisationOptions =
  | 'symbols'
  | 'cell-background'
  | 'colored-labels'
  | 'symbols-colored-labels'
  | 'values-colored-labels'

export type TableFormatting = {
  color: string
  applyTo: 'value-color' | 'cell-background'
  selection: [[number, number], [number, number]]
  minLimitType: ConditionType
  minLimitValue: number
  maxLimitType?: ConditionType
  maxLimitValue?: number
}

export type IndexingVisualisation = {
  color: string
  symbol: Symbol
  visualise: VisualisationOptions
  thresholdType: Omit<ConditionType, 'eq'>
  thresholdValue: number
  position?: SymbolPosition
}

export type TableUserSettings = {
  showColHeaders?: boolean
  showRowHeaders?: boolean
  showCoordinates?: boolean
}

export type TableVisualSettings = {
  /** Conditional formatting that lives at `visualSettings.table.formatting` */
  table: {
    formatting: {
      conditions: TableFormatting[]
      useFormattedValues: boolean
    }
  } & TableUserSettings
  percentageIndexing: IndexingVisualisation[]
  significanceTest: {
    visualise: VisualisationOptions
    symbol: 'arrow' | 'pyramid'
    significantColor: string
    insignificantColor: string
  }
  numberFormat?: string
}

export type SigTestVisualisation<T = string> = T extends 'all_vs_all'
  ? SigTestAllvsAllVisualisation
  : SigTestCommonTypeVisualisation

export type SigTestCommonTypeVisualisation = {
  visualise: VisualisationOptions
  symbol: 'arrow' | 'pyramid'
  significantColor: string
  insignificantColor: string
  position?: SymbolPosition
}

export type SigTestAllvsAllVisualisation = {
  useBrackets: boolean
  useUppercase: boolean
}

export enum VisualisationSymbols {
  arrowUp = '↑',
  arrowDown = '↓',
  arrow = '↑',
  pyramidUp = '▲',
  pyramidDown = '▼',
  pyramid = '▲'
}
